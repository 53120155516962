import { Pipe, PipeTransform } from '@angular/core';
import { IResultSupplyItem } from 'src/app/data-models/result-supply-item/result-supply-item.interface';

@Pipe({
  name: 'resultSupplyValueCell',
  standalone: true,
})
export class ResultSupplyValueCellPipe implements PipeTransform {
  transform(key: string, day: number, mapKeyId: Map<string, IResultSupplyItem[]>): string {
    const value = mapKeyId.get(key + day)?.reduce((acc, item) => +item.volume + acc, 0);
    return value ? (Math.round((value / 1000) * 100) / 100).toString() : '';
  }
}
