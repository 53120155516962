import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IJournal } from '../../../../../data-models/enrichment-standard/journal.interface';
import { SvgIconsEnum } from '../../../../../core/enums/svg-icons.enum';
import { JournalProviderService } from '../../../../calendar-plan/services/journal-provider.service';

@Component({
  selector: 'documents-list-item',
  templateUrl: './documents-list-item.component.html',
  styleUrls: ['./documents-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsListItemComponent {
  @Input() document!: any;
  @Input() mapIds!: Map<number, boolean>;

  @Output() openModalItem = new EventEmitter<IJournal>();

  readonly svgIconsEnum = SvgIconsEnum;

  isSingleClick = true;

  constructor(
    private journalProviderService: JournalProviderService,
    private cdr: ChangeDetectorRef,
  ) {}

  toggleItem(): void {
    this.isSingleClick = true;
    setTimeout(() => {
      if (!this.isSingleClick) {
        return;
      }

      if (this.mapIds.get(+this.document.id)) {
        this.mapIds.set(+this.document.id, false);
      } else {
        this.mapIds.set(+this.document.id, true);
        this.journalProviderService.setNewValue(+this.document.id);
      }
      this.cdr.detectChanges();
    }, 250);
  }

  changeDocument(id: string) {
    this.isSingleClick = true;
    setTimeout(() => {
      if (!this.isSingleClick) {
        return;
      }

      this.mapIds.clear();
      this.mapIds.set(+id, true);
      this.cdr.markForCheck();
    }, 250);
  }

  openModal(): void {
    this.isSingleClick = false;
    this.openModalItem.emit(this.document);
  }
}
