<div class="modal-actions-root">
  <nguk-button matTooltip="Сохранить" type="primary" (click)="save.emit()">
    <mat-icon [svgIcon]="svgIconsEnum.Save"></mat-icon>
  </nguk-button>

  <ng-container>
    <!-- <nguk-button *ngIf="showCopy" matTooltip="Копировать" type="secondary" class="copy-button" (click)="copy.emit()">
      <mat-icon [svgIcon]="svgIconsEnum.Duplicate"></mat-icon>
    </nguk-button> -->

    <nguk-button
      *ngIf="isChange && showDelete"
      matTooltip="Удалить"
      type="secondary"
      class="delete-button"
      (click)="delete.emit()"
    >
      <mat-icon [svgIcon]="svgIconsEnum.Delete"></mat-icon> </nguk-button
  ></ng-container>
</div>
