<div class="nguk-input-ctrl-root" [class.-horizontal]="direction === 'horizontal'">
  <ng-content select="[label]" />

  <mat-form-field appearance="outline">
    <input
      matInput
      [type]="type"
      [step]="step"
      [min]="min"
      [max]="max"
      [formControl]="control"
      [placeholder]="placeholder"
      (focusout)="focusOut()"
    />
    <button mat-icon-button matSuffix (click)="clickIcon.emit()" *ngIf="matSuffix">
      <mat-icon [svgIcon]="matSuffix"></mat-icon>
    </button>
    <nguk-control-error [showError]="errorState">{{ error }}</nguk-control-error>
  </mat-form-field>
</div>
