import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { ModalBase } from '../../../ui/base/modal.base';
import { ButtonComponent } from '../../../ui/components/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { ModalComponent } from '../../../ui/components/modal/modal.component';
import { TextComponent } from '../../../ui/components/text/text.component';

@Component({
  selector: 'logout-confirmation-modal',
  templateUrl: './logout-confirmation-modal.component.html',
  styleUrls: ['./logout-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    MatIconModule,
    ModalComponent,
    TextComponent
  ]
})
export class LogoutConfirmationModalComponent extends ModalBase implements AfterViewInit {
  constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngAfterViewInit(): void {
    this.initModal();
  }
}
