<form [formGroup]="formData" class="form" *ngIf="formData">
  <div class="general-info">
    <div class="general-info__item">
      <div class="info-item__input">
        <nguk-autocomplete
          [control]="getControl('warehouse')"
          [options]="
            (dictionariesEnum.Warehouse
              | ngukDictionaryPipe: getControl('warehouse').value
              | async)!
          "
          [dictionary]="dictionariesEnum.Warehouse"
        >
          <ng-container label>
            <nguk-text color="grey" weight="500" styleName="text-sm">Склад</nguk-text>
          </ng-container>
        </nguk-autocomplete>
      </div>
    </div>

    <div class="general-info__item">
      <div class="info-item__input">
        <nguk-autocomplete
          (closed)="closedAutocomplete()"
          [control]="getControl('warehouseForOperatingPlan')"
          [options]="listWarehouseOP"
          [dictionary]="dictionariesEnum.WarehouseForOperatingPlan"
        >
          <ng-container label>
            <nguk-text color="grey" weight="500" styleName="text-sm">Склад ОП</nguk-text>
          </ng-container>
        </nguk-autocomplete>
      </div>
    </div>

    <div class="general-info__item">
      <div class="info-item__input">
        <nguk-autocomplete
          [control]="getControl('materialType')"
          [options]="
            (dictionariesEnum.MaterialType
              | ngukDictionaryPipe: getControl('materialType').value
              | async)!
          "
          [dictionary]="dictionariesEnum.MaterialType"
        >
          <ng-container label>
            <nguk-text color="grey" weight="500" styleName="text-sm">Тип</nguk-text>
          </ng-container>
        </nguk-autocomplete>
      </div>
    </div>

    <div class="general-info__item">
      <div class="info-item__input">
        <nguk-autocomplete
          [control]="getControl('mark')"
          [options]="
            (dictionariesEnum.Mark | ngukDictionaryPipe: getControl('mark').value | async)!
          "
          [dictionary]="dictionariesEnum.Mark"
        >
          <ng-container label>
            <nguk-text color="grey" weight="500" styleName="text-sm">Марка</nguk-text>
          </ng-container>
        </nguk-autocomplete>
      </div>
    </div>

    <div class="general-info__item">
      <div class="info-item__input">
        <nguk-autocomplete
          [control]="getControl('area')"
          [options]="
            (dictionariesEnum.Area | ngukDictionaryPipe: getControl('area').value | async)!
          "
          [dictionary]="dictionariesEnum.Area"
        >
          <ng-container label>
            <nguk-text color="grey" weight="500" styleName="text-sm">Участок</nguk-text>
          </ng-container>
        </nguk-autocomplete>
      </div>
    </div>

    <div class="general-info__item">
      <div class="info-item__input">
        <nguk-autocomplete
          [control]="getControl('layer')"
          [options]="
            (dictionariesEnum.Layer | ngukDictionaryPipe: getControl('layer').value | async)!
          "
          [dictionary]="dictionariesEnum.Layer"
        >
          <ng-container label>
            <nguk-text color="grey" weight="500" styleName="text-sm">Пласт</nguk-text>
          </ng-container>
        </nguk-autocomplete>
      </div>
    </div>

    <div class="general-info__item">
      <div class="info-item__input">
        <nguk-autocomplete
          [control]="getControl('profile')"
          [options]="
            (dictionariesEnum.Profile | ngukDictionaryPipe: getControl('profile').value | async)!
          "
          [dictionary]="dictionariesEnum.Profile"
        >
          <ng-container label>
            <nguk-text color="grey" weight="500" styleName="text-sm">Профиль</nguk-text>
          </ng-container>
        </nguk-autocomplete>
      </div>
    </div>

    <div class="general-info__item">
      <div class="info-item__input">
        <nguk-datepicker-ctrl [control]="getControl('dateAvailability')">
          <ng-container label>
            <nguk-text color="grey" weight="500" styleName="text-sm">Дата</nguk-text>
          </ng-container>
        </nguk-datepicker-ctrl>
      </div>
    </div>

    <ng-container *ngFor="let item of columns">
      <div class="general-info__item">
        <nguk-text color="grey" weight="500" styleName="text-sm">{{ item.display }}</nguk-text>
        <div class="info-item__input">
          <nguk-input-ctrl [control]="getControl(item.name)"></nguk-input-ctrl>
        </div>
      </div>
    </ng-container>
  </div>
</form>
