import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SvgIconsEnum } from '../../../../../core/enums/svg-icons.enum';
import { ButtonComponent } from '../../../../ui/components/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { NgForOf } from '@angular/common';
import { SectionComponent } from '../../../../ui/components/section/section.component';
import { TextComponent } from '../../../../ui/components/text/text.component';
import { EditNsiModalComponent } from '../../modals/edit-nsi-modal/edit-nsi-modal.component';
import { finalize, forkJoin, take } from 'rxjs';
import { NsiService } from '../../../services/nsi.service';
import { ActionModal } from '../../../../ui/base/action-modal';
import { NsiDataInstanceItemComponent } from '../nsi-data-instance-item/nsi-data-instance-item.component';
import { IDataInstance } from '../../../../../data-models/enrichment-standard/data-instance.interface';
import { NsiDictionaryModel } from '../../../../../data-models/dictionary/dictionary.model';
import { Utils } from '../../../../../helpers/utils';
import { SpinnerService } from '../../../../ui/services/spinner.service';
import { CheckCalculationCompletePipe } from '../../../../data/pipes/check-calculation-complete.pipe';
import { ICalculationLog } from '../../../../../data-models/calculation-log/calculation-log.interface';
import { Router } from '@angular/router';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';

@Component({
  selector: 'nsi-data-instances',
  templateUrl: './nsi-data-instances.component.html',
  styleUrls: ['./nsi-data-instances.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    MatIconModule,
    NgForOf,
    SectionComponent,
    TextComponent,
    NsiDataInstanceItemComponent,
    CheckCalculationCompletePipe,
  ],
  providers: [CheckCalculationCompletePipe],
})
export class NsiDataInstancesComponent extends ActionModal implements OnInit {
  @ViewChild('editNSIModalTplRef', { read: ViewContainerRef })
  editNSIModalTplRef!: ViewContainerRef;

  readonly utils = Utils;
  readonly svgIconsEnum = SvgIconsEnum;

  calculationLogMap = new Map<string, IJournal>();
  dataInstances: IDataInstance[] = [];
  dataInstancesOpenedState: Map<string, boolean> = new Map([]);
  dataInstancesDictionaries: Map<string, NsiDictionaryModel[]> = new Map([]);

  constructor(
    private router: Router,
    private ss: SpinnerService,
    private cdr: ChangeDetectorRef,
    private nsiService: NsiService,
    private checkCalculationCompletePipe: CheckCalculationCompletePipe,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getDataInstances();
  }

  handleOpenStateChanged(dataInstance: IDataInstance): void {
    const isDataInstanceOpened = this.dataInstancesOpenedState.get(dataInstance.id!);
    if (isDataInstanceOpened) {
      this.dataInstancesOpenedState.set(dataInstance.id!, false);
      this.cdr.markForCheck();
      return;
    }

    this.ss.startSpinner();
    this.nsiService
      .getDictionariesByDataInstance(dataInstance)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((dictionaries: NsiDictionaryModel[]) => {
        this.dataInstancesDictionaries.set(dataInstance.id!, dictionaries);
        this.dataInstancesOpenedState.set(dataInstance.id!, true);
        this.cdr.markForCheck();
      });
  }

  openEditNSI(dataInstance: IDataInstance): void {
    this.dataInstancesOpenedState.set(dataInstance.id!, false);
    this.modalContainer = this.editNSIModalTplRef;
    this.ss.startSpinner();
    this.nsiService
      .getDictionariesByDataInstance(dataInstance)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((dictionaries: NsiDictionaryModel[]) => {
        const modalRef = this.open(EditNsiModalComponent);
        this.dataInstancesDictionaries.set(dataInstance.id!, dictionaries);
        modalRef.instance.dataInstance = dataInstance;
        modalRef.instance.dictionaries = dictionaries;
        modalRef.instance.isCalculationComplete = this.checkCalculationCompletePipe.transform(
          dataInstance,
          this.calculationLogMap,
        );
        modalRef.instance.openSubject.pipe(take(1)).subscribe((reload: any) => {
          if (!reload) {
            this.cdr.detectChanges();
            return;
          }

          this.getDataInstances(true);
        });
        this.cdr.markForCheck();
      });
  }

  createNewNSI(): void {
    this.modalContainer = this.editNSIModalTplRef;
    const modalRef = this.open(EditNsiModalComponent);
    modalRef.instance.openSubject.pipe(take(1)).subscribe((reload: any) => {
      if (!reload) {
        this.cdr.detectChanges();
        return;
      }

      this.getDataInstances(true);
    });
    this.cdr.markForCheck();
  }

  getDataInstances(isAfterModal?: boolean): void {
    this.ss.startSpinner();
    forkJoin([this.nsiService.getDataInstances(), this.nsiService.getCalculationLogs()])
      .pipe(take(1))
      .subscribe(([dataInstances, calculationLogs]: [IDataInstance[], IJournal[]]) => {
        this.calculationLogMap.clear();
        calculationLogs.forEach((item) => this.calculationLogMap.set(item.id, item));
        this.dataInstances = dataInstances.sort(
          (lhs: IDataInstance, rhs: IDataInstance) =>
            new Date(rhs.dateCreated).getTime() - new Date(lhs.dateCreated).getTime(),
        );
        dataInstances.forEach((dataInstance: IDataInstance) => {
          this.dataInstancesOpenedState.set(dataInstance.id!, false);
          this.dataInstancesDictionaries.set(dataInstance.id!, []);
        });

        if (
          isAfterModal ||
          (!this.router.url.includes('list') && !this.router.url.includes('matrix'))
        ) {
          this.ss.stopSpinner();
        }

        if (this.router.url.includes('list') || this.router.url.includes('matrix')) {
          const openedDataInstanceId = this.router.url.match(/\d+/)?.[0];
          this.nsiService
            .getDictionariesByDataInstance({ id: openedDataInstanceId } as any)
            .pipe(take(1), finalize(this.ss.stopSpinner))
            .subscribe((dictionaries: NsiDictionaryModel[]) => {
              this.dataInstancesDictionaries.set(+openedDataInstanceId! as any, dictionaries);
              this.dataInstancesOpenedState.set(+openedDataInstanceId! as any, true);
              this.cdr.markForCheck();
            });
        }
        this.cdr.markForCheck();
      }, this.ss.stopSpinner);
  }
}
