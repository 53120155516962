import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from '../forms/input/input.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../button/button.component';
import { TextComponent } from '../text/text.component';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { CheckboxComponent } from 'src/app/modules/forms/components/checkbox/checkbox.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'nguk-overlay-filtering',
  templateUrl: './overlay-filtering.component.html',
  styleUrls: ['./overlay-filtering.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    MatIconModule,
    ButtonComponent,
    TextComponent,
    CheckboxComponent,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
})
export class OverlayFilteringComponent implements OnInit {
  @Output() toClose = new EventEmitter<void>();
  @Output() filtered = new EventEmitter<string[]>();

  @Input() filterData!: string[];
  @Input() column!: string;

  currentCheckboxes!: { name: string; index: number }[];
  filteredCheckbox!: { name: string; index: number }[];

  formData!: FormGroup;

  readonly svgIconsEnum = SvgIconsEnum;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.currentCheckboxes = this.filterData.map((item, index) => ({
      name: item,
      index,
    }));

    this.formData = this.fb.group({
      search: [null],
      general: [false],
      checkboxes: this.fb.array(
        this.filterData.map((item) => {
          return this.fb.group({
            name: item,
            checked: this.getCheckedValue(item),
          });
        }),
      ),
    });

    this.onFilterValues(null);

    this.getFormControl('search').valueChanges.subscribe((res) => this.onFilterValues(res));
    this.getFormControl('general').valueChanges.subscribe((res) => {
      this.filteredCheckbox.forEach((item) => {
        (this.formArray.controls[item.index].get('checked') as FormControl).setValue(res);
      });
    });
  }

  voidCurrentFilter() {
    const query = { ...this.route.snapshot.queryParams };
    delete query[this.column];

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: query,
    });

    this.close();
  }

  sendFiltered(): void {
    const result: string[] = this.formArray.value
      .filter((item: { name: string; checked: boolean }) => item.checked)
      .map((item: { name: string; checked: boolean }) => item.name);

    const query = { ...this.route.snapshot.queryParams };

    delete query[this.column];

    if (result.length > 0 && result.length < this.filterData.length) {
      query[this.column] = result.toString();
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: query,
    });

    this.close();
  }

  getCheckedValue(name: string): boolean {
    const query = this.route.snapshot.queryParams;

    if (query[this.column] === '' && name === '') return true;
    if (!query[this.column]) return false;
    return (query[this.column] as string).split(',').includes(name);
  }

  someComplete(): boolean {
    const amountTrueValues: boolean[] = [];

    this.filteredCheckbox.forEach((item) => {
      const value = !!this.formArray.controls[item.index].get('checked')?.value;
      if (value) amountTrueValues.push(true);
    });

    return amountTrueValues.length > 0 && amountTrueValues.length < this.filteredCheckbox.length;
  }

  close(): void {
    this.toClose.emit();
  }

  getControlFormArray(index: number): FormControl {
    return (this.formArray.controls[index] as FormGroup).get('checked') as FormControl;
  }

  getFormControl(value: string): FormControl {
    return this.formData.get(value) as FormControl;
  }

  onFilterValues(value: string | null): void {
    if (!value) {
      this.filteredCheckbox = [...this.currentCheckboxes];
      this.cdr.detectChanges();
      return;
    }

    this.filteredCheckbox = this.currentCheckboxes.filter((item) =>
      item.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
    );

    this.cdr.detectChanges();
  }

  get formArray(): FormArray {
    return this.formData.get('checkboxes') as FormArray;
  }
}
