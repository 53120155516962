import { Pipe, PipeTransform } from '@angular/core';
import { IResultMiningPlanItemTransform } from 'src/app/data-models/result-mining-plan-item/result-mining-plan-item.interface';

@Pipe({
  name: 'resultMiningPlanHorizontalLine',
  standalone: true,
})
export class ResultMiningPlanHorizontalLinePipe implements PipeTransform {
  transform(
    row: IResultMiningPlanItemTransform,
    index: number,
    data: IResultMiningPlanItemTransform[],
  ): boolean {
    if (index === 0) return !row.area.id;
    return data[index].area.id === data[index - 1].area.id;
  }
}
