import { IDocument } from 'src/app/data-models/document/document.interface';

export const dataDocuments: IDocument[] = [
  {
    id: '1',
    dateCreated: '2024-01-18T21:25:37+00:00',
    datePlanStarted: '2023-10-31T21:00:00+00:00',
    datePlanFinished: '2023-11-30T00:00:00+00:00',
    maxDuration: 600,
    note: 'Журнал 1',
    status: 1,
    dataInstances: [
      {
        id: 159,
        type: 'resourceAvailability',
      },
      {
        id: 1,
        type: 'shippingPlan',
      },
      {
        id: 2,
        type: 'warehouseBalance',
      },
      {
        id: 3,
        type: 'miningPlan',
      },
      {
        id: 4,
        type: 'enrichmentStandard',
      },
    ],
    productionPlans: [],
  },

  {
    id: '2',
    dateCreated: '2024-01-28T21:25:37+00:00',
    datePlanStarted: '2023-12-01T21:00:00+00:00',
    datePlanFinished: '2023-12-30T00:00:00+00:00',
    maxDuration: 660,
    note: 'Журнал 2',
    status: 2,
    dataInstances: [
      {
        id: 159,
        type: 'resourceAvailability',
      },
      {
        id: 1,
        type: 'shippingPlan',
      },
      {
        id: 2,
        type: 'warehouseBalance',
      },
      {
        id: 3,
        type: 'miningPlan',
      },
      {
        id: 4,
        type: 'enrichmentStandard',
      },
    ],
    productionPlans: [],
  },
];
