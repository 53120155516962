<div class="modal-table">
  <ng-template #smallTable>
    <div class="modal-table-easy">
      <div class="modal-table-easy__item" *ngFor="let item of listRow">
        <nguk-text weight="400" styleName="text-sm">{{ item.display }}</nguk-text>
        <div
          class="container-value"
          [attr.role]="'none'"
          (click)="selectValue(item.display, item.value)"
          [class.active-value]="checkActiveValue(item.display, item.value)"
        >
          {{ item.value }}
        </div>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="dataSource; else smallTable">
    <div class="modal-table-header">
      <nguk-text weight="600" styleName="text-xs" color="grey">Приёмник</nguk-text>
    </div>
    <div class="modal-table-main">
      <nguk-text weight="600" styleName="text-xs" class="source-label">Источник</nguk-text>

      <div class="modal-table-main__table">
        <table mat-table [dataSource]="dataSource">
          <ng-container *ngFor="let item of columns; let i = index" [matColumnDef]="item.name">
            <th
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              [class.area-header]="item.name === 'area'"
            >
              <nguk-text weight="400" styleName="text-xs" color="grey" class="opacity-text">{{
                item.display
              }}</nguk-text>
            </th>
            <td mat-cell *matCellDef="let row; let i = index">
              <div
                class="container-value"
                [class.select-item]="item.name === 'type'"
                [class.active-value]="checkActiveValue(item.name, row[item.name])"
                [class.cursor-pointer]="item.name !== 'type' && row[item.name]"
                [attr.role]="'none'"
                (click)="selectValue(item.name, row[item.name])"
              >
                <nguk-text weight="400" styleName="text-sm">
                  {{ row[item.name] }}
                </nguk-text>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </ng-container>
</div>
