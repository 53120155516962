import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { ModalBase } from '../../../../ui/base/modal.base';
import { ITaskLog } from '../../../../../data-models/task-log/task-log.interface';
import { FormControl } from '@angular/forms';
import { CalculationsService } from '../../../services/calculations.service';
import { finalize, take } from 'rxjs';
import { SpinnerService } from '../../../../ui/services/spinner.service';

@Component({
  selector: 'calculations-edit-calculation-modal',
  templateUrl: './edit-calculation-modal.component.html',
  styleUrls: ['./edit-calculation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCalculationModalComponent extends ModalBase implements AfterViewInit {
  rowIndex!: number;
  calculation!: ITaskLog;
  noteControl = new FormControl<string | null>(null);

  constructor(
    cdr: ChangeDetectorRef,
    private ss: SpinnerService,
    private calculationsService: CalculationsService,
  ) {
    super(cdr);
  }

  ngAfterViewInit(): void {
    this.noteControl.patchValue(this.calculation?.calculationLog?.note ?? '');
    this.initModal();
  }

  saveCalculation(): void {
    this.ss.startSpinner();
    this.calculationsService
      .updateTaskLog(this.calculation, this.noteControl)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(this.handleSaveSuccess, this.handleSaveError);
  }

  private handleSaveSuccess = (): void => {
    this.closeModal(true);
  };

  private handleSaveError = (): void => {};
}
