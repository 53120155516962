<form [formGroup]="formData" class="form">
  <div class="container-item">
    <div class="form-item">
      <nguk-text color="grey" weight="500" styleName="text-sm">Дата</nguk-text>
      <div class="form-item__input">
        <nguk-datepicker-ctrl [control]="getControl('date')" [min]="minDate"></nguk-datepicker-ctrl>
      </div>
    </div>

    <div class="form-item">
      <div class="form-item__input">
        <nguk-autocomplete
          [control]="getControl('mark')"
          [options]="
            (dictionariesEnum.Mark | ngukDictionaryPipe: getControl('mark').value | async)!
          "
          [dictionary]="dictionariesEnum.Mark"
        >
          <ng-container label>
            <nguk-text color="grey" weight="500" styleName="text-sm">Марка</nguk-text>
          </ng-container>
        </nguk-autocomplete>
      </div>
    </div>

    <div class="form-item">
      <div class="form-item__input">
        <nguk-autocomplete
          [control]="getControl('area')"
          [options]="
            (dictionariesEnum.Area | ngukDictionaryPipe: getControl('area').value | async)!
          "
          [dictionary]="dictionariesEnum.Area"
        >
          <ng-container label>
            <nguk-text color="grey" weight="500" styleName="text-sm">Участок</nguk-text>
          </ng-container>
        </nguk-autocomplete>
      </div>
    </div>

    <div class="form-item">
      <div class="form-item__input">
        <nguk-autocomplete
          [control]="getControl('layer')"
          [options]="
            (dictionariesEnum.Layer | ngukDictionaryPipe: getControl('layer').value | async)!
          "
          [dictionary]="dictionariesEnum.Layer"
        >
          <ng-container label>
            <nguk-text color="grey" weight="500" styleName="text-sm">Пласт</nguk-text>
          </ng-container>
        </nguk-autocomplete>
      </div>
    </div>

    <div class="form-item">
      <div class="form-item__input">
        <nguk-autocomplete
          [control]="getControl('profile')"
          [options]="
            (dictionariesEnum.Profile | ngukDictionaryPipe: getControl('profile').value | async)!
          "
          [dictionary]="dictionariesEnum.Profile"
        >
          <ng-container label>
            <nguk-text color="grey" weight="500" styleName="text-sm">Профиль</nguk-text>
          </ng-container>
        </nguk-autocomplete>
      </div>
    </div>

    <div class="form-item">
      <nguk-text color="grey" weight="500" styleName="text-sm">Ad</nguk-text>
      <div class="form-item__input">
        <nguk-input-ctrl [control]="getControl('ad')"></nguk-input-ctrl>
      </div>
    </div>

    <div class="form-item">
      <nguk-text color="grey" weight="500" styleName="text-sm">Vd</nguk-text>
      <div class="form-item__input">
        <nguk-input-ctrl [control]="getControl('vd')"></nguk-input-ctrl>
      </div>
    </div>

    <div class="form-item">
      <nguk-text color="grey" weight="500" styleName="text-sm">Y</nguk-text>
      <div class="form-item__input">
        <nguk-input-ctrl [control]="getControl('y')"></nguk-input-ctrl>
      </div>
    </div>

    <div class="form-item">
      <nguk-text color="grey" weight="500" styleName="text-sm">Объем</nguk-text>
      <div class="form-item__input">
        <nguk-input-ctrl [control]="getControl('volume')"></nguk-input-ctrl>
      </div>
    </div>
  </div>

  <modal-mining-plan-form-table
    [isShow]="isShow"
    [formData]="formData"
    [data]="data"
    (setArrayListMiningPlanVolume)="setArrayListMiningPlanVolume.emit($event)"
    (setRemoveListIds)="setRemoveListIds.emit($event)"
  />
</form>
