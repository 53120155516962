<div class="data-instances-list-short-root">
  <nguk-section>
    <ng-container sectionTitle>Экземпляры данных</ng-container>
    <ng-container sectionActions>
      <nguk-button type="primary" class="create-button" (click)="openModal()">
        <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
      </nguk-button>
    </ng-container>

    <upload-file-button
      class="upload-file-button"
      *ngIf="dataInstances?.length"
      (upload)="openModalUpload()"
    ></upload-file-button>

    <div class="data-instances-list">
      <nguk-sidebar-list-item
        [class.data-instance-item-active]="
          currentDataInstanceId && dataInstance.id?.toString() === currentDataInstanceId.toString()
        "
        *ngFor="let dataInstance of dataInstances; let index = index; let last = last"
        [data]="dataInstance"
        [mapIds]="mapIds"
        [indexData]="index"
        [lastData]="last"
        [isCalculationComplete]="dataInstance | checkCalculationComplete: journalMap : journalOPMap"
        (onToggleItem)="selectDataInstance(dataInstance)"
        (openModalItem)="openModal(dataInstance)"
      />
    </div>
  </nguk-section>
</div>

<ng-template #modal></ng-template>
