<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <nguk-text class="modal-title" weight="600" styleName="text-xl">Отмена расчёта</nguk-text>

    <nguk-text class="confirmation-text">
      Вы действительно отменить расчёт? Все полученные данные будут безвозвратно утеряны.
    </nguk-text>

    <div class="actions">
      <nguk-button type="primary" (click)="closeModal(false)">
        <nguk-text weight="400" styleName="text-sm">Продолжить расчёт</nguk-text>
      </nguk-button>

      <nguk-button type="secondary" (click)="closeModal(true)">
        <nguk-text weight="400" styleName="text-sm">Отменить расчёт</nguk-text>
      </nguk-button>
    </div>
  </div>
</nguk-modal>
