<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header">
      <div class="modal-header-info">
        <nguk-text weight="600" styleName="text-xl">{{
          !journal || (journal && !journal.id)
            ? 'Создание нового журнала'
            : (toggleControl.value === 'Редактирование' ? 'Редактирование Р ' : 'Копирование Р ') +
              journal.id
        }}</nguk-text>

        <nguk-toggle
          [toggleList]="toggleList"
          [control]="toggleControl"
          *ngIf="journal && journal.id"
        ></nguk-toggle>
      </div>

      <calendar-plan-modal-journal-list-short-actions
        class="modal-journal-list-short-actions"
        [isCopy]="isCopy"
        [formData]="formData"
        [isOperationalPlan]="isOperationalPlan"
        (closeModal)="updateData()"
      ></calendar-plan-modal-journal-list-short-actions>
    </div>

    <calendar-plan-modal-journal-list-short-parameters
      [isHasCalculated]="isHasCalculated"
      [mapDataInstance]="mapDataInstance"
      [journal]="journal"
      [isOperationalPlan]="isOperationalPlan"
      (setFormData)="getFormData($event)"
    ></calendar-plan-modal-journal-list-short-parameters>

    <calendar-plan-modal-journal-list-short-note
      [formData]="formData"
    ></calendar-plan-modal-journal-list-short-note>

    <calendar-plan-modal-journal-list-short-row-data
      [isHasCalculated]="isHasCalculated"
      [formData]="formData"
      [isOperationalPlan]="isOperationalPlan"
      (chooseDataInstances)="chooseDataInstances($event)"
    ></calendar-plan-modal-journal-list-short-row-data>

    <calendar-plan-modal-journal-list-short-print
      *ngIf="journal && journal.id"
      [journal]="journal"
      [isOperationalPlan]="isOperationalPlan"
    ></calendar-plan-modal-journal-list-short-print>

    <calendar-plan-modal-journal-list-short-actions-footer
      [journal]="journal"
      [isCopy]="isCopy"
      [formData]="formData"
      [isOperationalPlan]="isOperationalPlan"
      (isFulfilled)="changeStatusModal($event)"
    />
  </div>
</nguk-modal>
