import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalJournalListShortPrintHeaderComponent } from '../modal-journal-list-short-print-header/modal-journal-list-short-print-header.component';
import { ModalJournalListShortPrintListComponent } from '../modal-journal-list-short-print-list/modal-journal-list-short-print-list.component';
import { IEditJournal, IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'calendar-plan-modal-journal-list-short-print',
  templateUrl: './modal-journal-list-short-print.component.html',
  styleUrls: ['./modal-journal-list-short-print.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalJournalListShortPrintHeaderComponent,
    ModalJournalListShortPrintListComponent,
  ],
})
export class ModalJournalListShortPrintComponent {
  @Input() journal!: IJournal | IEditJournal;
  @Input() isOperationalPlan = false;

  formCheckbox!: FormGroup;

  getFormGroup(fg: FormGroup): void {
    this.formCheckbox = fg;
  }
}
