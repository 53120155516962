import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SpinnerEventModel } from '../models/spinner-event.model';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  spinner$: BehaviorSubject<SpinnerEventModel> = new BehaviorSubject<SpinnerEventModel>(
    new SpinnerEventModel(false),
  );

  get loading(): boolean {
    return this.spinner$.getValue().show;
  }

  private defaultConfig = new SpinnerEventModel(true);

  startSpinner = (config?: any): void => {
    if (config instanceof SpinnerEventModel) {
      this.spinner$.next(config);
    } else {
      if (this.spinner$.getValue().show) {
        return;
      }

      this.spinner$.next(this.defaultConfig);
    }
  };

  stopSpinner = (): void => {
    this.spinner$.next(new SpinnerEventModel(false));
  };
}
