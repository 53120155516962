import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, take } from 'rxjs';
import { JournalApiService } from 'src/app/api/journal.api.service';
import { OperationalPlanJournalApiService } from 'src/app/api/operational-plan-journal.api.service';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';

@Injectable({
  providedIn: 'root',
})
export class CheckFinishCaluclationService {
  constructor(
    private journalApiService: JournalApiService,
    private operationalPlanJournalApiService: OperationalPlanJournalApiService,
  ) {}

  getIsShow(dataInstance: IDataInstance): Observable<boolean> {
    return forkJoin([this.getJournals(), this.getJournalsOP()]).pipe(
      take(1),
      map(([journal, journalOP]) => {
        const journalMap = new Map<string, boolean>();
        journal.forEach((item) => {
          journalMap.set(item.id, !!item.productionPlans.length);
        });

        const journalOPMap = new Map<string, boolean>();
        journalOP.forEach((item) => {
          journalOPMap.set(item.id, !!item.productionPlans.length);
        });

        return (
          dataInstance.operationalPlan.some((item) => journalMap.get(item.id)) ||
          dataInstance.operationalPlan.some((item) => journalOPMap.get(item.id))
        );
      }),
    );
  }

  getJournals(): Observable<IJournal[]> {
    return this.journalApiService.getList(1, [{ name: 'itemsPerPage', value: 500 * 1000 }]);
  }

  getJournalsOP(): Observable<IJournal[]> {
    return this.operationalPlanJournalApiService.getList(1, [
      { name: 'itemsPerPage', value: 500 * 1000 },
    ]);
  }
}
