import { Route } from '@angular/router';
import { RoutesHelper } from '../../helpers/routes.helper';
import { NotFoundPageComponent } from '../not-found/components/pages/not-found-page/not-found-page.component';
import { AuthorizedLayoutComponent } from '../layout/components/authorized-layout/authorized-layout.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { CalendarPlanModule } from '../calendar-plan/calendar-plan.module';
import { CalculationsModule } from '../calculations/calculations.module';
import { dataRoutes } from '../data/components/pages/raw-data/routes';
import { NsiModule } from '../nsi/nsi.module';
import { PrintComponent } from '../print/pages/print/print.component';
import { AuthGuard } from '../../core/guards/auth.guard';

export const routes: Route[] = [
  {
    path: RoutesHelper.EMPTY_PATH,
    component: AuthorizedLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: RoutesHelper.EMPTY_PATH,
        pathMatch: 'full',
        redirectTo: RoutesHelper.DASHBOARD_PATH,
      },
      {
        path: RoutesHelper.DATA_PATH,
        loadChildren: () => dataRoutes,
      },
      {
        path: RoutesHelper.DASHBOARD_PATH,
        loadChildren: () => DashboardModule,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN,
        loadChildren: () => CalendarPlanModule,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_URL,
        loadChildren: () => CalendarPlanModule,
      },
      {
        path: RoutesHelper.DATA_OPERATIONAL_PLAN,
        loadChildren: () => CalendarPlanModule,
        data: {
          isOperationalPlan: true,
        },
      },
      {
        path: RoutesHelper.DATA_OPERATIONAL_PLAN_URL,
        loadChildren: () => CalendarPlanModule,
        data: {
          isOperationalPlan: true,
        },
      },
      {
        path: RoutesHelper.NSI_PATH,
        loadChildren: () => NsiModule,
      },
      {
        path: RoutesHelper.CALCULATIONS_PATH,
        loadChildren: () => CalculationsModule,
      },
    ],
  },
  {
    path: RoutesHelper.PRINT_PATH,
    loadComponent: () => PrintComponent,
    canActivate: [AuthGuard],
  },
  {
    path: RoutesHelper.PRINT_URL,
    loadComponent: () => PrintComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    loadComponent: () => NotFoundPageComponent,
  },
];
