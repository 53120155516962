import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import { IResultSupplyItem } from '../data-models/result-supply-item/result-supply-item.interface';

@Injectable({
  providedIn: 'root',
})
export class ResultSupplyItemApiService extends EntityApi<IResultSupplyItem, IResultSupplyItem> {
  protected readonly api = 'result/supplyItem';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
