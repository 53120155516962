import { Injectable, isDevMode } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorCodeEnum } from '../enums/error-code.enum';

@Injectable({
  providedIn: 'root',
})
export class ValidationErrorService {
  getValidationErrorMessage(control: FormControl): string | null {
    if (!control.errors) {
      return null;
    }

    if (control.hasError(ErrorCodeEnum.Required)) {
      return 'Обязательное поле';
    }

    if (control.hasError(ErrorCodeEnum.MatDatepickerParse)) {
      return 'Формат должен быть ДД/ММ/ГГГГ';
    }

    if (control.hasError(ErrorCodeEnum.MatDatepickerMin)) {
      return 'Выбрана дата ранее допустимой';
    }

    if (control.hasError(ErrorCodeEnum.MatDatepickerMax)) {
      return 'Выбрана дата позже допустимой';
    }

    if (control.hasError(ErrorCodeEnum.Min)) {
      return `Не меньше ${control.errors['min'].min}`;
    }
    if (control.hasError(ErrorCodeEnum.DatePlanning)) {
      return 'Значение должно быть в пределах [Min; Max]';
    }

    if (isDevMode()) {
      console.warn('Неизвестная валидационная ошибка', control.errors);
    }

    return null;
  }
}
