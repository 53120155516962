import { Component, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'nguk-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  standalone: true,
  imports: [CommonModule, TextComponent],
})
export class CardComponent {
  @HostBinding('class.nguk-card') private baseClass = true;
}
