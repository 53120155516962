import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operationalPlanColorShift',
  standalone: true,
})
export class OperationalPlanColorShiftPipe implements PipeTransform {
  transform(index: number): boolean {
    return !!(Math.floor((index - 1) / 12) % 2);
  }
}
