import { ChangeDetectorRef, ComponentRef, Injectable, Type } from '@angular/core';
import { takeWhile } from 'rxjs';
import { IJournal, IEditJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { PopupComponent } from 'src/app/modules/ui/components/popup/popup.component';
import { ModalChooseDataInstancesComponent } from '../../components/parts/modal-choose-data-instances/modal-choose-data-instances/modal-choose-data-instances.component';
import { ModalJournalListShortComponent } from '../../components/parts/modal-journal-list-short/modal-journal-list-short/modal-journal-list-short.component';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import * as moment from 'moment';

type TModals = ModalJournalListShortComponent | ModalChooseDataInstancesComponent | PopupComponent;

@Injectable({
  providedIn: 'root',
})
export class JournalListShortModalsActionsService {
  open!: (comp: Type<TModals>) => ComponentRef<TModals>;

  readonly svgIconsEnum = SvgIconsEnum;

  constructor(private cdr: ChangeDetectorRef) {}

  openModalJournal(
    mapDataInstance: Map<string, IDataInstance>,
    journal?: IJournal | IEditJournal,
    isCopy = false,
  ): void {
    let isAlive = true;
    let isAliveTurn = true;
    const modalComponent = this.open(
      ModalJournalListShortComponent,
    ) as ComponentRef<ModalJournalListShortComponent>;

    this.cdr.markForCheck();
    modalComponent.instance.mapDataInstance = mapDataInstance;

    if (journal) modalComponent.instance.journal = journal;
    modalComponent.instance.isCopy = isCopy;

    modalComponent.instance.openNextModalSubject.pipe(takeWhile(() => isAlive)).subscribe(() => {
      isAliveTurn = false;
      const type = modalComponent.instance.currentTypeDataInstances;
      const currentJournal = modalComponent.instance.formData.value as IEditJournal;

      currentJournal.datePlanStarted = moment(currentJournal.datePlanStarted)
        .utcOffset(0, true)
        .format();

      const copy = modalComponent.instance.isCopy;

      this.openModalChooseDataInstances(mapDataInstance, type, currentJournal, copy);

      this.cdr.markForCheck();
      isAliveTurn = true;
    });

    modalComponent.instance.statusClosedSubject
      .pipe(takeWhile(() => isAlive))
      .subscribe((status) => {
        isAliveTurn = false;
        status === 'success' ? this.openSuccessModal() : this.openErrorModal();

        this.cdr.markForCheck();
        isAliveTurn = true;
      });

    modalComponent.instance.openSubject.pipe(takeWhile(() => isAlive)).subscribe(() => {
      setTimeout(() => {
        if (isAliveTurn) isAlive = false;
      }, 500);
    });
  }

  openModalChooseDataInstances(
    mapDataInstance: Map<string, IDataInstance>,
    type: string,
    journal: IEditJournal,
    isCopy = false,
  ): void {
    let isAlive = true;
    let isAliveTurn = true;

    const modalComponent = this.open(
      ModalChooseDataInstancesComponent,
    ) as ComponentRef<ModalChooseDataInstancesComponent>;
    modalComponent.instance.journal = journal;
    modalComponent.instance.typeDataInstances = type;

    modalComponent.instance.openNextModalSubject.pipe(takeWhile(() => isAlive)).subscribe(() => {
      isAliveTurn = false;

      const currentJournal = modalComponent.instance.journal;
      this.openModalJournal(mapDataInstance, currentJournal, isCopy);

      this.cdr.markForCheck();
      isAliveTurn = true;
    });

    modalComponent.instance.openSubject.pipe(takeWhile(() => isAlive)).subscribe(() => {
      setTimeout(() => {
        if (isAliveTurn) isAlive = false;
      }, 500);
    });
  }

  private openSuccessModal(): void {
    const modal = this.open(PopupComponent) as ComponentRef<PopupComponent>;
    modal.instance.icon = this.svgIconsEnum.CancelCalculationDone;
    modal.instance.title = 'Расчёт отправлен';
  }

  private openErrorModal(): void {
    const modal = this.open(PopupComponent) as ComponentRef<PopupComponent>;
    modal.instance.icon = this.svgIconsEnum.ErrorCalculationDone;
    modal.instance.title = 'Сервер Решателя временно недоступен';
    modal.instance.text = 'Попробуйте повторить попытку позднее';
  }
}
