import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { FilterDataComponent } from '../filter-data/filter-data.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ModalChooseDataInstancesActionsComponent } from '../modal-choose-data-instances-actions/modal-choose-data-instances-actions.component';
import { ModalChooseDataInstancesListComponent } from '../modal-choose-data-instances-list/modal-choose-data-instances-list.component';
import { DataInstanceApiService } from 'src/app/api/data-instance.api.service';
import { finalize, take } from 'rxjs';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { IEditJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { SpinnerService } from '../../../../../ui/services/spinner.service';

@Component({
  selector: 'calendar-plan-modal-choose-data-instances',
  templateUrl: './modal-choose-data-instances.component.html',
  styleUrls: ['./modal-choose-data-instances.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    FilterDataComponent,
    TextComponent,
    ModalChooseDataInstancesActionsComponent,
    ModalChooseDataInstancesListComponent,
  ],
})
export class ModalChooseDataInstancesComponent extends ModalBase implements OnInit, AfterViewInit {
  journal!: IEditJournal;
  typeDataInstances!: string;

  dataInstances!: IDataInstance[];
  filteredDataInstances!: IDataInstance[];

  constructor(
    cdRef: ChangeDetectorRef,
    private ss: SpinnerService,
    private dataInstanceApiService: DataInstanceApiService,
  ) {
    super(cdRef);
  }

  ngOnInit(): void {
    this.getDataList();
  }

  ngAfterViewInit(): void {
    this.initModal();
  }

  getDataList(): void {
    this.ss.startSpinner();
    this.dataInstanceApiService
      .getList(1, [
        { name: 'type', value: this.typeDataInstances },
        { name: 'itemsPerPage', value: 500000 },
      ])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((data) => {
        this.dataInstances = data;
        this.cdRef.detectChanges();
      });
  }

  getFilteredData(data: IDataInstance[]): void {
    this.filteredDataInstances = data;
    this.cdRef.detectChanges();
  }
}
