import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SvgIconsEnum } from '../../../../../core/enums/svg-icons.enum';
import { initialDataSubmenu } from 'src/app/modules/calendar-plan/components/parts/journal-list-short/constants/path';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize, take } from 'rxjs';
import { JournalApiService } from 'src/app/api/journal.api.service';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { RoutesHelper } from 'src/app/helpers/routes.helper';
import { JournalListShortModalsActionsService } from 'src/app/modules/calendar-plan/services/journal-list-short/journal-list-short-modals-actions.service';
import { ActionModal } from 'src/app/modules/ui/base/action-modal';
import { IDocument } from 'src/app/data-models/document/document.interface';
import { dataDocuments } from './mock/documents.mock';
import { SpinnerService } from '../../../../ui/services/spinner.service';

@Component({
  selector: 'documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [JournalListShortModalsActionsService],
})
export class DocumentsListComponent extends ActionModal implements OnInit, AfterViewInit {
  @ViewChild('modal', { read: ViewContainerRef }) modal!: ViewContainerRef;

  readonly svgIconsEnum = SvgIconsEnum;
  readonly initialDataSubmenu = initialDataSubmenu;

  id!: number;
  documents!: IDocument[];

  mapIds = new Map<number, boolean>();

  constructor(
    private ss: SpinnerService,
    private cdr: ChangeDetectorRef,
    private journalApiService: JournalApiService,
    private router: Router,
    private route: ActivatedRoute,
    private journalListShortModalsActionsService: JournalListShortModalsActionsService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.modalContainer = this.modal;
  }

  ngOnInit(): void {
    this.getListJournals();
  }

  getListJournals(): void {
    this.ss.startSpinner();
    this.journalApiService
      .getList(1)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((data) => {
        this.documents = dataDocuments.sort(
          (lhs, rhs) => new Date(rhs.dateCreated).getTime() - new Date(lhs.dateCreated).getTime(),
        );

        this.redirectPage();
      });
  }

  redirectPage(): void {
    const id = this.route.snapshot.params['documentId'];

    if (!isNaN(Number(id))) {
      this.mapIds.set(+id, true);
      this.id = +id;
      this.cdr.detectChanges();
      return;
    }

    const firstJournal = this.documents[0];

    const shippingId = firstJournal.dataInstances.find((item: any) => item.type === 'shippingPlan')
      ?.id;
    this.router.navigate([
      RoutesHelper.DOCUMENTS_PATH,
      firstJournal.id,
      RoutesHelper.DATA_SHIPPING_PLAN_PATH,
      shippingId,
    ]);
  }

  openModalItem(journal: IJournal) {
    this.journalListShortModalsActionsService.open = this.open.bind(this);
    // this.journalListShortModalsActionsService.openModalJournal(journal);
  }

  openModalJournal() {
    this.journalListShortModalsActionsService.open = this.open.bind(this);
    // this.journalListShortModalsActionsService.openModalJournal(undefined, true);
  }
}
