import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEnrichmentStandard } from 'src/app/data-models/enrichment-standard/enrichment-standard.interface';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { InputComponent } from 'src/app/modules/forms/components/input/input.component';
import { AutocompleteComponent } from '../../../../../forms/components/autocomplete/autocomplete.component';
import { DictionaryPipe } from '../../../../../../core/pipes/dictionary.pipe';
import { DictionariesEnum } from 'src/app/core/enums/dictionaries.enum';

@Component({
  selector: 'modal-enrichment-standard-list-form',
  templateUrl: './modal-enrichment-standard-list-form.component.html',
  styleUrls: ['./modal-enrichment-standard-list-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TextComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    InputComponent,
    AutocompleteComponent,
    DictionaryPipe,
  ],
})
export class ModalEnrichmentStandardListFormComponent implements OnInit {
  formData!: FormGroup;

  @Output() form = new EventEmitter<FormGroup>();
  @Input() data!: IEnrichmentStandard[];
  @Input() dataInstanceId!: string;
  @Input() toggleValue!: string;
  @Input() isShow!: boolean;
  @Input() saveData!: boolean;

  isTk1 = true;
  index = 0;

  readonly dictionariesEnum = DictionariesEnum;

  dataFormat!: IEnrichmentStandard[];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.isTk1 = this.toggleValue === 'TK1' ? true : false;
    this.initFormGroup();
  }

  initFormGroup(): void {
    const data = this.data;
    const size = this.data.length;

    this.formData = this.fb.group({
      id: [size && data.every((el) => el.id === data[0].id) ? data[0].id : null],
      dataInstance: this.fb.group({
        id: [
          size && data.every((el) => el.dataInstance.id === data[0].dataInstance.id)
            ? data[0].dataInstance.id
            : this.dataInstanceId,
        ],
      }),
      ordinaryCoalMark: [
        size &&
        data.every(
          (el) => el.ordinaryCoalMark && el.ordinaryCoalMark.id === data[0].ordinaryCoalMark.id,
        )
          ? data[0].ordinaryCoalMark
          : null,
        [Validators.required],
      ],
      ordinaryCoalMiningArea: [
        size &&
        data.every(
          (el) =>
            el.ordinaryCoalMiningArea &&
            el.ordinaryCoalMiningArea.id === data[0].ordinaryCoalMiningArea.id,
        )
          ? data[0].ordinaryCoalMiningArea
          : null,
        [Validators.required],
      ],
      ordinaryCoalMiningLayer: [
        size &&
        data.every(
          (el) =>
            el.ordinaryCoalMiningLayer &&
            el.ordinaryCoalMiningLayer.id === data[0].ordinaryCoalMiningLayer.id,
        )
          ? data[0].ordinaryCoalMiningLayer
          : null,
        [Validators.required],
      ],
      ordinaryCoalMiningProfile: [
        size &&
        data.every(
          (el) =>
            el.ordinaryCoalMiningProfile &&
            el.ordinaryCoalMiningProfile.id === data[0].ordinaryCoalMiningProfile.id,
        )
          ? data[0].ordinaryCoalMiningProfile
          : null,
        [Validators.required],
      ],
      ordinaryCoalAd: [
        size && data.every((el) => el.ordinaryCoalAd === data[0].ordinaryCoalAd)
          ? data[0].ordinaryCoalAd
          : null,
        [Validators.required],
      ],
      ordinaryCoalVd: [
        size && data.every((el) => el.ordinaryCoalVd === data[0].ordinaryCoalVd)
          ? data[0].ordinaryCoalVd
          : null,
        [Validators.required],
      ],
      ordinaryCoalY: [
        size && data.every((el) => el.ordinaryCoalY === data[0].ordinaryCoalY)
          ? data[0].ordinaryCoalY
          : null,
      ],
      tk1ConcentrateMark: [
        size &&
        data.every(
          (el) =>
            el.tk1ConcentrateMark && el.tk1ConcentrateMark.id === data[0].tk1ConcentrateMark.id,
        )
          ? data[0].tk1ConcentrateMark
          : null,
      ],
      tk1ConcentrateAd: [
        size && data.every((el) => el.tk1ConcentrateAd === data[0].tk1ConcentrateAd)
          ? data[0].tk1ConcentrateAd
          : null,
      ],
      tk1ConcentrateKx: [
        size && data.every((el) => el.tk1ConcentrateKx === data[0].tk1ConcentrateKx)
          ? data[0].tk1ConcentrateKx
          : null,
      ],
      tk1DropoutMark: [
        size &&
        data.every((el) => el.tk1DropoutMark && el.tk1DropoutMark.id === data[0].tk1DropoutMark.id)
          ? data[0].tk1DropoutMark
          : null,
      ],
      tk1DropoutAd: [
        size && data.every((el) => el.tk1DropoutAd === data[0].tk1DropoutAd)
          ? data[0].tk1DropoutAd
          : null,
      ],
      tk1DropoutKx: [
        size && data.every((el) => el.tk1DropoutKx === data[0].tk1DropoutKx)
          ? data[0].tk1DropoutKx
          : null,
      ],
      tk1SludgeMark: [
        size &&
        data.every((el) => el.tk1SludgeMark && el.tk1SludgeMark.id === data[0].tk1SludgeMark.id)
          ? data[0].tk1SludgeMark
          : null,
      ],
      tk1SludgeAd: [
        size && data.every((el) => el.tk1SludgeAd === data[0].tk1SludgeAd)
          ? data[0].tk1SludgeAd
          : null,
      ],
      tk1SludgeKx: [
        size && data.every((el) => el.tk1SludgeKx === data[0].tk1SludgeKx)
          ? data[0].tk1SludgeKx
          : null,
      ],
      tk2ConcentrateMark: [
        size &&
        data.every(
          (el) =>
            el.tk2ConcentrateMark && el.tk2ConcentrateMark.id === data[0].tk2ConcentrateMark.id,
        )
          ? data[0].tk2ConcentrateMark
          : null,
      ],
      tk2ConcentrateAd: [
        size && data.every((el) => el.tk2ConcentrateAd === data[0].tk2ConcentrateAd)
          ? data[0].tk2ConcentrateAd
          : null,
      ],
      tk2ConcentrateKx: [
        size && data.every((el) => el.tk2ConcentrateKx === data[0].tk2ConcentrateKx)
          ? data[0].tk2ConcentrateKx
          : null,
      ],
      tk2IndustrialProductMark: [
        size &&
        data.every(
          (el) =>
            el.tk2IndustrialProductMark &&
            el.tk2IndustrialProductMark.id === data[0].tk2IndustrialProductMark.id,
        )
          ? data[0].tk2IndustrialProductMark
          : null,
      ],
      tk2IndustrialProductAd: [
        size && data.every((el) => el.tk2IndustrialProductAd === data[0].tk2IndustrialProductAd)
          ? data[0].tk2IndustrialProductAd
          : null,
      ],
      tk2IndustrialProductKx: [
        size && data.every((el) => el.tk2IndustrialProductKx === data[0].tk2IndustrialProductKx)
          ? data[0].tk2IndustrialProductKx
          : null,
      ],
      tk1Enrichment: [
        size && data.every((el) => el.tk1Enrichment === data[0].tk1Enrichment)
          ? data[0].tk1Enrichment
          : true,
      ],
      tk2Enrichment: [
        size && data.every((el) => el.tk2Enrichment === data[0].tk2Enrichment)
          ? data[0].tk2Enrichment
          : true,
      ],
    });

    if (this.isShow) {
      this.formData.disable();
      this.formData.updateValueAndValidity();
    }

    this.form.emit(this.formData);
  }

  getControl(name: string): FormControl {
    return this.formData.get(name) as FormControl;
  }
}
