import { routes } from './../../../../../nsi/routes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalActionsComponent } from 'src/app/modules/ui/components/modal-actions/modal-actions.component';
import { FormControl, FormGroup } from '@angular/forms';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { DataInstanceApiService } from 'src/app/api/data-instance.api.service';
import { finalize, take } from 'rxjs';
import {
  ICopyDataInstanceResponse,
  IDataInstance,
} from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { DataInstanceCopyApiService } from 'src/app/api/data-instance-copy.api.service';
import * as moment from 'moment';
import { SpinnerService } from '../../../../../ui/services/spinner.service';
import { WagonFleetApiService } from 'src/app/api/wagon-fleet.api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataInstancesProviderService } from 'src/app/modules/data/services/data-instances-provider.service';
import { RoutesHelper } from 'src/app/helpers/routes.helper';

@Component({
  selector: 'modal-data-instances-list-short-actions',
  templateUrl: './modal-data-instances-list-short-actions.component.html',
  styleUrls: ['./modal-data-instances-list-short-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalActionsComponent],
})
export class ModalDataInstancesListShortActionsComponent implements OnInit {
  @Input() formData!: FormGroup;
  @Input() data!: IDataInstance;
  @Output() readonly close = new EventEmitter<void>();
  @Output() readonly updateData = new EventEmitter<void>();
  @Input() toggleControl!: FormControl<string | null>;
  @Input() typeDataInstances!: string;

  readonly svgIconsEnum = SvgIconsEnum;
  isCopy = false;

  constructor(
    private ss: SpinnerService,
    private dataInstanceApiService: DataInstanceApiService,
    private dataInstanceCopyApiService: DataInstanceCopyApiService,
    private cdr: ChangeDetectorRef,
    private wagonFleetApiService: WagonFleetApiService,
    private dataInstancesProviderService: DataInstancesProviderService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.toggleControl.valueChanges.subscribe((value) => {
      if (!value) return;
      this.isCopy = value.toLowerCase() === 'редактирование' ? false : true;
      this.cdr.detectChanges();
    });
  }

  create(): void {
    const value = { ...this.formData.value };
    delete value['id'];
    if (value.dateStarted)
      value.dateStarted = moment(value.dateStarted).startOf('day').utcOffset(0, true).format();
    this.ss.startSpinner();
    this.dataInstanceApiService
      .create(value)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((dataInstance) => {
        if (this.typeDataInstances === 'wagonFleet') {
          this.createNewWagonFleet(dataInstance);
        }

        this.close.emit();
        this.goToNewDataInstance(dataInstance.id!);
      });
  }

  goToNewDataInstance(dataInstanceId: string | number): void {
    const path = this.dataInstancesProviderService.getPathCurrentPage();
    this.router.navigate([path, dataInstanceId], { relativeTo: this.route });
  }

  createNewWagonFleet(dataInstance: IDataInstance): void {
    this.ss.startSpinner();
    this.wagonFleetApiService
      .create({
        dataInstance: {
          id: dataInstance.id!,
        },
      })
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => this.close.emit());
  }

  save(): void {
    if (this.formData.invalid) return;

    this.data && !this.isCopy
      ? this.change()
      : this.data && this.isCopy
        ? this.duplicate()
        : this.create();
  }

  change(id?: number): void {
    const value = { ...this.formData.value };
    if (value.dateStarted)
      value.dateStarted = moment(value.dateStarted).startOf('day').utcOffset(0, true);
    if (id) value.id = id;
    this.ss.startSpinner();
    this.dataInstanceApiService
      .update(value)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => {
        this.close.emit();
      });
  }

  isEqualObjects() {
    const value = { ...this.formData.value };

    const diffDateStarted = moment(value.dateStarted).diff(moment(this.data.dateStarted), 'days');
    const diffDateFinished = moment(value.dateFinished).diff(
      moment(this.data.dateFinished),
      'days',
    );
    const diffNote = this.data.note === value.note;
    return !diffDateStarted && !diffDateFinished && diffNote;
  }

  duplicate(): void {
    const body = { fromId: this.formData.value.id };

    this.ss.startSpinner();
    this.dataInstanceCopyApiService
      .create(body)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((result) => {
        !this.formData.value.calculationLog.length && !this.isEqualObjects()
          ? this.change((result as unknown as ICopyDataInstanceResponse).dataInstance.id)
          : this.close.emit();

        this.goToNewDataInstance((result as unknown as ICopyDataInstanceResponse).dataInstance.id);
      });
  }

  remove(): void {
    this.ss.startSpinner();
    this.dataInstanceApiService
      .delete(this.formData.value.id)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => this.close.emit());
  }
}
