import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { SectionComponent } from 'src/app/modules/ui/components/section/section.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/app/modules/ui/services/spinner.service';
import { ActionModal } from 'src/app/modules/ui/base/action-modal';
import { forkJoin, take, takeWhile } from 'rxjs';
import { RedirectDataService } from 'src/app/modules/data/services/redirect-data.service';
import * as moment from 'moment';
import { ResourceAvailabilityApiService } from 'src/app/api/resource-availability.api.service';
import { ResourceAvailabilityConditionApiService } from 'src/app/api/resource-availability-condition.api.service';
import {
  IResourceAvailability,
  IResourceAvailabilityTransform,
} from 'src/app/data-models/resource-availability/resource-availability.interface';
import { IResourceAvailabilityCondition } from 'src/app/data-models/resource-availability-condition/resource-availability-condition.interface';
import {
  IEditResourceAvailabilityCondition,
  IEditResourceAvailabilityConditionTransform,
} from '../../../../../../data-models/resource-availability-condition/edit-resource-availability-condition.interface';
import { ThSortFilterComponent } from 'src/app/modules/ui/components/th-sort-filter/th-sort-filter.component';
import { FilterSortByColumnService } from 'src/app/modules/ui/services/filter-sort-by-column.service';
import { WeekendDayPipe } from '../../../../../../core/pipes/weekend-day.pipe';
import { ModalFundService } from 'src/app/modules/data/services/fund/modal-fund.service';
import { DataFundService } from 'src/app/modules/data/services/fund/data-fund.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CheckFinishCaluclationService } from 'src/app/modules/data/services/check-correct-caluclation.service';

@Component({
  selector: 'fund-list',
  templateUrl: './fund-list.component.html',
  styleUrls: ['./fund-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    TextComponent,
    SectionComponent,
    MatIconModule,
    ButtonComponent,
    ThSortFilterComponent,
    WeekendDayPipe,
    MatTooltipModule,
  ],
})
export class FundListComponent extends ActionModal implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('modal', { read: ViewContainerRef }) modal!: ViewContainerRef;

  @ViewChild('table', { read: ElementRef })
  table!: ElementRef<HTMLTableElement>;

  @Output() getCurrentTable = new EventEmitter();

  data!: IResourceAvailabilityTransform[];
  rawData!: IResourceAvailabilityTransform[];

  dataMap: Map<string, IEditResourceAvailabilityCondition[]> = new Map();

  hasValueDay: Map<string, boolean> = new Map();
  getValueDay: Map<string, string> = new Map();

  dataInstance!: IDataInstance;

  readonly svgIconsEnum = SvgIconsEnum;
  readonly typeDataInstances = 'resourceAvailability';

  isShow = false;
  isSingleClick = true;
  isAlive = true;

  columnsDays: IDataColumnTable[] = [];
  columnsDaysTotal: IDataColumnTable[] = [];
  columns: IDataColumnTable[] = [
    { name: 'index', display: '#' },
    { name: 'task', display: 'Задача' },
    { name: 'ruleName', display: 'Имя' },
    { name: 'ruleType', display: 'Тип' },
    { name: 'resourceCode', display: 'Код ресурса' },
    { name: 'generalNorm', display: 'Норма' },
  ];

  arrayData: IResourceAvailabilityTransform[] = [];

  days!: number;

  displayedColumns!: string[];
  displayedColumnsTotal!: string[];

  currentActionsRows: number | undefined = undefined;

  totalValueMap = new Map<string, string>();

  constructor(
    private ss: SpinnerService,
    private cdr: ChangeDetectorRef,
    private resourceAvailabilityApiService: ResourceAvailabilityApiService,
    private resourceAvailabilityConditionApiService: ResourceAvailabilityConditionApiService,
    private route: ActivatedRoute,
    private router: Router,
    private redirectDataService: RedirectDataService,
    private filterSortByColumnService: FilterSortByColumnService,
    private checkFinishCaluclationService: CheckFinishCaluclationService,
    private modalFundService: ModalFundService,
    private dataFundService: DataFundService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(takeWhile(() => this.isAlive)).subscribe((params) => {
      const id = params['dataInstanceId'];
      id
        ? this.getDataInstance(id)
        : this.redirectDataService.initRedirect(this.router, this.route);
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngAfterViewInit() {
    this.getCurrentTable.emit(this.table.nativeElement);
  }

  updateData(): void {
    const id = this.route.snapshot.params['dataInstanceId'];
    if (!id) return;

    this.redirectDataService.voidParamsCurrentPage();
    this.redirectDataService.initParamsCurrentPage(this.route, id);
    this.getDataInstance(id);
  }

  subscribeSortingFiltering(): void {
    this.route.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe((query) => {
      this.data = this.filterSortByColumnService.sort(query, '', this.rawData);
      this.data = this.filterSortByColumnService.filter(query, this.data);

      this.cdr.detectChanges();
    });
  }

  getDataCalculationLog(): void {
    this.checkFinishCaluclationService.getIsShow(this.dataInstance).subscribe((isShow) => {
      this.isShow = isShow;
      this.cdr.detectChanges();
    });
  }

  getDataInstance(id: string) {
    this.redirectDataService.initParamsCurrentPage(this.route, id);
    this.ss.startSpinner();
    this.redirectDataService
      .getDataInstance(id)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.dataInstance = res;

          this.modalContainer = this.modal;
          this.closeInteraction = this.updateData.bind(this);

          this.initData(id);
        },
        () => this.ss.stopSpinner(),
      );
  }

  initData(id: string): void {
    this.getDataCalculationLog();
    this.initColumnsDays();
    this.initDisplayedColumns();
    this.getDataList(id);
  }

  getDataList(id: string): void {
    forkJoin([
      this.resourceAvailabilityApiService.getList(1, [
        { name: 'dataInstance.id', value: id },
        { name: 'itemsPerPage', value: 20000 },
      ]),

      this.resourceAvailabilityConditionApiService.getList(1, [
        { name: 'itemsPerPage', value: 20000 },
      ]),
    ])
      .pipe(take(1))
      .subscribe(
        ([data, dataCondition]) => {
          this.dataMap.clear();
          this.hasValueDay.clear();
          this.getValueDay.clear();
          this.initTransformData(data, dataCondition);
          this.subscribeSortingFiltering();
          this.ss.stopSpinner();
        },
        () => this.ss.stopSpinner(),
      );
  }

  getCurrentValue(
    row: IResourceAvailabilityTransform,
    item: IDataColumnTable,
  ): IEditResourceAvailabilityConditionTransform[] {
    return this.dataFundService.getCurrentValue(row, item, this.dataInstance.dateStarted);
  }

  checkChooseDay(row: IResourceAvailabilityTransform, item: IDataColumnTable): boolean {
    return this.dataMap.get(`${row.id}-${item.display}`) ? true : false;
  }

  initColumnsDays(): void {
    const days = moment(this.dataInstance.dateFinished).diff(this.dataInstance.dateStarted, 'days');

    this.columnsDays = Array.from(Array(days), (_, index) => ({
      name: `c${index + 1}`,
      display: `${index + 1}`,
    }));
    this.columnsDaysTotal = Array.from(Array(days), (_, index) => ({
      name: `t${index + 1}`,
      display: `${index + 1}`,
    }));
  }

  initDisplayedColumns(): void {
    this.displayedColumns = [...this.columns, ...this.columnsDays].map((col) => col.name);
    this.displayedColumns.push('total-row');

    this.displayedColumnsTotal = [
      'index-total',
      ...this.columnsDaysTotal.map((col) => col.name),
      'total-row-amount',
    ];
  }

  chooseDay(row: IResourceAvailabilityTransform, item: IDataColumnTable) {
    this.isSingleClick = true;
    setTimeout(() => {
      if (!this.isSingleClick) return;
      const value = this.getCurrentValue(row, item);

      const idMap = `${value[0].resourceAvailability.id}-${value[0].day}`;

      this.dataMap.get(idMap) ? this.dataMap.delete(idMap) : this.dataMap.set(idMap, value);
      this.cdr.detectChanges();
    }, 250);
  }

  initModalMiningPlanServiceData(): void {
    this.modalFundService.dataInstance = this.dataInstance;
    this.modalFundService.isShow = this.isShow;
    this.modalFundService.initData = this.updateData.bind(this);
    this.modalFundService.open = this.open.bind(this);
    this.modalFundService.cdr = this.cdr;
    this.modalFundService.dataMap = this.dataMap;
  }

  openModal(row?: IResourceAvailabilityTransform, index?: number, isCopy = false): void {
    this.isSingleClick = false;
    this.initModalMiningPlanServiceData();
    this.modalFundService.openModal(row, index, isCopy);
  }

  openModalSelectResource(data: IResourceAvailabilityTransform, index: number, isCopy: boolean) {
    this.isSingleClick = false;
    this.initModalMiningPlanServiceData();
    this.modalFundService.openModalSelectResource(data, index, isCopy);
  }

  openModalCell(
    row?: IResourceAvailabilityTransform,
    item?: IDataColumnTable,
    index?: number,
  ): void {
    this.initModalMiningPlanServiceData();
    this.modalFundService.openModalCell(row, item, index);
  }

  initTransformData(
    data: IResourceAvailability[],
    dataCondition: IResourceAvailabilityCondition[],
  ): void {
    const dataFormat = this.dataFundService.initTransformData(
      data,
      dataCondition,
      this.columnsDays,
      this.dataInstance.dateStarted,
      this.getValueDay,
      this.hasValueDay,
    );
    this.totalValueMap.clear();

    const dataWithTotal = this.dataFundService.createTotalValue(
      dataFormat,
      this.totalValueMap,
      this.getValueDay,
      this.columnsDays.length,
    );

    this.data = dataWithTotal;
    this.rawData = [...dataWithTotal];

    this.cdr.detectChanges();
  }

  checkValueDay(row: IResourceAvailabilityTransform, item: IDataColumnTable): boolean {
    const id = row.id + '-' + item.display;
    return !!this.hasValueDay.get(id);
  }

  getValueCurrentDay(row: IResourceAvailabilityTransform, item: IDataColumnTable): string {
    const id = row.id + '-' + item.display;
    return this.getValueDay.get(id) || '';
  }

  addColumn(): void {
    this.columnsDays.push({
      name: `c${this.columnsDays.length + 1}`,
      display: `${this.columnsDays.length + 1}`,
    });

    this.columnsDaysTotal.push({
      name: `t${this.columnsDaysTotal.length + 1}`,
      display: `${this.columnsDaysTotal.length + 1}`,
    });

    this.initDisplayedColumns();

    this.cdr.detectChanges();
  }
}
