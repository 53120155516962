import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { InputComponent } from 'src/app/modules/forms/components/input/input.component';

@Component({
  selector: 'modal-select-resource-input',
  templateUrl: './modal-select-resource-input.component.html',
  styleUrls: ['./modal-select-resource-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TextComponent, InputComponent],
})
export class ModalSelectResourceInputComponent {
  @Input() value!: string;
}
