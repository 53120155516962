import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'hoveringCalculation',
  standalone: true,
})
export class HoveringCalculationPipe implements PipeTransform {
  listStatuses = ['Ошибка расчета', 'Выполнено'];

  transform(dateStarted: string, status: string): boolean {
    const diffHours = moment().diff(moment(dateStarted), 'hours');
    return diffHours >= 4 && !this.listStatuses.includes(status);
  }
}
