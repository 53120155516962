<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header">
      <nguk-text class="modal-title" weight="600" styleName="text-xl">
        Выход из системы
      </nguk-text>
    </div>

    <nguk-text class="confirmation-text" weight="400" styleName="text-xs">
      Вы действительно хотите выйти из системы?
    </nguk-text>

    <div class="actions">
      <nguk-button type="primary" (click)="closeModal(true)">
        <nguk-text weight="400" styleName="text-sm">Выйти из системы</nguk-text>
      </nguk-button>

      <nguk-button type="secondary" (click)="closeModal(false)">
        <nguk-text weight="400" styleName="text-sm">Остаться</nguk-text>
      </nguk-button>
    </div>
  </div>
</nguk-modal>
