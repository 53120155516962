import { ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { SvgIconsEnum } from '../../../core/enums/svg-icons.enum';
import { TStatusClosed } from '../types/status-closed-modal.type';

export class ModalBase {
  readonly svgIconsEnum = SvgIconsEnum;

  readonly openSubject = new Subject<boolean | null>();
  readonly statusClosedSubject = new Subject<TStatusClosed>();
  readonly openNextModalSubject = new Subject<boolean>();

  isVisible = false;

  constructor(protected cdRef: ChangeDetectorRef) {}

  initModal(): void {
    setTimeout(() => {
      this.isVisible = true;
      this.cdRef.detectChanges();
    });
  }

  changeStatus(status: TStatusClosed, isUpdate = true): void {
    status === 'success' && isUpdate ? this.closeModal(true) : this.closeModal(false);

    setTimeout(() => {
      this.statusClosedSubject.next(status);
    }, 300);
  }

  closeModal(save: boolean | null): void {
    this.isVisible = false;
    this.cdRef.detectChanges();

    setTimeout(() => {
      this.openSubject.next(save);
      this.cdRef.detectChanges();
    }, 300);
  }

  openNextModal() {
    this.closeModal(false);

    setTimeout(() => {
      this.openNextModalSubject.next(true);
    }, 300);
  }
}
