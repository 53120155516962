import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ControlDirective } from '../../../../forms/directives/control.directive';

@Component({
  selector: 'nguk-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatInputModule, ReactiveFormsModule, FormsModule, MatIconModule],
})
export class InputComponent extends ControlDirective {
  @Input() label!: string;
  @Input() svgIcon!: string;
  @HostBinding('class.nguk-input') inputBaseClass = true;
}
