import { ChangeDetectorRef, Injectable } from '@angular/core';
import * as moment from 'moment';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import {
  IMiningPlan,
  IMiningPlanTransform,
} from 'src/app/data-models/mining-plan/mining-plan.interface';

@Injectable({
  providedIn: 'root',
})
export class DatalMiningPlanArrayService {
  initTransformData(
    data: IMiningPlan[],
    dateStarted: Date,
    dataMaps: Map<string, IMiningPlan>,
    days: number,
  ): IMiningPlanTransform[] {
    const dataFormat = data.reduce((result: IMiningPlanTransform[], item) => {
      const key = `${item.area?.title ?? '-'}_${item.layer?.title ?? '-'}_${
        item.profile?.id ?? '-'
      }_${item.ad}_${item.vd}_${item.y}_${item.mark?.title}`;

      const existingItem: IMiningPlanTransform | undefined = result.find(
        (group) => group.key === key,
      );

      const day = (moment(item.dateMining).diff(moment(dateStarted), 'days') + 1).toString();

      if (+day > days) return result;

      item.day = day;

      dataMaps.set(`${key}-${day}`, item);

      if (existingItem) {
        existingItem.values!.push({
          id: item.id!,
          volume: item.volume,
          day: day,
          dateMining: item.dateMining,
        });

        existingItem.total += +item.volume ?? 0;
      } else {
        result.push({
          dataInstance: { id: item.dataInstance.id },
          area: item.area,
          layer: item.layer,
          profile: item.profile,
          ad: item.ad,
          vd: item.vd,
          y: item.y,
          mark: item.mark,
          key,
          total: +item.volume,
          values: [
            {
              id: item.id!,
              volume: item.volume,
              day: day,
              dateMining: item.dateMining,
            },
          ],
        });
      }

      return result;
    }, []);

    return this.sortingByArea(dataFormat);
  }

  sortingByArea(result: IMiningPlanTransform[]): IMiningPlanTransform[] {
    return result.sort((a, b) => {
      const nameA = a.area.title.toUpperCase();
      const nameB = b.area.title.toUpperCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });
  }

  chooseDay(
    row: IMiningPlanTransform,
    item: IDataColumnTable,
    isSingleClick: boolean,
    mapCurrentCells: Map<string, IMiningPlan>,
    dataMaps: Map<string, IMiningPlan>,
    dateStarted: Date,
    cdr: ChangeDetectorRef,
  ) {
    if (!isSingleClick) return;

    const key = `${row.key}-${item.display}`;
    if (mapCurrentCells.get(key)) {
      mapCurrentCells.delete(key);
      cdr.detectChanges();
      return;
    }
    let currentValue: IMiningPlan | undefined = dataMaps.get(key);

    if (!currentValue) {
      const value = { ...dataMaps.get(`${row.key}-${row.values[0].day}`) } as IMiningPlan;

      delete value['id'];
      value.day = item.display;
      value.volume = '';
      currentValue = value;
      currentValue.dateMining = moment(dateStarted)
        .add(+item.display - 1, 'days')
        .utcOffset(0, true)
        .format();
    }

    currentValue.key = row.key;

    mapCurrentCells.set(key, currentValue);
    cdr.detectChanges();
  }
}
