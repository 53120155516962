import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import { IMiningPlan } from '../data-models/mining-plan/mining-plan.interface';
import { IEditMiningPlan } from '../data-models/mining-plan/edit-mining-plan.interface';
import { Observable } from 'rxjs';
import { ApiRequestModel } from '../core/models/api-request.model';
import { IFileUploadResponse } from '../data-models/file-upload-response.interface';

@Injectable({
  providedIn: 'root',
})
export class MiningPlanApiService extends EntityApi<IMiningPlan, IEditMiningPlan> {
  protected readonly api = 'sourceData/miningPlan';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }

  uploadFile = (formData: FormData, dataInstanceId: string): Observable<IFileUploadResponse> => {
    const apiModel = new ApiRequestModel(this.api, formData)
      .addToPath('upload')
      .addToPath(dataInstanceId);

    return this.apiProvider.postFile(apiModel);
  }
}
