import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'nguk-blur',
  template: `<div class="nguk-blur-root"></div>`,
  styleUrls: ['./blur.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class BlurComponent implements OnInit {
  @Input() height = '96px';
  @Input() bottom = '0';

  @HostBinding('class.nguk-blur') private baseClass = true;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.style.setProperty('height', this.height);
    this.elementRef.nativeElement.style.setProperty('bottom', this.bottom);
  }
}
