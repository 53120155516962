import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { NotificationsService } from '../services/notifications.service';
import { NotificationModel } from '../models/notification.model';
import { NotificationTypeEnum } from '../enums/notification-type.enum';

@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private notificationsService: NotificationsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status) {
          const notification = new NotificationModel(NotificationTypeEnum.Error, errorResponse.error.detail);
          this.notificationsService.showNotification(notification);
        }

        return throwError(errorResponse);
      }),
    );
  }
}
