export enum ShippingPlanModalListColumnsEnum {
  Index = 'index',
  RequiredWagonMark = 'requiredWagonMark',
  FactoryProductsMixture = 'factoryProductsMixture',
  ContractualAd = 'contractualAd',
  ContractualVd = 'contractualVd',
  ContractualY = 'contractualY',
  PlannedAd = 'plannedAd',
  PlannedVd = 'plannedVd',
  PlannedY = 'plannedY',
  Actions = 'actions',
}
