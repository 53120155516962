import { Pipe, PipeTransform } from '@angular/core';
import { IWarehouseDynamicsItemTransform } from 'src/app/data-models/warehouse-dynamics-item/warehouse-dynamics.interface';

@Pipe({
  name: 'warehouseDynamicsHorizontalLine',
  standalone: true,
})
export class WarehouseDynamicsHorizontalLinePipe implements PipeTransform {
  transform(index: number, data: IWarehouseDynamicsItemTransform[]): boolean {
    if (index === 0) return false;
    return data[index].warehouse.id === data[index - 1].warehouse.id;
  }
}
