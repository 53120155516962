<div class="journal-list-item-root" [class.-opened]="isOpened">
  <div
    class="journal-item-header"
    [attr.role]="'none'"
    (click)="handleClick(dataInstance)"
    (dblclick)="handleDoubleClick(dataInstance)"
  >
    <div class="journal-item-name">
      <nguk-text weight="400" styleName="text-xs">{{ index + 1 }}</nguk-text>
    </div>
    <div class="vertical-line"></div>
    <nguk-text
      weight="400"
      styleName="text-xs"
      class="journal-item-note"
    >
      {{ dataInstance.note }}
    </nguk-text>
    <div class="vertical-line"></div>
    <div class="journal-item-date">
      <nguk-text
        weight="400"
        styleName="text-xs"
        class="journal-item-date__date"
      >
        {{ dataInstance.dateCreated | date: 'dd.MM' }}
      </nguk-text>
      <mat-icon [svgIcon]="svgIconsEnum.Clock" />
      <nguk-text
        weight="400"
        styleName="text-xs"
        class="journal-item-date__time"
      >
        {{ dataInstance.dateCreated | date: 'hh:mm' }}
      </nguk-text>
    </div>
    <div class="vertical-line"></div>

    <div class="journal-item-info">
      <div class="journal-item-info-lockClose" *ngIf="isCalculationComplete; else closeCalculate">
        <mat-icon [svgIcon]="svgIconsEnum.LockClose"></mat-icon>
      </div>

      <ng-template #closeCalculate>
        <div class="journal-item-info-lockOpen">
          <mat-icon [svgIcon]="svgIconsEnum.LockOpen"></mat-icon>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="journal-list-details" [attr.role]="'none'">
    <div
      *ngFor="let dictionary of dictionaries; let first = first; let last = last"
      class="journal-submenu-item-root"
    >
      <div class="wrapper">
        <div
          class="submenu-item-header"
          [class.-first]="first"
          [class.-last]="last"
          [attr.role]="'none'"
          [routerLink]="getDictionaryRouterLink(dictionary)"
          [routerLinkActive]="'-active'"
        >
          <mat-icon [svgIcon]="getListIcon(dictionary)" />
          <nguk-text weight="400" styleName="text-xs">{{ dictionary.title }}</nguk-text>
        </div>
      </div>
    </div>
  </div>
</div>
