import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CheckboxComponent } from 'src/app/modules/forms/components/checkbox/checkbox.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { IEditJournal, IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';

@Component({
  selector: 'calendar-plan-modal-journal-list-short-print-list',
  templateUrl: './modal-journal-list-short-print-list.component.html',
  styleUrls: ['./modal-journal-list-short-print-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CheckboxComponent,
    TextComponent,
    MatIconModule,
  ],
})
export class ModalJournalListShortPrintListComponent implements OnInit {
  @Input() journal!: IJournal | IEditJournal;
  @Input() isOperationalPlan = false;

  @Output() setFormGroup = new EventEmitter<FormGroup>();

  formCheckbox!: FormGroup;

  readonly svgIconsEnum = SvgIconsEnum;

  arrayCheckbox = [
    {
      title: 'Исходные данные',
      array: 'dataInstance',
      opened: true,
      checkbox: [
        { title: 'Сбыт', control: 'shippingPlan' },
        { title: 'Остатки', control: 'warehouseBalance' },
        { title: 'План добычи', control: 'miningPlan' },
        { title: 'ТК', control: 'enrichmentStandard' },
      ],
    },
    {
      title: 'План производства',
      array: 'productionPlan',
      opened: true,
      checkbox: [
        { title: 'Добыча', control: 'resultMiningPlan' },
        { title: 'Обогащение', control: 'resultSupply' },
        { title: 'Динамика', control: 'warehouseDynamics' },
        { title: 'Логистика', control: 'internalLogistics' },
        { title: 'Отгрузка', control: 'commodityShipment' },
      ],
    },
  ];

  arrayCheckboxOP = [
    {
      title: 'Исходные данные',
      array: 'dataInstance',
      opened: true,
      checkbox: [
        { title: 'Сбыт', control: 'shippingPlan' },
        { title: 'Остатки', control: 'warehouseBalance' },
        { title: 'План добычи', control: 'miningPlan' },
        { title: 'ТК', control: 'enrichmentStandard' },
        { title: 'Парк вагонов', control: 'wagonFleet' },
      ],
    },
    {
      title: 'План производства',
      array: 'productionPlan',
      opened: true,
      checkbox: [
        { title: 'Добыча', control: 'resultMiningPlan' },
        { title: 'Обогащение', control: 'resultSupply' },
        { title: 'Складская динамика', control: 'warehouseDynamics' },
        // { title: 'Логистика', control: 'internalLogistics' },
        // { title: 'Отгрузка', control: 'commodityShipment' },
        { title: 'Динамика вагонов', control: 'wagonDynamics' },
        { title: 'Гант', control: 'gantt' },
      ],
    },
  ];

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initFormGroup();
    this.setFormGroup.emit(this.formCheckbox);
  }

  findCurrentIdDataInstance(type: string): string | undefined {
    return this.journal.dataInstances.find((item) => item.type === type)?.id;
  }

  findCurrentIdProductionPlans(type: string): string | undefined {
    return this.journal.productionPlans.find((item) => item.type === type)?.id;
  }

  initFormGroup(): void {
    this.formCheckbox = this.fb.group({
      dataInstance: this.fb.array([]),
      productionPlan: this.fb.array([]),
    });

    (this.isOperationalPlan ? this.arrayCheckboxOP : this.arrayCheckbox).forEach((itemCheckbox) => {
      itemCheckbox.checkbox.forEach((item) => {
        const isDataInstances = itemCheckbox.array === 'dataInstance';
        const fg = this.fb.group({
          type: [item.control],
          isActive: [false],
          id: [
            isDataInstances
              ? this.findCurrentIdDataInstance(item.control)
              : this.findCurrentIdProductionPlans(item.control),
          ],
        });

        isDataInstances
          ? this.getFormArray('dataInstance').push(fg)
          : this.getFormArray('productionPlan').push(fg);
      });
    });
  }

  getFormArray(str: string): FormArray {
    return this.formCheckbox.get(str) as FormArray;
  }

  getControl(fa: string, fc: string, index: number): FormControl {
    return (this.getFormArray(fa).controls[index] as FormGroup).get(fc) as FormControl;
  }
}
