<div class="authorized-layout-header-root">
  <div class="header-main-content">
    <nguk-logo [hasInfoIcon]="true" />
  </div>
  <nguk-navigation />
  <div class="user-content">
    <header-user (logout)="openLogoutConfirmationModal()" />
  </div>
</div>

<ng-template #modal />
