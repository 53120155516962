import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';

@Component({
  selector: 'modal-loading',
  standalone: true,
  imports: [CommonModule, ModalComponent, MatProgressSpinnerModule, TextComponent],
  templateUrl: './modal-loading.component.html',
  styleUrls: ['./modal-loading.component.scss'],
})
export class ModalLoadingComponent extends ModalBase implements AfterViewInit {
  @Output() loading = new EventEmitter<void>();

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngAfterViewInit(): void {
    this.initModal();
    setTimeout(() => {
      this.loading.emit();
    }, 100);
  }
}
