import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProductionPlan } from '../../../data-models/production-plan/production-plan.interface';
import { ProductionPlanApiService } from '../../../api/production-plan.api.service';
import { InternalLogisticsApiService } from '../../../api/internal-logistics.api.service';
import {
  IInternalLogistics,
  IInternalLogisticsTransform,
} from '../../../data-models/internal-logistics/internal-logistics.interface';
import { JournalApiService } from 'src/app/api/journal.api.service';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import * as moment from 'moment';
import { OperationalPlanJournalApiService } from 'src/app/api/operational-plan-journal.api.service';

@Injectable()
export class InternalLogisticsService {
  constructor(
    private internalLogisticsApiService: InternalLogisticsApiService,
    private productionPlanApiService: ProductionPlanApiService,
    private journalApiService: JournalApiService,
    private operationalPlanJournalApiService: OperationalPlanJournalApiService,
  ) {}

  getProductionPlan(productionPlanId: string): Observable<IProductionPlan> {
    return this.productionPlanApiService.getOne(productionPlanId);
  }

  getJournal(journalId: string, isOperationalPlan: boolean): Observable<IJournal> {
    return isOperationalPlan
      ? this.operationalPlanJournalApiService.getOne(journalId)
      : this.journalApiService.getOne(journalId);
  }

  getListItems(productionPlanId: string): Observable<IInternalLogistics[]> {
    const query = [
      { name: 'productionPlan.id', value: productionPlanId },
      { name: 'itemsPerPage', value: 500 * 1000 },
    ];

    return this.internalLogisticsApiService.getList(1, query);
  }

  initTransformData(
    data: IInternalLogistics[],
    journal: IJournal,
    mapKeyId: Map<string, IInternalLogistics[]>,
    isOperationalPlan: boolean,
  ): IInternalLogisticsTransform[] {
    const dataFormat = data.reduce((result: IInternalLogisticsTransform[], item) => {
      const finishValue =
        moment(item.date).diff(
          moment(journal.datePlanFinished),
          isOperationalPlan ? 'hours' : 'days',
        ) + 1;

      if (finishValue > 0) {
        return result;
      }

      const key = `${item.direction ?? ''}_${item.mark?.title ?? ''}`;

      const currentDay: number =
        moment(item.date).diff(
          moment(journal.datePlanStarted),
          isOperationalPlan ? 'hours' : 'days',
        ) + 1;

      const currentKey = key + currentDay;
      const currentValue = mapKeyId.get(currentKey);

      if (currentValue) {
        currentValue.push(item);
        mapKeyId.set(currentKey, currentValue);
      } else {
        mapKeyId.set(currentKey, [item]);
      }

      const existingItem: IInternalLogisticsTransform | undefined = result.find(
        (group) => group.key === key,
      );

      const dataDay = {
        ...item,
        day: currentDay,
      };

      if (existingItem) {
        existingItem.values?.push(dataDay);
        existingItem.rowVolume += +item.volume;
      } else {
        const value = {
          key,
          direction: item.direction,
          mark: item.mark,
        };

        const itemTransform: IInternalLogisticsTransform = {
          ...value,
          key,
          values: [dataDay],
          rowVolume: item.volume ? +item.volume : 0,
        };

        result.push(itemTransform);
      }

      return result;
    }, []);

    return this.sortingByMark(dataFormat);
  }

  sortingByMark(result: IInternalLogisticsTransform[]): IInternalLogisticsTransform[] {
    return result.sort((a, b) => {
      const nameA = a.direction.toUpperCase();
      const nameB = b.direction.toUpperCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });
  }

  initDirectionMarkMap(
    data: IInternalLogisticsTransform[],
    directionMarkMap: Map<string, string[]>,
  ): void {
    directionMarkMap.clear();

    data.forEach((item) => {
      const currentDirection = directionMarkMap.get(item.direction);

      if (currentDirection) {
        if (!currentDirection.includes(item.mark.title)) {
          currentDirection.push(item.mark.title);
          directionMarkMap.set(item.direction, currentDirection);
        }
      } else {
        directionMarkMap.set(item.direction, [item.mark.title]);
      }
    });
  }

  createTotalValues(
    data: IInternalLogisticsTransform[],
    directionMarkMap: Map<string, string[]>,
    totalValueMap: Map<string, { day: number; amount: string }[]>,
    mapKeyId: Map<string, IInternalLogistics[]>,
    days: number,
    amountTotalMap: Map<string, string>,
  ): void {
    this.initDirectionMarkMap(data, directionMarkMap);
    totalValueMap.clear();

    const arr: { direction: string; values: { day: number; amount: string }[] }[] = [];

    directionMarkMap.forEach((value, direction) => {
      const currentArr: { day: number; amount: string }[] = [];

      for (let day = 1; day <= days; day++) {
        const total = value.reduce((acc, mark) => {
          const item = mapKeyId.get(`${direction}_${mark}${day}`);
          if (item) {
            const filtered = item.reduce((acc, i) => {
              return i.volume ? (acc += +i.volume) : acc;
            }, 0);

            return (acc += +filtered);
          } else return acc;
        }, 0);
        currentArr.push({ day: day, amount: (Math.round((total / 1000) * 100) / 100).toString() });
      }
      arr.push({ direction: direction, values: currentArr });
    });

    if (arr.length) {
      const res = arr[0].values.reduce(
        (acc, _, index) => {
          let start = 0;

          arr.forEach((i) => {
            start += +i.values[index].amount;
          });
          acc.push({
            day: index + 1,
            amount: (Math.round(start * 100) / 100).toString(),
          });
          return acc;
        },
        [] as { day: number; amount: string }[],
      );

      arr.push({ direction: 'total', values: res });
    }

    arr.forEach((item) => {
      totalValueMap.set(item.direction, item.values);

      const amount = item.values.reduce((acc, el) => (acc += +el.amount), 0);

      amountTotalMap.set(item.direction, amount ? (Math.round(amount * 100) / 100).toString() : '');
    });

    console.log(totalValueMap);
  }
}
