import { Component, HostBinding, Input } from '@angular/core';
import { ControlDirective } from '../../directives/control.directive';
import { ControlErrorComponent } from '../control-error/control-error.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgForOf } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { TextComponent } from '../../../ui/components/text/text.component';

@Component({
  selector: 'nguk-selection-switch-ctrl',
  templateUrl: './selection-switch.component.html',
  styleUrls: ['./selection-switch.component.scss'],
  standalone: true,
  imports: [
    ControlErrorComponent,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    NgForOf,
    PaginatorModule,
    TextComponent
  ]
})
export class SelectionSwitchComponent extends ControlDirective {
  @Input() options: { value: any; label: string; additionalLabel?: string }[] = [];
  @HostBinding('class.nguk-selection-switch-ctrl') readonly selectionSwitchBaseClass = true;
}
