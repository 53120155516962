import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';

@Component({
  selector: 'modal-enrichment-standard-list-confirm',
  templateUrl: './modal-enrichment-standard-list-confirm.component.html',
  styleUrls: ['./modal-enrichment-standard-list-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalComponent, TextComponent, ButtonComponent],
})
export class ModalEnrichmentStandardListConfirmComponent
  extends ModalBase
  implements AfterViewInit
{
  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngAfterViewInit(): void {
    this.initModal();
  }
}
