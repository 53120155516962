import { EntityApi } from '../core/abstract/entity.api';
import { Injectable } from '@angular/core';
import { ApiProvider } from '../core/providers/api.provider';
import { IShippingPlanLoadingRule } from '../data-models/shipping-plan-loading-rule/shipping-plan-loading-rule.interface';
import { IEditShippingPlanLoadingRule } from '../data-models/shipping-plan-loading-rule/edit-shipping-plan-loading-rule.interface';

@Injectable({
  providedIn: 'root',
})
export class ShippingPlanLoadingRuleApiService extends EntityApi<
  IShippingPlanLoadingRule,
  IEditShippingPlanLoadingRule
> {
  protected readonly api = 'sourceData/shippingPlanLoadingRule';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
