import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortalsService {
  private headerTemplatePortalSource = new BehaviorSubject<null | TemplateRef<unknown>>(null);
  headerTemplatePortal$ = this.headerTemplatePortalSource.asObservable();

  set headerTemplatePortal(templateRef: TemplateRef<unknown> | null) {
    this.headerTemplatePortalSource.next(templateRef);
  }
}
