import { AfterViewInit, Directive, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { PortalsService } from '../../../core/services/portals.service';

@Directive({
  selector: '[ngukPage]',
  standalone: true,
})
export class Page implements AfterViewInit, OnDestroy {
  @ViewChild('headerPortalTemplateRef', { read: TemplateRef })
  headerPortalTemplateRef!: TemplateRef<unknown>;

  constructor(protected portalsService: PortalsService) {}

  ngAfterViewInit(): void {
    if (this.headerPortalTemplateRef) {
      this.portalsService.headerTemplatePortal = this.headerPortalTemplateRef;
    }
  }

  ngOnDestroy(): void {
    if (this.headerPortalTemplateRef) {
      this.portalsService.headerTemplatePortal = null;
    }
  }
}
