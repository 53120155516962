<div class="documents-submenu-item-root" [class.-opened]="isOpened && menuItems.length">
  <div class="wrapper">
    <div class="submenu-item-header" (click)="isOpened = !isOpened">
      <mat-icon *ngIf="menuItems.length" [svgIcon]="svgIconsEnum.ArrowUpDown" />
      <nguk-text weight="400" styleName="text-xs">{{ title }}</nguk-text>
    </div>

    <div class="document-submenu-item-list">
      <nguk-text
        *ngFor="let menuItem of menuItems"
        weight="400"
        styleName="text-xs"
        class="submenu-list-item"
        [class.active-current]="isActive()"
        (click)="changeItem(menuItem)"
        [routerLink]="getRouterLink(menuItem)"
        [routerLinkActive]="'-active'"
      >
        {{ menuItem.text }}
      </nguk-text>
    </div>
  </div>
</div>
