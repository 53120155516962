import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsService } from './services/documents.service';
import { DocumentsPageComponent } from './components/pages/documents-page/documents-page.component';
import { PageComponent } from '../ui/components/page/page.component';
import { DocumentsListItemComponent } from './components/parts/documents-list-item/documents-list-item.component';
import { DocumentsListComponent } from './components/parts/documents-list/documents-list.component';
import { DocumentsContentComponent } from './components/parts/documents-content/documents-content.component';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { ButtonComponent } from '../ui/components/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { SectionComponent } from '../ui/components/section/section.component';
import { TextComponent } from '../ui/components/text/text.component';
import { DocumentsListSubmenuItemComponent } from './components/parts/documents-list-submenu-item/documents-list-submenu-item.component';
import { DocumentsContentHeaderComponent } from './components/parts/documents-content-header/documents-content-header.component';
import { SidebarListItemComponent } from '../ui/components/sidebar-list/sidebar-list-item.component';

@NgModule({
  declarations: [
    DocumentsPageComponent,
    DocumentsListComponent,
    DocumentsListItemComponent,
    DocumentsContentComponent,
    DocumentsListSubmenuItemComponent,
    DocumentsContentHeaderComponent,
  ],
  providers: [DocumentsService],
  imports: [
    CommonModule,
    PageComponent,
    RouterModule.forChild(routes),
    ButtonComponent,
    MatIconModule,
    SectionComponent,
    TextComponent,
    SidebarListItemComponent,
  ],
})
export class DocumentsModule {}
