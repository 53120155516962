<div class="wagon-fleet-list">
  <div class="sticky-container">
    <wagon-fleet-laden-unladen
      [listWagonAvailability]="listWagonAvailability"
      [dataInstance]="dataInstance"
      [wagonFleetId]="wagonFleetId"
      [isShow]="isShow"
      (updateData)="getDataLadenUnladlen(wagonFleetId)"
      (getCurrentTable)="getCurrentTable.emit($event)"
    />
    <wagon-fleet-under-loading
      [listWagonLoaded]="listWagonLoaded"
      [dataInstance]="dataInstance"
      [wagonFleetId]="wagonFleetId"
      [isShow]="isShow"
      (updateData)="getDataWagonLoaded(wagonFleetId)"
      (getCurrentSheet)="getCurrentSheet.emit($event)"
    />
  </div>
</div>
