<div class="nsi-row-actions-root">
  <nguk-button
    type="secondary"
    matTooltip="Копировать строку"
    (click)="duplicate.emit()"
  >
    <mat-icon [svgIcon]="svgIconsEnum.Duplicate" />
  </nguk-button>

  <nguk-button
    class="-delete-icon"
    type="secondary"
    matTooltip="Удалить строку"
    (click)="delete.emit()"
  >
    <mat-icon [svgIcon]="svgIconsEnum.Delete" />
  </nguk-button>
</div>
