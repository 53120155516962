import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommodityShipmentListComponent } from 'src/app/modules/calendar-plan/components/parts/commodity-shipment/commodity-shipment-list.component';
import { InternalLogisticsListComponent } from 'src/app/modules/calendar-plan/components/parts/internal-logistics-list/internal-logistics-list.component';
import { ResultMiningPlanListComponent } from 'src/app/modules/calendar-plan/components/parts/result-mining-plan-list/result-mining-plan-list.component';
import { ResultSupplyComponent } from 'src/app/modules/calendar-plan/components/parts/result-supply/result-supply.component';
import { WarehouseDynamicsComponent } from 'src/app/modules/calendar-plan/components/parts/warehouse-dynamics/warehouse-dynamics.component';
import { EnrichmentStandardListComponent } from 'src/app/modules/data/components/parts/enrichment-standard/enrichment-standard-list/enrichment-standard-list.component';
import { MiningPlanListComponent } from 'src/app/modules/data/components/parts/mining-plan/mining-plan-list/mining-plan-list.component';
import { ShippingPlanListComponent } from 'src/app/modules/data/components/parts/shipping-plan/shipping-plan-list/shipping-plan-list.component';
import { WarehouseBalanceListComponent } from 'src/app/modules/data/components/parts/warehouse-balance/warehouse-balance-list/warehouse-balance-list.component';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ActivatedRoute } from '@angular/router';
import { WagonFleetListComponent } from 'src/app/modules/data/components/parts/wagon-fleet/wagon-fleet-list/wagon-fleet-list.component';
import { GanttComponent } from '../../../calendar-plan/components/parts/gantt/gantt.component';
import { WorkSheet } from 'xlsx';

@Component({
  selector: 'print-tables',
  templateUrl: './print-tables.component.html',
  styleUrls: ['./print-tables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    MiningPlanListComponent,
    ShippingPlanListComponent,
    TextComponent,
    EnrichmentStandardListComponent,
    WarehouseBalanceListComponent,
    ResultMiningPlanListComponent,
    ResultSupplyComponent,
    WarehouseDynamicsComponent,
    InternalLogisticsListComponent,
    CommodityShipmentListComponent,
    WagonFleetListComponent,
    GanttComponent,
  ],
})
export class PrintTablesComponent implements OnInit {
  mapList = new Map<string, string>();
  listTables: { table: HTMLTableElement | WorkSheet; label: string }[] = [];

  @Output() getListTables = new EventEmitter();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const params = this.route.snapshot.queryParams;

    Object.keys(params).forEach((key) => {
      if (key === 'type') return;
      this.mapList.set(key, params[key]);
    });
  }

  setTable(table: HTMLTableElement | WorkSheet, label?: string): void {
    this.listTables.push({
      table,
      label: label ?? 'Под погрузкой',
    });

    this.getListTables.emit([...this.listTables]);
  }
}
