import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from '../../../../../ui/components/section/section.component';
import { ButtonComponent } from '../../../../../ui/components/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { MatTableModule } from '@angular/material/table';
import { TextComponent } from '../../../../../ui/components/text/text.component';
import { WagonFleetDropdownComponent } from '../wagon-fleet-dropdown/wagon-fleet-dropdown.component';
import { DictionariesEnum } from 'src/app/core/enums/dictionaries.enum';
import { DictionaryPipe } from '../../../../../../core/pipes/dictionary.pipe';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { InputComponent } from '../../../../../forms/components/input/input.component';
import { concat, finalize, forkJoin, of, take, tap } from 'rxjs';
import { CustomerApiService } from 'src/app/api/customer.api.service';
import { MarkApiService } from 'src/app/api/dictionaries/mark.api.service';
import { wagons } from '../wagon-fleet-under-loading/mockWagon';
import { IDictionaryModel } from 'src/app/data-models/dictionary-model/dictionary-model.interface';
import {
  IEmptyWagonAmountPeriod,
  IWagonAvailability,
} from 'src/app/data-models/wagon-availability/wagon-availability.interface';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { WagonAvailabilityApiService } from 'src/app/api/wagon-availability.api.service';
import { IEditWagonAvailability } from 'src/app/data-models/wagon-availability/edit-wagon-availability.interface';
import { SpinnerService } from 'src/app/modules/ui/services/spinner.service';
import { isNull, isNumber } from 'lodash-es';
import { DatepickerComponent } from '../../../../../forms/components/datepicker/datepicker.component';
import * as moment from 'moment';
import { DatePairComponent } from '../../../../../ui/components/date-pair/date-pair.component';
import * as XLSX from 'xlsx';

interface IColumn {
  name: string;
  display: string;
  type?: string;
  options?: IDictionaryModel[];
}

@Component({
  selector: 'wagon-fleet-laden-unladen',
  templateUrl: './wagon-fleet-laden-unladen.component.html',
  styleUrls: ['./wagon-fleet-laden-unladen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SectionComponent,
    ButtonComponent,
    MatIconModule,
    MatTableModule,
    TextComponent,
    WagonFleetDropdownComponent,
    DictionaryPipe,
    InputComponent,
    DatepickerComponent,
    DatePairComponent,
  ],
})
export class WagonFleetLadenUnladenComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() listWagonAvailability!: IWagonAvailability[];

  @Input() dataInstance!: IDataInstance;
  @Input() wagonFleetId!: string;
  @Input() isShow!: boolean;

  @ViewChild('table', { read: ElementRef })
  table!: ElementRef<HTMLTableElement>;
  @Output() getCurrentTable = new EventEmitter();

  @Output() updateData = new EventEmitter<void>();

  readonly svgIconsEnum = SvgIconsEnum;
  readonly dictionariesEnum = DictionariesEnum;

  data: IWagonAvailability[] = [];

  activeRow: number | undefined | 'th-table' = undefined;
  currentActionsRows = undefined;
  activeDateColumns: number | undefined = undefined;

  formData!: FormArray;
  formDataDate!: FormArray;
  customer = new FormControl<string>('');

  customersList: IDictionaryModel[] = [];
  marksList: IDictionaryModel[] = [];
  wagonList: IDictionaryModel[] = [];

  defaultDateMoment!: moment.Moment;

  columns: IColumn[] = [
    { name: 'index', display: '#', type: 'text' },
    { name: 'customer', display: 'Заказчик', type: 'dropdown', options: this.customersList },
    {
      name: 'wagonType',
      display: 'Тип вагона',
      type: 'dropdown',
      options: this.wagonList,
    },
    { name: 'emptyWagonAmount', display: 'На тер-рии', type: 'number' },
  ];

  columnsLaden: IColumn[] = [
    { name: 'loadedWagonMark1', display: 'Марка', type: 'dropdown', options: this.marksList },
    { name: 'loadedWagonAmount1', display: 'Кол-во' },
    { name: 'loadedWagonMark2', display: 'Марка', type: 'dropdown', options: this.marksList },
    { name: 'loadedWagonAmount2', display: 'Кол-во', type: 'number' },
    { name: 'loadedWagonMark3', display: 'Марка', type: 'dropdown', options: this.marksList },
    { name: 'loadedWagonAmount3', display: 'Кол-во', type: 'number' },
  ];

  defaultColumn: IColumn[] = [...this.columns, ...this.columnsLaden];

  columnsDate: IColumn[] = [];

  displayedColumns: string[] = [];
  displayedColumnsDate!: string[];
  removeIds: string[] = [];

  constructor(
    private fb: FormBuilder,
    private customerApiService: CustomerApiService,
    private markApiService: MarkApiService,
    private cdr: ChangeDetectorRef,
    private wagonAvailabilityApiService: WagonAvailabilityApiService,
    private ss: SpinnerService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['listWagonAvailability']?.currentValue) return;

    if (!this.wagonFleetId) return;
    if (!this.listWagonAvailability) return;

    this.initFormArray();

    this.listWagonAvailability.forEach((item) => {
      this.createNewFormGroup(null, item);
    });

    if (this.listWagonAvailability.length < 5) {
      while (this.formData.length < 5) {
        this.createNewFormGroup(null);
      }
    }

    if (
      !this.listWagonAvailability.length ||
      !this.listWagonAvailability[0].emptyWagonAmountPeriod?.length
    ) {
      let i = 4;
      this.defaultDateMoment = moment(this.dataInstance.dateStarted)
        .utcOffset(0, true)
        .startOf('day')
        .add(6, 'hours');
      while (i) {
        this.addColumn(true);
        i--;
      }
    }

    this.data = this.formData.value;
    this.updateColumnsDate();
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.initListDropdown();
    this.initColumnsDays();
    this.initFormArray();
  }

  ngAfterViewInit() {
    this.getCurrentTable.emit(this.table.nativeElement);
  }

  getCurrentList(value: string): IDictionaryModel[] {
    switch (value) {
      case 'Марка':
        return this.marksList;
      case 'Тип вагона':
        return this.wagonList;
      default:
        return this.customersList;
    }
  }

  initListDropdown(): void {
    const query = [
      {
        name: 'itemsPerPage',
        value: 500 * 1000,
      },
    ];
    forkJoin([this.customerApiService.getList(1, query), this.markApiService.getList(1, query)])
      .pipe(take(1))
      .subscribe(([customers, mark]) => {
        this.customersList = customers;
        this.marksList = mark;
        this.wagonList = wagons;

        this.cdr.markForCheck();
      });
  }

  initColumnsDays(): void {
    this.displayedColumns = [...this.columns, ...this.columnsLaden].map((item) => item.name);
  }

  initFormArray(): void {
    this.formData = this.fb.array([]);
    this.formDataDate = this.fb.array([]);
  }

  selectRow(index: number): void {
    if (this.isShow) return;
    this.activeRow = index;
  }

  getControl(index: number, value: string): FormControl {
    return (this.formData.controls[index] as FormGroup).get(value) as FormControl;
  }

  getArray(index: number, value: string): FormArray {
    return (this.formData.controls[index] as FormGroup).get(value) as FormArray;
  }

  getEmptyAmountWagonControl(index: number, period: number): FormControl {
    return (
      (this.formData.controls[index].get('emptyWagonAmountPeriod') as FormArray).controls[
        period
      ] as FormGroup
    )?.get('amount') as FormControl;
  }

  createNewFormGroup(index: number | null, value?: IWagonAvailability): void {
    const item: IWagonAvailability | null =
      index || index === 0 ? this.formData.value[index] : value ?? null;
    const fg = this.fb.group({
      customer: this.fb.array(
        item?.customer?.map((fg) => {
          return this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          });
        }) ?? [],
      ),
      wagonType: this.fb.array(
        item?.wagonType?.map((fg) => {
          return this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          });
        }) ?? [],
      ),
      id: [item?.id && isNull(index) ? item.id : null],

      wagonFleet: this.fb.group({
        id: [this.wagonFleetId],
      }),

      loadedWagonMark1: this.fb.array(
        item?.loadedWagonMark1?.map((fg) => {
          return this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          });
        }) ?? [],
      ),
      loadedWagonMark2: this.fb.array(
        item?.loadedWagonMark2?.map((fg) => {
          return this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          });
        }) ?? [],
      ),
      loadedWagonMark3: this.fb.array(
        item?.loadedWagonMark3?.map((fg) => {
          return this.fb.group({
            id: [fg?.id ?? null],
            title: [fg?.title ?? null],
          });
        }) ?? [],
      ),
      loadedWagonAmount1: [item?.loadedWagonAmount1 ?? null],
      loadedWagonAmount2: [item?.loadedWagonAmount2 ?? null],
      loadedWagonAmount3: [item?.loadedWagonAmount3 ?? null],
      emptyWagonAmount: [item?.emptyWagonAmount ?? null],
      emptyWagonAmountPeriod: this.fb.array([]),
    });
    this.initEmptyWagonPeriodControl(fg, item);

    this.formData.push(fg);
  }

  updateColumnsDate(): void {
    if (!this.data.length) {
      this.columnsDate = [];
      this.initColumnsDays();
      return;
    }

    this.columnsDate = this.data[0].emptyWagonAmountPeriod?.length
      ? this.data[0].emptyWagonAmountPeriod?.map((item, index) => ({
          name: `emptyWagonAmountPeriod${index + 1}`,
          display: '',
          type: 'number',
        }))
      : this.formDataDate.length
        ? this.formDataDate.value.map((_: unknown, index: number) => ({
            name: `emptyWagonAmountPeriod${index + 1}`,
            display: '',
            type: 'number',
          }))
        : [];

    this.displayedColumns = [...this.columns, ...this.columnsDate, ...this.columnsLaden].map(
      (item) => item.name,
    );
  }

  convertDateToUTC(date: string | Date): Date {
    const localDate = moment(date).utc();
    const localYear = localDate.year();
    const localMonth = localDate.month();
    const localDay = localDate.date();
    const localHours = localDate.hours();

    return new Date(localYear, localMonth, localDay, localHours, 0, 0);
  }

  initEmptyWagonPeriodControl(fg: FormGroup, item: IWagonAvailability | null): void {
    if (!item?.emptyWagonAmountPeriod?.length && this.formDataDate.length) {
      let i = this.formDataDate.length;
      while (i) {
        this.createNewColumnDate(fg);
        i--;
      }
      return;
    }

    item?.emptyWagonAmountPeriod?.forEach((row, index) => {
      this.createNewColumnDate(fg, row);
      this.createNewElementFormDataDate(false, index, row);
    });
  }

  createNewColumnDate(fg: FormGroup, row?: IEmptyWagonAmountPeriod): void {
    const groupPeriodList = this.fb.group({
      date: [row?.date ?? null],
      amount: [row?.amount ?? null],
    });

    (fg.get('emptyWagonAmountPeriod') as FormArray)?.push(groupPeriodList);
  }

  createNewElementFormDataDate(
    isInit = false,
    index?: number,
    row?: IEmptyWagonAmountPeriod,
  ): void {
    if (!isInit) {
      if (isNumber(index) && this.formDataDate.controls[index]) return;
      const controlPeriodDate = this.fb.control(
        this.convertDateToUTC(row?.date || this.dataInstance.dateStarted || new Date()),
      );
      this.formDataDate.push(controlPeriodDate);
    } else {
      const controlPeriodDate = this.fb.control(
        this.convertDateToUTC(this.defaultDateMoment.format()),
      );
      this.formDataDate.push(controlPeriodDate);
      this.defaultDateMoment.add(12, 'hours');
    }
  }

  getControlDate(i: number): FormControl {
    return this.formDataDate.controls[i] as FormControl;
  }

  addColumn(isInit = false): void {
    this.formData.controls.forEach((fg) => {
      this.createNewColumnDate(fg as FormGroup);
    });

    this.createNewElementFormDataDate(isInit);

    this.data = this.formData.value;
    this.updateColumnsDate();

    this.cdr.detectChanges();
  }

  addRow(index?: number): void {
    this.createNewFormGroup(typeof index === 'number' ? index : null);
    this.data = this.formData.value;
    this.updateColumnsDate();
    this.cdr.detectChanges();
  }

  removeRow(index: number): void {
    const id = this.formData.controls[index].get('id')?.value;

    if (id) this.removeIds.push(id);
    this.formData.removeAt(index);
    this.data = this.formData.value;
  }

  removeColumn(index: number): void {
    this.formData.controls.forEach((fg) => {
      (fg.get('emptyWagonAmountPeriod') as FormArray).removeAt(index);
    });

    this.formDataDate.removeAt(index);
    this.data = this.formData.value;
    this.updateColumnsDate();

    this.cdr.detectChanges();
  }

  save(): void {
    this.ss.startSpinner();

    const listDates = (this.formDataDate.value as string[]).map((date) =>
      date ? moment(date).utcOffset(0, true).format() : '',
    );

    const arrValue = [...(this.formData.value as IEditWagonAvailability[])].map((item) => {
      item.emptyWagonAmountPeriod?.map((period, index) => {
        period.date = listDates[index];
        return period;
      });
      return item;
    });

    const createValuesRequests = arrValue
      .filter((item) => !item.id)
      .map((item) => {
        const body = { ...item };
        delete body['id'];
        return this.wagonAvailabilityApiService.create(item);
      });

    const changeValuesRequests = arrValue
      .filter((item) => item.id)
      .map((item) => this.wagonAvailabilityApiService.update(item));

    const removeValuesRequests = this.removeIds.map((id) =>
      this.wagonAvailabilityApiService.delete(id),
    );

    forkJoin([
      createValuesRequests.length ? concat(...createValuesRequests) : of([]),
      ...changeValuesRequests,
      ...removeValuesRequests,
    ])
      .pipe(
        take(1),
        finalize(this.ss.stopSpinner),
        tap(() => {
          this.updateData.emit();
          this.removeIds = [];
        }),
      )
      .subscribe();
  }

  getCurrentPeriod(date: string): string {
    const dateMoment = moment(date).utcOffset(0, true);
    return dateMoment.format('DD.MM | HH.mm');
  }
}
