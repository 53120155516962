<div class="current-user">
  <div class="user-data">
    <nguk-text class="user-data__name" weight="400" styleName="text-xs">
      Кудрявцева К.
    </nguk-text>
    <nguk-text class="user-data__role" weight="500" styleName="text-xs">
      Планировщик КП
    </nguk-text>
  </div>
  <mat-icon class="exit-icon" [svgIcon]="svgIconsEnum.ExitIcon" (click)="logout.emit()"></mat-icon>
</div>
