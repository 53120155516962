import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, finalize, forkJoin, of, take } from 'rxjs';
import {
  IEditWarehouseBalanceItem,
  IWarehouseBalanceItem,
} from 'src/app/data-models/enrichment-standard/warehouse-balance.interface';
import { WarehouseBalanceItemApiService } from 'src/app/api/warehouse-balance-item.api.service';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { cloneDeep } from 'lodash-es';
import { DictionariesEnum } from 'src/app/core/enums/dictionaries.enum';
import { DictionariesService } from 'src/app/core/services/dictionaries.service';
import { IDictionaryModel } from 'src/app/data-models/dictionary-model/dictionary-model.interface';
import { SpinnerService } from 'src/app/modules/ui/services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class ModalWarehouseBalanceArrayService {
  close!: EventEmitter<void>;
  update!: EventEmitter<void>;
  isCopy!: boolean;

  formData!: FormGroup;
  data!: IWarehouseBalanceItem[];
  dataInstance!: IDataInstance;
  warehouseBalanceId!: string;

  fieldsToSkip = ['id', 'dataInstance'];

  paramsDictionaries = [
    DictionariesEnum.Mark,
    DictionariesEnum.Area,
    DictionariesEnum.Layer,
    DictionariesEnum.Profile,
  ];
  currentParams: DictionariesEnum[] = [];

  constructor(
    private warehouseBalanceItemApiService: WarehouseBalanceItemApiService,
    private dictionariesService: DictionariesService,
    private ss: SpinnerService,
  ) {}

  chechValidForm(): boolean {
    if (this.data.length > 1) return true;

    this.formData.markAllAsTouched();

    if (this.formData.invalid) {
      Object.keys(this.formData.controls).forEach((controlName) => {
        this.formData.get(controlName)?.updateValueAndValidity();
      });
      return false;
    } else return true;
  }

  createOptionsDictionaries(): Observable<IDictionaryModel[]> {
    const data = { ...this.formData.value };
    this.currentParams = this.paramsDictionaries.filter(
      (item) => data[item] && typeof data[item] === 'string',
    );

    const requestDictionaries = this.currentParams.map((item) =>
      this.dictionariesService.createOption(item, data[item]),
    );

    return requestDictionaries.length > 0 ? forkJoin(requestDictionaries) : of([]);
  }

  transformData(
    data: IWarehouseBalanceItem[],
    form: IWarehouseBalanceItem,
  ): IWarehouseBalanceItem[] {
    return data.map((item) => {
      Object.keys(form).forEach((key) => {
        if (this.fieldsToSkip.includes(key)) return;
        if (form[key] && item[key] !== form[key]) item[key] = form[key];
      });
      return item;
    });
  }

  saveArray(): void {
    if (!this.chechValidForm()) return;

    this.ss.startSpinner();

    this.createOptionsDictionaries()
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((arrayResponse) => {
        arrayResponse.forEach((response, index) => {
          this.formData.get(this.currentParams[index])?.setValue(response);
        });

        const form: IWarehouseBalanceItem = { ...this.formData.value };
        const dataFormat = cloneDeep(this.data);

        const data = this.transformData(dataFormat, form);
        this.nextStepToSaveArray(this.data.length ? data : [form]);
      });
  }

  nextStepToSaveArray(data: IEditWarehouseBalanceItem[]): void {
    this.ss.startSpinner();

    const requests = data.map((item) => {
      const body: IEditWarehouseBalanceItem = { ...item };
      if (!this.isCopy) {
        return this.changeItem(body);
      } else {
        delete body['id'];
        return this.createItem(body);
      }
    });
    forkJoin(requests)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => {
        this.isCopy && this.data.length ? this.update.emit() : this.close.emit();
      });
  }

  changeItem(body: IEditWarehouseBalanceItem) {
    return this.warehouseBalanceItemApiService
      .update(body ? body : this.formData.value)
      .pipe(take(1));
  }

  createItem(body: IEditWarehouseBalanceItem) {
    return this.warehouseBalanceItemApiService.create(body).pipe(take(1));
  }

  removeArray() {
    const request = this.data.map((item) => {
      return this.warehouseBalanceItemApiService.delete(item.id);
    });
    forkJoin(request)
      .pipe(take(1))
      .subscribe(() => {
        this.close.emit();
      });
  }

  getControlValue(value: string): string {
    return this.formData.get(value)?.value || '';
  }
}
