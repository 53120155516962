import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ModalBase } from '../../../../ui/base/modal.base';

@Component({
  selector: 'calculations-cancel-calculation-modal',
  templateUrl: './cancel-calculation-modal.component.html',
  styleUrls: ['./cancel-calculation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelCalculationModalComponent extends ModalBase implements AfterViewInit {
  constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngAfterViewInit(): void {
    this.initModal();
  }
}
