import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'nguk-spinner',
  template: `
    <div class="nguk-spinner-root">
      <ng-container *ngIf="spinner$ | async as spinner">
        <mat-spinner *ngIf="spinner.show" [diameter]="spinner.size"></mat-spinner>
      </ng-container>
    </div>
  `,
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class SpinnerComponent {
  readonly spinner$ = this.spinnerService.spinner$.asObservable();

  constructor(private spinnerService: SpinnerService) {}
}
