<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header" *ngIf="!isShow">
      <nguk-text weight="600" styleName="text-xl" *ngIf="!data.length">Создание</nguk-text>

      <nguk-toggle
        [toggleList]="toggleList"
        [control]="toggleControl"
        *ngIf="data.length"
      ></nguk-toggle>

      <modal-enrichment-standard-list-actions
        class="modal-enrichment-standard-list-actions"
        [toggleControl]="toggleControl"
        [data]="data"
        [toggleValue]="toggleValue"
        [formData]="formData"
        (close)="closeModal(true)"
        (saveData)="saveData = true"
        (updateData)="updateData()"
      ></modal-enrichment-standard-list-actions>
    </div>

    <modal-enrichment-standard-list-form
      [isShow]="isShow"
      [dataInstanceId]="dataInstanceId"
      [toggleValue]="toggleValue"
      (form)="getForm($event)"
      [data]="data"
      [saveData]="saveData"
    ></modal-enrichment-standard-list-form>
  </div>
</nguk-modal>
