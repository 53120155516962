import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from '../../../../ui/components/section/section.component';
import { MatTableModule } from '@angular/material/table';
import { TextComponent } from '../../../../ui/components/text/text.component';
import { ActivatedRoute } from '@angular/router';
import { Observable, take, takeWhile } from 'rxjs';
import { JournalApiService } from 'src/app/api/journal.api.service';
import { OperationalPlanJournalApiService } from 'src/app/api/operational-plan-journal.api.service';
import { IGraph } from 'src/app/data-models/graph/graph.interface';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { HttpClient } from '@angular/common/http';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'calendar-plan-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SectionComponent, MatTableModule, TextComponent, MatIconModule],
})
export class GraphsComponent implements OnInit, OnDestroy {
  isOperationalPlan = false;
  alive = true;
  data!: IGraph[];

  id!: string;
  url!: string;

  readonly svgIconsEnum = SvgIconsEnum;

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private journalApiService: JournalApiService,
    private operationalPlanJournalApiService: OperationalPlanJournalApiService,
    private http: HttpClient,
  ) {}

  ngOnDestroy(): void {
    this.alive = false;
  }

  ngOnInit(): void {
    this.initParams();
  }

  initLinkForDownload(): void {
    const urlFull = process.env['BACKEND_URL'] as string;
    const link = urlFull.slice(0, urlFull.length - 4);
    this.url = `${link}/graph/${this.id}.zip`;
  }

  initParams(): void {
    this.isOperationalPlan = !!this.route.parent?.snapshot.data['isOperationalPlan'];

    this.route.parent?.params.pipe(takeWhile(() => this.alive)).subscribe((params) => {
      this.id = params['calendarPlanId'];
      if (!this.id) {
        this.url = '';
        return;
      }

      this.initLinkForDownload();

      const journalObs = this.isOperationalPlan
        ? this.getJournalOP(this.id)
        : this.getJournalKP(this.id);

      journalObs.subscribe((journal) => {
        this.data = (journal.graph?.list ?? [])
          .sort((a, b) => a.position - b.position)
          .map((item) => {
            item.size = (Math.round((+item.size / 1024) * 100) / 100).toString() + ' kb';
            return item;
          });

        if (!this.data.length) this.url = '';
        this.cdr.detectChanges();
      });
    });
  }

  getJournalKP(id: string): Observable<IJournal> {
    return this.journalApiService.getOne(id).pipe(take(1));
  }
  getJournalOP(id: string): Observable<IJournal> {
    return this.operationalPlanJournalApiService.getOne(id).pipe(take(1));
  }

  downloadGraph(): void {
    const urlFull = process.env['BACKEND_URL'] as string;
    const url = urlFull.slice(0, urlFull.length - 4);
    const a = document.createElement('a');
    a.href = `${url}/graph/${this.id}.zip`;
    a.download = `${this.id}.zip`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}
