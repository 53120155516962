import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { takeWhile } from 'rxjs';
import { ModalBase } from './modal.base';

export class ActionModal {
  isAliveModal = true;
  modalContainer!: ViewContainerRef;
  closeInteraction!: () => void;

  open<T extends ModalBase>(comp: Type<T>): ComponentRef<T> {
    this.isAliveModal = true;
    this.modalContainer.clear();
    const modalComponent = this.modalContainer.createComponent(comp);

    modalComponent.instance.openSubject
      .pipe(takeWhile(() => this.isAliveModal))
      .subscribe((res) => this.destroy(res));

    return modalComponent;
  }

  private destroy = (res: boolean | null): void => {
    this.isAliveModal = false;
    this.modalContainer.clear();

    if (!this.closeInteraction || !res) return;
    this.closeInteraction();
  };
}
