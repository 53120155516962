import { EntityApi } from '../core/abstract/entity.api';
import { Injectable } from '@angular/core';
import { ApiProvider } from '../core/providers/api.provider';
import { DictionaryRowItemModel } from '../data-models/dictionary/dictionary-row-item.model';
import { EditDictionaryRowItemModel } from '../data-models/dictionary/edit-dictionary-row-item.model';

@Injectable({
  providedIn: 'root'
})
export class DictionaryRowItemApiService extends EntityApi<
  DictionaryRowItemModel,
  EditDictionaryRowItemModel
> {
  protected readonly api = 'sourceData/dictionaryRowItem';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
