<div class="print-info-root" *ngIf="journal">
  <div class="data-info">
    <div class="calculation-log-info">
      <nguk-text weight="700" styleName="text-xs">Основные данные</nguk-text>
      <div class="parameter">
        <nguk-text styleName="text-xs" color="grey" class="title">Номер расчета</nguk-text>
        <nguk-text styleName="text-xs">{{ journal.id }}</nguk-text>
      </div>

      <div class="parameter">
        <nguk-text styleName="text-xs" color="grey" class="title">Дата создания расчета</nguk-text>
        <nguk-text styleName="text-xs">{{ journal.dateCreated | date: 'dd.MM.YYYY' }}</nguk-text>
      </div>

      <div class="parameter">
        <nguk-text styleName="text-xs" color="grey" class="title">Интервал планирования</nguk-text>
        <nguk-text styleName="text-xs"
          >с {{ journal.datePlanStarted | date: 'dd.MM.YYYY' }} по
          {{ journal.datePlanFinished | date: 'dd.MM.YYYY' }}
        </nguk-text>
      </div>

      <div class="parameter -comments">
        <nguk-text styleName="text-xs" color="grey" class="title">Комментарий</nguk-text>
        <nguk-text styleName="text-xs">{{ journal.note }}</nguk-text>
      </div>
    </div>
    <div class="data-instance-info">
      <nguk-text weight="700" styleName="text-xs"
        >План рассчитан на основе следующих экземпляров исходных данных:
      </nguk-text>

      <div class="parameter">
        <nguk-text styleName="text-xs" color="grey" class="title">Сбытовая потребность </nguk-text>
        <nguk-text styleName="text-xs" class="value"
          >Сформирован:
          {{ mapJournalDataInstance.get('shippingPlan')?.dateCreated | date: 'dd.MM.YYYY' }}.
          Примечание: {{ mapJournalDataInstance.get('shippingPlan')?.note }}</nguk-text
        >
      </div>

      <div class="parameter">
        <nguk-text styleName="text-xs" color="grey" class="title">Складские остатки </nguk-text>
        <nguk-text styleName="text-xs" class="value"
          >Сформирован:
          {{ mapJournalDataInstance.get('warehouseBalance')?.dateCreated | date: 'dd.MM.YYYY' }}.
          Примечание: {{ mapJournalDataInstance.get('warehouseBalance')?.note }}</nguk-text
        >
      </div>

      <div class="parameter">
        <nguk-text styleName="text-xs" color="grey" class="title">План добычи</nguk-text>
        <nguk-text styleName="text-xs" class="value"
          >Сформирован:
          {{ mapJournalDataInstance.get('miningPlan')?.dateCreated | date: 'dd.MM.YYYY' }}.
          Примечание: {{ mapJournalDataInstance.get('miningPlan')?.note }}</nguk-text
        >
      </div>

      <div class="parameter">
        <nguk-text styleName="text-xs" color="grey" class="title">Нормы выхода</nguk-text>
        <nguk-text styleName="text-xs" class="value"
          >Сформирован:
          {{ mapJournalDataInstance.get('enrichmentStandard')?.dateCreated | date: 'dd.MM.YYYY' }}.
          Примечание: {{ mapJournalDataInstance.get('enrichmentStandard')?.note }}</nguk-text
        >
      </div>

      <div class="parameter" *ngIf="mapJournalDataInstance.get('wagonFleet')">
        <nguk-text styleName="text-xs" color="grey" class="title">Парк вагонов</nguk-text>
        <nguk-text styleName="text-xs" class="value"
          >Сформирован:
          {{ mapJournalDataInstance.get('wagonFleet')?.dateCreated | date: 'dd.MM.YYYY' }}.
          Примечание: {{ mapJournalDataInstance.get('wagonFleet')?.note ?? '' }}</nguk-text
        >
      </div>
    </div>
  </div>

  <div class="data-developed-info">
    <div class="service">
      <nguk-text weight="700" styleName="text-xs"
        >План рассчитан средствами подсистемы «Оперативное планирование фабрик»
      </nguk-text>
    </div>
  </div>
</div>
