import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Output,
} from '@angular/core';
import { SvgIconsEnum } from '../../../../../core/enums/svg-icons.enum';
import { ButtonComponent } from '../../../../ui/components/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'nsi-row-actions',
  templateUrl: './nsi-row-actions.component.html',
  styleUrls: ['./nsi-row-actions.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, MatIconModule, MatTooltipModule],
})
export class NsiRowActionsComponent {
  @Output() readonly duplicate = new EventEmitter<void>();
  @Output() readonly delete = new EventEmitter<void>();
  @HostBinding('class.nsi-row-actions') baseClass = true;

  readonly svgIconsEnum = SvgIconsEnum;
}
