import { Component } from '@angular/core';
import { RoutesHelper } from '../../../../helpers/routes.helper';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NavigationButtonComponent } from '../../../ui/components/navigation-button/navigation-button.component';
import { TextComponent } from '../../../ui/components/text/text.component';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconsEnum } from '../../../../core/enums/svg-icons.enum';

@Component({
  selector: 'nguk-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, NavigationButtonComponent, TextComponent, MatIconModule],
})
export class NavigationComponent {
  readonly routesHelper = RoutesHelper;
  readonly svgIconsEnum = SvgIconsEnum;
}
