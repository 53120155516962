<div class="journal-list-short-root">
  <nguk-section>
    <ng-container sectionTitle>Журнал расчётов</ng-container>
    <ng-container sectionActions>
      <nguk-button type="primary" class="create-button" (click)="openModalJournal()">
        <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
      </nguk-button>
    </ng-container>

    <div class="journal-list">
      <ng-container *ngIf="dictionaryMap">
        <nguk-sidebar-list-item
          *ngFor="let item of data; let index = index; let last = last"
          [firstId]="id"
          [data]="item"
          [indexData]="index"
          [lastData]="last"
          [mapIds]="mapIds"
          [submenu]="initialDataSubmenu"
          [dictionaryMap]="dictionaryMap"
          (openModalItem)="openModalItem($event)"
        ></nguk-sidebar-list-item>
      </ng-container>
    </div>
  </nguk-section>
</div>

<ng-template #modal></ng-template>
