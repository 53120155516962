<div
  class="nguk-notification-root -{{ notification?.type }}"
  [class.-show]="isVisible"
>
  <ng-container>
    <mat-icon class="type-icon" [svgIcon]="notificationTypeIcon" />
    <mat-icon class="close-icon" [svgIcon]="svgIconsEnum.Close" (click)="closeNotification()" />

    <div class="content">
      <nguk-text styleName="text-sm">{{ notification?.text }}</nguk-text>
    </div>
  </ng-container>
</div>
