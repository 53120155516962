import { CommonModule } from '@angular/common';
import { SvgIconsEnum } from '../../../../core/enums/svg-icons.enum';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'nguk-modal-actions',
  templateUrl: './modal-actions.component.html',
  styleUrls: ['./modal-actions.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ButtonComponent, MatIconModule, MatTooltipModule],
})
export class ModalActionsComponent {
  @Input() isChange = true;
  @Input() showSave = true;
  @Input() showCopy = false;
  @Input() showDelete = true;
  @Output() readonly save = new EventEmitter<void>();
  @Output() readonly copy = new EventEmitter<void>();
  @Output() readonly delete = new EventEmitter<void>();

  readonly svgIconsEnum = SvgIconsEnum;
}
