<div class="modal-journal-list-short-actions-footer-root">
  <div class="container-actions" *ngIf="journal && !isCopy && showButtonCalculate; else idLogs">
    <nguk-checkbox-ctrl [control]="graphsControl">
      <ng-container label>
        <nguk-text color="grey" weight="400" styleName="text-xs">Графы</nguk-text>
      </ng-container>
    </nguk-checkbox-ctrl>

    <nguk-button type="primary" (click)="calculate()">
      <mat-icon [svgIcon]="svgIconsEnum.Calculate"></mat-icon>
      <nguk-text weight="400" styleName="text-sm">Произвести расчёт</nguk-text>
    </nguk-button>
  </div>

  <ng-template #idLogs>
    <nguk-text
      weight="400"
      color="grey"
      styleName="text-xs"
      class="idLogs"
      *ngIf="journal?.taskLogs?.length"
      >id расчета: {{ plannerId }}</nguk-text
    >
  </ng-template>
</div>
