import { Pipe, PipeTransform } from '@angular/core';
import { IResultSupplyItemTransform } from 'src/app/data-models/result-supply-item/result-supply-item.interface';

@Pipe({
  name: 'resultSupplyBorderHidden',
  standalone: true,
})
export class ResultSupplyBorderHiddenPipe implements PipeTransform {
  transform(index: number, data: IResultSupplyItemTransform[]): boolean {
    return data[index + 1] && data[index].enrichmentComplex !== data[index + 1].enrichmentComplex;
  }
}
