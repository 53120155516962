import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import { ICommodityShipmentQualitySpecification } from '../data-models/commodity-shipment-quality-specification/commodity-shipment-quality-specification.interface';

@Injectable({
  providedIn: 'root',
})
export class CommodityShipmentQualitySpecificationApiService extends EntityApi<
  ICommodityShipmentQualitySpecification,
  ICommodityShipmentQualitySpecification
> {
  protected readonly api = 'result/commodityShipmentQualitySpecification';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
