import { Pipe, PipeTransform } from '@angular/core';
import { IInternalLogistics } from 'src/app/data-models/internal-logistics/internal-logistics.interface';

@Pipe({
  name: 'internalLogisticsValueCell',
  standalone: true,
})
export class InternalLogisticsValueCellPipe implements PipeTransform {
  transform(key: string, day: number, mapKeyId: Map<string, IInternalLogistics[]>): string {
    const value = mapKeyId.get(key + day)?.reduce((acc, item) => +item.volume + acc, 0);
    return value ? (Math.round((value / 1000) * 100) / 100).toString() : '';
  }
}
