import { EntityApi } from '../core/abstract/entity.api';
import { Injectable } from '@angular/core';
import { ApiProvider } from '../core/providers/api.provider';
import { ITaskLog } from '../data-models/task-log/task-log.interface';
import { IEditTaskLog } from '../data-models/task-log/edit-create-task-log.interface';
import { Observable } from 'rxjs';
import { ApiRequestModel } from '../core/models/api-request.model';

@Injectable({
  providedIn: 'root',
})
export class TaskLogApiService extends EntityApi<ITaskLog, IEditTaskLog> {
  protected readonly api = 'result/taskLog';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }

  check(): Observable<{ check: boolean }> {
    const apiModel = new ApiRequestModel(this.api)
      .addToPath('check')
      .addToQuery('page', 1)
      .addToQuery('itemsPerPage', 50 * 100);

    return this.apiProvider.get(apiModel);
  }

  reloadData(id: string) {
    const apiModel = new ApiRequestModel(this.api, {}).addToPath(id).addToPath('reload');
    return this.apiProvider.put(apiModel);
  }
}
