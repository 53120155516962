import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { ToggleComponent } from 'src/app/modules/forms/components/toggle/toggle.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FileValidators, NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { ModalUploadRelatedSystemsComponent } from '../modal-upload-related-systems/modal-upload-related-systems.component';

enum ToggleListValue {
  Excel = 'Из Excel',
  Other = 'Из смежных систем',
}

@Component({
  selector: 'modal-upload',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TextComponent,
    ButtonComponent,
    ToggleComponent,
    MatIconModule,
    ReactiveFormsModule,
    NgxFileDragDropModule,
    ModalUploadRelatedSystemsComponent,
  ],
  templateUrl: './modal-upload.component.html',
  styleUrls: ['./modal-upload.component.scss'],
})
export class ModalUploadComponent extends ModalBase implements AfterViewInit {
  filesUpload!: File[];
  fileControl = new FormControl([], [FileValidators.required, FileValidators.maxFileCount(1)]);
  status: 'success' | 'error' | undefined = undefined;
  text = '';

  arrayError = ['Сбытовая портебность', 'Пласт', 'Компл-я ЖД состава'];

  readonly toggleListValue = ToggleListValue;

  get isFileAttached(): boolean {
    return Array.isArray(this.fileControl.value) && !!this.fileControl.value.length;
  }

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  toggleList = [ToggleListValue.Excel, ToggleListValue.Other];
  toggleControl = new FormControl<string | null>(this.toggleList[0]);

  ngAfterViewInit(): void {
    this.initModal();
  }

  uploadFile(): void {
    if (!this.isFileAttached) {
      return;
    }

    this.filesUpload = [...(this.fileControl.value as File[])];
    this.closeModal(null);
  }

  clear() {
    this.fileControl.setValue(null);
  }
}
