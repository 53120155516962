import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { IResultSupplyItem } from 'src/app/data-models/result-supply-item/result-supply-item.interface';

@Component({
  selector: 'calendar-plan-modal-result-supply',
  templateUrl: './modal-result-supply.component.html',
  styleUrls: ['./modal-result-supply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalComponent, TextComponent, MatTableModule, TextComponent],
})
export class ModalResultSupplyComponent extends ModalBase implements AfterViewInit {
  data!: IResultSupplyItem[];
  day!: number;

  columns: IDataColumnTable[] = [
    { name: 'index', display: '#' },
    { name: 'warehouse', display: 'Направление' },
    { name: 'area', display: 'Участок' },
    { name: 'mark', display: 'Марка' },
    { name: 'layer', display: 'Пласт' },
    { name: 'profile', display: 'Профиль' },
    { name: 'ad', display: 'Ad' },
    { name: 'vd', display: 'Vd' },
    { name: 'y', display: 'Y' },
  ];

  displayedColumns = this.columns.map((item) => item.name);

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngAfterViewInit(): void {
    this.initModal();
  }
}
