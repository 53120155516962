<div class="nguk-autocomplete-ctrl-root" [class.-horizontal]="direction === 'horizontal'">
  <ng-content select="[label]" />

  <mat-form-field appearance="outline">
    <input type="text" matInput [formControl]="control" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (closed)="closed.emit()">
      <mat-option *ngFor="let option of options" [value]="option">
        <div class="mat-option-container">
          <nguk-text styleName="text-sm">{{ option.title }}</nguk-text>
        </div>
      </mat-option>
    </mat-autocomplete>
    <nguk-control-error [showError]="errorState">{{ error }}</nguk-control-error>
  </mat-form-field>
</div>
