import { Component } from '@angular/core';

@Component({
  selector: 'documents-content-header',
  templateUrl: './documents-content-header.component.html',
  styleUrls: ['./documents-content-header.component.scss'],
})
export class DocumentsContentHeaderComponent {

}
