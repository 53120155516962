import { Route } from '@angular/router';
import { RoutesHelper } from '../../helpers/routes.helper';
import { NsiPageComponent } from './components/pages/nsi-page/nsi-page.component';
import { NsiTableMatrixTypeComponent } from './components/parts/nsi-table-matrix-type/nsi-table-matrix-type.component';
import { NsiTableListTypeComponent } from './components/parts/nsi-table-list-type/nsi-table-list-type.component';

export const routes: Route[] = [
  {
    path: RoutesHelper.EMPTY_PATH,
    component: NsiPageComponent,
    children: [
      {
        path: `:dataInstanceId/${RoutesHelper.NSI_LIST_PATH}/:dictionaryId`,
        component: NsiTableListTypeComponent,
      },
      {
        path: `:dataInstanceId/${RoutesHelper.NSI_MATRIX_PATH}/:dictionaryId`,
        component: NsiTableMatrixTypeComponent,
      },
    ],
  },
];
