import { Pipe, PipeTransform } from '@angular/core';
import { IInternalLogisticsTransform } from 'src/app/data-models/internal-logistics/internal-logistics.interface';

@Pipe({
  name: 'internalLogisticsBorderHidden',
  standalone: true,
})
export class InternalLogisticsBorderHiddenPipe implements PipeTransform {
  transform(index: number, data: IInternalLogisticsTransform[]): boolean {
    return data[index + 1] && data[index].direction !== data[index + 1].direction;
  }
}
