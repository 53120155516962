import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';

@Component({
  selector: 'header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TextComponent, MatIconModule],
})
export class HeaderUserComponent {
  @Output() readonly logout = new EventEmitter<void>();

  readonly svgIconsEnum = SvgIconsEnum;
}
