import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import { IEnrichmentStandard } from '../data-models/enrichment-standard/enrichment-standard.interface';
import { IEditEnrichmentStandard } from '../data-models/enrichment-standard/edit-enrichment-standard.interface';
import { Observable } from 'rxjs';
import { ApiRequestModel } from '../core/models/api-request.model';
import { IFileUploadResponse } from '../data-models/file-upload-response.interface';

@Injectable({
  providedIn: 'root',
})
export class EnrichmentStandardApiService extends EntityApi<
  IEnrichmentStandard,
  IEditEnrichmentStandard
> {
  protected readonly api = 'sourceData/enrichmentStandard';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }

  uploadFile = (formData: FormData, dataInstanceId: string): Observable<IFileUploadResponse> => {
    const apiModel = new ApiRequestModel(this.api, formData)
      .addToPath('upload')
      .addToPath(dataInstanceId);

    return this.apiProvider.postFile(apiModel);
  }
}
