import { Pipe, PipeTransform } from '@angular/core';
import { TShippingPlanListRow } from '../../types/shipping-plan-list-row.type';

@Pipe({
  name: 'shippingPlanCheckHorizontalArea',
  standalone: true,
})
export class ShippingPlanCheckHorizontalAreaPipe implements PipeTransform {
  transform(index: number, data: TShippingPlanListRow[]): boolean {
    if (index === 0) return false;
    return data[index].station === data[index - 1].station;
  }
}
