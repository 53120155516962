import { Injectable } from '@angular/core';
import { DataInstancesProviderService } from './data-instances-provider.service';
import { DataInstanceApiService } from 'src/app/api/data-instance.api.service';
import { Observable, map, take } from 'rxjs';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { TabStateService } from 'src/app/core/services/tab-state.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectDataService {
  constructor(
    private dataInstancesProviderService: DataInstancesProviderService,
    private dataInstanceApiService: DataInstanceApiService,
    private tabStateService: TabStateService,
  ) {}

  getDataInstance(id: string): Observable<IDataInstance> {
    this.dataInstancesProviderService.setNewDataInstance(id.toString());
    return this.dataInstanceApiService.getOne(id);
  }

  redirectToOwnPage(typeDataInstances: string): Observable<string | undefined> {
    return this.dataInstanceApiService
      .getList(1, [
        { name: 'type', value: typeDataInstances },
        { name: 'itemsPerPage', value: 500000 },
      ])
      .pipe(
        take(1),
        map((res) => {
          const dataInstances = res.sort(
            (lhs: IDataInstance, rhs: IDataInstance) =>
              new Date(rhs.dateCreated).getTime() - new Date(lhs.dateCreated).getTime(),
          );

          if (dataInstances.length) {
            const id = dataInstances[0].id;
            this.dataInstancesProviderService.setNewDataInstance(id!.toString());
            return id;
          }

          return undefined;
        }),
      );
  }

  redirectToCalendarPlan(idJournal: string, type: string): Observable<string | undefined> {
    return this.dataInstanceApiService.getList(1).pipe(
      take(1),
      map((res) => {
        const find = res.find(
          (item) =>
            item.calculationLog?.find((val) => +val.id === +idJournal && item.type === type),
        );
        return find?.id;
      }),
    );
  }

  initRedirect(router: Router, route: ActivatedRoute) {
    const idJournal = route.parent?.snapshot.params['calendarPlanId'];
    const data = route.snapshot.data;

    const typeDataInstances = route.snapshot.data['typeDataInstances'];

    if (!typeDataInstances) return;

    const savedTab = this.tabStateService.getLastTabUrl();

    idJournal
      ? this.redirectToCalendarPlan(idJournal, typeDataInstances).subscribe((id) => {
          if (id) {
            const currentId = savedTab.type === data['typeDataInstances'] ? savedTab.id : id;
            router.navigate([data['path'], currentId], { relativeTo: route });
          }
        })
      : this.redirectToOwnPage(typeDataInstances).subscribe((id) => {
          if (id) {
            const currentId = savedTab.type === data['typeDataInstances'] ? savedTab.id : id;

            router.navigate([currentId], { relativeTo: route });
          } else this.initParamsCurrentPage(route);
        });
  }

  initParamsCurrentPage(route: ActivatedRoute, id?: string) {
    const data = route.snapshot.data;

    this.dataInstancesProviderService.setNewCurrentPage(
      data['title'] || '',
      data['typeDataInstances'] || '',
      data['path'] || '',
    );

    this.saveLastTabOrJournal(route, id);
  }

  saveLastTabOrJournal(route: ActivatedRoute, id?: string): void {
    if (!id) return;

    const idJournal = route.parent?.snapshot.params['calendarPlanId'];
    const data = route.snapshot.data;

    if (idJournal) {
      this.setLastJournal(route, id, idJournal);
    } else {
      this.tabStateService.setCurrentTabUrl({
        type: data['typeDataInstances'] || '',
        path: data['path'] || '',
        id,
      });
    }
  }

  voidParamsCurrentPage(): void {
    this.dataInstancesProviderService.setNewCurrentPage('', '', '');
  }

  setLastJournal(route: ActivatedRoute, id: string, idJournal: string): void {
    const isOP = !!route.parent?.snapshot.data['isOperationalPlan'];
    const journal = {
      path: route.snapshot.url[0].path || '',
      dataInstanceId: id,
      id: idJournal,
      typeJournal: isOP ? 'OP' : 'KP',
    };

    isOP
      ? this.tabStateService.setLastJournalOP(journal)
      : this.tabStateService.setLastJournalKP(journal);
  }
}
