import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { TextComponent } from '../text/text.component';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { TSideBarSubmenuRoute } from 'src/app/modules/calendar-plan/types/journal-submenu-route.type';
import { SidebarListSubmenuItemComponent } from '../sidebar-list-submenu-item/sidebar-list-submenu-item.component';
import { BehaviorSubject, filter, takeWhile } from 'rxjs';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { IDocument } from 'src/app/data-models/document/document.interface';
import { SidebarListHasItemPipe } from '../../pipes/sidebar-list/sidebar-list-has-item.pipe';
import { NsiDictionaryModel } from 'src/app/data-models/dictionary/dictionary.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProviderActiveIdService } from 'src/app/modules/calendar-plan/services/journal-list-short/provider-active-id.service';
import * as moment from 'moment';

@Component({
  selector: 'nguk-sidebar-list-item',
  templateUrl: './sidebar-list-item.component.html',
  styleUrls: ['./sidebar-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TextComponent,
    SidebarListSubmenuItemComponent,
    SidebarListHasItemPipe,
  ],
})
export class SidebarListItemComponent implements OnInit, OnDestroy {
  @Input() data!: IJournal | IDataInstance | IDocument;
  @Input() mapIds!: Map<number, boolean>;
  @Input() lastData!: boolean;
  @Input() indexData!: number;
  @Input() firstId!: number;
  @Input() submenu!: TSideBarSubmenuRoute[];
  @Input() isCalculationComplete = false;
  @Input() dictionaryMap!: Map<string, NsiDictionaryModel[]>;

  itemJournal!: IJournal;
  itemDataInstance!: IDataInstance;
  itemDocument!: IDocument;

  @Output() openModalItem = new EventEmitter<IJournal>();
  @Output() onToggleItem = new EventEmitter<void>();

  readonly svgIconsEnum = SvgIconsEnum;

  isSingleClick = true;
  isAlive = true;
  isOperationalPlan = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    public providerActiveIdService: ProviderActiveIdService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeWhile(() => this.isAlive),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngOnInit(): void {
    this.isOperationalPlan = !!this.route.snapshot.data['isOperationalPlan'];

    if ('taskLogs' in this.data) {
      this.itemJournal = this.data;
    } else if ('status' in this.data) {
      this.itemDocument = this.data;
    } else {
      this.itemDataInstance = this.data;
    }
  }

  isOpened(): boolean {
    return this.mapIds.get(+this.data.id!) || false;
  }

  getPeriod(): number {
    const startDate = moment(this.itemDataInstance.dateStarted);
    const finishDate = moment(
      this.itemDataInstance?.dateFinished || this.itemDataInstance.dateStarted,
    );
    const diff = finishDate.diff(startDate, 'days');
    return diff;
  }

  get note(): string {
    return this.data?.note ?? '';
  }

  toggleItem(): void {
    this.isSingleClick = true;

    setTimeout(() => {
      if (!this.isSingleClick) return;
      this.onToggleItem.emit();

      if (!this.mapIds) return;
      if (this.mapIds.get(+this.data.id!)) {
        this.mapIds.set(+this.data.id!, false);
      } else {
        this.mapIds.set(+this.data.id!, true);
        this.providerActiveIdService.isOpenedIdSource.next(+this.data.id!);
      }
      this.cdr.detectChanges();
    }, 250);
  }

  changeJournal(id: string) {
    this.mapIds.clear();
    this.mapIds.set(+id, true);
    this.providerActiveIdService.isOpenedIdSource.next(+id);

    this.cdr.detectChanges();
  }

  openModal(): void {
    this.isSingleClick = false;
    this.openModalItem.emit(this.itemJournal);
  }
}
