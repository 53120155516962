import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PageComponent } from 'src/app/modules/ui/components/page/page.component';
import { DataInstancesListShortComponent } from '../../parts/data-instances-list-short/data-instances-list-short.component';
import { DataInstancesProviderService } from '../../../services/data-instances-provider.service';
import { takeWhile } from 'rxjs';
import { Page } from 'src/app/modules/ui/base/page';
import { PortalsService } from 'src/app/core/services/portals.service';

@Component({
  selector: 'raw-data',
  templateUrl: './raw-data.component.html',
  styleUrls: ['./raw-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, PageComponent, DataInstancesListShortComponent, RouterModule],
})
export class RawDataComponent extends Page implements OnInit, OnDestroy {
  headerTitle!: string;
  typeDataInstances!: string;
  path!: string;
  isAlive = true;

  constructor(
    private dataInstancesProviderService: DataInstancesProviderService,
    private cdr: ChangeDetectorRef,
    portalsService: PortalsService,
  ) {
    super(portalsService);
  }

  ngOnInit(): void {
    this.dataInstancesProviderService.currentPage$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.headerTitle = data.title;
        this.typeDataInstances = data.typeDataInstances;
        this.path = data.path;
        this.cdr.detectChanges();
      });
  }

  override ngOnDestroy(): void {
    this.isAlive = false;
    if (this.headerPortalTemplateRef) {
      this.portalsService.headerTemplatePortal = null;
    }
  }
}
