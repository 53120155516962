import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'nguk-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class ModalComponent {
  @Input() visible!: boolean;
  @Input() close!: () => void;

  readonly svgIconsEnum = SvgIconsEnum;
}
