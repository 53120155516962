<div
  class="modal-container-background closed-state"
  [class.opened-state]="visible"
  [attr.role]="'none'"
  (click)="close()"
></div>

<div class="modal-container closed-state" [class.opened-state]="visible">
  <div class="modal-content">
    <mat-icon
      *ngIf="close"
      class="close-icon"
      [svgIcon]="svgIconsEnum.Close"
      (click)="close()"
    ></mat-icon>

    <ng-content></ng-content>
  </div>
</div>
