import { Pipe, PipeTransform } from '@angular/core';
import { DictionariesEnum } from '../enums/dictionaries.enum';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, switchMap } from 'rxjs';
import { IDictionaryModel } from '../../data-models/dictionary-model/dictionary-model.interface';
import { DictionariesService } from '../services/dictionaries.service';
import { isObject } from 'lodash-es';

@Pipe({
  name: 'ngukDictionaryPipe',
  standalone: true,
})
export class DictionaryPipe implements PipeTransform {
  private readonly searchAllowedSubject = new BehaviorSubject<boolean>(true);

  constructor(private dictionariesService: DictionariesService) {}

  transform(
    dictionaryName: DictionariesEnum,
    controlValue: string,
  ): Observable<IDictionaryModel[]> {
    const searchText = isObject(controlValue) || controlValue === null ? '' : controlValue;

    return this.searchAllowedSubject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => this.dictionariesService.getDictionary(dictionaryName, searchText)),
    );
  }
}
