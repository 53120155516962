<div class="overlay-filtering-root">
  <div class="search">
    <div class="container-input">
      <nguk-input
        [control]="getFormControl('search')"
        [svgIcon]="svgIconsEnum.Search"
        placeholder="Поиск по значению"
      ></nguk-input>
    </div>

    <div class="close" [attr.role]="'none'" (click)="close()">
      <mat-icon [svgIcon]="svgIconsEnum.Close"></mat-icon>
    </div>
  </div>

  <form [formGroup]="formData" class="checkboxes">
    <nguk-checkbox-ctrl [indeterminate]="someComplete()" [control]="getFormControl('general')">
      <ng-container label>
        <nguk-text styleName="text-xs">Выделить всё</nguk-text>
      </ng-container>
    </nguk-checkbox-ctrl>
    <div class="array-checkboxes">
      <ng-container *ngFor="let item of filteredCheckbox; let index = index">
        <nguk-checkbox-ctrl
          [control]="getControlFormArray(item.index)"
          [matTooltip]="
            column === 'dateAvailability' ? (item.name | date: 'dd.MM.yyyy') || '' : item.name
          "
          [matTooltipPosition]="'above'"
        >
          <ng-container label>
            <nguk-text styleName="text-xs">{{
              column === 'dateAvailability' ? (item.name | date: 'dd.MM.yyyy') : item.name
            }}</nguk-text>
          </ng-container>
        </nguk-checkbox-ctrl>
      </ng-container>
    </div>
  </form>

  <div class="buttons">
    <nguk-button [type]="'primary'" (click)="sendFiltered()">
      <mat-icon [svgIcon]="svgIconsEnum.Filtering"></mat-icon>
      <nguk-text styleName="text-xs">Применить</nguk-text>
    </nguk-button>

    <nguk-button [type]="'secondary'" (click)="voidCurrentFilter()">
      <mat-icon [svgIcon]="svgIconsEnum.Remove"></mat-icon>
      <nguk-text styleName="text-xs">Сбросить</nguk-text>
    </nguk-button>
  </div>
</div>
