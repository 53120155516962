import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { take, takeWhile } from 'rxjs';
import { MiningPlanApiService } from 'src/app/api/mining-plan.api.service';
import { MatTableModule } from '@angular/material/table';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import {
  IMiningPlan,
  IMiningPlanTransform,
} from 'src/app/data-models/mining-plan/mining-plan.interface';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { SectionComponent } from 'src/app/modules/ui/components/section/section.component';
import { ModalMiningPlanComponent } from '../modal-mining-plan/modal-mining-plan.component';
import { ActionModal } from 'src/app/modules/ui/base/action-modal';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/app/modules/ui/services/spinner.service';
import { RedirectDataService } from 'src/app/modules/data/services/redirect-data.service';
import * as moment from 'moment';
import { MiningPlanSelectedCellPipe } from 'src/app/modules/data/pipes/mining-plan/mining-plan-selected-cell.pipe';
import { MiningPlanCheckHorizontalAreaPipe } from 'src/app/modules/data/pipes/mining-plan/mining-plan-check-horizontal-area.pipe';
import { FilterSortByColumnService } from 'src/app/modules/ui/services/filter-sort-by-column.service';
import { ThSortFilterComponent } from 'src/app/modules/ui/components/th-sort-filter/th-sort-filter.component';
import { MiningPlanValueCellPipe } from '../../../../pipes/mining-plan/mining-plan-value-cell.pipe';
import { WeekendDayPipe } from '../../../../../../core/pipes/weekend-day.pipe';
import { DatalMiningPlanArrayService } from 'src/app/modules/data/services/mining-plan/data-mining-plan-array.service';
import { CheckFinishCaluclationService } from 'src/app/modules/data/services/check-correct-caluclation.service';

@Component({
  selector: 'mining-plan-list',
  templateUrl: './mining-plan-list.component.html',
  styleUrls: ['./mining-plan-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    TextComponent,
    MatSortModule,
    SectionComponent,
    MatIconModule,
    ButtonComponent,
    MiningPlanSelectedCellPipe,
    MiningPlanCheckHorizontalAreaPipe,
    ThSortFilterComponent,
    MiningPlanValueCellPipe,
    WeekendDayPipe,
  ],
})
export class MiningPlanListComponent
  extends ActionModal
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChildren(MatSort) sorts!: QueryList<MatSort>;
  @ViewChild('modal', { read: ViewContainerRef }) modal!: ViewContainerRef;

  @Input() id!: string | undefined;

  @ViewChild('table', { read: ElementRef })
  table!: ElementRef<HTMLTableElement>;

  @Output() getCurrentTable = new EventEmitter();

  isShow = false;
  isOperationalPlan = false;

  readonly svgIconsEnum = SvgIconsEnum;
  readonly typeDataInstances = 'miningPlan';

  data!: IMiningPlanTransform[];
  rawData!: IMiningPlanTransform[];
  dataMaps!: Map<string, IMiningPlan>;
  dataInstance!: IDataInstance;
  dataInstanceId!: string;
  isAlive = true;

  totalColumns = new Map<number, number>();

  columnsDays: IDataColumnTable[] = [];
  columnsDaysTotal: IDataColumnTable[] = [];
  columns: IDataColumnTable[] = [
    { name: 'index', display: '#' },
    { name: 'mark', display: 'Марка' },
    { name: 'layer', display: 'Пласт' },
    { name: 'profile', display: 'Профиль' },
    { name: 'ad', display: 'Ad' },
    { name: 'vd', display: 'Vd' },
    { name: 'y', display: 'Y' },
  ];
  displayedColumns!: string[];
  displayedColumnsTotal!: string[];
  isSingleClick = true;

  mapCurrentCells!: Map<string, IMiningPlan>;

  constructor(
    private ss: SpinnerService,
    private cdr: ChangeDetectorRef,
    private miningPlanApiService: MiningPlanApiService,
    private route: ActivatedRoute,
    private router: Router,
    private redirectDataService: RedirectDataService,
    private filterSortByColumnService: FilterSortByColumnService,
    private checkFinishCaluclationService: CheckFinishCaluclationService,
    private datalMiningPlanArrayService: DatalMiningPlanArrayService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(takeWhile(() => this.isAlive)).subscribe((params) => {
      this.isOperationalPlan =
        !!this.route.parent?.snapshot.data['isOperationalPlan'] ||
        !!this.route.snapshot.queryParams['type'];

      const id = params['dataInstanceId'] || this.id;

      id
        ? this.getDataInstance(id)
        : this.redirectDataService.initRedirect(this.router, this.route);
    });

    if (this.id) this.isShow = true;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngAfterViewInit() {
    this.getCurrentTable.emit(this.table.nativeElement);
  }

  updateData(): void {
    const id = this.route.snapshot.params['dataInstanceId'];

    if (!id) return;

    this.redirectDataService.voidParamsCurrentPage();
    this.redirectDataService.initParamsCurrentPage(this.route);
    this.getDataInstance(id);
  }

  getDataInstance(id: string): void {
    this.redirectDataService.initParamsCurrentPage(this.route);
    this.ss.startSpinner();
    this.redirectDataService
      .getDataInstance(id)
      .pipe(take(1))
      .subscribe((res) => {
        this.dataInstance = res;
        this.initData(id);
      });
  }

  initData(id: string): void {
    this.ss.startSpinner();
    this.initColumnsDays();
    this.initDisplayedColumns();
    this.getDataList(id);
    this.getDataCalculationLog();
  }

  getDataCalculationLog(): void {
    this.checkFinishCaluclationService
      .getIsShow(this.dataInstance)
      .subscribe((isShow) => (this.isShow = isShow));
  }

  getDataList(id: string): void {
    this.miningPlanApiService
      .getList(1, [
        { name: 'dataInstance.id', value: id },
        { name: 'itemsPerPage', value: 500 * 1000 },
      ])
      .pipe(take(1))
      .subscribe(
        (data: IMiningPlan[]) => {
          this.dataMaps = new Map<string, IMiningPlan>();
          this.mapCurrentCells = new Map<string, IMiningPlan>();

          const dataTransformed = this.datalMiningPlanArrayService.initTransformData(
            data,
            this.dataInstance.dateStarted,
            this.dataMaps,
            this.columnsDays.length,
          );

          console.log(dataTransformed);

          this.rawData = [...dataTransformed];
          this.data = [...dataTransformed];
          this.cdr.detectChanges();

          this.ss.stopSpinner();

          this.subscribeSortingFiltering();
          this.dataInstanceId = id;
        },
        () => this.ss.stopSpinner(),
      );
  }

  initColumnsDays(): void {
    const days = moment(this.dataInstance.dateFinished).diff(this.dataInstance.dateStarted, 'days');

    this.columnsDays = Array.from(Array(days), (_, index) => ({
      name: `c${index + 1}`,
      display: `${index + 1}`,
    }));

    this.columnsDaysTotal = Array.from(Array(days), (_, index) => ({
      name: `t${index + 1}`,
      display: `${index + 1}`,
    }));
  }

  initDisplayedColumns(): void {
    this.displayedColumns = [...this.columns, ...this.columnsDays].map((col) => col.name);
    this.displayedColumns.push('amount-row');
    this.displayedColumnsTotal = [
      'index-total',
      ...this.columnsDaysTotal.map((col) => col.name),
      'total-row',
    ];
  }

  chooseDay(row: IMiningPlanTransform, item: IDataColumnTable) {
    this.isSingleClick = true;
    setTimeout(() => {
      this.datalMiningPlanArrayService.chooseDay(
        row,
        item,
        this.isSingleClick,
        this.mapCurrentCells,
        this.dataMaps,
        this.dataInstance.dateStarted,
        this.cdr,
      );
    }, 250);
  }

  openModal(row?: IMiningPlanTransform, item?: IDataColumnTable): void {
    this.isSingleClick = false;
    this.modalContainer = this.modal;
    const modalComponent = this.open(ModalMiningPlanComponent);

    modalComponent.instance.dataInstance = this.dataInstance;
    modalComponent.instance.isShow = this.isShow;
    modalComponent.instance.updateData = this.updateData.bind(this);
    this.closeInteraction = this.updateData.bind(this);

    if (this.mapCurrentCells.size > 1) {
      modalComponent.instance.data = Array.from(this.mapCurrentCells, ([_, value]) => value);
      return;
    }

    if (row && item) {
      let currentDay: IMiningPlan | undefined = this.dataMaps.get(`${row.key}-${item.display}`);

      if (!currentDay) {
        currentDay = this.dataMaps.get(`${row.key}-${row.values[0].day}`) as IMiningPlan;
        delete currentDay['id'];
        currentDay.day = item.display;
        currentDay.volume = '';
        currentDay.dateMining = moment(this.dataInstance.dateStarted)
          .add(+currentDay.day - 1, 'days')
          .format();
      }
      modalComponent.instance.data = [currentDay];
    } else {
      modalComponent.instance.data = [];
    }
  }

  subscribeSortingFiltering(): void {
    this.route.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe((query) => {
      this.data = this.filterSortByColumnService.sort(query, 'area', this.rawData);
      this.data = this.filterSortByColumnService.filter(query, this.data);

      this.createTotalValues();

      this.cdr.detectChanges();
    });
  }

  createTotalValues(): void {
    this.totalColumns.clear();
    let totalAmountRow = 0;

    let i = 1;
    while (i <= this.columnsDays.length) {
      const amount = this.data.reduce((preValue, item) => {
        const elMiningPlan = this.dataMaps.get(`${item.key}-${i}`);
        if (elMiningPlan) {
          preValue += +elMiningPlan.volume;
        }
        return preValue;
      }, 0);
      if (amount > 0) {
        this.totalColumns.set(i, amount);
        totalAmountRow += amount;
      }

      i++;
    }
    this.totalColumns.set(0, totalAmountRow);
  }
}
