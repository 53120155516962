import { RoutesHelper } from 'src/app/helpers/routes.helper';
import { TSideBarSubmenuRoute } from 'src/app/modules/calendar-plan/types/journal-submenu-route.type';

export const initialDataSubmenu: TSideBarSubmenuRoute[] = [
  {
    title: 'Исходные данные',
    type: 'dataInstances',
    value: [
      {
        text: 'Сбытовая потребность',
        url: [RoutesHelper.DATA_SHIPPING_PLAN_PATH],
        type: 'shippingPlan',
        isCalendarPlan: true,
        isOperationalPlan: true,
      },
      {
        text: 'Складские остатки',
        url: [RoutesHelper.DATA_WAREHOUSE_BALANCE],
        type: 'warehouseBalance',
        isCalendarPlan: true,
        isOperationalPlan: true,
      },
      {
        text: 'План добычи',
        url: [RoutesHelper.DATA_MINING_PLAN],
        type: 'miningPlan',
        isCalendarPlan: true,
        isOperationalPlan: true,
      },
      {
        text: 'ТК',
        url: [RoutesHelper.DATA_ENRICHMENT_STANDARD],
        type: 'enrichmentStandard',
        isCalendarPlan: true,
        isOperationalPlan: true,
      },
      {
        text: 'Доступность ресурсов',
        url: [RoutesHelper.DATA_FUND],
        type: 'resourceAvailability',
        isCalendarPlan: true,
        isOperationalPlan: true,
      },
      {
        text: 'Парк вагонов',
        url: [RoutesHelper.DATA_WAGON_FLEET],
        type: 'wagonFleet',
        isCalendarPlan: false,
        isOperationalPlan: true,
      },
    ],
  },
  {
    title: 'План производства',
    type: 'productionPlans',
    value: [
      {
        text: 'Добыча',
        url: [RoutesHelper.DATA_CALENDAR_PLAN_RESULT_MINING_PLAN],
        type: 'resultMiningPlan',
        isCalendarPlan: true,
        isOperationalPlan: true,
      },
      {
        text: 'Обогащение',
        url: [RoutesHelper.DATA_CALENDAR_PLAN_RESULT_SUPPLY],
        type: 'resultSupply',
        isCalendarPlan: true,
        isOperationalPlan: true,
      },
      {
        text: 'Складская динамика',
        url: [RoutesHelper.DATA_CALENDAR_PLAN_WAREHOUSE_DYNAMICS],
        type: 'warehouseDynamics',
        isCalendarPlan: true,
        isOperationalPlan: true,
      },
      {
        text: 'Внутренняя логистика',
        url: [RoutesHelper.PLAN_INTERNAL_LOGISTICS_PATH],
        type: 'internalLogistics',
        isCalendarPlan: true,
        isOperationalPlan: true,
      },
      {
        text: 'Динамика вагонов',
        url: [RoutesHelper.PLAN_WAGON_DYNAMICS_PATH],
        type: 'wagonDynamics',
        isCalendarPlan: false,
        isOperationalPlan: true,
      },
      {
        text: 'Товарная отгрузка',
        url: [RoutesHelper.PLAN_COMMODITY_SHIPMENT_PATH],
        type: 'commodityShipment',
        isCalendarPlan: true,
        isOperationalPlan: true,
      },
      {
        text: 'Гант',
        url: [RoutesHelper.PLAN_TACTS_PATH],
        type: 'gantt',
        isCalendarPlan: true,
        isOperationalPlan: true,
      },
    ],
  },
  // {
  //   title: 'Генератор маршрутов',
  //   type: 'routesGenerator',
  //   value: [],
  // },
  {
    title: 'НСИ',
    type: 'dictionary',
    value: [],
  },
  {
    title: 'Графы',
    type: 'graphs',
    value: [],
  },
];
