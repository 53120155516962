export class RoutesHelper {
  static readonly EMPTY_PATH = '';
  static readonly PRINT_PATH = 'print';

  static readonly DASHBOARD_PATH = 'dashboard';

  static readonly DATA_PATH = 'data';

  static readonly DATA_DATA_INSTANCE_URL = ':dataInstanceId';
  static readonly CALENDAR_PLAN_URL = ':calendarPlanId';

  static readonly PRINT_URL = `print/${RoutesHelper.CALENDAR_PLAN_URL}`;

  static readonly DATA_SHIPPING_PLAN_PATH = 'shipping-plan';
  static readonly DATA_SHIPPING_PLAN_URL = `shipping-plan/${RoutesHelper.DATA_DATA_INSTANCE_URL}`;
  static readonly DATA_ENRICHMENT_STANDARD = 'enrichment-standard';
  static readonly DATA_ENRICHMENT_STANDARD_URL = `enrichment-standard/${RoutesHelper.DATA_DATA_INSTANCE_URL}`;
  static readonly DATA_WAREHOUSE_BALANCE = 'warehouse-balance';
  static readonly DATA_WAREHOUSE_BALANCE_URL = `warehouse-balance/${RoutesHelper.DATA_DATA_INSTANCE_URL}`;
  static readonly DATA_MINING_PLAN = 'mining-plan';
  static readonly DATA_MINING_PLAN_URL = `mining-plan/${RoutesHelper.DATA_DATA_INSTANCE_URL}`;
  static readonly DATA_FUND = 'fund';
  static readonly DATA_FUND_URL = `fund/${RoutesHelper.DATA_DATA_INSTANCE_URL}`;
  static readonly DATA_WAGON_FLEET = 'wagon-fleet';
  static readonly DATA_WAGON_FLEET_URL = `wagon-fleet/${RoutesHelper.DATA_DATA_INSTANCE_URL}`;

  static readonly DATA_CALENDAR_PLAN = 'calendar-plan';
  static readonly DATA_CALENDAR_PLAN_URL = `calendar-plan/${RoutesHelper.CALENDAR_PLAN_URL}`;
  static readonly DATA_CALENDAR_PLAN_RESULT_MINING_PLAN = 'result-mining-plan';
  static readonly DATA_CALENDAR_PLAN_RESULT_MINING_PLAN_URL =
    'result-mining-plan/:resultMiningPlanId';
  static readonly DATA_CALENDAR_PLAN_RESULT_SUPPLY = 'result-supply';
  static readonly DATA_CALENDAR_PLAN_RESULT_SUPPLY_URL = 'result-supply/:resultSupplyId';
  static readonly DATA_CALENDAR_PLAN_WAREHOUSE_DYNAMICS = 'warehouse-dynamics';
  static readonly DATA_CALENDAR_PLAN_WAREHOUSE_DYNAMICS_URL =
    'warehouse-dynamics/:warehouseDynamicsId';
  static readonly PLAN_INTERNAL_LOGISTICS_PATH = 'internal-logistics';
  static readonly PLAN_INTERNAL_LOGISTICS_URL = 'internal-logistics/:productionPlanId';
  static readonly PLAN_COMMODITY_SHIPMENT_PATH = 'commodity-shipment';
  static readonly PLAN_COMMODITY_SHIPMENT_URL = 'commodity-shipment/:productionPlanId';
  static readonly PLAN_WAGON_DYNAMICS_PATH = 'wagon-dynamics';
  static readonly PLAN_WAGON_DYNAMICS_URL = 'wagon-dynamics/:wagonDynamicsId';
  static readonly PLAN_TACTS_PATH = 'gantt';
  static readonly PLAN_TACTS_URL = 'gantt/:ganttId';

  static readonly DATA_CALENDAR_PLAN_RESULT_GRAPHS = 'graphs';

  static readonly DATA_OPERATIONAL_PLAN = 'operational-plan';
  static readonly DATA_OPERATIONAL_PLAN_URL = `operational-plan/${RoutesHelper.CALENDAR_PLAN_URL}`;

  static readonly DOCUMENTS_PATH = 'documents';
  static readonly DOCUMENTS_URL = 'documents/:documentId';

  static readonly NSI_PATH = 'nsi';
  static readonly NSI_LIST_PATH = 'list';
  static readonly NSI_MATRIX_PATH = 'matrix';
  static readonly NSI_LIST_URL = `nsi/${RoutesHelper.DATA_DATA_INSTANCE_URL}/${RoutesHelper.NSI_LIST_PATH}/:dictionaryId`;
  static readonly NSI_MATRIX_URL = `nsi/${RoutesHelper.DATA_DATA_INSTANCE_URL}/${RoutesHelper.NSI_MATRIX_PATH}/:dictionaryId`;

  static readonly CALCULATIONS_PATH = 'calculations';
}
