import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SortFilterComponent } from '../sort-filter/sort-filter.component';
import { DataFilterPipe } from 'src/app/core/pipes/data-filter.pipe';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'nguk-th-sort-filter',
  templateUrl: './th-sort-filter.component.html',
  styleUrls: ['./th-sort-filter.component.scss'],
  standalone: true,
  imports: [CommonModule, SortFilterComponent, DataFilterPipe, TextComponent],
})
export class ThSortFilterComponent {
  @HostBinding('class.nguk-th-sort-filter') private baseClass = true;

  @Input() columnName!: string;
  @Input() columnDisplay!: string;
  @Input() rawData!: any[];

  currentShowFilter = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  checkHasFilter(filter: string): boolean {
    const query = this.route.snapshot.queryParams;
    return query[filter] || query[filter] === '' || query['columnSort'] === filter;
  }
}
