import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBase } from '../../base/modal.base';
import { ModalComponent } from '../modal/modal.component';
import { MatIconModule } from '@angular/material/icon';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'nguk-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalComponent, MatIconModule, TextComponent],
})
export class PopupComponent extends ModalBase implements AfterViewInit {
  @Input() icon!: string;
  @Input() title!: string;
  @Input() listTitle!: string[];
  @Input() text!: string;
  @Input() isAutoClose = true;

  constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngAfterViewInit(): void {
    this.initModal();

    if (!this.isAutoClose) return;

    setTimeout(() => {
      this.closeModal(false);
    }, 1500);
  }
}
