import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RoutingModule } from './modules/routing/routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { SpinnerComponent } from './modules/ui/components/spinner/spinner.component';
import { NotificationComponent } from './modules/ui/components/notification/notification.component';
import localeRu from '@angular/common/locales/ru';
import { HttpErrorHandlerInterceptor } from './core/interceptors/http-error-handler.interceptor';
import { appInitializeFactory } from './core/factories/app-initialize.factory';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

registerLocaleData(localeRu);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RoutingModule,
    HttpClientModule,
    SpinnerComponent,
    NotificationComponent,
    KeycloakAngularModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializeFactory,
      deps: [KeycloakService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorHandlerInterceptor, multi: true },
  ],
})
export class AppModule {}
