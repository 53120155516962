import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleComponent } from 'src/app/modules/forms/components/toggle/toggle.component';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { FormControl, FormGroup } from '@angular/forms';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { IResourceAvailabilityTransform } from 'src/app/data-models/resource-availability/resource-availability.interface';
import { ModalGeneralActionsComponent } from '../modal-general-actions/modal-general-actions.component';
import { ModalGeneralFormComponent } from '../modal-general-form/modal-general-form.component';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';

@Component({
  selector: 'modal-general',
  templateUrl: './modal-general.component.html',
  styleUrls: ['./modal-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TextComponent,
    ToggleComponent,
    ModalGeneralFormComponent,
    ModalGeneralActionsComponent,
  ],
})
export class ModalGeneralComponent extends ModalBase implements AfterViewInit {
  data!: IResourceAvailabilityTransform;
  currentIndex!: number;

  updateData!: () => void;
  formData!: FormGroup;

  dataInstance!: IDataInstance;

  currentData!: string | null;
  isCopy!: boolean;
  toggleList = ['Редактирование', 'Копирование'];
  toggleControl = new FormControl<string | null>(null);

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  openModalSelectResource(value: IResourceAvailabilityTransform) {
    this.data = value;
    this.closeModal(null);
  }

  getForm(form: FormGroup): void {
    this.formData = form;
  }

  ngAfterViewInit(): void {
    this.initModal();
    this.toggleControl.setValue(this.toggleList[this.isCopy ? 1 : 0]);
  }
}
