import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import { ICommodityShipmentMaterial } from '../data-models/commodity-shipment-material/commodity-shipment-material.interface';

@Injectable({
  providedIn: 'root',
})
export class CommodityShipmentMaterialApiService extends EntityApi<
  ICommodityShipmentMaterial,
  ICommodityShipmentMaterial
> {
  protected readonly api = 'result/commodityShipmentMaterial';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
