import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ActionModal } from '../../base/action-modal';
import { ModalLicenseComponent } from '../modal-license/modal-license.component';
import { RouterLink } from '@angular/router';
import { RoutesHelper } from 'src/app/helpers/routes.helper';

@Component({
  selector: 'nguk-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterLink],
})
export class LogoComponent extends ActionModal {
  @ViewChild('modal', { read: ViewContainerRef, static: true }) modal!: ViewContainerRef;

  readonly routesHelper = RoutesHelper;

  openModal(event: Event): void {
    this.modalContainer = this.modal;
    this.open(ModalLicenseComponent);
    event.stopPropagation();
  }

  @Input() hasInfoIcon = false;
  readonly svgIconsEnum = SvgIconsEnum;
  showIconInfo = false;
}
