import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../button/button.component';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { OverlayModule } from '@angular/cdk/overlay';
import { OverlayFilteringComponent } from '../overlay-filtering/overlay-filtering.component';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'nguk-sort-filter',
  templateUrl: './sort-filter.component.html',
  styleUrls: ['./sort-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, ButtonComponent, OverlayModule, OverlayFilteringComponent],
})
export class SortFilterComponent implements OnInit, OnDestroy {
  @HostBinding('nguk-sort-filter') private baseClass = true;

  @Input() filterData!: string[];
  @Input() column!: string;

  currentColumn!: string;

  readonly svgIconsEnum = SvgIconsEnum;

  typeSorting: 'desc' | 'asc' | '' = '';

  isOpenFiltering = false;
  isAlive = true;
  isHasFilter!: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe((query) => {
      this.currentColumn = query['columnSort'] ?? '';
      this.typeSorting =
        this.currentColumn === this.column && query['typeSort'] ? query['typeSort'] : '';
      this.isHasFilter = !!query[this.column];
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  changeStatusSorting(): void {
    switch (this.typeSorting) {
      case 'desc':
        this.typeSorting = 'asc';

        break;
      case 'asc':
        this.typeSorting = '';
        break;

      default:
        this.typeSorting = 'desc';
        break;
    }

    const query = { ...this.route.snapshot.queryParams };
    delete query['typeSort'];
    delete query['columnSort'];

    if (this.typeSorting) {
      query['typeSort'] = this.typeSorting;
      query['columnSort'] = this.column;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: query,
    });
  }

  openModalFiltering(): void {
    this.isOpenFiltering = !this.isOpenFiltering;
  }

  close(): void {
    this.isOpenFiltering = false;
  }
}
