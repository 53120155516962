<div class="documents-content-header-root">
  <div class="document-info-block document-number">
    <nguk-text weight="600" styleName="text-lg">Д 1341</nguk-text>
  </div>

  <div class="document-info-block document-calculation-number">
    <nguk-text weight="400" color="grey" styleName="text-xs" class="info-block-label"
      >Расчёт</nguk-text
    >
    <nguk-text weight="400" color="blue" styleName="text-xs" class="info-block-value"
      >Р 1341</nguk-text
    >
  </div>

  <div class="document-info-block document-creation-date">
    <nguk-text weight="400" color="grey" styleName="text-xs" class="info-block-label"
      >Дата создания</nguk-text
    >
    <nguk-text weight="400" color="black" styleName="text-xs" class="info-block-value"
      >12.01.2024</nguk-text
    >
  </div>

  <div class="document-info-block document-calculation-days">
    <nguk-text weight="400" color="grey" styleName="text-xs" class="info-block-label"
      >Кол-во дней расчёта</nguk-text
    >
    <nguk-text weight="400" color="black" styleName="text-xs" class="info-block-value"
      >13</nguk-text
    >
  </div>

  <div class="document-info-block document-calculation-time">
    <nguk-text weight="400" color="grey" styleName="text-xs" class="info-block-label"
      >Время расчёта</nguk-text
    >
    <nguk-text weight="400" color="black" styleName="text-xs" class="info-block-value"
      >600 сек.</nguk-text
    >
  </div>

  <div class="document-info-block document-status">
    <nguk-text color="grey" styleName="text-xs" class="info-block-label">Статус</nguk-text>
  </div>
</div>
