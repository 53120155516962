import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, DestroyRef,
  EventEmitter,
  Output, ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../../../../../ui/components/modal/modal.component';
import { TextComponent } from '../../../../../ui/components/text/text.component';
import { ModalBase } from '../../../../../ui/base/modal.base';
import { ShippingPlanLoadingRuleApiService } from '../../../../../../api/shipping-plan-loading-rule.api.service';
import { combineLatestWith, finalize, forkJoin, mergeMap, Observable, of, take, tap } from 'rxjs';
import { ShippingPlanModalFormComponent } from '../shipping-plan-modal-form/shipping-plan-modal-form.component';
import { TShippingPlanDay } from '../../../../types/shipping-plan-day.type';
import { ShippingPlanModalListComponent } from '../shipping-plan-modal-list/shipping-plan-modal-list.component';
import { IShippingPlanLoadingRule } from '../../../../../../data-models/shipping-plan-loading-rule/shipping-plan-loading-rule.interface';
import { EditShippingPlanForm } from '../../../../forms/edit-shipping-plan.form';
import { ShippingPlanApiService } from '../../../../../../api/shipping-plan.api.service';
import { IShippingPlan } from '../../../../../../data-models/shipping-plan/shipping-plan.interface';
import { ModalActionsComponent } from '../../../../../ui/components/modal-actions/modal-actions.component';
import { cloneDeep, differenceBy, omit } from 'lodash-es';
import { IEditShippingPlan } from '../../../../../../data-models/shipping-plan/edit-shipping-plan.interface';
import { ModalWarehouseBalanceItemsActionsComponent } from '../../warehouse-balance/modal-warehouse-balance-items-actions/modal-warehouse-balance-items-actions.component';
import { EditShippingPlanLoadingRuleForm } from '../../../../forms/edit-shipping-plan-loading-rule.form';
import { TShippingPlanListRow } from '../../../../types/shipping-plan-list-row.type';
import { IDataInstance } from '../../../../../../data-models/enrichment-standard/data-instance.interface';
import { ShippingPlanService } from '../../../../services/shipping-plan.service';
import { ToggleComponent } from '../../../../../forms/components/toggle/toggle.component';
import { FormControl, Validators } from '@angular/forms';
import { DictionariesEnum } from '../../../../../../core/enums/dictionaries.enum';
import { DictionariesService } from '../../../../../../core/services/dictionaries.service';
import { SpinnerService } from '../../../../../ui/services/spinner.service';
import { IDictionaryModel } from '../../../../../../data-models/dictionary-model/dictionary-model.interface';
import * as moment from 'moment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  templateUrl: './shipping-plan-modal.component.html',
  styleUrls: ['./shipping-plan-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TextComponent,
    ModalWarehouseBalanceItemsActionsComponent,
    ShippingPlanModalFormComponent,
    ShippingPlanModalListComponent,
    ModalActionsComponent,
    ToggleComponent,
  ],
})
export class ShippingPlanModalComponent extends ModalBase implements AfterViewInit {
  @Output() readonly refreshList = new EventEmitter<void>();
  @ViewChild(ShippingPlanModalListComponent) shippingPlanModalList!: ShippingPlanModalListComponent;

  row!: TShippingPlanListRow;
  shippingPlan!: IShippingPlan;
  data!: TShippingPlanDay;
  dataInstance!: IDataInstance;
  selectedCells!: { row: TShippingPlanListRow; dayIndex: number }[];
  columnIndex!: number;
  editShippingPlanForm!: EditShippingPlanForm;
  shippingPlanLoadingRules!: IShippingPlanLoadingRule[];
  tableRows: EditShippingPlanLoadingRuleForm[] = [];
  toggleList = ['Редактирование', 'Копирование'];
  toggleControl = new FormControl<string | null>(this.toggleList[0]);
  isShow = false;

  get modalTitle(): string {
    if (this.isShow) {
      return `Ячейка ${this.row.index} - ${this.columnIndex + 1}`;
    }

    if (this.isMassEdit) {
      return this.toggleControl.value === 'Копирование' ? 'Копирование' : 'Массовое редактирование';
    }

    if (!this.data) {
      return this.row
        ? `Создание ячейки ${this.row.index} - ${this.columnIndex + 1}`
        : 'Создание ячейки';
    }

    return this.toggleControl.value === 'Копирование'
      ? `Копирование ячейки ${this.row.index} - ${this.columnIndex + 1}`
      : `Редактирование ячейки ${this.row.index} - ${this.columnIndex + 1}`;
  }

  private get isMassEdit(): boolean {
    return this.selectedCells?.length > 1;
  }

  constructor(
    cdRef: ChangeDetectorRef,
    private dr: DestroyRef,
    private ss: SpinnerService,
    private dictionariesService: DictionariesService,
    private shippingPlanService: ShippingPlanService,
    private shippingPlanApiService: ShippingPlanApiService,
    private shippingPlanLoadingRuleApiService: ShippingPlanLoadingRuleApiService,
  ) {
    super(cdRef);
  }

  ngAfterViewInit(): void {
    this.initModal();
    if (this.isMassEdit) {
      this.editShippingPlanForm = this.buildMassEditForm();
      return;
    }

    if (!this.data && this.row) {
      this.editShippingPlanForm = EditShippingPlanForm.createForm({
        expectedShipmentDate: this.shippingPlanService.getExpectedShipmentDate(
          this.dataInstance,
          this.columnIndex,
        ),
        customer: this.row.customer,
        trainWagonsEquipment: this.row.trainWagonsEquipment,
        station: this.row.station,
        dataInstance: { id: Number(this.dataInstance.id) },
      } as unknown as IShippingPlan);

      return;
    } else if (!this.data && !this.row) {
      this.editShippingPlanForm = EditShippingPlanForm.createForm({
        dataInstance: { id: Number(this.dataInstance.id) },
      } as unknown as IShippingPlan);

      return;
    }

    this.ss.startSpinner();
    this.shippingPlanService
      .getShippingPlan(this.data.shippingPlanId)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((shippingPlan: IShippingPlan) => {
        this.shippingPlan = shippingPlan;
        this.editShippingPlanForm = EditShippingPlanForm.createForm(this.shippingPlan);
        this.cdRef.markForCheck();
      });

    this.ss.startSpinner();
    this.shippingPlanService
      .getShippingPlanLoadingRules(this.data.shippingPlanId)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((shippingPlanLoadingRules: IShippingPlanLoadingRule[]) => {
        this.shippingPlanLoadingRules = shippingPlanLoadingRules;
        this.tableRows = [
          ...this.shippingPlanLoadingRules.map((_) => {
            const form = EditShippingPlanLoadingRuleForm.createForm(_);
            this.makeWagonMarkSubscription(form);

            return form;
          }),
        ];
        this.cdRef.markForCheck();
      });
  }

  isVisibleToggle(): boolean {
    return !!(this.row && this.data) || this.isMassEdit;
  }

  handleSave(): void {
    const isShippingPlanLoadingRulesInvalid = this.tableRows.length &&
      this.tableRows.some((row: EditShippingPlanLoadingRuleForm, index: number) => {
        if (!row.validate()) {
          this.shippingPlanModalList.makeEditable(index);

          return true;
        }

        return false;
      });
    if (isShippingPlanLoadingRulesInvalid) {
      if (!this.isMassEdit) {
        this.editShippingPlanForm.validate();
      }
      return;
    }

    if (this.isMassEdit) {
      return this.makeMassEdit(this.toggleControl.value === 'Копирование');
    } else {
      if (!this.editShippingPlanForm.validate()) {
        return;
      }
    }

    let nextStepMethod;
    if (this.toggleControl.value === 'Копирование') {
      nextStepMethod = () => this.makeCopy(this.editShippingPlanForm);
    } else if (!this.data) {
      nextStepMethod = () => this.makeCreate(this.editShippingPlanForm);
    } else {
      nextStepMethod = () => this.makeUpdate(this.editShippingPlanForm);
    }

    this.createDictionaryItems(nextStepMethod);
  }

  handleDelete(): void {
    if (!this.data?.shippingPlanId) {
      return;
    }

    if (this.isMassEdit) {
      const days = this.selectedCells.map((cell) => cell.row.days[cell.dayIndex]).filter(Boolean);

      if (!days.length) {
        this.closeModal(true);
      } else {
        this.ss.startSpinner();
        forkJoin(days.map((day) => this.shippingPlanApiService.delete(day.shippingPlanId)))
          .pipe(take(1), finalize(this.ss.stopSpinner))
          .subscribe(() => this.closeModal(true));
      }

      return;
    }

    this.ss.startSpinner();
    const loadingRulesRequests = this.shippingPlanLoadingRules?.length
      ? this.shippingPlanLoadingRules.map((shippingPlanLoadingRule: IShippingPlanLoadingRule) =>
          this.shippingPlanLoadingRuleApiService.delete(shippingPlanLoadingRule.id),
        )
      : of(null);

    forkJoin(loadingRulesRequests)
      .pipe(
        mergeMap(() => this.shippingPlanApiService.delete(this.data.shippingPlanId)),
        take(1),
        finalize(this.ss.stopSpinner),
      )
      .subscribe(() => this.closeModal(true));
  }

  handleAddRow(): void {
    const newRow = EditShippingPlanLoadingRuleForm.createForm({
        id: '',
        shippingPlan: {
          id: '',
        },
        requiredWagonMark: '',
        factoryProductsMixture: '',
        plannedAd: '',
        plannedVd: '',
        plannedY: '',
        contractualAd: '',
        contractualVd: '',
        contractualY: '',
      } as IShippingPlanLoadingRule);
    this.tableRows = [
      ...(this.tableRows || []),
      newRow,
    ].filter(Boolean);
    this.cdRef.markForCheck();
    this.makeWagonMarkSubscription(newRow);
  }

  handleCopyRow(rowIndex: number): void {
    const copiedRow = { ...this.tableRows[rowIndex].getFormData(), id: '' };
    const copiedForm = EditShippingPlanLoadingRuleForm.createForm(copiedRow);
    this.tableRows = [
      ...this.tableRows.slice(0, rowIndex + 1),
      copiedForm,
      ...this.tableRows.slice(rowIndex + 1),
    ];
    this.cdRef.markForCheck();
    this.makeWagonMarkSubscription(copiedForm);
  }

  handleDeleteRow(rowIndex: number): void {
    this.tableRows = this.tableRows.filter((_, index: number) => index !== rowIndex);
    this.cdRef.markForCheck();
  }

  private makeCopy = (editShippingPlanForm: EditShippingPlanForm): void => {
    this.ss.startSpinner();
    this.shippingPlanService
      .copy(editShippingPlanForm, this.tableRows)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(() => this.refreshList.emit());
  };

  private makeUpdate = (
    editShippingPlanForm: EditShippingPlanForm,
    keepModalOpened?: boolean,
  ): void => {
    this.ss.startSpinner();
    this.shippingPlanApiService
      .update(editShippingPlanForm.getFormData())
      .pipe(
        mergeMap((shippingPlan: IEditShippingPlan) => {
          const deletedShippingLoadingRules = differenceBy(
            this.shippingPlanLoadingRules,
            this.tableRows?.map((_) => _.getFormData()),
            'id',
          );

          let loadingRulesRequests =
            this.tableRows?.map((shippingPlanLoadingRule: EditShippingPlanLoadingRuleForm) => {
              const model = {
                ...shippingPlanLoadingRule.getFormData(),
                shippingPlan: { id: String(shippingPlan.id) },
              };

              if (shippingPlanLoadingRule.id.value) {
                return this.shippingPlanLoadingRuleApiService.update(model as any);
              } else {
                return this.shippingPlanLoadingRuleApiService.create(omit(model, 'id') as any);
              }
            }) ?? [];

          if (deletedShippingLoadingRules.length) {
            loadingRulesRequests = [
              ...(loadingRulesRequests || []).filter(Boolean),
              ...(deletedShippingLoadingRules.map((lr) =>
                this.shippingPlanLoadingRuleApiService.delete(lr.id),
              ) as any),
            ];
          }

          if (!loadingRulesRequests.length) {
            loadingRulesRequests.push(of(null as any));
          }
          return forkJoin(loadingRulesRequests);
        }),
        take(1),
        finalize(this.ss.stopSpinner),
      )
      .subscribe(() => {
        if (keepModalOpened) {
          return;
        }

        this.closeModal(true);
      });
  };

  makeCreate = (editShippingPlanForm: EditShippingPlanForm, keepModalOpened?: boolean): void => {
    this.ss.startSpinner();
    this.shippingPlanApiService
      .create(omit(editShippingPlanForm.getFormData(), 'id'))
      .pipe(
        mergeMap((shippingPlan: IEditShippingPlan) => {
          const loadingRulesRequests =
            this.tableRows?.map((shippingPlanLoadingRule: EditShippingPlanLoadingRuleForm) => {
              const model = {
                ...shippingPlanLoadingRule.getFormData(),
                shippingPlan: { id: String(shippingPlan.id) },
              };

              return this.shippingPlanLoadingRuleApiService.create(omit(model, 'id') as any);
            }) ?? [];

          if (!loadingRulesRequests.length) {
            loadingRulesRequests.push(of(null as any));
          }
          return forkJoin(loadingRulesRequests);
        }),
        take(1),
        finalize(this.ss.stopSpinner),
      )
      .subscribe(() => {
        if (keepModalOpened) {
          return;
        }

        this.closeModal(true);
      });
  };

  private makeMassEdit = (isCopyMode: boolean): void => {
    const requests: (() => void)[] = [];
    let cells = this.selectedCells;

    const isSameCell =
      this.editShippingPlanForm.expectedShipmentDate.value &&
      this.editShippingPlanForm.station.value &&
      this.editShippingPlanForm.customer.value &&
      this.editShippingPlanForm.trainWagonsEquipment.value;

    if (isSameCell) {
      this.ss.startSpinner();
      cells = [cells[0]];
      const [, ...cellsToDelete] = [...this.selectedCells];
      cellsToDelete
        .filter((cell) => cell.row.days[cell.dayIndex])
        .forEach((cell) => {
          requests.push(() =>
            this.shippingPlanApiService
              .delete(cell.row.days[cell.dayIndex].shippingPlanId)
              .pipe(take(1), finalize(this.ss.stopSpinner))
              .subscribe(),
          );
        });
    }

    cells.forEach((cell: { row: TShippingPlanListRow; dayIndex: number }) => {
      const day = cell.row.days[cell.dayIndex];
      const copiedForm = cloneDeep(this.editShippingPlanForm);
      const customer =
        day?.customer ||
        this.dictionariesService.getDictionaryOption(DictionariesEnum.Customer, cell.row.customer);
      copiedForm.id.setValue(day?.shippingPlanId, { emitEvent: false });
      copiedForm.customer.setValue(copiedForm.customer.value || day?.customer || customer, {
        emitEvent: false,
      });
      copiedForm.trainWagonsEquipment.setValue(
        copiedForm.trainWagonsEquipment.value || cell.row?.trainWagonsEquipment,
        { emitEvent: false },
      );
      copiedForm.station.setValue(copiedForm.station.value || day?.station, { emitEvent: false });
      copiedForm.position.setValue(copiedForm.position.value || day?.position, { emitEvent: false });
      copiedForm.trainVolume.setValue(copiedForm.trainVolume.value || day?.trainVolume, { emitEvent: false });
      copiedForm.expectedShipmentDate.setValue(
        copiedForm.expectedShipmentDate.value ||
        this.shippingPlanService.getExpectedShipmentDate(
          this.dataInstance,
          day?.expectedShipmentDate || cell.dayIndex - 1,
        ),
      );

      if (day && !isCopyMode) {
        requests.push(() => this.makeUpdate(copiedForm, true));
      } else {
        requests.push(() => this.makeCreate(copiedForm, true));
      }
    });

    requests.forEach((requestFn) => requestFn());
    this.closeModal(true);
  };

  private createDictionaryItemIfNeeded(
    control: FormControl,
    dictionary: DictionariesEnum,
  ): Observable<IDictionaryModel> {
    const controlValue = control.value;
    const isDictionaryItem = controlValue !== null && typeof controlValue !== 'string';
    if (isDictionaryItem) {
      return of(controlValue);
    }

    this.ss.startSpinner();
    return this.dictionariesService
      .createOption(dictionary, controlValue as string)
      .pipe(take(1), finalize(this.ss.stopSpinner));
  }

  private buildMassEditForm(): EditShippingPlanForm {
    const rows = this.selectedCells.map((cell) => cell.row);
    const days = this.selectedCells.map(
      (cell) =>
        cell.row.days[cell.dayIndex] || {
          expectedShipmentDay: this.shippingPlanService.getExpectedShipmentDate(
            this.dataInstance,
            cell.dayIndex - 1,
          ),
        },
    );
    const shippingPlanLike = {
      dataInstance: this.dataInstance,
      expectedShipmentDate: days.every((day) =>
        moment(day?.expectedShipmentDate).isSame(moment(days[0]?.expectedShipmentDate), 'days'),
      )
        ? days[0]?.expectedShipmentDate
        : null,
      station: rows.every((row) => row.station === rows[0].station) ? rows[0].station : null,
      customer: rows.every((row) => row.customer === rows[0].customer) ? rows[0].customer : null,
      trainVolume: days.every((day) => day?.trainVolume === days[0]?.trainVolume)
        ? days[0]?.trainVolume
        : null,
      position: days.every((day) => day?.position === days[0]?.position) ? days[0]?.position : null,
      trainWagonsEquipment: rows.every(
        (row) => row.trainWagonsEquipment === rows[0].trainWagonsEquipment,
      )
        ? rows[0].trainWagonsEquipment
        : null,
    };

    return EditShippingPlanForm.createForm(shippingPlanLike as unknown as IEditShippingPlan);
  }

  private createDictionaryItems = (nextStepMethod: () => void): void => {
    this.ss.startSpinner();
    const stationControl = this.editShippingPlanForm.station;
    const customerControl = this.editShippingPlanForm.customer;
    const trainWagonsEquipmentControl = this.editShippingPlanForm.trainWagonsEquipment;

    this.createDictionaryItemIfNeeded(stationControl, DictionariesEnum.Station)
      .pipe(
        tap((dictionaryItem: IDictionaryModel) =>
          stationControl.setValue(dictionaryItem, { emitEvent: false }),
        ),
        combineLatestWith(
          this.createDictionaryItemIfNeeded(customerControl, DictionariesEnum.Customer).pipe(
            tap((dictionaryItem: IDictionaryModel) =>
              customerControl.setValue(dictionaryItem, { emitEvent: false }),
            ),
            finalize(this.ss.stopSpinner),
          ),
          this.createDictionaryItemIfNeeded(
            trainWagonsEquipmentControl,
            DictionariesEnum.TrainPicking,
          ).pipe(
            tap((dictionaryItem: IDictionaryModel) =>
              trainWagonsEquipmentControl.setValue(dictionaryItem, { emitEvent: false }),
            ),
            finalize(this.ss.stopSpinner),
          ),
        ),
      )
      .subscribe(nextStepMethod);
  };

  private makeWagonMarkSubscription(form: EditShippingPlanLoadingRuleForm): void {
    form
      .getControl('requiredWagonMark')?.valueChanges
      .pipe(takeUntilDestroyed(this.dr))
      .subscribe((requiredWagonMarkValue: string) => {
        const contractualYControl = form.getControl('contractualY')!;
        if (['OC', 'CC', 'KC', 'ОС', 'СС', 'КС', 'ОСНЗ'].includes(requiredWagonMarkValue?.toUpperCase())) {
          contractualYControl?.setValidators([Validators.required]);
        } else {
          contractualYControl?.clearValidators();
        }
        contractualYControl.updateValueAndValidity();
      });
  }
}
