import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEnrichmentStandard } from 'src/app/data-models/enrichment-standard/enrichment-standard.interface';
import { ModalActionsComponent } from 'src/app/modules/ui/components/modal-actions/modal-actions.component';
import { FormControl, FormGroup } from '@angular/forms';
import { ActionModal } from 'src/app/modules/ui/base/action-modal';
import { ModalEnrichmentStandardArrayService } from 'src/app/modules/data/services/enrichment-standard/modal-enrichment-standard-array.service';

@Component({
  selector: 'modal-enrichment-standard-list-actions',
  templateUrl: './modal-enrichment-standard-list-actions.component.html',
  styleUrls: ['./modal-enrichment-standard-list-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalActionsComponent],
  providers: [ModalEnrichmentStandardArrayService],
})
export class ModalEnrichmentStandardListActionsComponent
  extends ActionModal
  implements OnInit, AfterViewInit
{
  @ViewChild('modalConfirm', { read: ViewContainerRef }) modalConfirm!: ViewContainerRef;

  @Input() formData!: FormGroup;
  @Input() data!: IEnrichmentStandard[];
  @Input() toggleValue!: string;
  @Input() toggleControl!: FormControl<string | null>;

  @Output() readonly close = new EventEmitter<void>();
  @Output() readonly updateData = new EventEmitter<void>();
  @Output() readonly saveData = new EventEmitter<void>();

  isCopy = false;
  isTk1 = false;

  constructor(
    private modalEnrichmentStandardArrayService: ModalEnrichmentStandardArrayService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }
  ngAfterViewInit(): void {
    this.modalContainer = this.modalConfirm;
  }

  ngOnInit(): void {
    this.isTk1 = this.toggleValue === 'TK1' ? true : false;

    this.toggleControl.valueChanges.subscribe((value) => {
      if (!value) return;
      this.isCopy = value.toLowerCase() === 'редактирование' ? false : true;

      this.cdr.detectChanges();
    });
  }

  initModalMiningPlanServiceData(service: ModalEnrichmentStandardArrayService): void {
    service.close = this.close;
    service.update = this.updateData;
    service.open = this.open.bind(this);

    service.isCopy = this.isCopy;
    service.formData = this.formData;
    service.isTk1 = this.isTk1;

    service.data = this.data;
    const amountIds = this.data.reduce((acc, item) => acc + (item.id ? +item.id : 0), 0);
    service.isChange = amountIds === 0;
  }

  initActionsData(): void {
    this.initModalMiningPlanServiceData(this.modalEnrichmentStandardArrayService);
  }

  save(): void {
    this.initActionsData();
    this.saveData.emit();
    this.modalEnrichmentStandardArrayService.saveArray();
  }

  remove() {
    this.initActionsData();
    this.modalEnrichmentStandardArrayService.removeArray();
  }
}
