<div
  class="nguk-textarea-ctrl-root"
  [ngClass]="{ '-horizontal': direction === 'horizontal', '-vertical': direction === 'vertical' }"
>
  <ng-content select="[label]" />

  <mat-form-field appearance="outline">
    <textarea matInput [formControl]="control" [placeholder]="placeholder"></textarea>

    <nguk-control-error [showError]="errorState">{{ error }}</nguk-control-error>
  </mat-form-field>
</div>
