import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { CalendarPlanComponent } from './components/pages/calendar-plan/calendar-plan.component';
import { JournalListShortComponent } from './components/parts/journal-list-short/journal-list-short.component';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../ui/components/page/page.component';
import { TextComponent } from '../ui/components/text/text.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../ui/components/button/button.component';
import { SectionComponent } from '../ui/components/section/section.component';
import { SidebarListItemComponent } from '../ui/components/sidebar-list/sidebar-list-item.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PageComponent,
    TextComponent,
    MatIconModule,
    ButtonComponent,
    SectionComponent,
    SidebarListItemComponent,
  ],
  declarations: [CalendarPlanComponent, JournalListShortComponent],
  exports: [],
})
export class CalendarPlanModule {}
