<nguk-modal [visible]="isVisible" [close]="openNextModal.bind(this)">
  <div class="modal">
    <nguk-text weight="600" styleName="text-xl">Экземпляры данных</nguk-text>

    <calendar-plan-filter-data
      class="filter-data"
      [dataInstances]="dataInstances"
      (filteredData)="getFilteredData($event)"
      *ngIf="dataInstances"
    ></calendar-plan-filter-data>

    <calendar-plan-modal-choose-data-instances-list
      class="modal-choose-data-instances-list"
      [journal]="journal"
      [typeDataInstances]="typeDataInstances"
      *ngIf="filteredDataInstances"
      [dataInstances]="filteredDataInstances"
    ></calendar-plan-modal-choose-data-instances-list>
  </div>
</nguk-modal>
