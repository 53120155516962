<div class="modal-mining-plan-form-table-root" *ngIf="dataSource">
  <form class="form form-list" [formGroup]="formData">
    <mat-table [dataSource]="dataSource" formArrayName="table">
      <ng-container [matColumnDef]="'prev-value'">
        <mat-header-cell *matHeaderCellDef [attr.colspan]="listColumns.length">
          <nguk-text weight="400" styleName="text-xl" class="prev-value">...</nguk-text>
        </mat-header-cell>
      </ng-container>

      <ng-container [matColumnDef]="'startTime'">
        <mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs">Начало</nguk-text>
        </mat-header-cell>

        <mat-cell
          *matCellDef="let row; let index = index"
          [formGroupName]="index"
          (click)="chooseActiveRow(index)"
        >
          <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
            <nguk-input-ctrl
              [min]="0"
              [max]="24"
              type="number"
              [control]="getControlArray(index, 'startTime')"
            ></nguk-input-ctrl>
          </ng-container>

          <ng-template #textView>
            <div class="date-container" [attr.role]="'none'">
              <mat-icon
                [svgIcon]="svgIconsEnum.Clock"
                *ngIf="getControlArray(index, 'startTime').value"
              />
              <nguk-text weight="400" styleName="text-xs" class="date-container__time">
                {{ getTextValueHour(getControlArray(index, 'startTime').value) }}
              </nguk-text>
            </div>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'finishTime'">
        <mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs">Окончание</nguk-text>
        </mat-header-cell>

        <mat-cell
          *matCellDef="let row; let index = index"
          [formGroupName]="index"
          (click)="chooseActiveRow(index)"
        >
          <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
            <nguk-input-ctrl
              [min]="0"
              [max]="24"
              type="number"
              [control]="getControlArray(index, 'finishTime')"
            ></nguk-input-ctrl>
          </ng-container>
          <ng-template #textView>
            <div class="date-container" [attr.role]="'none'">
              <mat-icon
                [svgIcon]="svgIconsEnum.Clock"
                *ngIf="getControlArray(index, 'finishTime').value"
              />
              <nguk-text weight="400" styleName="text-xs" class="date-container__time">
                {{ getTextValueHour(getControlArray(index, 'finishTime').value) }}
              </nguk-text>
            </div>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'volume'">
        <mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs">Объем</nguk-text>
        </mat-header-cell>

        <mat-cell *matCellDef="let row; let index = index" (click)="chooseActiveRow(index)">
          <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
            <nguk-input-ctrl type="number" [min]="0" [control]="getControlArray(index, 'volume')" />
          </ng-container>

          <ng-template #textView>
            <nguk-text weight="400" styleName="text-xs" class="text-view">
              {{ getControlArray(index, 'volume').value }}
            </nguk-text>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="'actions'">
        <mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs">Действия</nguk-text>
        </mat-header-cell>

        <mat-cell *matCellDef="let row; let index = index">
          <div class="actions-row" *ngIf="!isShow">
            <nguk-button
              class="copy-button"
              type="secondary"
              matTooltip="Копировать строку"
              (click)="addFormGroupToFormArray(null, index)"
            >
              <mat-icon [svgIcon]="svgIconsEnum.Duplicate"></mat-icon>
            </nguk-button>

            <nguk-button
              class="delete-button"
              type="secondary"
              matTooltip="Удалить строку"
              (click)="removeItem(index)"
            >
              <mat-icon [svgIcon]="svgIconsEnum.Delete"></mat-icon>
            </nguk-button>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="listColumns; sticky: true"></mat-header-row>
      <ng-container *ngIf="data.length > 1">
        <mat-header-row *matHeaderRowDef="['prev-value']"></mat-header-row>
      </ng-container>
      <mat-row *matRowDef="let row; columns: listColumns"></mat-row>
    </mat-table>

    <div class="add-row" *ngIf="!isShow" [attr.role]="'none'" (click)="addNewRow()">
      <nguk-button type="secondary">
        <ng-container>
          <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
          <nguk-text weight="400" styleName="text-sm">Добавить строку</nguk-text>
        </ng-container>
      </nguk-button>
    </div>
  </form>
</div>
