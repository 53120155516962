<div class="documents-list-item-root" [class.-opened]="mapIds.get(+document.id)">
  <div
    class="document-item-header"
    [attr.role]="'none'"
    (click)="toggleItem()"
    (dblclick)="openModal()"
  >
    <div class="document-item-name">
      <div class="mat-icon-button">
        <mat-icon [svgIcon]="svgIconsEnum.ArrowUpDown" />
      </div>
      <nguk-text weight="400" styleName="text-xs">Р {{ document.id }}</nguk-text>
    </div>
    <div class="document-item-info">
      <div class="document-item-info-time">
        <mat-icon [svgIcon]="svgIconsEnum.Clock" />
        <nguk-text weight="400" styleName="text-xs">24ч</nguk-text>
      </div>
      <div class="document-item-info-days">
        <mat-icon [svgIcon]="svgIconsEnum.CalendarDays" />
        <nguk-text weight="400" styleName="text-xs">10д</nguk-text>
      </div>
    </div>
  </div>

  <div class="document-list-details" [attr.role]="'none'">
    <!-- <documents-list-submenu-item
      title="Исходные данные"
      [menuItems]="initialDataSubmenu"
      [document]="document"
      [mapIds]="mapIds"
      (changeDocument)="changeDocument($event)"
    />
    <documents-list-submenu-item
      title="План производства"
      [menuItems]="productionPlanSubmenu"
      [document]="document"
      [mapIds]="mapIds"
      (changeDocument)="changeDocument($event)"
    />
    <documents-list-submenu-item
      title="Генератор маршрутов"
      [menuItems]="[]"
      [document]="document"
      [mapIds]="mapIds"
      (changeDocument)="changeDocument($event)"
    /> -->
  </div>
</div>

<ng-template #modal></ng-template>
