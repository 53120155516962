<div class="shipping-plan-modal-list-root">
  <mat-table [dataSource]="tableRows">
    <ng-container matColumnDef="empty1">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
    </ng-container>

    <ng-container matColumnDef="empty2">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
    </ng-container>

    <ng-container matColumnDef="contract">
      <mat-header-cell *matHeaderCellDef [attr.colspan]="3">
        <nguk-text weight="700" styleName="text-sm"> Контрактные </nguk-text>
      </mat-header-cell>
    </ng-container>

    <ng-container matColumnDef="requirement">
      <mat-header-cell *matHeaderCellDef [attr.colspan]="3">
        <nguk-text weight="700" styleName="text-sm"> Плановые </nguk-text>
      </mat-header-cell>
    </ng-container>

    <ng-container matColumnDef="empty3">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
    </ng-container>

    <ng-container matColumnDef="empty4">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
    </ng-container>

    <ng-container [matColumnDef]="listColumnsEnum.Index">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="black" weight="400" styleName="text-sm">#</nguk-text>
      </mat-header-cell>

      <mat-cell *matCellDef="let row; let index = index">
        <nguk-text weight="400" styleName="text-sm">{{ index + 1 }}</nguk-text>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="listColumnsEnum.RequiredWagonMark">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="black" weight="400" styleName="text-sm">Товар</nguk-text>
      </mat-header-cell>

      <mat-cell *matCellDef="let row; let index = index">
        <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
          <nguk-input [control]="row.requiredWagonMark"></nguk-input>
        </ng-container>

        <ng-template #textView>
          <nguk-text
            weight="400"
            styleName="text-sm"
            class="text-view"
            (click)="editRowIndex = index"
          >
            {{ row.requiredWagonMark.value }}
          </nguk-text>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="listColumnsEnum.FactoryProductsMixture">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="black" weight="400" styleName="text-sm">Смесь продуктов</nguk-text>
      </mat-header-cell>

      <mat-cell *matCellDef="let row; let index = index">
        <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
          <nguk-input [control]="row.factoryProductsMixture"></nguk-input>
        </ng-container>

        <ng-template #textView>
          <nguk-text
            weight="400"
            styleName="text-sm"
            class="text-view"
            (click)="editRowIndex = index"
          >
            {{ row.factoryProductsMixture.value }}
          </nguk-text>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="listColumnsEnum.ContractualAd">
      <mat-header-cell class="-colored" *matHeaderCellDef>
        <nguk-text color="black" weight="400" styleName="text-sm">Ad</nguk-text>
      </mat-header-cell>

      <mat-cell class="-colored" *matCellDef="let row; let index = index">
        <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
          <nguk-input [control]="row.contractualAd"></nguk-input>
        </ng-container>

        <ng-template #textView>
          <nguk-text
            weight="400"
            styleName="text-sm"
            class="text-view"
            (click)="editRowIndex = index"
          >
            {{ row.contractualAd?.value }}
          </nguk-text>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="listColumnsEnum.ContractualVd">
      <mat-header-cell class="-colored" *matHeaderCellDef>
        <nguk-text color="black" weight="400" styleName="text-sm">Vd</nguk-text>
      </mat-header-cell>

      <mat-cell class="-colored" *matCellDef="let row; let index = index">
        <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
          <nguk-input [control]="row.contractualVd"></nguk-input>
        </ng-container>

        <ng-template #textView>
          <nguk-text
            weight="400"
            styleName="text-sm"
            class="text-view"
            (click)="editRowIndex = index"
          >
            {{ row.contractualVd?.value }}
          </nguk-text>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="listColumnsEnum.ContractualY">
      <mat-header-cell class="-colored" *matHeaderCellDef>
        <nguk-text color="black" weight="400" styleName="text-sm">Y</nguk-text>
      </mat-header-cell>

      <mat-cell class="-colored" *matCellDef="let row; let index = index">
        <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
          <nguk-input [control]="row.contractualY"></nguk-input>
        </ng-container>

        <ng-template #textView>
          <nguk-text
            weight="400"
            styleName="text-sm"
            class="text-view"
            (click)="editRowIndex = index"
          >
            {{ row.contractualY?.value }}
          </nguk-text>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="listColumnsEnum.PlannedAd">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="black" weight="400" styleName="text-sm">Ad</nguk-text>
      </mat-header-cell>

      <mat-cell *matCellDef="let row; let index = index">
        <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
          <nguk-input [control]="row.plannedAd"></nguk-input>
        </ng-container>

        <ng-template #textView>
          <nguk-text
            weight="400"
            styleName="text-sm"
            class="text-view"
            (click)="editRowIndex = index"
          >
            {{ row.plannedAd.value }}
          </nguk-text>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="listColumnsEnum.PlannedVd">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="black" weight="400" styleName="text-sm">Vd</nguk-text>
      </mat-header-cell>

      <mat-cell *matCellDef="let row; let index = index">
        <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
          <nguk-input [control]="row.plannedVd"></nguk-input>
        </ng-container>

        <ng-template #textView>
          <nguk-text
            weight="400"
            styleName="text-sm"
            class="text-view"
            (click)="editRowIndex = index"
          >
            {{ row.plannedVd.value }}
          </nguk-text>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="listColumnsEnum.PlannedY">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="black" weight="400" styleName="text-sm">Y</nguk-text>
      </mat-header-cell>

      <mat-cell *matCellDef="let row; let index = index">
        <ng-container *ngIf="editRowIndex === index && !isShow; else textView">
          <nguk-input [control]="row.plannedY"></nguk-input>
        </ng-container>

        <ng-template #textView>
          <nguk-text
            weight="400"
            styleName="text-sm"
            class="text-view"
            (click)="editRowIndex = index"
          >
            {{ row.plannedY.value }}
          </nguk-text>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="listColumnsEnum.Actions">
      <mat-header-cell *matHeaderCellDef>
        <nguk-text color="black" weight="400" styleName="text-sm">Действия</nguk-text>
      </mat-header-cell>

      <mat-cell *matCellDef="let row; let index = index">
        <div class="actions-row" *ngIf="!isShow">
          <nguk-button
            class="copy-button"
            type="secondary"
            matTooltip="Копировать строку"
            (click)="copyRow.emit(index)"
          >
            <mat-icon [svgIcon]="svgIconsEnum.Duplicate"></mat-icon>
          </nguk-button>

          <nguk-button
            class="delete-button"
            type="secondary"
            matTooltip="Удалить строку"
            (click)="deleteRow.emit(index)"
          >
            <mat-icon [svgIcon]="svgIconsEnum.Delete"></mat-icon>
          </nguk-button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="
        ['empty1', 'empty2', 'contract', 'requirement', 'empty3', 'empty4'];
        sticky: true
      "
    ></mat-header-row>
    <mat-header-row *matHeaderRowDef="listColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: listColumns"></mat-row>
  </mat-table>

  <div class="add-row" *ngIf="!isShow" [attr.role]="'none'" (click)="addRow.emit()">
    <nguk-button type="secondary">
      <ng-container>
        <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
        <nguk-text weight="400" styleName="text-sm">Добавить строку</nguk-text>
      </ng-container>
    </nguk-button>
  </div>
</div>
