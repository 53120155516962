import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from 'src/app/modules/forms/components/input/input.component';
import { SelectComponent } from 'src/app/modules/forms/components/select/select.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { DatepickerComponent } from 'src/app/modules/forms/components/datepicker/datepicker.component';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IEditJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import * as moment from 'moment';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { ValidateDatePlanning } from '../../../validators/date-planning.validator';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'calendar-plan-modal-journal-list-short-parameters',
  templateUrl: './modal-journal-list-short-parameters.component.html',
  styleUrls: ['./modal-journal-list-short-parameters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TextComponent, SelectComponent, InputComponent, DatepickerComponent],
})
export class ModalJournalListShortParametersComponent implements OnInit {
  @Input() journal!: IEditJournal | undefined;
  @Input() isHasCalculated = false;
  @Input() mapDataInstance!: Map<string, IDataInstance>;
  @Input() isOperationalPlan = false;

  @Output() setFormData = new EventEmitter<FormGroup>();

  formData!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initFormData();

    this.setFormData.emit(this.formData);
  }

  getControl(str: string): FormControl {
    return this.formData.get(str) as FormControl;
  }

  getAmountDays(): number {
    if (!this.journal?.datePlanFinished || !this.journal?.datePlanStarted) return 0;

    if (this.isOperationalPlan)
      return (
        moment(this.journal?.datePlanFinished).diff(
          moment(this.journal?.datePlanStarted),
          'hours',
        ) / 12
      );

    return moment(this.journal?.datePlanFinished).diff(
      moment(this.journal?.datePlanStarted),
      'days',
    );
  }

  getUTCDate(date: string | Date, isVoidTime = false) {
    const localDate = moment(date).utc();
    const localYear = localDate.year();
    const localMonth = localDate.month();
    const localDay = localDate.date();
    const localHours = isVoidTime ? 0 : localDate.hours();
    const localMinutes = isVoidTime ? 0 : localDate.minutes();
    const localSeconds = isVoidTime ? 0 : localDate.seconds();

    return new Date(localYear, localMonth, localDay, localHours, localMinutes, localSeconds);
  }

  initFormData(): void {
    this.formData = this.fb.group({
      id: [this.journal?.id ?? ''],
      dateCreated: [this.journal?.dateCreated ?? '', [Validators.required]],
      datePlanStarted: [
        this.journal?.datePlanStarted
          ? this.getUTCDate(this.journal.datePlanStarted)
          : this.getUTCDate(new Date(), true),
        [Validators.required],
      ],
      datePlanFinished: [this.journal?.datePlanFinished ?? '', [Validators.required]],
      amountDays: [this.journal ? this.getAmountDays() : 0],
      maxDuration: [this.journal?.maxDuration ?? 0, [Validators.required]],
      note: [this.journal?.note ?? ''],
      dataInstances: this.fb.array([]),
      productionPlans: this.fb.array([]),
      taskLogs: this.fb.array([]),
    });

    this.initSubscribeAmountDays();

    this.formData
      .get('datePlanStarted')
      ?.addValidators(ValidateDatePlanning(this.formData, this.mapDataInstance));

    if (!this.journal) return;

    this.formData.get('datePlanStarted')?.markAllAsTouched();

    if (this.isHasCalculated) this.formData.disable();

    this.initFormArray();
  }

  initSubscribeAmountDays(): void {
    this.getControl('amountDays')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((num) => {
        const startDate = moment(this.getControl('datePlanStarted').value).utcOffset(0, true);

        if (!this.isOperationalPlan) startDate.startOf('day');

        const finishDate = this.isOperationalPlan
          ? startDate.add(num * 12, 'hours')
          : startDate.add(num, 'days');

        this.getControl('datePlanFinished').setValue(finishDate.format());
      });
  }

  initFormArray(): void {
    this.journal?.dataInstances.forEach((item) => {
      const fg = this.fb.group({
        id: [item.id],
        type: [item.type],
      });
      this.getFormArray('dataInstances').push(fg);
    });

    this.journal?.productionPlans.forEach((item) => {
      const fg = this.fb.group({
        id: [item.id],
        type: [item.type],
      });
      this.getFormArray('productionPlans').push(fg);
    });

    this.journal?.taskLogs.forEach((item) => {
      const fg = this.fb.group({
        id: [item.id],
      });
      this.getFormArray('taskLogs').push(fg);
    });
  }

  getFormArray(str: string): FormArray {
    return this.formData.get(str) as FormArray;
  }
}
