import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputComponent } from 'src/app/modules/forms/components/input/input.component';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { IMiningPlan } from 'src/app/data-models/mining-plan/mining-plan.interface';
import { MiningPlanVolumeApiService } from 'src/app/api/mining-plan-volume.api.service';
import { forkJoin, take } from 'rxjs';
import { IMiningPlanVolume } from 'src/app/data-models/mining-plan-volume/mining-plan-volume.interface';
import { isNull } from 'lodash-es';

@Component({
  selector: 'modal-mining-plan-form-table',
  templateUrl: './modal-mining-plan-form-table.component.html',
  styleUrls: ['./modal-mining-plan-form-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TextComponent,
    InputComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    MatIconModule,
    MatTooltipModule,
    MatTableModule,
  ],
})
export class ModalMiningPlanFormTableComponent implements OnInit {
  @Input() formData!: FormGroup;
  @Input() isShow!: boolean;
  @Input() data!: IMiningPlan[];

  @Output() setArrayListMiningPlanVolume = new EventEmitter<IMiningPlanVolume[][]>();
  @Output() setRemoveListIds = new EventEmitter<number[]>();
  readonly svgIconsEnum = SvgIconsEnum;

  dataSource!: IMiningPlanVolume[][];

  listMiningPlanVolume!: IMiningPlanVolume[][];

  removeListIds: number[] = [];

  editRowIndex!: number;
  listColumns = ['startTime', 'finishTime', 'volume', 'actions'];

  constructor(
    private fb: FormBuilder,
    private miningPlanVolumeApiService: MiningPlanVolumeApiService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initDataMiningVolume();
  }

  initDataMiningVolume(): void {
    const requests = this.data
      .filter((item) => item.id)
      .map((item) => {
        const query = [
          {
            name: 'miningPlan.id',
            value: item.id!,
          },
          {
            name: 'itemsPerPage',
            value: 200,
          },
        ];

        return this.miningPlanVolumeApiService.getList(1, query);
      });

    if (!requests.length) {
      this.dataSource = [...this.tableArray.value];
      this.cdr.detectChanges();
      return;
    }

    forkJoin(requests)
      .pipe(take(1))
      .subscribe((res) => {
        this.listMiningPlanVolume = res;
        this.setArrayListMiningPlanVolume.emit(res);

        if (this.data.length === 1) {
          this.listMiningPlanVolume[0]?.forEach((item) => {
            this.addFormGroupToFormArray(item);
          });
        }

        this.dataSource = [...this.tableArray.value];
        this.cdr.detectChanges();
      });
  }

  get tableArray(): FormArray {
    return this.formData.get('table') as FormArray;
  }

  chooseActiveRow(index: number): void {
    if (this.editRowIndex === index) return;
    this.editRowIndex = index;
  }

  getControlArray(index: number, name: string): FormControl {
    const group = this.tableArray.controls[index] as FormGroup;
    return group.get(name) as FormControl;
  }

  removeItem(index: number) {
    const id = this.tableArray.value[index].id as string | null;
    if (id) {
      this.removeListIds.push(+id);
      this.setRemoveListIds.emit(this.removeListIds);
    }
    this.tableArray.removeAt(index);
    this.dataSource = [...this.tableArray.value];
  }

  addFormGroupToFormArray(item: IMiningPlanVolume | null, index?: number) {
    if (index || index === 0) item = this.tableArray.value[index];

    const fg = this.fb.group({
      startTime: [item?.startTime ?? null],
      finishTime: [item?.finishTime ?? null],
      volume: [item?.volume ?? '0'],
      id: [item?.id ?? null],
      miningPlan: this.fb.group({
        id: [item?.miningPlan.id ?? null],
      }),
    });

    this.tableArray.push(fg);
    this.dataSource = [...this.tableArray.value];
    this.cdr.detectChanges();
  }

  addNewRow(): void {
    this.addFormGroupToFormArray(null);
  }

  getTextValueHour(value: number | null): string {
    if (isNull(value)) return '...';

    if (value < 10) return `0${value}:00`;

    return `${value}:00`;
  }
}
