import { Pipe, PipeTransform } from '@angular/core';
import { IMiningPlanTransform } from 'src/app/data-models/mining-plan/mining-plan.interface';

@Pipe({
  name: 'miningPlanSelectedCell',
  standalone: true,
})
export class MiningPlanSelectedCellPipe implements PipeTransform {
  transform(index: number, data: IMiningPlanTransform[]): boolean {
    return data[index + 1] && data[index].area.title !== data[index + 1].area.title;
  }
}
