import { FormContext } from '../../../core/abstract/form.context';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IEditShippingPlan } from '../../../data-models/shipping-plan/edit-shipping-plan.interface';
import * as moment from 'moment';

export class EditShippingPlanForm extends FormContext<IEditShippingPlan> {
  static createForm(shippingPlan?: IEditShippingPlan): EditShippingPlanForm {
    const form = new FormGroup({
      id: new FormControl(shippingPlan?.id),
      dataInstance: new FormControl(shippingPlan?.dataInstance),
      customer: new FormControl(shippingPlan?.customer, [Validators.required]),
      position: new FormControl(shippingPlan?.position),
      trainWagonsEquipment: new FormControl(shippingPlan?.trainWagonsEquipment, [
        Validators.required,
      ]),
      expectedShipmentDate: new FormControl(
        shippingPlan?.expectedShipmentDate
          ? new Date(shippingPlan?.expectedShipmentDate as string)
          : null,
        [Validators.required],
      ),
      gapTimeForRZD: new FormControl(EditShippingPlanForm.getRzhdControlValue(shippingPlan), [
        Validators.required,
      ]),
      shipmentGapStart: new FormControl(shippingPlan?.shipmentGapStart),
      shipmentGapDuration: new FormControl(shippingPlan?.shipmentGapDuration),
      trainVolume: new FormControl(shippingPlan?.trainVolume, [Validators.required]),
      station: new FormControl(shippingPlan?.station, [Validators.required]),
      wagonType: new FormControl(shippingPlan?.wagonType?.[0] ?? [shippingPlan?.wagonType] ?? [], [
        Validators.required,
      ]),
    });

    return new EditShippingPlanForm(form, shippingPlan);
  }

  private static getRzhdControlValue(shippingPlan?: IEditShippingPlan): 1 | 2 | 3 | null {
    if (typeof shippingPlan?.shipmentGapStart !== 'number') {
      return 1;
    }

    return shippingPlan?.shipmentGapStart === 6 ? 1 : shippingPlan?.shipmentGapStart === 18 ? 2 : 3;
  }

  get id(): FormControl {
    return this.getControl('id') as FormControl;
  }
  get dataInstance(): FormControl {
    return this.getControl('dataInstance') as FormControl;
  }
  get customer(): FormControl {
    return this.getControl('customer') as FormControl;
  }
  get position(): FormControl {
    return this.getControl('position') as FormControl;
  }
  get trainWagonsEquipment(): FormControl {
    return this.getControl('trainWagonsEquipment') as FormControl;
  }
  get expectedShipmentDate(): FormControl {
    return this.getControl('expectedShipmentDate') as FormControl;
  }
  get gapTimeForRZD(): FormControl {
    return this.getControl('gapTimeForRZD') as FormControl;
  }
  get shipmentGapStart(): FormControl {
    return this.getControl('shipmentGapStart') as FormControl;
  }
  get shipmentGapDuration(): FormControl {
    return this.getControl('shipmentGapDuration') as FormControl;
  }
  get trainVolume(): FormControl {
    return this.getControl('trainVolume') as FormControl;
  }
  get station(): FormControl {
    return this.getControl('station') as FormControl;
  }
  get wagonType(): FormControl {
    return this.getControl('wagonType') as FormControl;
  }

  setRequired(value: boolean) {
    if (!value) return;

    this.form.disable();
    this.form.updateValueAndValidity();
  }

  constructor(form: FormGroup, data?: IEditShippingPlan) {
    super(form, data);
  }

  override getFormData(): IEditShippingPlan {
    const [shipmentGapStart, shipmentGapDuration] = this.getRzhdGapValues(this.gapTimeForRZD.value);

    return {
      ...this.form.value,
      wagonType: Array.isArray(this.wagonType.value)
        ? this.wagonType.value
        : this.wagonType.value
          ? [this.wagonType.value]
          : null,
      expectedShipmentDate: moment(this.expectedShipmentDate.value).utcOffset(0, false).format(),
      trainWagonsEquipment:
        this.trainWagonsEquipment.value?.title ?? this.trainWagonsEquipment.value,
      shipmentGapStart,
      shipmentGapDuration,
    };
  }

  private getRzhdGapValues(rzhdValue: number): [number, number] {
    let shipmentGapStart, shipmentGapDuration;
    if (rzhdValue === 1) {
      shipmentGapStart = 6;
      shipmentGapDuration = 12;
    } else if (rzhdValue === 2) {
      shipmentGapStart = 18;
      shipmentGapDuration = 12;
    } else {
      shipmentGapStart = 0;
      shipmentGapDuration = 24;
    }

    return [shipmentGapStart, shipmentGapDuration];
  }
}
