import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { IResourceAvailabilityTransform } from 'src/app/data-models/resource-availability/resource-availability.interface';
import { ModalSelectResourceTableComponent } from '../modal-select-resource-table/modal-select-resource-table.component';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ModalSelectResourceInputComponent } from '../modal-select-resource-input/modal-select-resource-input.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'modal-select-resource',
  templateUrl: './modal-select-resource.component.html',
  styleUrls: ['./modal-select-resource.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TextComponent,
    ModalSelectResourceTableComponent,
    ModalSelectResourceInputComponent,
    ReactiveFormsModule,
  ],
})
export class ModalSelectResourceComponent extends ModalBase implements AfterViewInit {
  data!: IResourceAvailabilityTransform;
  currentIndex!: number;

  control = new FormControl({ value: '', disabled: true });
  mapValue = new Map<string, boolean>();

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngAfterViewInit(): void {
    this.initModal();
  }
}
