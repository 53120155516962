import { EntityApi } from '../core/abstract/entity.api';
import { Injectable } from '@angular/core';
import { ApiProvider } from '../core/providers/api.provider';
import { DictionaryColumnModel } from '../data-models/dictionary/dictionary-column.model';
import { EditDictionaryColumnModel } from '../data-models/dictionary/edit-dictionary-column.model';

@Injectable({
  providedIn: 'root'
})
export class DictionaryColumnApiService extends EntityApi<
  DictionaryColumnModel,
  EditDictionaryColumnModel
> {
  protected readonly api = 'sourceData/dictionaryColumn';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
