<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <nguk-button type="primary" class="uploadButton" *ngIf="toggleControl.value === toggleList[1]"
      >Загрузить в систему</nguk-button
    >

    <div class="modal-header">
      <nguk-text weight="600" styleName="text-xl">Импорт файла</nguk-text>
      <nguk-toggle *ngIf="!status" [toggleList]="toggleList" [control]="toggleControl" />
    </div>
    <div class="modal-main">
      <ng-container *ngIf="toggleControl.value === toggleListValue.Excel; else other">
        <mat-icon
          [svgIcon]="
            !status
              ? svgIconsEnum.Excel
              : status === 'success'
                ? svgIconsEnum.FileUploadedSuccessful
                : svgIconsEnum.FileUploadedWrongly
          "
        />

        <div class="dropzone" *ngIf="!status; else statusUploaded">
          <nguk-text weight="600" styleName="text-3xl">Перетащите документ сюда</nguk-text>
          <nguk-text weight="400" styleName="text-base" class="second-line">
            или нажмите на кнопку
          </nguk-text>

          <nguk-button
            [type]="'primary'"
            class="upload-button"
            [class.-attached]="isFileAttached"
            (click)="uploadFile()"
          >
            {{ isFileAttached ? 'Отправить файл' : 'Добавить файл' }}
          </nguk-button>

          <ngx-file-drag-drop
            class="ngx-file-drag-drop"
            activeBorderColor="#3F51B5"
            emptyPlaceholder="''"
            [multiple]="false"
            [formControl]="fileControl"
          />
        </div>

        <ng-template #statusUploaded>
          <div class="status-uploaded">
            <nguk-text weight="600" styleName="text-3xl">{{
              status === 'success'
                ? 'Файл успешно загружен и обработан!'
                : 'При добавлении файла обнаружены ошибки'
            }}</nguk-text>

            <nguk-text weight="400" styleName="text-base" class="title">{{
              status === 'success' ? 'Вы можете начать работу с его данными' : text
            }}</nguk-text>

            <ng-container *ngIf="status === 'error'">
              <!-- <div class="places-error">
                <div class="places-error-item" *ngFor="let item of arrayError">
                  <nguk-text weight="400" styleName="text-xs">{{ item }}</nguk-text>
                </div>
              </div> -->

              <nguk-button [type]="'primary'" class="upload-button" (click)="status = undefined">
                Вернуться к загрузке
              </nguk-button>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #other>
        <modal-upload-related-systems></modal-upload-related-systems>
      </ng-template>
    </div>
  </div>
</nguk-modal>
