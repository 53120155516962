<div
  class="nguk-th-sort-filter-root"
  (mouseover)="columnName !== 'index' ? (currentShowFilter = true) : null"
  (mouseleave)="currentShowFilter = false"
  (focus)="(null)"
  (blur)="(null)"
>
  <div class="container-text visible-block" [class.hidden-block]="currentShowFilter">
    <nguk-text weight="400" styleName="text-xs" class="opacity-text">
      {{ columnDisplay }}
    </nguk-text>
    <div class="circle" *ngIf="checkHasFilter(columnName)"></div>
  </div>

  <nguk-sort-filter
    class="visible-block"
    [class.hidden-block]="!currentShowFilter"
    [column]="columnName"
    [filterData]="rawData | ngukDataFilterPipe: columnName"
  ></nguk-sort-filter>
</div>
