<div class="nguk-section-root">
  <div class="title-and-actions">
    <nguk-text weight="600" styleName="text-lg">
      <ng-content select="[sectionTitle]"></ng-content>
    </nguk-text>
    <ng-content select="[sectionActions]"></ng-content>
  </div>

  <ng-content></ng-content>
</div>
