import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

export abstract class FormContext<T> {
  static touchAll(control: AbstractControl): void {
    if (control?.markAsTouched) {
      control.markAsTouched({ onlySelf: false });
      control.updateValueAndValidity({ onlySelf: false });
    }

    if (control instanceof FormGroup || control instanceof FormArray) {
      Object.values(control.controls).forEach((c: AbstractControl) => FormContext.touchAll(c));
    }

    if (Array.isArray(control?.value)) {
      control.value.filter(Boolean).forEach((formContext: FormContext<any>) =>
        FormContext.touchAll(formContext.form),
      );
    }
  }

  get invalid(): boolean {
    return this.form.invalid;
  }

  protected constructor(
    public form: FormGroup,
    protected data?: T,
  ) {}

  getControl(key: string): FormControl | null {
    return this.form.get(key) as FormControl;
  }

  validate(): boolean {
    FormContext.touchAll(this.form);
    return this.form.valid;
  }

  disable(options: any): void {
    this.form.disable(options);
  }

  enable(options: any): void {
    this.form.enable(options);
  }

  abstract getFormData(): T;
}
