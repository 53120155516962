import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { MatTableModule } from '@angular/material/table';
import { ResultMiningPlanItemApiService } from 'src/app/api/result-mining-plan-item.api.service';
import { IResultMiningPlanItem } from 'src/app/data-models/result-mining-plan-item/result-mining-plan-item.interface';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';

@Component({
  selector: 'calendar-plan-modal-result-mining-plan',
  templateUrl: './modal-result-mining-plan.component.html',
  styleUrls: ['./modal-result-mining-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalComponent, TextComponent, MatTableModule, TextComponent],
})
export class ModalResultMiningPlanComponent extends ModalBase implements AfterViewInit {
  data!: IResultMiningPlanItem[];

  day!: number;

  columns: IDataColumnTable[] = [
    { name: 'index', display: '#' },
    { name: 'area', display: 'Участок' },
    { name: 'mark', display: 'Марка' },
    { name: 'layer', display: 'Пласт' },
    { name: 'profile', display: 'Профиль' },
    { name: 'ad', display: 'Ad' },
    { name: 'vd', display: 'Vd' },
    { name: 'y', display: 'Y' },
  ];

  displayedColumns = this.columns.map((item) => item.name);

  constructor(
    cdRef: ChangeDetectorRef,
    private resultMiningPlanItemApiService: ResultMiningPlanItemApiService,
  ) {
    super(cdRef);
  }

  ngAfterViewInit(): void {
    this.initModal();
  }
}
