import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TextComponent } from '../text/text.component';
import { SvgIconsEnum } from '../../../../core/enums/svg-icons.enum';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
  selector: 'nguk-date-pair',
  template: `
    <div *ngIf="date" class="nguk-date-pair-root">
      <nguk-text weight="400" styleName="text-xs">
        <ng-content select="[left]"></ng-content>
      </nguk-text>

      <mat-icon [svgIcon]="svgIconsEnum.TimePairClock" />

      <nguk-text weight="400" styleName="text-xs">
        <ng-content select="[right]"></ng-content>
      </nguk-text>
    </div>
  `,
  styleUrls: ['./date-pair.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TextComponent, MatIconModule, NgIf],
})
export class DatePairComponent {
  @Input() date!: string;
  @HostBinding('class.nguk-date-pair') private baseClass = true;

  readonly svgIconsEnum = SvgIconsEnum;
}
