import { Injectable } from '@angular/core';
import { EntityApi } from 'src/app/core/abstract/entity.api';
import { ApiProvider } from 'src/app/core/providers/api.provider';
import { IDictionaryModel } from 'src/app/data-models/dictionary-model/dictionary-model.interface';
import { IEditDictionaryModel } from 'src/app/data-models/dictionary-model/edit-dictionary-model.interface';

@Injectable({
  providedIn: 'root',
})
export class MarkApiService extends EntityApi<IDictionaryModel, IEditDictionaryModel> {
  protected readonly api = 'dict/mark';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
