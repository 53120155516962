<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header">
      <div class="modal-header-info">
        <nguk-text class="modal-title" weight="600" styleName="text-xl"
          >Редактирование ячейки</nguk-text
        >
      </div>

      <nguk-modal-actions [showCopy]="false" [showDelete]="false" (save)="(null)" />
    </div>

    <nguk-textarea-ctrl
      direction="vertical"
      placeholder="Введите текст комментария"
      [control]="noteControl"
    >
      <ng-container label>
        <nguk-text color="grey" weight="400" styleName="text-sm">Комментарий</nguk-text>
      </ng-container>
    </nguk-textarea-ctrl>
  </div>
</nguk-modal>
