import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { finalize, take, takeWhile } from 'rxjs';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { TextComponent } from '../../../../../ui/components/text/text.component';
import { SectionComponent } from '../../../../../ui/components/section/section.component';
import { IShippingPlan } from '../../../../../../data-models/shipping-plan/shipping-plan.interface';
import { EShippingPlanColorAccent } from '../../../../../../enums/shipping-plan-color-accent.enum';
import { ActionModal } from '../../../../../ui/base/action-modal';
import { ShippingPlanModalComponent } from '../shipping-plan-modal/shipping-plan-modal.component';
import { ShippingPlanCustomerNamePipe } from '../../../../pipes/shipping-plan/shipping-plan-customer-name.pipe';
import { TShippingPlanListRow } from '../../../../types/shipping-plan-list-row.type';
import { TShippingPlanListColumn } from '../../../../types/shipping-plan-list-column.type';
import { IDataInstance } from '../../../../../../data-models/enrichment-standard/data-instance.interface';
import { ButtonComponent } from '../../../../../ui/components/button/button.component';
import { SvgIconsEnum } from '../../../../../../core/enums/svg-icons.enum';
import { MatIconModule } from '@angular/material/icon';
import { ShippingPlanService } from '../../../../services/shipping-plan.service';
import { SpinnerService } from '../../../../../ui/services/spinner.service';
import { ShippingPlanColorPipe } from '../../../../pipes/shipping-plan/shipping-plan-color.pipe';
import { ShippingPlanColspanPipe } from '../../../../pipes/shipping-plan/shipping-plan-colspan.pipe';
import { RedirectDataService } from '../../../../services/redirect-data.service';
import { FilterSortByColumnService } from 'src/app/modules/ui/services/filter-sort-by-column.service';
import { ThSortFilterComponent } from 'src/app/modules/ui/components/th-sort-filter/th-sort-filter.component';
import { ShippingPlanCheckHorizontalAreaPipe } from 'src/app/modules/data/pipes/shipping-plan/shipping-plan-check-horizontal-area.pipe';
import { WeekendDayPipe } from '../../../../../../core/pipes/weekend-day.pipe';
import { CheckFinishCaluclationService } from 'src/app/modules/data/services/check-correct-caluclation.service';
import * as moment from 'moment';

@Component({
  selector: 'shipping-plan-list',
  templateUrl: './shipping-plan-list.component.html',
  styleUrls: ['./shipping-plan-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatSortModule,
    MatTableModule,
    NgForOf,
    TextComponent,
    SectionComponent,
    ShippingPlanCustomerNamePipe,
    NgIf,
    ButtonComponent,
    MatIconModule,
    DatePipe,
    ShippingPlanColorPipe,
    ShippingPlanColspanPipe,
    ThSortFilterComponent,
    ShippingPlanCheckHorizontalAreaPipe,
    WeekendDayPipe,
  ],
})
export class ShippingPlanListComponent
  extends ActionModal
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() id!: string | undefined;
  @Output() getCurrentTable = new EventEmitter();
  @ViewChild('modal', { read: ViewContainerRef, static: true }) modal!: ViewContainerRef;
  @ViewChild('table', { read: ElementRef }) table!: ElementRef<HTMLTableElement>;

  isShow = false;
  isOperationalPlan = false;

  readonly svgIconsEnum = SvgIconsEnum;
  readonly colorAccentEnum = EShippingPlanColorAccent;

  totalValueMap = new Map<string, string>();
  isSingleClick!: boolean;
  isAlive = true;
  dataInstance!: IDataInstance;
  data!: TShippingPlanListRow[];
  rawData!: TShippingPlanListRow[];
  dayColumns!: TShippingPlanListColumn[];
  dayColumnsTotal!: TShippingPlanListColumn[];
  constantColumns: TShippingPlanListColumn[] = [
    { columnName: 'index', columnHeading: '#' },
    { columnName: 'customer', columnHeading: 'Заказчик' },
    { columnName: 'trainWagonsEquipment', columnHeading: 'Компл-я ЖД состава' },
  ];
  displayedColumns: string[] = ['customer', 'trainWagonsEquipment'];
  displayedColumnsTotal!: string[];
  selectedCells: { row: TShippingPlanListRow; dayIndex: number }[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private ss: SpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private shippingPlanService: ShippingPlanService,
    private redirectDataService: RedirectDataService,
    private filterSortByColumnService: FilterSortByColumnService,
    private checkFinishCaluclationService: CheckFinishCaluclationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeWhile(() => this.isAlive)).subscribe((params: Params) => {
      this.isOperationalPlan =
        !!this.activatedRoute.parent?.snapshot.data['isOperationalPlan'] ||
        !!this.activatedRoute.snapshot.queryParams['type'];

      const dataInstanceId = params['dataInstanceId'] || this.id;
      if (dataInstanceId) {
        this.getDataInstanceByShippingPlanId(dataInstanceId);
      } else {
        this.redirectDataService.initRedirect(this.router, this.activatedRoute);
      }

      if (this.id) {
        this.isShow = true;
        this.cdr.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngAfterViewInit() {
    this.getCurrentTable.emit(this.table.nativeElement);
  }

  updateData(): void {
    const id = this.activatedRoute.snapshot.params['dataInstanceId'];
    if (!id) return;

    this.redirectDataService.voidParamsCurrentPage();
    this.redirectDataService.initParamsCurrentPage(this.activatedRoute);
    this.getDataInstanceByShippingPlanId(id);
  }

  getDataCalculationLog(): void {
    this.checkFinishCaluclationService
      .getIsShow(this.dataInstance)
      .subscribe((isShow) => {
        this.isShow = isShow;
        this.cdr.markForCheck();
      });
  }

  subscribeSortingFiltering(): void {
    this.activatedRoute.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe((query) => {
      this.data = this.filterSortByColumnService.sort(query, 'station', this.rawData);
      this.data = this.filterSortByColumnService.filter(query, this.data);

      this.cdr.detectChanges();
    });
  }

  checkIsSelected(row: TShippingPlanListRow, dayIndex: number): boolean {
    return this.selectedCells.some((cell) => cell.row === row && cell.dayIndex === dayIndex);
  }

  selectPlan(row: TShippingPlanListRow, dayIndex: number): void {
    this.isSingleClick = true;
    setTimeout(() => {
      if (!this.isSingleClick) {
        return;
      }

      if (this.checkIsSelected(row, dayIndex)) {
        this.selectedCells = this.selectedCells.filter(
          (cell) => cell.row !== row || cell.dayIndex !== dayIndex,
        );
        this.cdr.detectChanges();
        return;
      }

      this.selectedCells.push({ row, dayIndex });
      this.cdr.detectChanges();
    }, 250);
  }

  openModal(row?: TShippingPlanListRow | null, numberOfDay?: string, columnIndex?: number): void {
    this.modalContainer = this.modal;
    const modalComponent = this.open(ShippingPlanModalComponent);
    modalComponent.instance.dataInstance = this.dataInstance;
    modalComponent.instance.selectedCells = this.selectedCells;
    modalComponent.instance.openSubject.subscribe(this.handleModalClosed);
    modalComponent.instance.refreshList.subscribe(() => this.updateData());

    if (!row) {
      return;
    }

    modalComponent.instance.row = row;
    modalComponent.instance.data = row.days[numberOfDay!];
    modalComponent.instance.columnIndex = columnIndex!;
    modalComponent.instance.isShow = this.isShow;
    this.cdr.markForCheck();
  }

  getListData(dataInstanceId: string): void {
    this.ss.startSpinner();
    this.getDataCalculationLog();

    this.shippingPlanService
      .getShippingPlanList(dataInstanceId)
      .pipe(take(1))
      .subscribe((shippingPlans: IShippingPlan[]): void => {
        this.initializeListColumns(this.dataInstance);

        this.totalValueMap.clear();

        this.data = this.shippingPlanService.mapShippingPlansToListRepresentation(
          this.dataInstance,
          shippingPlans,
          this.dayColumns.length,
        );

        this.shippingPlanService.createTotalValue(
          this.data,
          this.totalValueMap,
          this.dayColumns.length,
        );

        console.log(this.totalValueMap);

        console.log(this.data);

        this.rawData = [...this.data];
        this.subscribeSortingFiltering();
        this.ss.stopSpinner();
        this.cdr.detectChanges();
      }, this.ss.stopSpinner);
  }

  private handleModalClosed = (refresh?: boolean | null): void => {
    this.selectedCells = [];
    if (!refresh) {
      return;
    }

    this.updateData();
  };

  private initializeListColumns(dataInstance: IDataInstance): void {
    this.dayColumns = Array.from(
      Array(
        moment(dataInstance.dateFinished)
          .add(1, 'day')
          .diff(moment(dataInstance.dateStarted), 'days'),
      ),
      (_, index: number): TShippingPlanListColumn => ({
        columnHeading: String(index + 1),
        columnName: String(index + 1),
      }),
    );

    this.dayColumnsTotal = Array.from(
      Array(
        moment(dataInstance.dateFinished)
          .add(1, 'day')
          .diff(moment(dataInstance.dateStarted), 'days'),
      ),
      (_, index: number): TShippingPlanListColumn => ({
        columnHeading: String(index + 1),
        columnName: `t${index + 1}`,
      }),
    );

    this.displayedColumns = [...this.constantColumns, ...this.dayColumns].map(
      (column: TShippingPlanListColumn) => column.columnName,
    );
    this.displayedColumns.push('total-row');
    this.displayedColumnsTotal = [
      'index-total',
      ...this.dayColumnsTotal.map((column: TShippingPlanListColumn) => column.columnName),
      'total-row-amount',
    ];
  }

  private getDataInstanceByShippingPlanId = (dataInstanceId: string): void => {
    this.redirectDataService.initParamsCurrentPage(this.activatedRoute);
    this.ss.startSpinner();
    this.redirectDataService
      .getDataInstance(dataInstanceId)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((dataInstance: IDataInstance): void => {
        this.dataInstance = dataInstance;
        this.getListData(dataInstanceId);
      });
  };
}
