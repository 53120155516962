import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { RoutesHelper } from 'src/app/helpers/routes.helper';
import {
  TJournalSubmenuRoute,
  TSideBarSubmenuRouteValue,
} from 'src/app/modules/calendar-plan/types/journal-submenu-route.type';
import { TextComponent } from '../text/text.component';
import { MatIconModule } from '@angular/material/icon';
import { filter, Observable, takeWhile } from 'rxjs';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { IDocument } from 'src/app/data-models/document/document.interface';
import { NsiDictionaryModel } from 'src/app/data-models/dictionary/dictionary.model';
import { NsiTableTypeEnum } from '../../../nsi/enums/nsi-table-type.enum';

@Component({
  selector: 'nguk-sidebar-list-submenu-item',
  templateUrl: './sidebar-list-submenu-item.component.html',
  styleUrls: ['./sidebar-list-submenu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TextComponent, RouterModule, MatIconModule],
})
export class SidebarListSubmenuItemComponent implements OnInit, OnDestroy {
  @Input() data!: IJournal | IDataInstance | IDocument;
  @Input() title!: string;
  @Input() type!: string;
  @Input() menuItems!: TSideBarSubmenuRouteValue[];
  @Input() mapIds!: Map<number, boolean>;
  @Input() isOpenedId$!: Observable<number>;
  @Input() dictionaryMap!: Map<string, NsiDictionaryModel[]>;
  @Input() isOperationalPlan = false;

  dataJournal!: IJournal;
  dataDataInstance!: IDataInstance;
  dataDocument!: IDocument;

  listDictionary: NsiDictionaryModel[] = [];
  dictionaryId = '';

  @Output() readonly changeJournal = new EventEmitter<string>();
  @HostBinding('class.journal-submenu-item') private baseClass = true;

  isOpened: boolean | undefined = true;

  isAlive = true;

  readonly svgIconsEnum = SvgIconsEnum;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.isOpenedId$.pipe(takeWhile(() => this.isAlive)).subscribe((id) => {
      this.isOpened = true;
      this.cdr.detectChanges();
    });

    this.initCurrentData();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  handleStatusOpened(): void {
    if (this.type === 'graphs') return;
    this.isOpened = !this.isOpened;
  }

  getRouterLinksGraphs(): string[] {
    return [
      this.isOperationalPlan ? RoutesHelper.DATA_OPERATIONAL_PLAN : RoutesHelper.DATA_CALENDAR_PLAN,
      this.data.id!,
      RoutesHelper.DATA_CALENDAR_PLAN_RESULT_GRAPHS,
    ];
  }

  changeItemGraphs(): void {
    const newUrl = [
      this.isOperationalPlan ? RoutesHelper.DATA_OPERATIONAL_PLAN : RoutesHelper.DATA_CALENDAR_PLAN,
      this.data.id!,
      RoutesHelper.DATA_CALENDAR_PLAN_RESULT_GRAPHS,
    ];

    this.changeJournal.emit(this.data.id);
    this.cdr.markForCheck();

    this.router.navigate(newUrl);
  }

  initCurrentData(): void {
    if ('taskLogs' in this.data) {
      this.dataJournal = this.data;
      if (this.type === 'dictionary') {
        this.dictionaryId =
          this.dataJournal.dataInstances.find((item) => item.type === 'dictionary')?.id ?? '6';

        this.listDictionary = this.dictionaryMap.get(this.dictionaryId.toString()) || [];
      }

      this.cdr.detectChanges();
    } else if ('status' in this.data) {
      this.dataDocument = this.data;
    } else {
      this.dataDataInstance = this.data;
    }
  }

  getRouterLinkNSI(item: NsiDictionaryModel): string[] {
    const listFormat =
      item.format === NsiTableTypeEnum.List
        ? RoutesHelper.NSI_LIST_PATH
        : RoutesHelper.NSI_MATRIX_PATH;

    return [RoutesHelper.NSI_PATH, this.dictionaryId, listFormat, item.id.toString()];
  }

  getItemMenu(type: string): { id: string; type: string } | undefined {
    if (!this.dataJournal && !this.dataDocument) return;

    return (
      (this.dataJournal ?? this.dataDocument).dataInstances.find((item) => item.type === type) ??
      (this.dataJournal ?? this.dataDocument).productionPlans.find((item) => item.type === type)
    );
  }

  getRouterLink(menuItem: TJournalSubmenuRoute): string[] | null {
    return this.getItemMenu(menuItem.type) ? menuItem.url : null;
  }

  changeItemDictionary(item: NsiDictionaryModel): void {
    const listFormat =
      item.format === NsiTableTypeEnum.List
        ? RoutesHelper.NSI_LIST_PATH
        : RoutesHelper.NSI_MATRIX_PATH;
    void this.router.navigate([
      this.isOperationalPlan ? RoutesHelper.DATA_OPERATIONAL_PLAN : RoutesHelper.DATA_CALENDAR_PLAN,
      this.data.id,
      RoutesHelper.NSI_PATH,
      this.dictionaryId,
      listFormat,
      item.id,
    ]);

    this.changeJournal.emit(this.data.id);
  }

  changeItem(menuItem: TJournalSubmenuRoute): void {
    const item = this.getItemMenu(menuItem.type);

    if (!item) return;

    this.router.navigate([
      this.dataJournal
        ? this.isOperationalPlan
          ? RoutesHelper.DATA_OPERATIONAL_PLAN
          : RoutesHelper.DATA_CALENDAR_PLAN
        : RoutesHelper.DOCUMENTS_PATH,
      this.data.id,
      ...menuItem.url,
      item?.id,
    ]);

    this.changeJournal.emit(this.data.id);
    this.cdr.markForCheck();
  }

  isActive(): boolean {
    return (
      +this.route.parent?.snapshot.params[this.dataJournal ? 'calendarPlanId' : 'documentId'] ===
      +this.data.id!
    );
  }
}
