import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { IDictionaryModel } from 'src/app/data-models/dictionary-model/dictionary-model.interface';
import { CheckboxComponent } from 'src/app/modules/forms/components/checkbox/checkbox.component';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_RADIO_DEFAULT_OPTIONS,
  MatRadioButton,
  MatRadioChange,
  MatRadioModule,
} from '@angular/material/radio';
import { isNumber } from 'lodash-es';
import { ButtonComponent } from '../../../../../ui/components/button/button.component';

@Component({
  selector: 'wagon-fleet-dropdown',
  templateUrl: './wagon-fleet-dropdown.component.html',
  styleUrls: ['./wagon-fleet-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TextComponent,
    MatIconModule,
    CheckboxComponent,
    OverlayModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
  ],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ],
})
export class WagonFleetDropdownComponent implements OnChanges {
  @Input() header!: string;
  @Input() showText = false;
  @Input() isMainCell = false;
  @Input() control!: FormArray;
  @Input() isRadio = false;
  @Input() isShow = false;

  @Input() options!: IDictionaryModel[];

  arrayOptions: IDictionaryModel[] = [];

  readonly svgIconsEnum = SvgIconsEnum;

  isOpen = false;

  formData!: FormArray;

  formDataRadio = new FormControl();

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    if (this.control && this.options) {
      this.initFormData(this.options);
      this.arrayOptions = [...this.options].sort((a, b) =>
        a.title > b.title ? 1 : a.title < b.title ? -1 : 0,
      );

      this.cdr.detectChanges();

      if (!this.control?.length) return;

      if (this.isRadio) {
        const findeEl = this.arrayOptions.find((item) => item.id === this.control.value[0].id);
        this.formDataRadio.setValue(findeEl);
      } else {
        this.control.value.forEach((element: IDictionaryModel) => {
          const index = (
            this.formData?.value as { checkbox: boolean; value: IDictionaryModel }[]
          )?.findIndex((fg) => +fg.value.id === +element.id);

          if (index === -1) return;

          this.formData?.controls[index].get('checkbox')?.setValue(true);
          this.handleCheckbox();
        });
      }

      this.cdr.detectChanges();
    }
  }

  changeCurrentValue(event: MatRadioChange): void {
    while (this.control.length) {
      this.control.removeAt(0);
    }

    const fg = this.fb.group({
      id: [event.value.id],
      title: [event.value.title],
    });

    this.control.push(fg);
  }

  resetForm(): void {
    while (this.control.length) {
      this.control.removeAt(0);
    }

    this.formDataRadio.reset();
  }

  initFormData(options: IDictionaryModel[]): void {
    this.formData = this.fb.array([]);
    options
      .sort((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0))
      .forEach((item) => {
        const fg = this.fb.group({
          checkbox: [false],
          value: item,
        });
        this.formData.push(fg);
      });
  }

  getCheckboxControl(index: number): FormControl {
    return this.formData.controls[index].get('checkbox') as FormControl;
  }

  togglePopup(e: Event) {
    if (this.isShow) return;
    this.isOpen = true;
    e.stopPropagation();
  }

  closeOverlay() {
    this.isOpen = false;
  }

  handleCheckbox(): void {
    while (this.control.length) {
      this.control.removeAt(0);
    }

    this.formData.value
      .filter((item: { checkbox: false; value: IDictionaryModel }) => item.checkbox)
      .forEach((item: { checkbox: false; value: IDictionaryModel }) => {
        const fg = this.fb.group({
          id: [item.value.id],
          title: [item.value.title],
        });

        this.control.push(fg);
      });

    this.cdr.detectChanges();
  }

  get currentDropdown(): string {
    return (
      this.control?.value?.map((item: IDictionaryModel) => item.title).join('; ') ||
      (this.showText ? this.header : '—')
    );
  }

  get checkValueCurrentDropdown(): boolean {
    return !!this.control?.value?.map((item: IDictionaryModel) => item.title).join('; ');
  }
}
