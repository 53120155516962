import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, take } from 'rxjs';
import { JournalApiService } from 'src/app/api/journal.api.service';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { RoutesHelper } from 'src/app/helpers/routes.helper';
import { ActionModal } from 'src/app/modules/ui/base/action-modal';
import { initialDataSubmenu } from './constants/path';
import { JournalListShortModalsActionsService } from '../../../services/journal-list-short/journal-list-short-modals-actions.service';
import { DictionaryApiService } from 'src/app/api/dictionary.api.service';
import { NsiDictionaryModel } from 'src/app/data-models/dictionary/dictionary.model';
import { SpinnerService } from '../../../../ui/services/spinner.service';
import { DataInstanceApiService } from 'src/app/api/data-instance.api.service';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { OperationalPlanJournalApiService } from 'src/app/api/operational-plan-journal.api.service';
import { ProviderActiveIdService } from '../../../services/journal-list-short/provider-active-id.service';
import { TabStateService } from 'src/app/core/services/tab-state.service';

@Component({
  selector: 'calendar-plan-journal-list-short',
  templateUrl: './journal-list-short.component.html',
  styleUrls: ['./journal-list-short.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [JournalListShortModalsActionsService],
})
export class JournalListShortComponent extends ActionModal implements OnInit, AfterViewInit {
  @ViewChild('modal', { read: ViewContainerRef }) modal!: ViewContainerRef;

  readonly svgIconsEnum = SvgIconsEnum;
  readonly initialDataSubmenu = initialDataSubmenu;

  id!: number;
  data!: IJournal[];
  mapIds = new Map<number, boolean>();
  mapDataInstance = new Map<string, IDataInstance>();

  dictionaryMap!: Map<string, NsiDictionaryModel[]>;

  isOperationalPlan = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private ss: SpinnerService,
    private journalApiService: JournalApiService,
    private operationalPlanJournalApiService: OperationalPlanJournalApiService,
    private router: Router,
    private route: ActivatedRoute,
    private journalListShortModalsActionsService: JournalListShortModalsActionsService,
    private dictionaryApiService: DictionaryApiService,
    private dataInstanceApiService: DataInstanceApiService,
    private providerActiveIdService: ProviderActiveIdService,
    private tabStateService: TabStateService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.modalContainer = this.modal;
    this.closeInteraction = this.getListJournals.bind(this);
  }

  ngOnInit(): void {
    this.isOperationalPlan = !!this.route.snapshot.data['isOperationalPlan'];
    this.cdr.detectChanges();

    this.initDataInstances();
    this.getListJournals();
    this.getDataDictionary();
  }

  initDataInstances(): void {
    this.dataInstanceApiService
      .getList(1, [{ name: 'itemsPerPage', value: 500 * 1000 }])
      .pipe(take(1))
      .subscribe((dataInstances) => {
        dataInstances.forEach((item) => this.mapDataInstance.set(item.id!, item));
      });
  }

  getDataDictionary(): void {
    this.ss.startSpinner();
    this.dictionaryApiService
      .getList(1, [
        {
          name: 'itemsPerPage',
          value: 50000,
        },
      ])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((dictionaryList) => {
        const dictionaryMap = new Map<string, NsiDictionaryModel[]>();
        dictionaryList.forEach((dictionary) => {
          if (!dictionary.dataInstance) return;

          const currentId = dictionary.dataInstance.id.toString();
          const currentDictionary = dictionaryMap.get(currentId);
          if (currentDictionary) {
            currentDictionary.push(dictionary);
            dictionaryMap.set(currentId, currentDictionary);
          } else {
            dictionaryMap.set(currentId, [dictionary]);
          }
        });

        this.dictionaryMap = dictionaryMap;
        this.cdr.detectChanges();
      });
  }

  getListJournals(): void {
    this.ss.startSpinner();
    this.isOperationalPlan ? this.geListOperationalPlanJournal() : this.geListCalendarPlanJournal();
  }

  geListCalendarPlanJournal(): void {
    this.journalApiService
      .getList(1, [
        {
          name: 'itemsPerPage',
          value: 50000,
        },
      ])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((data) => {
        this.data = data.sort(
          (lhs, rhs) => new Date(rhs.dateCreated).getTime() - new Date(lhs.dateCreated).getTime(),
        );

        this.redirectPage();
      });
  }
  geListOperationalPlanJournal(): void {
    this.operationalPlanJournalApiService
      .getList(1, [
        {
          name: 'itemsPerPage',
          value: 50000,
        },
      ])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((data) => {
        this.data = data.sort(
          (lhs, rhs) => new Date(rhs.dateCreated).getTime() - new Date(lhs.dateCreated).getTime(),
        );

        this.redirectPage();
      });
  }

  redirectPage(): void {
    const savedJournal = this.isOperationalPlan
      ? this.tabStateService.getLastJournalOP()
      : this.tabStateService.getLastJournalKP();

    const calendarId = this.route.snapshot.params['calendarPlanId'];

    const id = savedJournal.id ? savedJournal.id : calendarId;

    if (!isNaN(Number(id))) {
      this.mapIds.set(+id, true);
      this.id = +id;
      this.cdr.detectChanges();

      if (this.mapIds) this.providerActiveIdService.isOpenedIdSource.next(this.id);

      if (savedJournal.id) {
        const navigationParams = [
          savedJournal.typeJournal === 'OP'
            ? RoutesHelper.DATA_OPERATIONAL_PLAN
            : RoutesHelper.DATA_CALENDAR_PLAN,
          savedJournal.id,
          savedJournal.path,
        ];

        if (savedJournal.dataInstanceId) {
          navigationParams.push(savedJournal.dataInstanceId);
        }

        this.router.navigate(navigationParams);
      } else {
        const currentJournal = this.data.find((item) => +item.id === +id);

        if (!currentJournal) return;
        const shippingId = currentJournal.dataInstances.find((item) => item.type === 'shippingPlan')
          ?.id;
        this.router.navigate([
          this.isOperationalPlan
            ? RoutesHelper.DATA_OPERATIONAL_PLAN
            : RoutesHelper.DATA_CALENDAR_PLAN,
          id,
          RoutesHelper.DATA_SHIPPING_PLAN_PATH,
          shippingId,
        ]);
      }

      return;
    }

    const firstJournal = this.data.filter((_) => _.dataInstances.length)[0];

    if (!firstJournal) {
      this.router.navigate([
        this.isOperationalPlan
          ? RoutesHelper.DATA_OPERATIONAL_PLAN
          : RoutesHelper.DATA_CALENDAR_PLAN,
      ]);
      return;
    }

    const shippingId = firstJournal.dataInstances.find((item) => item.type === 'shippingPlan')?.id;

    this.router.navigate([
      this.isOperationalPlan ? RoutesHelper.DATA_OPERATIONAL_PLAN : RoutesHelper.DATA_CALENDAR_PLAN,
      firstJournal.id,
      RoutesHelper.DATA_SHIPPING_PLAN_PATH,
      shippingId,
    ]);
  }

  openModalItem(journal: IJournal): void {
    this.journalListShortModalsActionsService.open = this.open.bind(this);
    this.journalListShortModalsActionsService.openModalJournal(this.mapDataInstance, journal);
  }

  openModalJournal(): void {
    this.journalListShortModalsActionsService.open = this.open.bind(this);
    this.journalListShortModalsActionsService.openModalJournal(
      this.mapDataInstance,
      undefined,
      true,
    );
  }
}
