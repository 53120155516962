import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { DashboardPageComponent } from './components/pages/dashboard-page/dashboard-page.component';
import { PageComponent } from '../ui/components/page/page.component';
import { CardComponent } from '../ui/components/card/card.component';
import { TextComponent } from '../ui/components/text/text.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { ListContainerComponent } from '../table/components/list-container/list-container.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PageComponent,
    CardComponent,
    TextComponent,
    MatIconModule,
    MatTableModule,
    ListContainerComponent,
  ],
  declarations: [DashboardPageComponent],
})
export class DashboardModule {}
