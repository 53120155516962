import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { WagonFleetLadenUnladenComponent } from '../wagon-fleet-laden-unladen/wagon-fleet-laden-unladen.component';
import { WagonFleetUnderLoadingComponent } from '../wagon-fleet-under-loading/wagon-fleet-under-loading.component';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, take, takeWhile } from 'rxjs';
import { RedirectDataService } from 'src/app/modules/data/services/redirect-data.service';
import { SpinnerService } from 'src/app/modules/ui/services/spinner.service';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { WagonAvailabilityApiService } from 'src/app/api/wagon-availability.api.service';
import { IWagonAvailability } from 'src/app/data-models/wagon-availability/wagon-availability.interface';
import { WagonLoadedApiService } from 'src/app/api/wagon-loaded.api.service';
import { IWagonLoaded } from 'src/app/data-models/wagon-loaded/wagon-loaded.interface';
import { WagonFleetApiService } from 'src/app/api/wagon-fleet.api.service';
import { CheckFinishCaluclationService } from 'src/app/modules/data/services/check-correct-caluclation.service';
import { SectionComponent } from '../../../../../ui/components/section/section.component';

@Component({
  selector: 'wagon-fleet-list',
  templateUrl: './wagon-fleet-list.component.html',
  styleUrls: ['./wagon-fleet-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    WagonFleetLadenUnladenComponent,
    WagonFleetUnderLoadingComponent,
    SectionComponent,
  ],
})
export class WagonFleetListComponent implements OnInit, OnDestroy {
  readonly typeDataInstances = 'wagonFleet';

  @Input() id!: string | undefined;

  @Output() getCurrentSheet = new EventEmitter();
  @Output() getCurrentTable = new EventEmitter();

  isShow = false;
  isAlive = true;
  isOperationalPlan = true;

  dataInstance!: IDataInstance;
  wagonFleetId!: string;

  listWagonAvailability!: IWagonAvailability[];
  listWagonLoaded!: IWagonLoaded[];

  constructor(
    private ss: SpinnerService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private redirectDataService: RedirectDataService,
    private checkFinishCaluclationService: CheckFinishCaluclationService,
    private wagonAvailabilityApiService: WagonAvailabilityApiService,
    private wagonLoadedApiService: WagonLoadedApiService,
    private wagonFleetApiService: WagonFleetApiService,
  ) {}

  ngOnDestroy(): void {
    this.isAlive = true;
  }

  ngOnInit(): void {
    this.route.params.pipe(takeWhile(() => this.isAlive)).subscribe((params) => {
      const id = params['dataInstanceId'] || this.id;
      id
        ? this.getDataInstance(id)
        : this.redirectDataService.initRedirect(this.router, this.route);
    });

    if (this.id) this.isShow = true;
  }

  getDataInstance(id: string): void {
    this.redirectDataService.initParamsCurrentPage(this.route, id);

    this.ss.startSpinner();
    this.redirectDataService
      .getDataInstance(id)
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((res) => {
        this.dataInstance = res;
        this.initData(id);
      });
  }

  initData(id: string): void {
    this.getDataCalculationLog();
    this.getDataWagonFleet(id);
  }

  getDataWagonFleet(id: string): void {
    this.wagonFleetApiService
      .getList(1, [
        { name: 'itemsPerPage', value: 200000 },
        { name: 'dataInstance.id', value: id },
      ])
      .pipe(take(1))
      .subscribe((wagonFleet) => {
        this.wagonFleetId = wagonFleet[0].id;
        this.getDataLadenUnladlen(this.wagonFleetId);
        this.getDataWagonLoaded(this.wagonFleetId);
      });
  }

  getDataCalculationLog(): void {
    this.checkFinishCaluclationService.getIsShow(this.dataInstance).subscribe((isShow) => {
      this.isShow = isShow;
      this.cdr.detectChanges();
    });
  }

  getDataLadenUnladlen(id: string): void {
    this.ss.startSpinner();
    this.wagonAvailabilityApiService
      .getList(1, [
        { name: 'wagonFleet.id', value: id },
        { name: 'itemsPerPage', value: 200000 },
      ])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((res) => {
        this.listWagonAvailability = [...res];
        this.cdr.markForCheck();
      });
  }

  getDataWagonLoaded(id: string): void {
    this.ss.startSpinner();
    this.wagonLoadedApiService
      .getList(1, [
        { name: 'wagonFleet.id', value: id },
        { name: 'itemsPerPage', value: 200000 },
      ])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe((res) => {
        this.listWagonLoaded = res.sort((a, b) => a.tact - b.tact);
        this.cdr.markForCheck();
      });
  }
}
