import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Page } from '../../../../ui/base/page';

@Component({
  selector: 'dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardPageComponent extends Page {}
