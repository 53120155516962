import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { finalize, forkJoin, take, takeWhile } from 'rxjs';
import { WarehouseBalanceApiService } from 'src/app/api/warehouse-balance.api.service';
import {
  IWarehouseBalance,
  IWarehouseBalanceGeneral,
  IWarehouseBalanceItem,
} from 'src/app/data-models/enrichment-standard/warehouse-balance.interface';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { MatSortModule } from '@angular/material/sort';
import { ActionModal } from 'src/app/modules/ui/base/action-modal';
import { ModalWarehouseBalanceItemsComponent } from '../modal-warehouse-balance-items/modal-warehouse-balance-items.component';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { SectionComponent } from 'src/app/modules/ui/components/section/section.component';
import { WarehouseBalanceItemApiService } from 'src/app/api/warehouse-balance-item.api.service';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { MatIconModule } from '@angular/material/icon';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/app/modules/ui/services/spinner.service';
import { RedirectDataService } from 'src/app/modules/data/services/redirect-data.service';
import { WarehouseBalanceCheckHorizontalLinePipe } from 'src/app/modules/data/pipes/warehouse-balance/warehouse-balance-check-horizontal-line.pipe';
import { WarehouseBalanceBorderHiddenPipe } from 'src/app/modules/data/pipes/warehouse-balance/warehouse-balance-border-hidden.pipe';
import { SortFilterComponent } from 'src/app/modules/ui/components/sort-filter/sort-filter.component';
import { DataFilterPipe } from '../../../../../../core/pipes/data-filter.pipe';
import { ThSortFilterComponent } from 'src/app/modules/ui/components/th-sort-filter/th-sort-filter.component';
import { FilterSortByColumnService } from 'src/app/modules/ui/services/filter-sort-by-column.service';
import { ToggleComponent } from 'src/app/modules/forms/components/toggle/toggle.component';
import { FormControl } from '@angular/forms';
import { WarehouseBalanceViewTableEnum } from 'src/app/modules/data/enums/warehouse-balance-view-table.enum';
import { CheckFinishCaluclationService } from 'src/app/modules/data/services/check-correct-caluclation.service';

@Component({
  selector: 'warehouse-balance-list',
  templateUrl: './warehouse-balance-list.component.html',
  styleUrls: ['./warehouse-balance-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    TextComponent,
    MatSortModule,
    SectionComponent,
    ButtonComponent,
    MatIconModule,
    WarehouseBalanceCheckHorizontalLinePipe,
    WarehouseBalanceBorderHiddenPipe,
    SortFilterComponent,
    DataFilterPipe,
    ThSortFilterComponent,
    ToggleComponent,
  ],
})
export class WarehouseBalanceListComponent
  extends ActionModal
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('modal', { read: ViewContainerRef }) modal!: ViewContainerRef;

  @Input() id!: string | undefined;

  @ViewChild('table', { read: ElementRef })
  table!: ElementRef<HTMLTableElement>;

  @Output() getCurrentTable = new EventEmitter();

  isShow = false;
  isFlatTable = false;

  readonly typeDataInstances = 'warehouseBalance';

  toggleList = [WarehouseBalanceViewTableEnum.Group, WarehouseBalanceViewTableEnum.Flat];
  toggleControl = new FormControl<string | null>(this.toggleList[0]);

  data!: IWarehouseBalance[] | IWarehouseBalanceItem[];
  rawData!: IWarehouseBalance[] | IWarehouseBalanceItem[];

  dataSource = new MatTableDataSource<IWarehouseBalance | IWarehouseBalanceItem>();

  dataGroup!: IWarehouseBalance[];
  rawDataGroup!: IWarehouseBalance[];

  dataFlat!: IWarehouseBalanceItem[];
  rawDataFlat!: IWarehouseBalanceItem[];

  dataInstance!: IDataInstance;
  dataWarehouseBalanceItems!: IWarehouseBalanceItem[];

  currentShowFilter: number | undefined = undefined;

  expandedElement!: string | null;
  expandedItemsMap = new Map<string, boolean>();

  readonly svgIconsEnum = SvgIconsEnum;

  mapRows = new Map<string, IWarehouseBalanceItem>();

  isSingleClick = true;
  isAlive = true;

  columns: IDataColumnTable[] = [
    { name: 'index', display: '#', hidden: true },
    { name: 'button-toggle', display: '', hidden: true },
    { name: 'materialType', display: 'Тип', hidden: true },
    { name: 'warehouseForOperatingPlan', display: 'Склад ОП' },
    { name: 'mark', display: 'Марка', hidden: true },
    { name: 'dateAvailability', display: 'Дата' },
    { name: 'volume', display: 'Объем' },
    { name: 'ad', display: 'Ad' },
    { name: 'vd', display: 'Vd' },
    { name: 'y', display: 'Y' },
    { name: 'csr', display: 'CSR' },
    { name: 'hgi', display: 'HGI' },
    { name: 'area', display: 'Участок' },
    { name: 'layer', display: 'Пласт' },
    { name: 'profile', display: 'Проф.' },
  ];

  displayedColumns: string[] = this.columns.map((item) => item.name);

  constructor(
    private ss: SpinnerService,
    private cdr: ChangeDetectorRef,
    private warehouseBalanceApiService: WarehouseBalanceApiService,
    private warehouseBalanceItemApiService: WarehouseBalanceItemApiService,
    private route: ActivatedRoute,
    private router: Router,
    private redirectDataService: RedirectDataService,
    private filterSortByColumnService: FilterSortByColumnService,
    private checkFinishCaluclationService: CheckFinishCaluclationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initSubscribeToggle();

    this.route.params.pipe(takeWhile(() => this.isAlive)).subscribe((params) => {
      const id = params['dataInstanceId'] || this.id;
      id
        ? this.getDataInstance(id)
        : this.redirectDataService.initRedirect(this.router, this.route);
    });

    if (this.id) this.isShow = true;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngAfterViewInit() {
    this.getCurrentTable.emit(this.table.nativeElement);
  }

  initSubscribeToggle(): void {
    this.toggleControl.valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      this.isFlatTable = value === WarehouseBalanceViewTableEnum.Flat;

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {},
      });

      this.data = this.isFlatTable ? [...this.dataFlat] : [...this.dataGroup];
      this.rawData = this.isFlatTable ? [...this.rawDataFlat] : [...this.rawDataGroup];

      this.dataSource.data = this.data;

      this.expandedItemsMap.clear();
      this.mapRows.clear();
      this.cdr.detectChanges();
    });
  }

  getDataCalculationLog(): void {
    this.checkFinishCaluclationService.getIsShow(this.dataInstance).subscribe((isShow) => {
      this.isShow = isShow;
      this.cdr.detectChanges();
    });
  }

  subscribeSortingFiltering(): void {
    this.route.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe((query) => {
      this.data = this.filterSortByColumnService.sort(query, 'warehouse', this.rawData);
      this.data = this.filterSortByColumnService.filter(query, this.data);

      this.dataSource.data = this.data;

      this.cdr.detectChanges();
    });
  }

  getDataInstance(id: string) {
    this.ss.startSpinner();
    this.redirectDataService.initParamsCurrentPage(this.route, id);
    this.redirectDataService
      .getDataInstance(id)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.dataInstance = res;
          this.expandedItemsMap.clear();

          this.initData(id);
        },
        () => this.ss.stopSpinner(),
      );
  }

  toggleCurrentRow(row: IWarehouseBalanceItem): void {
    if (this.isShow) return;

    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        this.mapRows.get(row.id) ? this.mapRows.delete(row.id) : this.mapRows.set(row.id, row);
        this.cdr.detectChanges();
      }
    }, 250);
  }

  toggleTable(row: IWarehouseBalance | IWarehouseBalanceItem): void {
    if ('warehouseBalance' in row) {
      this.toggleCurrentRow(row as IWarehouseBalanceItem);
      return;
    }

    // const findedEl = this.expandedItemsMap.get(row.id);
    this.expandedItemsMap.has(row.id)
      ? this.expandedItemsMap.delete(row.id)
      : this.expandedItemsMap.set(row.id, true);
    this.cdr.detectChanges();
  }

  openModal(row?: IWarehouseBalanceItem, warehouseBalanceId?: string): void {
    this.isSingleClick = false;
    this.modalContainer = this.modal;
    const modalComponent = this.open(ModalWarehouseBalanceItemsComponent);
    modalComponent.instance.updateData = this.initData.bind(this, this.dataInstance.id!);
    modalComponent.instance.dataInstance = this.dataInstance;
    modalComponent.instance.isShow = this.isShow;
    this.closeInteraction = this.initData.bind(this, this.dataInstance.id!);
    if (warehouseBalanceId) modalComponent.instance.warehouseBalanceId = warehouseBalanceId;

    if (this.mapRows.size) {
      const arrayData = [...this.mapRows].map((item) => item[1]);
      modalComponent.instance.data = arrayData;
    } else {
      modalComponent.instance.data = row ? [row] : [];
    }
  }

  initData(id: string): void {
    this.ss.startSpinner();
    this.getDataCalculationLog();

    forkJoin([
      this.warehouseBalanceApiService.getList(1, [
        { name: 'dataInstance.id', value: id },
        { name: 'itemsPerPage', value: 500 * 10000 },
      ]),
      this.warehouseBalanceItemApiService.getList(1, [
        { name: 'dataInstance.id', value: id },
        { name: 'itemsPerPage', value: 5000 * 100000 },
      ]),
    ])
      .pipe(take(1))
      .subscribe(
        ([groupedData, itemData]) => {
          const transformedData = this.transformData(groupedData, itemData);

          this.dataGroup = this.sortingData(transformedData);
          this.rawDataGroup = [...this.dataGroup];

          this.dataFlat = this.sortingData(itemData);
          this.rawDataFlat = [...this.dataFlat];

          this.data = this.isFlatTable ? [...this.dataFlat] : [...this.dataGroup];
          this.rawData = this.isFlatTable ? [...this.rawDataFlat] : [...this.rawDataGroup];

          this.dataSource.data = this.data;

          this.mapRows.clear();

          this.cdr.detectChanges();
          this.subscribeSortingFiltering();
          this.ss.stopSpinner();
        },
        () => this.ss.stopSpinner(),
      );
  }

  transformData(
    groupedData: IWarehouseBalance[],
    itemData: IWarehouseBalanceItem[],
  ): IWarehouseBalance[] {
    const idValueMap = new Map<string, IWarehouseBalanceItem[]>();

    itemData.forEach((el) => {
      const finedValue = idValueMap.get(el.warehouseBalance.id);

      if (finedValue) {
        finedValue.push(el);
        idValueMap.set(el.warehouseBalance.id, finedValue);
      } else {
        idValueMap.set(el.warehouseBalance.id, [el]);
      }
    });

    return groupedData.map((el) => {
      const values = idValueMap.get(el.id);
      el.values = values ?? [];
      return el;
    });
  }

  sortingData<T extends IWarehouseBalanceGeneral>(result: T[]): T[] {
    return result.sort((a, b) => {
      const warehouseA = a.warehouse.title.toUpperCase();
      const warehouseB = b.warehouse.title.toUpperCase();
      const markA = a.mark.title.toUpperCase();
      const markB = b.mark.title.toUpperCase();
      return warehouseA < warehouseB
        ? -1
        : warehouseA > warehouseB
          ? 1
          : markA < markB
            ? -1
            : markA > markB
              ? 1
              : 0;
    });
  }

  handleAllRows(): void {
    if (this.expandedItemsMap.size === this.data.length) {
      this.expandedItemsMap.clear();
    } else {
      this.data.forEach((item) => this.expandedItemsMap.set(item.id, true));
    }
  }
}
