import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextComponent } from '../../../../../ui/components/text/text.component';
import { TextareaComponent } from '../../../../../forms/components/textarea/textarea.component';

@Component({
  selector: 'calendar-plan-modal-journal-list-short-note',
  templateUrl: './modal-journal-list-short-note.component.html',
  styleUrls: ['./modal-journal-list-short-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TextComponent, FormsModule, ReactiveFormsModule, TextareaComponent],
})
export class ModalJournalListShortNoteComponent implements OnInit {
  @Input() formData!: FormGroup;

  get control(): FormControl {
    return this.formData.get('note') as FormControl;
  }

  ngOnInit(): void {
    // this.control.valueChanges.subscribe((value) => {
    //   if()
    // });
  }
}
