import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TextComponent } from '../../../../../ui/components/text/text.component';
import { EditShippingPlanForm } from '../../../../forms/edit-shipping-plan.form';
import { DatepickerComponent } from '../../../../../forms/components/datepicker/datepicker.component';
import { InputComponent } from '../../../../../forms/components/input/input.component';
import { IDataInstance } from '../../../../../../data-models/enrichment-standard/data-instance.interface';
import { Moment } from 'moment';
import { SelectComponent } from '../../../../../forms/components/select/select.component';
import { AutocompleteComponent } from '../../../../../forms/components/autocomplete/autocomplete.component';
import { DictionariesEnum } from '../../../../../../core/enums/dictionaries.enum';
import { DictionaryPipe } from '../../../../../../core/pipes/dictionary.pipe';
import { AsyncPipe } from '@angular/common';
import * as moment from 'moment';
import { SelectionSwitchComponent } from '../../../../../forms/components/selection-switch/selection-switch.component';
import { wagons } from '../../wagon-fleet/wagon-fleet-under-loading/mockWagon';
import { IDictionaryModel } from '../../../../../../data-models/dictionary-model/dictionary-model.interface';

@Component({
  selector: 'shipping-plan-modal-form',
  templateUrl: './shipping-plan-modal-form.component.html',
  styleUrls: ['./shipping-plan-modal-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TextComponent,
    InputComponent,
    DatepickerComponent,
    DatepickerComponent,
    InputComponent,
    SelectComponent,
    AutocompleteComponent,
    DictionaryPipe,
    AsyncPipe,
    SelectionSwitchComponent,
  ],
})
export class ShippingPlanModalFormComponent implements OnInit {
  @Input() dataInstance!: IDataInstance;
  @Input() isShow!: boolean;
  @Input({ required: true }) form!: EditShippingPlanForm;

  wagons: IDictionaryModel[] = wagons;
  datepickerMinDate!: Moment;
  datepickerMaxDate!: Moment;

  readonly dictionariesEnum = DictionariesEnum;
  readonly rzhdWindowOptions = [
    {
      value: 1,
      label: '6:00-18:00',
      additionalLabel: '(МСК)',
    },
    {
      value: 2,
      label: '18:00-6:00',
      additionalLabel: '(МСК)',
    },
    {
      value: 3,
      label: 'Любое',
      additionalLabel: '',
    },
  ];

  ngOnInit(): void {
    this.datepickerMinDate = moment(this.dataInstance.dateStarted);
    this.datepickerMaxDate = moment(this.dataInstance.dateStarted).add(60, 'days');

    this.form.setRequired(this.isShow);
  }
}
