<div class="nsi-data-instances-root">
  <nguk-section>
    <ng-container sectionTitle>Экземпляры данных</ng-container>
    <ng-container sectionActions>
      <nguk-button type="primary" class="create-button" (click)="createNewNSI()">
        <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
      </nguk-button>
    </ng-container>

    <div class="journal-list">
      <nsi-data-instance-item
        *ngFor="let dataInstance of dataInstances; let index = index"
        [index]="index"
        [isOpened]="dataInstancesOpenedState.get(dataInstance.id!)!"
        [isCalculationComplete]="dataInstance | checkCalculationComplete: calculationLogMap"
        [dataInstance]="dataInstance"
        [dictionaries]="utils.sortBy(dataInstancesDictionaries.get(dataInstance.id!)!, 'ordinal')"
        (toggleOpenState)="handleOpenStateChanged($event)"
        (openEditNSI)="openEditNSI($event)" />
    </div>
  </nguk-section>
</div>

<ng-template #editNSIModalTplRef></ng-template>
