import { Pipe, PipeTransform } from '@angular/core';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';

@Pipe({
  name: 'sidebarListHasItem',
  standalone: true,
})
export class SidebarListHasItemPipe implements PipeTransform {
  transform(type: string, data: IJournal): boolean {
    if (type === 'routesGenerator') return false;
    else if (type !== 'productionPlans') return true;
    else return !!data?.productionPlans.length;
  }
}
