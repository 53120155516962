<div class="nguk-select-root" [class.-horizontal]="direction === 'horizontal'">
  <ng-content select="[label]" />

  <mat-form-field appearance="outline">
    <mat-select
      [placeholder]="placeholder"
      [formControl]="control"
      [multiple]="multiple"
      [compareWith]="compareWithFn"
      panelClass="nguk-select-panel"
    >
      <mat-option *ngFor="let option of options" [value]="option.value" [title]="option.label">
        <nguk-text weight="400" styleName="text-sm" class="mat-option-text">
          {{ option.label }}
        </nguk-text>
      </mat-option>
    </mat-select>

    <nguk-control-error [showError]="errorState">{{ error }}</nguk-control-error>
  </mat-form-field>
</div>
