<div class="print-root">
  <div *ngIf="mapList.get('shippingPlan')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">Сбытовая потребность</nguk-text>
    <shipping-plan-list
      [id]="mapList.get('shippingPlan')"
      (getCurrentTable)="setTable($event, 'Сбытовая потребность')"
    ></shipping-plan-list>
  </div>

  <div *ngIf="mapList.get('miningPlan')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">План добычи</nguk-text>
    <mining-plan-list
      [id]="mapList.get('miningPlan')"
      (getCurrentTable)="setTable($event, 'План добычи')"
    ></mining-plan-list>
  </div>

  <div *ngIf="mapList.get('enrichmentStandard')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">Нормы ТК1</nguk-text>
    <enrichment-standard-list
      [id]="mapList.get('enrichmentStandard')"
      [type]="'TK1'"
      (getCurrentTable)="setTable($event, 'Нормы ТК1')"
    ></enrichment-standard-list>
  </div>

  <div *ngIf="mapList.get('enrichmentStandard')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">Нормы ТК2</nguk-text>
    <enrichment-standard-list
      [id]="mapList.get('enrichmentStandard')"
      [type]="'TK2'"
      (getCurrentTable)="setTable($event, 'Нормы ТК2')"
    ></enrichment-standard-list>
  </div>

  <div *ngIf="mapList.get('wagonFleet')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">Парк вагонов</nguk-text>
    <wagon-fleet-list
      [id]="mapList.get('wagonFleet')"
      (getCurrentSheet)="setTable($event)"
      (getCurrentTable)="setTable($event, 'Груженые и порожние')"
    ></wagon-fleet-list>
  </div>

  <div *ngIf="mapList.get('warehouseBalance')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">Остатки</nguk-text>
    <warehouse-balance-list
      [id]="mapList.get('warehouseBalance')"
      (getCurrentTable)="setTable($event, 'Остатки')"
    ></warehouse-balance-list>
  </div>

  <div *ngIf="mapList.get('resultMiningPlan')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">Добыча</nguk-text>
    <calendar-plan-result-mining-plan-list
      (getCurrentTable)="setTable($event, 'Добыча')"
      [id]="mapList.get('resultMiningPlan')"
    ></calendar-plan-result-mining-plan-list>
  </div>

  <div *ngIf="mapList.get('resultSupply')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">Обогащение</nguk-text>
    <calendar-plan-result-supply
      [id]="mapList.get('resultSupply')"
      (getCurrentTable)="setTable($event, 'Обогащение')"
    ></calendar-plan-result-supply>
  </div>

  <div *ngIf="mapList.get('warehouseDynamics')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">Складская динамика</nguk-text>
    <calendar-plan-warehouse-dynamics
      [id]="mapList.get('warehouseDynamics')"
      (getCurrentTable)="setTable($event, 'Складская динамика')"
    ></calendar-plan-warehouse-dynamics>
  </div>

  <div *ngIf="mapList.get('internalLogistics')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">Внутренняя логистика</nguk-text>
    <calendar-plan-internal-logistics-list
      [id]="mapList.get('internalLogistics')"
      (getCurrentTable)="setTable($event, 'Внутренняя логистика')"
    ></calendar-plan-internal-logistics-list>
  </div>

  <div *ngIf="mapList.get('commodityShipment')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">Товарная отгрузка</nguk-text>
    <calendar-plan-commodity-shipment-list
      [id]="mapList.get('commodityShipment')"
      (getCurrentTable)="setTable($event, 'Товарная отгрузка')"
    ></calendar-plan-commodity-shipment-list>
  </div>

  <div *ngIf="mapList.get('wagonDynamics')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">Динамика вагонов</nguk-text>
    <calendar-plan-warehouse-dynamics
      [id]="mapList.get('wagonDynamics')"
      [wagonDynamics]="true"
      (getCurrentTable)="setTable($event, 'Динамика вагонов')"
    ></calendar-plan-warehouse-dynamics>
  </div>

  <div *ngIf="mapList.get('gantt')" class="print-table">
    <nguk-text weight="600" styleName="text-sm">Гант</nguk-text>
    <calendar-plan-gantt
      [id]="mapList.get('gantt')"
      (getCurrentTable)="setTable($event, 'Гант')"
    ></calendar-plan-gantt>
  </div>
</div>
