import { Pipe, PipeTransform } from '@angular/core';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { IMiningPlanTransform } from 'src/app/data-models/mining-plan/mining-plan.interface';

@Pipe({
  name: 'miningPlanValueCell',
  standalone: true,
})
export class MiningPlanValueCellPipe implements PipeTransform {
  transform(row: IMiningPlanTransform, item: IDataColumnTable): string {
    return row.values.find((value) => value.day === item.display)?.volume || '';
  }
}
