<div
  class="nguk-logo-root"
  (mouseover)="showIconInfo = true"
  (focus)="(null)"
  (mouseleave)="showIconInfo = false"
  [routerLink]="routesHelper.DASHBOARD_PATH"
>
  <mat-icon [svgIcon]="svgIconsEnum.LogoNguk" class="logo-nguk"></mat-icon>

  <div class="vertical-line"></div>

  <mat-icon [svgIcon]="svgIconsEnum.LogoKonsom" class="logo-konsom"></mat-icon>

  <mat-icon
    [svgIcon]="svgIconsEnum.LogoInfo"
    class="logo-info"
    [class.-visible]="showIconInfo && hasInfoIcon"
    (click)="openModal($event)"
  ></mat-icon>
</div>

<ng-template #modal />
