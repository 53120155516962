import { Component, DestroyRef, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from '../../../ui/components/text/text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ControlDirective } from '../../directives/control.directive';
import { ControlErrorComponent } from '../control-error/control-error.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ValidationErrorService } from '../../services/validation-error.service';

type InputType = 'text' | 'number' | 'password' | 'time';

@Component({
  selector: 'nguk-input-ctrl',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    TextComponent,
    ReactiveFormsModule,
    ControlErrorComponent,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
  ],
})
export class InputComponent extends ControlDirective {
  @Input() type: InputType = 'text';
  @Input() min: number | null = null;
  @Input() max: number | null = null;
  @Input() matSuffix = '';
  @Input() step: null | string = null;

  @Output() clickIcon = new EventEmitter<void>();

  @HostBinding('class.nguk-input-ctrl') readonly inputBaseClass = true;

  constructor(dr: DestroyRef, validationErrorService: ValidationErrorService) {
    super(dr, validationErrorService);
  }

  focusOut() {
    if (this.type !== 'number') return;
    const numberValue = parseFloat(this.control.value as string);
    if (typeof this.max === 'number' && numberValue > this.max) this.control.setValue(this.max);
    else if (typeof this.min === 'number' && numberValue < this.min)
      this.control.setValue(this.min);
    else this.control.setValue(numberValue);
  }
}
