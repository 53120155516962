import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JournalProviderService {
  private isOpenedIdSource = new BehaviorSubject<number>(0);
  isOpenedId$ = this.isOpenedIdSource.asObservable();

  setNewValue(id: number): void {
    this.isOpenedIdSource.next(id);
  }
}
