import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { isObject } from 'lodash-es';
import { IDictionaryModel } from 'src/app/data-models/dictionary-model/dictionary-model.interface';

type TSoringElement = string | number | IDictionaryModel | { id: string };

@Injectable({
  providedIn: 'root',
})
export class FilterSortByColumnService {
  sort(query: Params, mainSortColumn: string, rawGroupedData: any[]): any[] {
    const type = query['typeSort'] || '';
    const column = query['columnSort'] || '';

    if (!type) {
      return [...rawGroupedData];
    }

    const isDesc = type === 'desc';

    return [...rawGroupedData].sort((a, b) => {
      let sortA;
      let sortB;

      if (mainSortColumn) {
        sortA = (a[mainSortColumn]?.title ?? a[mainSortColumn]).toUpperCase();
        sortB = (b[mainSortColumn]?.title ?? b[mainSortColumn]).toUpperCase();
      }

      const columnA = a[column];
      const columnB = b[column];

      const nameA = this.getValueElementSorting(columnA);
      const nameB = this.getValueElementSorting(columnB);

      if (mainSortColumn) {
        return sortA < sortB
          ? -1
          : sortA > sortB
            ? 1
            : nameA < nameB
              ? isDesc
                ? 1
                : -1
              : nameA > nameB
                ? isDesc
                  ? -1
                  : 1
                : 0;
      } else {
        return nameA < nameB ? (isDesc ? 1 : -1) : nameA > nameB ? (isDesc ? -1 : 1) : 0;
      }
    });
  }

  filter(query: Params, data: any[]): any[] {
    return data.filter((item) => {
      return Object.keys(query).every((key) => {
        if (key === 'typeSort' || key === 'columnSort') return true;

        const currentItem = item[key];

        if (!currentItem) return true;

        if (isObject(currentItem) && 'title' in currentItem) {
          return query[key].includes(currentItem.title);
        } else if (typeof currentItem === 'string') {
          return query[key].split(',').includes(currentItem);
        } else {
          return query[key].split(',').includes(currentItem);
        }
      });
    });
  }

  getValueElementSorting(element: TSoringElement): TSoringElement {
    let value: TSoringElement;

    if (isObject(element) && 'title' in element) {
      value = element.title;
    } else if (typeof element === 'string') {
      const numA = Number(element.split('..')[0]);
      value = !isNaN(numA) ? numA : element.toUpperCase();
    } else {
      value = element;
    }

    return value;
  }
}
