import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ShippingPlanModalListColumnsEnum } from '../../../../enums/shipping-plan-modal-list-columns.enum';
import { MatTableModule } from '@angular/material/table';
import { TextComponent } from '../../../../../ui/components/text/text.component';
import { SvgIconsEnum } from '../../../../../../core/enums/svg-icons.enum';
import { ButtonComponent } from '../../../../../ui/components/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';
import { InputComponent } from '../../../../../ui/components/forms/input/input.component';
import { EditShippingPlanLoadingRuleForm } from '../../../../forms/edit-shipping-plan-loading-rule.form';

@Component({
  selector: 'shipping-plan-modal-list',
  templateUrl: './shipping-plan-modal-list.component.html',
  styleUrls: ['./shipping-plan-modal-list.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatTableModule,
    TextComponent,
    ButtonComponent,
    MatIconModule,
    MatTooltipModule,
    NgIf,
    InputComponent,
  ],
})
export class ShippingPlanModalListComponent {
  @Input({ required: true }) tableRows!: EditShippingPlanLoadingRuleForm[];
  @Input() isShow!: boolean;
  @Output() readonly addRow = new EventEmitter<void>();
  @Output() readonly copyRow = new EventEmitter<number>();
  @Output() readonly deleteRow = new EventEmitter<number>();

  readonly svgIconsEnum = SvgIconsEnum;
  readonly listColumnsEnum = ShippingPlanModalListColumnsEnum;
  readonly listColumns = [
    ShippingPlanModalListColumnsEnum.Index,
    ShippingPlanModalListColumnsEnum.RequiredWagonMark,
    ShippingPlanModalListColumnsEnum.ContractualAd,
    ShippingPlanModalListColumnsEnum.ContractualVd,
    ShippingPlanModalListColumnsEnum.ContractualY,
    ShippingPlanModalListColumnsEnum.PlannedAd,
    ShippingPlanModalListColumnsEnum.PlannedVd,
    ShippingPlanModalListColumnsEnum.PlannedY,
    ShippingPlanModalListColumnsEnum.FactoryProductsMixture,
    ShippingPlanModalListColumnsEnum.Actions,
  ];

  editRowIndex!: number;

  constructor(private cdr: ChangeDetectorRef) {}

  makeEditable(index: number): void {
    this.editRowIndex = index;
    this.cdr.detectChanges();
  }
}
