import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlDirective } from '../../directives/control.directive';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';

@Component({
  selector: 'nguk-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatButtonToggleModule, FormsModule, ReactiveFormsModule, TextComponent],
})
export class ToggleComponent extends ControlDirective {
  @Input() toggleList!: string[];
}
