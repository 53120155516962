import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Page } from '../../../../ui/base/page';

@Component({
  selector: 'documents-page',
  templateUrl: './documents-page.component.html',
  styleUrls: ['./documents-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsPageComponent extends Page {}
