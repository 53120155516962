<div class="nguk-datepicker-ctrl-root" [class.-horizontal]="direction === 'horizontal'">
  <ng-content select="[label]" />
  <div class="container">
    <p-calendar
      dateFormat="dd.mm.yy"
      class="p-calendar-custom"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [placeholder]="placeholder"
      [formControl]="control"
      [numberOfMonths]="numberOfMonths"
      [showTime]="showTime"
      [appendTo]="appendTo"
      (onSelect)="convertDate($event)"
      (onInput)="inputDate()"
    />
    <nguk-control-error [showError]="errorState">{{ error }}</nguk-control-error>
  </div>
</div>
