<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header">
      <nguk-text weight="600" styleName="text-xl">ЭД от {{ date | date: 'dd.MM hh:mm' }}</nguk-text>
      <nguk-toggle [toggleList]="toggleList" [control]="toggleControl" *ngIf="data"></nguk-toggle>

      <modal-data-instances-list-short-actions
        class="modal-data-instances-list-short-actions"
        [toggleControl]="toggleControl"
        [typeDataInstances]="typeDataInstances"
        [data]="data"
        [formData]="formData"
        (close)="closeModal(true)"
        (updateData)="updateData()"
      >
      </modal-data-instances-list-short-actions>
    </div>

    <modal-data-instances-list-short-form
      [isCalculationComplete]="isCalculationComplete"
      [typeDataInstances]="typeDataInstances"
      (form)="getForm($event)"
      [data]="data"
    ></modal-data-instances-list-short-form>
  </div>
</nguk-modal>
