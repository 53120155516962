<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <nguk-text weight="600" styleName="text-xl">{{
      (data[0].area ? data[0].area.title + '|' : '') +
        (data[0].mark ? data[0].mark.title + '|' : '') +
        day
    }}</nguk-text>
    <div class="container-table" tabindex="0">
      <table mat-table [dataSource]="data" multiTemplateDataRows>
        <ng-container *ngFor="let item of columns; let i = index" [matColumnDef]="item.name">
          <th mat-header-cell *matHeaderCellDef>
            <nguk-text weight="400" styleName="text-xs" color="grey">{{ item.display }}</nguk-text>
          </th>

          <td mat-cell *matCellDef="let row; let ind = dataIndex">
            <nguk-text weight="400" styleName="text-xs">
              {{
                item.name === 'index'
                  ? ind + 1
                  : item.name === 'volume'
                    ? (
                        math.round((row[item.name] / (isWagonDynamics ? 1 : 1000)) * 100) / 100
                      ).toString()
                    : item.name === 'correspondent'
                      ? isOperationalPlan
                        ? row.correspondentForOperatingPlan
                        : row.correspondentForCalendarPlan
                      : row[item.name]?.title || row[item.name] || '-'
              }}
            </nguk-text>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</nguk-modal>
