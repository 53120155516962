<div class="shipping-plan-modal-form-root">
  <div class="fields-column">
    <nguk-datepicker-ctrl [control]="form.expectedShipmentDate" [min]="datepickerMinDate">
      <ng-container label>
        <nguk-text class="label" color="black" weight="400" styleName="text-sm">Дата</nguk-text>
      </ng-container>
    </nguk-datepicker-ctrl>

    <nguk-selection-switch-ctrl [control]="form.gapTimeForRZD" [options]="rzhdWindowOptions">
      <ng-container label>
        <nguk-text class="label" color="black" weight="400" styleName="text-sm">Окно РЖД</nguk-text>
      </ng-container>
    </nguk-selection-switch-ctrl>

    <nguk-autocomplete
      [control]="form.station"
      [options]="(dictionariesEnum.Station | ngukDictionaryPipe: form.station.value | async)!"
      [dictionary]="dictionariesEnum.Station"
    >
      <ng-container label>
        <nguk-text class="label" color="black" weight="400" styleName="text-sm">Станция</nguk-text>
      </ng-container>
    </nguk-autocomplete>

    <div class="controls-row">
      <nguk-autocomplete
        class="customer-control"
        [control]="form.customer"
        [options]="(dictionariesEnum.Customer | ngukDictionaryPipe: form.customer.value | async)!"
        [dictionary]="dictionariesEnum.Customer"
      >
        <ng-container label>
          <nguk-text
            class="label autocomplete-control-label"
            color="black"
            weight="400"
            styleName="text-sm"
            >Заказчик</nguk-text
          >
        </ng-container>
      </nguk-autocomplete>

      <nguk-input-ctrl class="position-control" [control]="form.position">
        <ng-container label>
          <nguk-text class="label control-label" color="black" weight="400" styleName="text-sm"
            >Позиция</nguk-text
          >
        </ng-container>
      </nguk-input-ctrl>
    </div>

    <nguk-input-ctrl [control]="form.trainVolume">
      <ng-container label>
        <nguk-text class="label" color="black" weight="400" styleName="text-sm"
          >Объем ЖД состава</nguk-text
        >
      </ng-container>
    </nguk-input-ctrl>

    <nguk-autocomplete
      [control]="form.trainWagonsEquipment"
      [options]="
        (dictionariesEnum.TrainPicking
          | ngukDictionaryPipe: form.trainWagonsEquipment.value
          | async)!
      "
      [dictionary]="dictionariesEnum.TrainPicking"
    >
      <ng-container label>
        <nguk-text class="label" color="black" weight="400" styleName="text-sm"
          >Комплектация ЖД состава</nguk-text
        >
      </ng-container>
    </nguk-autocomplete>

    <nguk-autocomplete [control]="form.wagonType" [options]="wagons">
      <ng-container label>
        <nguk-text class="label" color="black" weight="400" styleName="text-sm"
          >Тип вагона</nguk-text
        >
      </ng-container>
    </nguk-autocomplete>
  </div>
</div>
