<div class="wagon-fleet-laden-unladen-root">
  <nguk-section>
    <div class="sticky-container" tabindex="0">
      <div class="container-buttons" *ngIf="!isShow">
        <nguk-button type="primary" class="create-button" (click)="save()">
          <mat-icon [svgIcon]="svgIconsEnum.Save"></mat-icon>
        </nguk-button>
        <nguk-button type="primary" class="create-button" (click)="addRow()">
          <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
        </nguk-button>
      </div>
      <div class="main">
        <table mat-table [dataSource]="data" multiTemplateDataRows #table>
          <ng-container [matColumnDef]="'types'">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">
              <nguk-text weight="600" styleName="text-lg">Груженые/порожние</nguk-text>
            </th>
          </ng-container>

          <ng-container [matColumnDef]="'footer'">
            <td
              mat-footer-cell
              *matFooterCellDef="let row"
              [attr.colspan]="displayedColumns.length"
            >
              <nguk-button
                type="secondary"
                class="add-button -row"
                (click)="addRow()"
                *ngIf="!isShow"
              >
                <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
              </nguk-button>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="'laden'">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="6">
              <nguk-text weight="700" styleName="text-xs">Груженые</nguk-text>
            </th>
          </ng-container>

          <ng-container [matColumnDef]="'unladen'">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="1 + columnsDate.length">
              <nguk-text weight="700" styleName="text-xs">Порожние</nguk-text>
            </th>
          </ng-container>

          <ng-container [matColumnDef]="'empty-laden'">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"></th>
          </ng-container>

          <ng-container [matColumnDef]="'laden1'">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              <nguk-text weight="400" styleName="text-xs">1</nguk-text>
            </th>
          </ng-container>

          <ng-container [matColumnDef]="'laden2'">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              <nguk-text weight="400" styleName="text-xs">2</nguk-text>
            </th>
          </ng-container>

          <ng-container [matColumnDef]="'laden3'">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              <nguk-text weight="400" styleName="text-xs">3</nguk-text>
            </th>
          </ng-container>

          <ng-container [matColumnDef]="'expected-receipt'">
            <th
              mat-header-cell
              *matHeaderCellDef
              [attr.colspan]="1 + columnsDate.length"
              class="date-columns"
            >
              <nguk-text weight="400" styleName="text-xs"
                >Ожидаемое поступление до ..., шт</nguk-text
              >
            </th>
          </ng-container>

          <ng-container
            *ngFor="let item of defaultColumn; let i = index; let last = last"
            [matColumnDef]="item.name"
          >
            <th mat-header-cell *matHeaderCellDef>
              <nguk-text weight="600" styleName="text-xs" class="opacity-50">{{
                item.display
              }}</nguk-text>
            </th>

            <td mat-cell *matCellDef="let row; let ind = dataIndex">
              <ng-container *ngIf="item.name !== 'index'; else rowIndex">
                <ng-container *ngIf="item.type === 'dropdown'; else inputNumber">
                  <wagon-fleet-dropdown
                    [options]="getCurrentList(item.display)"
                    [header]="item.display"
                    [control]="getArray(ind, item.name)"
                    [isShow]="isShow"
                  ></wagon-fleet-dropdown>
                </ng-container>

                <ng-template #inputNumber>
                  <div class="container-input">
                    <nguk-input-ctrl
                      *ngIf="ind === activeRow && !isShow; else textInput"
                      [type]="'number'"
                      [control]="getControl(ind, item.name)"
                    />
                    <div
                      class="container-actions"
                      *ngIf="last && currentActionsRows === ind && !columnsDate.length"
                    >
                      <nguk-button
                        class="copy-button"
                        type="secondary"
                        matTooltip="Копировать строку"
                        (click)="addRow(ind)"
                      >
                        <mat-icon [svgIcon]="svgIconsEnum.Duplicate"></mat-icon>
                      </nguk-button>

                      <nguk-button
                        class="delete-button"
                        type="secondary"
                        matTooltip="Удалить строку"
                        (click)="removeRow(ind)"
                      >
                        <mat-icon [svgIcon]="svgIconsEnum.Delete"></mat-icon>
                      </nguk-button>
                    </div>
                  </div>

                  <ng-template #textInput
                    ><nguk-text weight="400" styleName="text-xs" *ngIf="ind !== activeRow">{{
                      getControl(ind, item.name).value
                    }}</nguk-text></ng-template
                  >
                </ng-template>
              </ng-container>

              <ng-template #rowIndex>
                <nguk-text weight="400" styleName="text-xs">{{ ind + 1 }}</nguk-text>
              </ng-template>
            </td>
          </ng-container>

          <ng-container
            *ngFor="let item of columnsDate; let i = index; let last = last"
            [matColumnDef]="item.name"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="th-date"
              (click)="isShow ? null : (activeRow = 'th-table')"
              [class.bg-blue]="i % 2"
              [class.last-period]="last"
              [class.bg-light-blue]="!(i % 2)"
              (mouseover)="activeDateColumns = i"
              (focus)="(null)"
              (mouseleave)="activeDateColumns = undefined"
            >
              <nguk-datepicker-ctrl
                *ngIf="activeRow === 'th-table' && !isShow; else textDate"
                [control]="getControlDate(i)"
                [numberOfMonths]="1"
                [appendTo]="'body'"
                [showTime]="true"
              />
              <ng-template #textDate>
                <nguk-date-pair
                  [date]="getCurrentPeriod(getControlDate(i).value)"
                  *ngIf="getControlDate(i).value; else emptyDate"
                >
                  <ng-container left>
                    {{ getControlDate(i).value | date: 'dd.MM' }}
                  </ng-container>
                  <ng-container right>
                    {{ getControlDate(i).value | date: 'HH:mm' }}
                  </ng-container>
                </nguk-date-pair>
                <ng-template #emptyDate>
                  <nguk-text weight="600" styleName="text-xs" class="opacity-50"> - </nguk-text>
                </ng-template>
              </ng-template>

              <div class="container-actions" *ngIf="activeDateColumns === i && !isShow">
                <nguk-button
                  class="delete-button"
                  type="secondary"
                  matTooltip="Удалить колонку"
                  (click)="removeColumn(i); $event.stopPropagation()"
                >
                  <mat-icon [svgIcon]="svgIconsEnum.Delete"></mat-icon>
                </nguk-button>
              </div>
            </th>

            <td
              mat-cell
              *matCellDef="let row; let ind = dataIndex"
              [class.last-period]="last"
              [class.bg-blue]="i % 2"
              [class.bg-light-blue]="!(i % 2)"
            >
              <div class="container-input">
                <nguk-input-ctrl
                  *ngIf="ind === activeRow && !isShow; else textInput"
                  [type]="'number'"
                  [control]="getEmptyAmountWagonControl(ind, i)"
                />
                <div class="container-actions" *ngIf="last && currentActionsRows === ind">
                  <nguk-button
                    class="copy-button"
                    type="secondary"
                    matTooltip="Копировать строку"
                    (click)="addRow(ind)"
                  >
                    <mat-icon [svgIcon]="svgIconsEnum.Duplicate"></mat-icon>
                  </nguk-button>

                  <nguk-button
                    class="delete-button"
                    type="secondary"
                    matTooltip="Удалить строку"
                    (click)="removeRow(ind)"
                  >
                    <mat-icon [svgIcon]="svgIconsEnum.Delete"></mat-icon>
                  </nguk-button>
                </div>
              </div>

              <ng-template #textInput
                ><nguk-text weight="400" styleName="text-xs" *ngIf="ind !== activeRow">{{
                  getEmptyAmountWagonControl(ind, i).value || ''
                }}</nguk-text></ng-template
              >
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['types', 'unladen', 'laden']"></tr>
          <tr
            mat-header-row
            *matHeaderRowDef="['empty-laden', 'expected-receipt', 'laden1', 'laden2', 'laden3']"
          ></tr>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns; let ind = dataIndex"
            (mouseover)="isShow ? null : (currentActionsRows = ind)"
            (focus)="(null)"
            (mouseleave)="currentActionsRows = undefined"
            (click)="selectRow(ind)"
          ></tr>
          <tr mat-footer-row *matFooterRowDef="['footer']"></tr>
        </table>

        <div class="container-add-column" *ngIf="!isShow">
          <nguk-button type="secondary" class="add-button -column" (click)="addColumn()">
            <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
          </nguk-button>
        </div>
      </div>
    </div>
  </nguk-section>
</div>
