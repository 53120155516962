<div class="textarea">
  <nguk-textarea-ctrl
    [control]="control"
    direction="vertical"
    [placeholder]="'Введите текст примечания'"
  >
    <ng-container label>
      <nguk-text color="grey" weight="500" styleName="text-sm">Примечание</nguk-text>
    </ng-container>
  </nguk-textarea-ctrl>
</div>
