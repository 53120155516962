import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import { IWagonAvailability } from '../data-models/wagon-availability/wagon-availability.interface';
import { IEditWagonAvailability } from '../data-models/wagon-availability/edit-wagon-availability.interface';

@Injectable({
  providedIn: 'root',
})
export class WagonAvailabilityApiService extends EntityApi<
  IWagonAvailability,
  IEditWagonAvailability
> {
  protected readonly api = 'sourceData/wagonAvailability';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
