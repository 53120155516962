import { Component } from '@angular/core';
import { IconsService } from './core/services/icons.service';
import { NsiService } from './modules/nsi/services/nsi.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private iconsService: IconsService,
    private normativeInfoService: NsiService,
  ) {
    iconsService.registerSvgIcons();
  }
}
