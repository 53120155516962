export const columnDisplayed = [
  'Такт',
  'Режим пр-ва ТК1',
  'Марка СГП/9',
  'Марка СГП/11',
  'Марка КЛ/10',
  'Заказчик ТК/15,16/Строка 1',
  'Заказчик ТК/15,16/Строка 2',
  'Заказчик ТК/15,16/Строка 3',
  'Тип вагона ТК/15,16/Строка 1',
  'Тип вагона ТК/15,16/Строка 2',
  'Тип вагона ТК/15,16/Строка 3',
  'Количество вагонов ТК/15,16/Строка 1',
  'Количество вагонов ТК/15,16/Строка 2',
  'Количество вагонов ТК/15,16/Строка 3',
  'Заказчик ТК/12,13/Строка 1',
  'Заказчик ТК/12,13/Строка 2',
  'Заказчик ТК/12,13/Строка 3',
  'Тип вагона ТК/12,13/Строка 1',
  'Тип вагона ТК/12,13/Строка 2',
  'Тип вагона ТК/12,13/Строка 3',
  'Количество вагонов ТК/12,13/Строка 1',
  'Количество вагонов ТК/12,13/Строка 2',
  'Количество вагонов ТК/12,13/Строка 3',
  'Заказчик СГП/9/Строка 1',
  'Заказчик СГП/9/Строка 2',
  'Заказчик СГП/9/Строка 3',
  'Тип вагона СГП/9/Строка 1',
  'Тип вагона СГП/9/Строка 2',
  'Тип вагона СГП/9/Строка 3',
  'Количество вагонов СГП/9/Строка 1',
  'Количество вагонов СГП/9/Строка 2',
  'Количество вагонов СГП/9/Строка 3',
  'Заказчик СГП/11/Строка 1',
  'Заказчик СГП/11/Строка 2',
  'Заказчик СГП/11/Строка 3',
  'Тип вагона СГП/11/Строка 1',
  'Тип вагона СГП/11/Строка 2',
  'Тип вагона СГП/11/Строка 3',
  'Количество вагонов СГП/11/Строка 1',
  'Количество вагонов СГП/11/Строка 2',
  'Количество вагонов СГП/11/Строка 3',
  'Заказчик КЛ/10/Строка 1',
  'Заказчик КЛ/10/Строка 2',
  'Заказчик КЛ/10/Строка 3',
  'Тип вагона КЛ/10/Строка 1',
  'Тип вагона КЛ/10/Строка 2',
  'Тип вагона КЛ/10/Строка 3',
  'Количество вагонов КЛ/10/Строка 1',
  'Количество вагонов КЛ/10/Строка 2',
  'Количество вагонов КЛ/10/Строка 3',
];
