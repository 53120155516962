<div class="modal-upload-related-systems-root">
  <nguk-toggle [toggleList]="toggleList" [control]="toggleControl" class="header-toggle" />

  <div class="data-instance">
    <nguk-text weight="400" class="title">Наименование нового ЭД</nguk-text>
    <div class="data-input">
      <nguk-input-ctrl [control]="getControl('nameDataInstance')"></nguk-input-ctrl>
    </div>
  </div>

  <div class="parameters">
    <div class="parameters__item">
      <nguk-text weight="400" class="title">Дата</nguk-text>
      <div class="container-input">
        <nguk-datepicker-ctrl
          [control]="getControl('date')"
          [appendTo]="'body'"
        ></nguk-datepicker-ctrl>
      </div>
    </div>

    <div class="parameters__item">
      <nguk-text weight="400" class="title">Время</nguk-text>
      <div class="container-input">
        <nguk-input-ctrl type="time" [control]="getControl('time')"></nguk-input-ctrl>
      </div>
    </div>

    <div class="parameters__item" *ngFor="let item of listDataInstances; let index = index">
      <nguk-text weight="400" class="title">{{ item.label }}</nguk-text>
      <nguk-slide-toggle [control]="getSlideToggleControl(index)"></nguk-slide-toggle>
    </div>
  </div>
</div>
