import { Pipe, PipeTransform } from '@angular/core';
import { EShippingPlanColorAccent } from '../../../../enums/shipping-plan-color-accent.enum';
import { TShippingPlanDay } from '../../types/shipping-plan-day.type';

@Pipe({
  name: 'shippingPlanColor',
  standalone: true,
})
export class ShippingPlanColorPipe implements PipeTransform {
  static requiredFields: (keyof TShippingPlanDay)[] = [
    'trainVolume',
    'customer',
    'trainWagonsEquipment',
    'expectedShipmentDate',
    'station',
  ];

  transform(day: TShippingPlanDay, expectedColor: EShippingPlanColorAccent): boolean {
    if (!day) {
      return false;
    }

    if (parseFloat(day.trainVolume) < 3.5) {
      return expectedColor === EShippingPlanColorAccent.Yellow;
    }

    return (
      ShippingPlanColorPipe.requiredFields.some((fieldName) => day[fieldName] === null) &&
      expectedColor === EShippingPlanColorAccent.Red
    );
  }
}
