<div class="enrichment-standard-list-root">
  <nguk-section>
    <div class="sticky-container">
      <div class="container-buttons" *ngIf="!isShow">
        <nguk-button
          type="primary"
          *ngIf="mapRows.size > 1"
          class="edit-button"
          (click)="openModal()"
        >
          <mat-icon [svgIcon]="svgIconsEnum.Edit"></mat-icon>
        </nguk-button>
        <nguk-button type="primary" class="create-button" (click)="openModal()">
          <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
        </nguk-button>
      </div>

      <table mat-table [dataSource]="data" multiTemplateDataRows #table>
        <ng-container *ngFor="let row of currentFirstHeaderRow" [matColumnDef]="row.name">
          <th
            mat-header-cell
            *matHeaderCellDef
            [attr.colspan]="row.colspan"
            class="horizontal-alignment"
            [class.grey-bg-columns]="row.name === 'concentrate' || row.name === 'tk1sludge'"
          >
            <nguk-text weight="700" styleName="text-sm" *ngIf="row.display; else toggle">{{
              row.display
            }}</nguk-text>

            <ng-template #createButton>
              <nguk-button type="primary" class="create-button" (click)="openModal()">
                <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
              </nguk-button>
            </ng-template>

            <ng-template #toggle>
              <ng-container *ngIf="row.name === 'toggle'; else createButton">
                <nguk-toggle [control]="toggleControl" [toggleList]="toggleList"></nguk-toggle>
              </ng-container>
            </ng-template>
          </th>
        </ng-container>

        <ng-container *ngFor="let item of currentColumns; let i = index" [matColumnDef]="item.name">
          <th
            mat-header-cell
            *matHeaderCellDef
            [class.grey-bg-columns]="item.name | enrichmentStandardBackground"
          >
            <nguk-th-sort-filter
              [columnName]="item.name"
              [columnDisplay]="item.display"
              [rawData]="rawData"
            ></nguk-th-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let row; let ind = dataIndex"
            [class.grey-bg-columns]="item.name | enrichmentStandardBackground"
          >
            <nguk-text weight="400" styleName="text-xs">
              {{ item.name === 'index' ? ind + 1 : row[item.name]?.title || row[item.name] || '' }}
            </nguk-text>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedFirstHeaderRow; sticky: true"></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-row"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns; let i = index"
          (click)="toggleRow(row)"
          (dblclick)="openModal(row)"
          [class.active-row]="this.mapRows.get(row.id)"
        ></tr>
      </table>
    </div>
  </nguk-section>
</div>

<ng-template #modal></ng-template>
