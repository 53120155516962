import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';

@Component({
  selector: 'modal-mining-plan-confirm',
  templateUrl: './modal-mining-plan-confirm.component.html',
  styleUrls: ['./modal-mining-plan-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalComponent, TextComponent, ButtonComponent],
})
export class ModalMiningPlanConfirmComponent extends ModalBase implements AfterViewInit {
  @Output() readonly confirm = new EventEmitter<void>();

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngAfterViewInit(): void {
    this.initModal();
  }

  confirmReplace() {
    this.closeModal(false);
    this.confirm.emit();
  }
}
