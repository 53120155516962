import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthorizedLayoutHeaderComponent } from '../authorized-layout-header/authorized-layout-header.component';
import { NavigationComponent } from '../navigation/navigation.component';

@Component({
  selector: 'nguk-authorized-layout',
  templateUrl: './authorized-layout.component.html',
  styleUrls: ['./authorized-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, AuthorizedLayoutHeaderComponent, NavigationComponent],
})
export class AuthorizedLayoutComponent {
  @HostBinding('class.authorized-layout') baseClass = true;

  isSidebarOpened!: boolean;
}
