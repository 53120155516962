<div class="sticky-container" tabindex="0">
  <mat-radio-group name="flavors" [formControl]="currentDataInstance">
    <table
      mat-table
      [dataSource]="dataInstances"
      [class.table-padding]="true"
      multiTemplateDataRows
    >
      <ng-container [matColumnDef]="'index'">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text weight="400" styleName="text-xs">#</nguk-text>
        </th>

        <td mat-cell *matCellDef="let row; let i = index">
          <mat-radio-button [value]="row">ЭД {{ row.id }}</mat-radio-button>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="'dateCreated'">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text weight="400" styleName="text-xs"
            >Дата
            <nguk-text weight="400" styleName="text-xs" class="horizontal-line">|</nguk-text>
            Время
          </nguk-text>
        </th>

        <td mat-cell *matCellDef="let row; let i = index">
          <nguk-text weight="400" styleName="text-xs"
            >{{ moment(row.dateCreated).format('DD.MM') }}
            <nguk-text weight="400" styleName="text-xs" class="horizontal-line">|</nguk-text>
            {{ moment(row.dateCreated).format('HH.mm') }}
          </nguk-text>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="'min'">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text weight="400" styleName="text-xs">Min</nguk-text>
        </th>

        <td mat-cell *matCellDef="let row; let i = index">
          <nguk-text weight="400" styleName="text-xs">
            {{ row.dateStarted ? moment(row.dateStarted).format('DD.MM.YY') : '-' }}
          </nguk-text>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="'max'">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text weight="400" styleName="text-xs">Max</nguk-text>
        </th>

        <td mat-cell *matCellDef="let row; let i = index">
          <nguk-text weight="400" styleName="text-xs">
            {{ row.dateFinished ? moment(row.dateFinished).format('DD.MM.YY') : '-' }}
          </nguk-text>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="'note'">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text weight="400" styleName="text-xs">Примечание</nguk-text>
        </th>

        <td mat-cell *matCellDef="let row; let i = index">
          <nguk-text weight="400" styleName="text-xs">
            {{ row.note }}
          </nguk-text>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="sticky-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-radio-group>
</div>
