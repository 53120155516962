import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  Input,
  NgZone,
  ViewChild,
} from '@angular/core';
import { IScrollContainer } from '../../interfaces/scroll-container.interface';
import { debounceTime, filter, fromEvent, map, Observable, of, Subject } from 'rxjs';
import { TableScrollEventModel } from '../../models/table-scroll-event.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TableScrollDirectionEnum } from '../../enums/table-scroll-direction.enum';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { BlurComponent } from 'src/app/modules/ui/components/blur/blur.component';

@Component({
  selector: 'nguk-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatProgressSpinnerModule, BlurComponent],
})
export class ListContainerComponent
  implements AfterViewInit, IScrollContainer<TableScrollEventModel>
{
  @Input() itemHeight = 100;
  @Input() loading!: boolean;
  @Input() spinnerSize = 50;
  @ViewChild('scrollable') scrollable!: ElementRef<HTMLDivElement>;
  @HostBinding('class.nguk-list-container') private baseClass = true;

  scroll$: Observable<TableScrollEventModel | null> = of(null);
  reset$ = new Subject<boolean>();

  get scrollableElement(): HTMLElement {
    return this.scrollable.nativeElement;
  }

  private previousHeight = 0;

  constructor(
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private dr: DestroyRef,
  ) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.scrollableElement, 'scroll').pipe(
        debounceTime(300),
        map(this.handleScroll),
        filter(Boolean),
        takeUntilDestroyed(this.dr),
      );
    });

    this.reset$.pipe(takeUntilDestroyed(this.dr)).subscribe(this.resetHeight);
  }

  private handleScroll = (event: Event): TableScrollEventModel | void => {
    if (!event) {
      return void 0;
    }

    const { scrollHeight, clientHeight, scrollTop } = event.target as any;
    const scroll = scrollHeight - clientHeight;
    if (scroll > this.previousHeight && scrollTop >= scroll - this.itemHeight) {
      this.previousHeight = scrollTop;
      return new TableScrollEventModel(TableScrollDirectionEnum.Bottom);
    } else if (scroll <= this.previousHeight) {
      return new TableScrollEventModel(TableScrollDirectionEnum.Top);
    }
  };

  private resetHeight = (): void => {
    this.previousHeight = 0;
  };
}
