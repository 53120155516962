<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header">
      <nguk-text class="modal-title" weight="600" styleName="text-xl"
        >Информация о лицензии</nguk-text
      >
    </div>

    <nguk-button matTooltip="Скачать" type="secondary" class="download" (click)="download()">
      <mat-icon [svgIcon]="svgIconsEnum.Download"></mat-icon>
    </nguk-button>

    <div class="info">
      <div class="info-item" *ngFor="let item of info">
        <nguk-text class="info-item__label" weight="400" styleName="text-sm" color="grey">{{
          item.label
        }}</nguk-text>
        <nguk-text weight="400" styleName="text-sm" color="black">{{ item.value }}</nguk-text>
      </div>
    </div>
  </div>
</nguk-modal>
