import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shippingPlanColspan',
  standalone: true,
})
export class ShippingPlanColspanPipe implements PipeTransform {
  transform(row: any, column: any, displayedColumns: string[]): number {
    return row.trainStation && column.columnName === 'index'
      ? displayedColumns.length
      : 1;
  }
}
