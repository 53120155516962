import { isNull } from 'lodash-es';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TextComponent } from '../../../../ui/components/text/text.component';
import { CommonModule, NgForOf } from '@angular/common';
import { SectionComponent } from '../../../../ui/components/section/section.component';
import { InputComponent } from '../../../../ui/components/forms/input/input.component';
import { SvgIconsEnum } from '../../../../../core/enums/svg-icons.enum';
import { ButtonComponent } from '../../../../ui/components/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { DataInstanceApiService } from 'src/app/api/data-instance.api.service';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { finalize, forkJoin, mergeMap, of, take, takeWhile } from 'rxjs';
import { ActionModal } from 'src/app/modules/ui/base/action-modal';
import { ModalDataInstancesListShortComponent } from '../modal-data-instances-list-short/modal-data-instances-list-short/modal-data-instances-list-short.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DataInstancesProviderService } from '../../../services/data-instances-provider.service';
import { SidebarListItemComponent } from 'src/app/modules/ui/components/sidebar-list/sidebar-list-item.component';
import { UploadFileButtonComponent } from '../upload-file-button/upload-file-button.component';
import { ModalUploadComponent } from '../modal-upload/modal-upload.component';
import { CalculationLogApiService } from 'src/app/api/calculation-log.api.service';
import { ICalculationLog } from 'src/app/data-models/calculation-log/calculation-log.interface';
import { CheckCalculationCompletePipe } from '../../../pipes/check-calculation-complete.pipe';
import { SpinnerService } from '../../../../ui/services/spinner.service';
import { IFileUploadResponse } from '../../../../../data-models/file-upload-response.interface';
import { NotificationsService } from '../../../../../core/services/notifications.service';
import { NotificationModel } from '../../../../../core/models/notification.model';
import { NotificationTypeEnum } from '../../../../../core/enums/notification-type.enum';
import { ModalLoadingComponent } from '../modal-loading/modal-loading.component';
import { JournalApiService } from 'src/app/api/journal.api.service';
import { OperationalPlanJournalApiService } from 'src/app/api/operational-plan-journal.api.service';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { TaskLogApiService } from 'src/app/api/task-log.api.service';
import { ITaskLog } from 'src/app/data-models/task-log/task-log.interface';

@Component({
  selector: 'data-instances-list-short',
  templateUrl: './data-instances-list-short.component.html',
  styleUrls: ['./data-instances-list-short.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TextComponent,
    NgForOf,
    SectionComponent,
    InputComponent,
    ButtonComponent,
    MatIconModule,
    SidebarListItemComponent,
    UploadFileButtonComponent,
    CheckCalculationCompletePipe,
  ],
})
export class DataInstancesListShortComponent extends ActionModal implements OnInit, OnChanges {
  @Input() typeDataInstances!: string;
  @Input() path!: string;
  @Input() mapIds!: Map<number, boolean>;

  @ViewChild('modal', { read: ViewContainerRef }) modal!: ViewContainerRef;

  isSingleClick = true;
  dataInstances!: IDataInstance[];
  currentDataInstanceId!: string;
  id!: number;

  journalMap = new Map<string, IJournal>();
  journalOPMap = new Map<string, IJournal>();
  taskLogMapOP = new Map<string, ITaskLog>();
  taskLogMapKP = new Map<string, ITaskLog>();

  readonly svgIconsEnum = SvgIconsEnum;

  constructor(
    private ss: SpinnerService,
    private cdr: ChangeDetectorRef,
    private dataInstanceApiService: DataInstanceApiService,
    private router: Router,
    private route: ActivatedRoute,
    private dataInstancesProviderService: DataInstancesProviderService,
    private operationalPlanJournalApiService: OperationalPlanJournalApiService,
    private journalApiService: JournalApiService,
    private notificationsService: NotificationsService,
    private taskLogApiService: TaskLogApiService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['typeDataInstances'].currentValue) return;
    this.initData();
  }

  ngOnInit(): void {
    this.closeInteraction = this.currentCloseInteraction;
    this.initCurrentId();
  }

  currentCloseInteraction() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }

  initData(): void {
    this.ss.startSpinner();
    forkJoin([
      this.dataInstanceApiService.getList(1, [
        { name: 'type', value: this.typeDataInstances },
        { name: 'itemsPerPage', value: 500000 },
      ]),
      this.journalApiService.getList(1, [{ name: 'itemsPerPage', value: 20000 }]),
      this.operationalPlanJournalApiService.getList(1, [{ name: 'itemsPerPage', value: 20000 }]),
      this.taskLogApiService.getList(1, [{ name: 'itemsPerPage', value: 20000 }]),
    ])
      .pipe(take(1), finalize(this.ss.stopSpinner))
      .subscribe(([dataInstance, journal, journalOP, taskLog]) => {
        this.journalMap.clear();
        this.journalOPMap.clear();
        this.taskLogMapOP.clear();
        this.taskLogMapKP.clear();

        journal.forEach((item) => this.journalMap.set(item.id, item));
        journalOP.forEach((item) => this.journalOPMap.set(item.id, item));

        taskLog.forEach((item) => {
          const idOP = item.operationalPlan?.id;
          const idKP = item.calculationLog?.id;

          if (idOP) {
            this.taskLogMapOP.set(idOP, item);
          }

          if (idKP) {
            this.taskLogMapKP.set(idKP, item);
          }
        });

        this.dataInstances = dataInstance.sort(
          (lhs: IDataInstance, rhs: IDataInstance) =>
            new Date(rhs.dateCreated).getTime() - new Date(lhs.dateCreated).getTime(),
        );
        this.cdr.detectChanges();
      });
  }

  initCurrentId(): void {
    this.dataInstancesProviderService.currentDataInstance$.subscribe((id) => {
      this.currentDataInstanceId = id;
      this.cdr.detectChanges();
    });
  }

  selectDataInstance(dataInstance: IDataInstance): void {
    this.isSingleClick = true;
    if (this.mapIds) return;

    setTimeout(() => {
      if (this.isSingleClick) {
        const id = dataInstance.id!.toString();
        this.dataInstancesProviderService.setNewDataInstance(id);

        this.router.navigate([this.path, id], { relativeTo: this.route });
      }
    }, 250);
  }

  openModal(row?: IDataInstance): void {
    this.isSingleClick = false;
    this.modalContainer = this.modal;
    const modalComponent = this.open(ModalDataInstancesListShortComponent);
    if (row) {
      modalComponent.instance.data = row;
      modalComponent.instance.isCalculationComplete = new CheckCalculationCompletePipe().transform(
        row,
        this.taskLogMapKP,
        this.taskLogMapOP,
      );
    }

    modalComponent.instance.updateData = this.initData.bind(this);
    modalComponent.instance.typeDataInstances = this.typeDataInstances;
  }

  openModalUpload(status?: 'success' | 'error', text?: string): void {
    let alive = true;
    this.modalContainer = this.modal;

    const modalComponent = this.open(ModalUploadComponent);
    if (status) modalComponent.instance.status = status;
    if (text) modalComponent.instance.text = text;

    modalComponent.instance.openSubject.pipe(takeWhile(() => alive)).subscribe((res) => {
      alive = false;
      if (!isNull(res)) return;
      this.openModalUploadLoading(
        modalComponent.instance.filesUpload,
        modalComponent.instance.isCreate,
        modalComponent.instance.note,
      );
      this.cdr.markForCheck();
    });
  }

  openModalUploadLoading(files: File[], isCreate: boolean, note: string): void {
    this.modalContainer = this.modal;

    const modalComponent = this.open(ModalLoadingComponent);
    modalComponent.instance.loading.subscribe(() => {
      this.uploadFile(modalComponent, files, isCreate, note);
    });
  }

  uploadFile = (
    modalRef: ComponentRef<ModalLoadingComponent>,
    files: File[],
    isCreate: boolean,
    note: string,
  ): void => {
    const newDataInstance: IDataInstance = {
      calculationLog: [],
      operationalPlan: [],
      dateCreated: new Date(),
      dateStarted: new Date(),
      dateFinished: new Date(),
      type: this.typeDataInstances,
      note: note,
    };

    (isCreate ? this.dataInstanceApiService.create(newDataInstance) : of(newDataInstance))
      .pipe(
        mergeMap((data) => {
          return this.dataInstancesProviderService.uploadDataFile(
            isCreate ? data.id! : this.currentDataInstanceId,
            this.typeDataInstances,
            files,
          );
        }),
      )
      .subscribe(
        (result: IFileUploadResponse) => {
          this.openModalUploadWithStatusResponse(modalRef, 'success');
        },
        (err) => {
          this.openModalUploadWithStatusResponse(modalRef, 'error', err.error.message);
          this.notificationsService.showNotification(
            new NotificationModel(
              NotificationTypeEnum.Error,
              'При загрузке файла произошла ошибка',
            ),
          );
        },
      );
  };

  openModalUploadWithStatusResponse(
    modalRef: ComponentRef<ModalLoadingComponent>,
    status?: 'success' | 'error',
    text?: string,
  ): void {
    let alive = true;
    modalRef.instance.closeModal(null);
    this.initData();
    modalRef.instance.openSubject.pipe(takeWhile(() => alive)).subscribe(() => {
      alive = false;
      this.openModalUpload(status, text);
      this.cdr.markForCheck();
    });
  }
}
