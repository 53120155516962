import { NgModule } from '@angular/core';
import { CalculationsPageComponent } from './components/pages/calculations-page/calculations-page.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { CalculationsListComponent } from './components/parts/calculations-list/calculations-list.component';
import { PageComponent } from '../ui/components/page/page.component';
import { SectionComponent } from '../ui/components/section/section.component';
import { MatTableModule } from '@angular/material/table';
import { InputComponent } from '../ui/components/forms/input/input.component';
import { TextComponent } from '../ui/components/text/text.component';
import { DatePairComponent } from '../ui/components/date-pair/date-pair.component';
import { CalculationsService } from './services/calculations.service';
import { CancelCalculationModalComponent } from './components/modals/cancel-calculation-modal/cancel-calculation-modal.component';
import { ModalComponent } from '../ui/components/modal/modal.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../ui/components/button/button.component';
import { EditCalculationModalComponent } from './components/modals/edit-calculation-modal/edit-calculation-modal.component';
import { ModalActionsComponent } from '../ui/components/modal-actions/modal-actions.component';
import { ToggleComponent } from '../forms/components/toggle/toggle.component';
import { TextareaComponent } from '../forms/components/textarea/textarea.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HoveringCalculationPipe } from './pipes/hovering-calculation.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PageComponent,
    SectionComponent,
    MatTableModule,
    InputComponent,
    TextComponent,
    DatePairComponent,
    ModalComponent,
    MatIconModule,
    ButtonComponent,
    ModalActionsComponent,
    ToggleComponent,
    TextareaComponent,
    MatIconModule,
    MatTooltipModule,
    HoveringCalculationPipe,
  ],
  declarations: [
    CalculationsPageComponent,
    CalculationsListComponent,
    CancelCalculationModalComponent,
    EditCalculationModalComponent,
  ],
  providers: [CalculationsService],
})
export class CalculationsModule {}
