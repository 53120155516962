import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ControlDirective } from '../../directives/control.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { ControlErrorComponent } from '../control-error/control-error.component';
import { IDictionaryModel } from '../../../../data-models/dictionary-model/dictionary-model.interface';
import { DefaultBehaviorPreventionDirective } from '../../../../core/directives/default-behavior-prevention.directive';
import { ValidationErrorService } from '../../services/validation-error.service';
import { DictionariesEnum } from '../../../../core/enums/dictionaries.enum';
import { MatTooltipModule } from '@angular/material/tooltip';
import { isNull } from 'lodash-es';

@Component({
  selector: 'nguk-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    TextComponent,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    ButtonComponent,
    ControlErrorComponent,
    DefaultBehaviorPreventionDirective,
    MatTooltipModule,
  ],
})
export class AutocompleteComponent extends ControlDirective implements OnChanges {
  @Input() options: IDictionaryModel[] = [];
  @Input() dictionary!: DictionariesEnum;
  @Output() closed = new EventEmitter<void>();

  constructor(dr: DestroyRef, validationErrorService: ValidationErrorService) {
    super(dr, validationErrorService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { options } = changes;
    if (options?.currentValue && options?.currentValue !== options?.previousValue) {
      this.checkIsFullMatch();
    }
  }

  displayFn(value: IDictionaryModel | string): string {
    if (typeof value === 'string') {
      return value;
    }

    return value?.title;
  }

  private checkIsFullMatch(): void {
    const controlValue = this.control.value;
    if (typeof controlValue !== 'string') {
      return;
    }

    const option = this.options.find((option: IDictionaryModel) => {
      return option.title === controlValue;
    });

    if (!option) {
      return;
    }

    this.resetControl(option);
  }

  private resetControl(value: IDictionaryModel | null): void {
    this.control.setValue(value);
    this.control.markAsUntouched();
    this.control.markAsPristine();
    this.control.updateValueAndValidity();
  }
}
