<div class="nguk-navigation-root">
  <nguk-navigation-button [link]="[routesHelper.DATA_PATH, routesHelper.DATA_SHIPPING_PLAN_PATH]">
    <nguk-text weight="400" styleName="text-xs">Сбытовая потребность</nguk-text>
  </nguk-navigation-button>

  <nguk-navigation-button [link]="[routesHelper.DATA_PATH, routesHelper.DATA_WAREHOUSE_BALANCE]">
    <nguk-text weight="400" styleName="text-xs">Складские остатки</nguk-text>
  </nguk-navigation-button>

  <nguk-navigation-button [link]="[routesHelper.DATA_PATH, routesHelper.DATA_MINING_PLAN]">
    <nguk-text weight="400" styleName="text-xs">План добычи</nguk-text>
  </nguk-navigation-button>

  <nguk-navigation-button [link]="[routesHelper.DATA_PATH, routesHelper.DATA_ENRICHMENT_STANDARD]">
    <nguk-text weight="400" styleName="text-xs">Нормы ТК</nguk-text>
  </nguk-navigation-button>

  <nguk-navigation-button [link]="[routesHelper.DATA_PATH, routesHelper.DATA_FUND]">
    <nguk-text weight="400" styleName="text-xs">Доступность ресурсов</nguk-text>
  </nguk-navigation-button>

  <nguk-navigation-button [link]="[routesHelper.DATA_PATH, routesHelper.DATA_WAGON_FLEET]">
    <nguk-text weight="400" styleName="text-xs">Парк вагонов</nguk-text>
  </nguk-navigation-button>

  <nguk-navigation-button [link]="routesHelper.DATA_CALENDAR_PLAN">
    <nguk-text weight="400" styleName="text-xs">Календарный план</nguk-text>
  </nguk-navigation-button>

  <nguk-navigation-button [link]="routesHelper.DATA_OPERATIONAL_PLAN">
    <nguk-text weight="400" styleName="text-xs">Оперативный план</nguk-text>
  </nguk-navigation-button>

  <!-- <nguk-navigation-button>
    <nguk-text weight="400" styleName="text-xs">ОП</nguk-text>
  </nguk-navigation-button> -->

  <!-- <nguk-navigation-button [link]="routesHelper.DOCUMENTS_PATH">
    <nguk-text weight="400" styleName="text-xs">Документы</nguk-text>
  </nguk-navigation-button> -->

  <nguk-navigation-button [link]="routesHelper.NSI_PATH">
    <nguk-text weight="400" styleName="text-xs">НСИ</nguk-text>
  </nguk-navigation-button>

  <nguk-navigation-button [link]="routesHelper.CALCULATIONS_PATH">
    <nguk-text weight="400" styleName="text-xs">Журнал задач</nguk-text>
  </nguk-navigation-button>
</div>
