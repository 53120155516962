<div class="documents-list-root">
  <nguk-section>
    <ng-container sectionTitle>Список документов</ng-container>
    <ng-container sectionActions>
      <nguk-button type="primary" class="create-button" (click)="(1)">
        <mat-icon [svgIcon]="svgIconsEnum.Plus"></mat-icon>
      </nguk-button>
    </ng-container>

    <div class="documents-list">
      <nguk-sidebar-list-item
        *ngFor="let item of documents; let index = index; let last = last"
        [firstId]="id"
        [data]="item"
        [indexData]="index"
        [lastData]="last"
        [mapIds]="mapIds"
        [submenu]="initialDataSubmenu"
        (openModalItem)="openModalItem($event)"
      ></nguk-sidebar-list-item>
    </div>
  </nguk-section>
</div>

<ng-template #modal></ng-template>
