import { Injectable } from '@angular/core';
import { EntityApi } from '../core/abstract/entity.api';
import { ApiProvider } from '../core/providers/api.provider';
import { ICommodityShipmentQualitySpecificationItem } from '../data-models/commodity-shipment-quality-specification-item/commodity-shipment-quality-specification-item.interface';

@Injectable({
  providedIn: 'root',
})
export class CommodityShipmentQualitySpecificationItemApiService extends EntityApi<
  ICommodityShipmentQualitySpecificationItem,
  ICommodityShipmentQualitySpecificationItem
> {
  protected readonly api = 'result/commodityShipmentQualitySpecificationItem';

  constructor(apiProvider: ApiProvider) {
    super(apiProvider);
  }
}
