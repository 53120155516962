
export class Utils {
  static getMinValueByKey<T = any>(list: T[], key: keyof T): number {
    return list.sort((lhs, rhs) => (lhs[key] as number) - (rhs[key] as number))[0][key] as number;
  }

  static getMaxValueByKey<T = any>(list: T[], key: keyof T): number {
    return list.sort((lhs, rhs) => (rhs[key] as number) - (lhs[key] as number))[0][key] as number;
  }

  static sortBy<T = any>(list: T[], key: keyof T): T[] {
    return list.sort((lhs, rhs) => (lhs[key] as number) - (rhs[key] as number));
  }
}
