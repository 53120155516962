import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBase } from '../../base/modal.base';
import { ModalComponent } from '../modal/modal.component';
import { TextComponent } from '../text/text.component';
import { ButtonComponent } from '../button/button.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'nguk-modal-license',
  templateUrl: './modal-license.component.html',
  styleUrls: ['./modal-license.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TextComponent,
    ModalComponent,
    ButtonComponent,
    MatIconModule,
  ],
})
export class ModalLicenseComponent extends ModalBase implements AfterViewInit {
  info = [
    { label: 'Номер версии', value: 'v.0.9.1' },
    { label: 'Номер лицензии', value: '26 11 03157' },
    { label: 'Разработчик лицензии', value: 'Консом' },
    { label: 'Допустимое количество пользователей', value: '120' },
    { label: 'Организация', value: 'НГУК' },
    { label: 'Владелец', value: 'Иванов Иван Иванович' },
  ];
  constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngAfterViewInit(): void {
    this.initModal();
  }

  download(): void {
    return;
  }
}
