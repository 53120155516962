import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleComponent } from 'src/app/modules/forms/components/toggle/toggle.component';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { FormGroup } from '@angular/forms';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { ModalCellFormComponent } from '../modal-cell-form/modal-cell-form.component';
import { ModalCellActionsComponent } from '../modal-cell-actions/modal-cell-actions.component';
import { IEditResourceAvailabilityConditionTransform } from '../../../../../../../data-models/resource-availability-condition/edit-resource-availability-condition.interface';

@Component({
  selector: 'modal-cell',
  templateUrl: './modal-cell.component.html',
  styleUrls: ['./modal-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TextComponent,
    ToggleComponent,
    ModalCellFormComponent,
    ModalCellActionsComponent,
  ],
})
export class ModalCellComponent extends ModalBase implements AfterViewInit {
  data!: IEditResourceAvailabilityConditionTransform[][];

  updateData!: () => void;
  formData!: FormGroup;

  dataInstance!: IDataInstance;
  currentRow!: number;
  currentDay!: number;

  resourceAvailabilityId!: string;

  isShow = false;

  removeIds: string[] = [];
  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  getForm(form: FormGroup): void {
    this.formData = form;
  }

  getRemoveIds(value: string[]): void {
    this.removeIds = [...value];
  }

  ngAfterViewInit(): void {
    this.initModal();
  }
}
