import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { WagonFleetDropdownComponent } from '../wagon-fleet-dropdown/wagon-fleet-dropdown.component';
import { DictionaryPipe } from '../../../../../../core/pipes/dictionary.pipe';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { DictionariesEnum } from 'src/app/core/enums/dictionaries.enum';
import { InputComponent } from '../../../../../forms/components/input/input.component';
import { SectionComponent } from '../../../../../ui/components/section/section.component';
import { ButtonComponent } from '../../../../../ui/components/button/button.component';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { MatIconModule } from '@angular/material/icon';
import { finalize, forkJoin, take, tap } from 'rxjs';
import { CustomerApiService } from 'src/app/api/customer.api.service';
import { MarkApiService } from 'src/app/api/dictionaries/mark.api.service';
import { IDictionaryModel } from 'src/app/data-models/dictionary-model/dictionary-model.interface';
import { wagons } from './mockWagon';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { IWagonLoaded } from 'src/app/data-models/wagon-loaded/wagon-loaded.interface';
import { WagonLoadedApiService } from 'src/app/api/wagon-loaded.api.service';
import { WagonFleetUnderLoadingFormService } from 'src/app/modules/data/services/wagon-fleet-under-loading/wagon-fleet-under-loading-form.service';
import { IEditWagonLoaded } from 'src/app/data-models/wagon-loaded/edit-wagon-loaded.interface';
import { SpinnerService } from 'src/app/modules/ui/services/spinner.service';
import { ProductionModeApiService } from 'src/app/api/dictionaries/productionMode.api.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import * as XLSX from 'xlsx';
import { columnDisplayed } from './columnDisplayed';
import { isArray } from 'lodash-es';

@Component({
  selector: 'wagon-fleet-under-loading',
  templateUrl: './wagon-fleet-under-loading.component.html',
  styleUrls: ['./wagon-fleet-under-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TextComponent,
    WagonFleetDropdownComponent,
    DictionaryPipe,
    InputComponent,
    SectionComponent,
    ButtonComponent,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class WagonFleetUnderLoadingComponent implements OnInit, OnChanges {
  @Input() dataInstance!: IDataInstance;
  @Input() listWagonLoaded!: IWagonLoaded[];
  @Input() wagonFleetId!: string;
  @Input() isShow!: boolean;

  @Output() updateData = new EventEmitter<void>();

  @Output() getCurrentSheet = new EventEmitter();

  amountRow = new Array(3);

  data: IWagonLoaded[] = [];
  readonly dictionariesEnum = DictionariesEnum;
  readonly svgIconsEnum = SvgIconsEnum;

  marksList: IDictionaryModel[] = [];
  customersList: IDictionaryModel[] = [];
  wagonList: IDictionaryModel[] = [];
  modeListTk1: IDictionaryModel[] = [];
  modeListTk2: IDictionaryModel[] = [];

  currentInput: string | undefined = undefined;

  control = new FormControl();

  visibleActionsTact: number | undefined = undefined;

  formData!: FormArray;

  removeIds: string[] = [];

  constructor(
    private fb: FormBuilder,
    private customerApiService: CustomerApiService,
    private markApiService: MarkApiService,
    private productionModeApiService: ProductionModeApiService,
    private wagonLoadedApiService: WagonLoadedApiService,
    private wagonFleetUnderLoadingFormService: WagonFleetUnderLoadingFormService,
    private ss: SpinnerService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataInstance'] && !changes['dataInstance'].firstChange)
      this.dataInstance = changes['dataInstance'].currentValue;
    if (changes['listWagonLoaded']?.currentValue) {
      this.listWagonLoaded = changes['listWagonLoaded'].currentValue;
      this.formData = this.fb.array([]);

      this.listWagonLoaded.forEach((item) => {
        const fg = this.wagonFleetUnderLoadingFormService.createColumnTact(
          this.formData,
          this.wagonFleetId,
          item,
        );
        this.formData.push(fg);
      });

      while (this.formData.length < 3) {
        const fg = this.wagonFleetUnderLoadingFormService.createColumnTact(
          this.formData,
          this.wagonFleetId,
          null,
        );
        this.formData.push(fg);
      }

      this.data = this.formData.value;

      const ws = XLSX.utils.json_to_sheet(
        this.listWagonLoaded.map((item) => {
          const obj = { ...item };

          if (obj.id) delete obj.id;

          delete obj.wagonFleet;

          Object.keys(obj).forEach((key) => {
            if (obj[key] && isArray(obj[key])) {
              obj[key] =
                (obj[key] as IDictionaryModel[])
                  .map((item: IDictionaryModel) => item.title)
                  .toString() ?? '';
            }
          });

          return obj;
        }),
      );
      XLSX.utils.sheet_add_aoa(ws, [columnDisplayed], { origin: 'A1' });

      this.getCurrentSheet.emit(ws);

      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.initListDropdown();
    this.initFormGroup();
  }

  initListDropdown(): void {
    const query = [
      {
        name: 'itemsPerPage',
        value: 500 * 1000,
      },
    ];
    forkJoin([
      this.customerApiService.getList(1, query),
      this.markApiService.getList(1, query),
      this.productionModeApiService.getList(1, query),
    ])
      .pipe(take(1))
      .subscribe(([customers, mark, productionMode]) => {
        this.customersList = customers;
        this.marksList = mark;
        this.wagonList = wagons;
        this.modeListTk1 = productionMode;
        this.modeListTk2 = productionMode;

        this.cdr.detectChanges();
      });
  }

  initFormGroup(): void {
    this.formData = this.fb.array([]);
  }

  addTact(): void {
    const fg = this.wagonFleetUnderLoadingFormService.createColumnTact(
      this.formData,
      this.wagonFleetId,
      null,
    );
    this.formData.push(fg);

    this.data = this.formData.value;
  }

  getControl(index: number, fc: string): FormControl {
    return this.formData.controls[index].get(fc) as FormControl;
  }

  getFormArray(index: number, fa: string): FormArray {
    return this.formData.controls[index].get(fa) as FormArray;
  }

  addColumn(index: number): void {
    const fg = this.wagonFleetUnderLoadingFormService.createColumnTact(
      this.formData,
      this.wagonFleetId,
      null,
      index,
    );

    this.formData.push(fg);

    this.data = this.formData.value;
  }

  removeColumn(index: number): void {
    const id = this.formData.controls[index].get('id')?.value;
    if (id) this.removeIds.push(id);

    this.formData.removeAt(index);
    this.data = this.formData.value;
  }

  save(): void {
    this.ss.startSpinner();

    const arrValue = (this.formData.value as IEditWagonLoaded[]).map((item, index) => {
      item.tact = index + 1;
      return item;
    });

    const createValuesRequests = arrValue
      .filter((item) => !item.id)
      .map((item) => {
        const body = { ...item };
        delete body['id'];
        return this.wagonLoadedApiService.create(item);
      });

    const changeValuesRequests = arrValue
      .filter((item) => item.id)
      .map((item) => this.wagonLoadedApiService.update(item));

    const removeValuesRequests = this.removeIds.map((id) => this.wagonLoadedApiService.delete(id));

    forkJoin([...createValuesRequests, ...changeValuesRequests, ...removeValuesRequests])
      .pipe(
        take(1),
        finalize(this.ss.stopSpinner),
        tap(() => {
          this.updateData.emit();
          this.removeIds = [];
        }),
      )
      .subscribe();
  }

  exportToExcel() {
    const timeSpan = new Date().toISOString();
    const prefix = 'ExportResult';
    const fileName = `${prefix}-${timeSpan}`;
    const table = document.getElementById('table-underload');

    console.log(table);

    const wb = XLSX.utils.table_to_book(table, <XLSX.Table2SheetOpts>{
      cellStyles: true,
    });

    const wb1 = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(this.listWagonLoaded);

    // XLSX.utils.sheet_add_dom(ws, table);
    XLSX.utils.book_append_sheet(wb1, ws, '1123');
    // this.listTables.forEach((item) => {
    //   const ws = XLSX.utils.table_to_sheet(item.table, <XLSX.Table2SheetOpts>{
    //     cellStyles: true,
    //   });
    //   XLSX.utils.book_append_sheet(wb, ws, item.label);
    // });

    XLSX.writeFile(wb1, `${fileName}.xlsx`);
  }
}
