import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { ModalBase } from 'src/app/modules/ui/base/modal.base';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { IWarehouseDynamicsItem } from 'src/app/data-models/warehouse-dynamics-item/warehouse-dynamics.interface';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';

@Component({
  selector: 'calendar-plan-modal-warehouse-dynamics',
  templateUrl: './modal-warehouse-dynamics.component.html',
  styleUrls: ['./modal-warehouse-dynamics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalComponent, TextComponent, MatTableModule, TextComponent],
})
export class ModalWarehouseDynamicsComponent extends ModalBase implements AfterViewInit {
  data!: IWarehouseDynamicsItem[];
  isOperationalPlan = false;
  isWagonDynamics = false;
  day!: number;

  readonly math = Math;

  columns: IDataColumnTable[] = [
    { name: 'index', display: '#' },
    { name: 'lineType', display: 'Направление' },
    { name: 'correspondent', display: 'Корреспондент' },
    { name: 'materialType', display: 'Тип материала' },
    { name: 'mark', display: 'Марка' },
    { name: 'ad', display: 'Ad' },
    { name: 'vd', display: 'Vd' },
    { name: 'y', display: 'Y' },
    { name: 'volume', display: 'Объем' },
  ];

  displayedColumns = this.columns.map((item) => item.name);

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngAfterViewInit(): void {
    this.initModal();
  }
}
