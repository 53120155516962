import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRequestModel } from '../models/api-request.model';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '../injection-tokens/environment.token';

@Injectable({
  providedIn: 'root',
})
export class ApiProvider {
  private get api(): string {
    return this.environment.apiURL;
  }

  constructor(
    @Inject(ENVIRONMENT) private environment: { apiURL: string },
    private http: HttpClient,
  ) {}

  get = <Response>(model: ApiRequestModel<void>): Observable<Response> => {
    return this.http.get<Response>(`${this.api}/${model.path}`, { params: model.query });
  };

  post = <Body, Response>(model: ApiRequestModel<Body>): Observable<Response> => {
    return this.http.post<Response>(`${this.api}/${model.path}`, model.body, {
      params: model.query,
    });
  };

  put = <Body, Response>(model: ApiRequestModel<Body>): Observable<Response> => {
    return this.http.put<Response>(`${this.api}/${model.path}`, model.body, {
      params: model.query,
    });
  };

  patch = <Body, Response>(model: ApiRequestModel<Body>): Observable<Response> => {
    return this.http.patch<Response>(`${this.api}/${model.path}`, model.body, {
      params: model.query,
    });
  };

  delete = <Response>(model: ApiRequestModel<void>): Observable<Response> => {
    return this.http.delete<Response>(`${this.api}/${model.path}`, { params: model.query });
  };

  postFile = <Body, Response>(model: ApiRequestModel<Body>): Observable<Response> => {
    return this.http.post<Response>(`${this.api}/${model.path}`, model.body, {
      params: model.query,
    });
  };
}
