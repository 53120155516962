import { Component, HostBinding, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from 'src/app/modules/ui/components/modal/modal.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { ToggleComponent } from 'src/app/modules/forms/components/toggle/toggle.component';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  FormGroup,
  FormArray,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { SlideToggleComponent } from 'src/app/modules/forms/components/slide-toggle/slide-toggle.component';
import { InputComponent } from '../../../../forms/components/input/input.component';
import { DatepickerComponent } from '../../../../forms/components/datepicker/datepicker.component';

@Component({
  selector: 'modal-upload-related-systems',
  standalone: true,
  templateUrl: './modal-upload-related-systems.component.html',
  styleUrls: ['./modal-upload-related-systems.component.scss'],
  imports: [
    CommonModule,
    ModalComponent,
    TextComponent,
    ButtonComponent,
    ToggleComponent,
    MatIconModule,
    ReactiveFormsModule,
    NgxFileDragDropModule,
    SlideToggleComponent,
    InputComponent,
    DatepickerComponent,
  ],
})
export class ModalUploadRelatedSystemsComponent implements OnInit {
  @HostBinding('class.modal-upload-related-systems') private radioBaseClass = true;

  listDataInstances = [
    { type: 'shippingPlan', label: 'Сбытовая потребность' },
    { type: 'warehouseBalance', label: 'Складские остатки' },
    { type: 'miningPlan', label: 'План добычи' },
    { type: 'enrichmentStandard', label: 'Нормы ТК' },
    { type: 'fund', label: 'Доступность ресурсов' },
    { type: 'wagonFleet', label: 'Парк вагонов' },
  ];

  formData!: FormGroup;

  toggleList = ['Создать новый ЭД'];
  toggleControl = new FormControl<string | null>(this.toggleList[0]);

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.formData = this.fb.group({
      nameDataInstance: [null],
      date: [new Date()],
      time: [null],
      dataInstances: this.fb.array(
        this.listDataInstances.map((item) => {
          return this.fb.group({
            type: [item.type],
            label: [item.label],
            value: [false],
          });
        }),
      ),
    });
  }

  getControl(value: string): FormControl {
    return this.formData.get(value) as FormControl;
  }

  get formArrayDataInstances(): FormArray {
    return this.formData.get('dataInstances') as FormArray;
  }

  getSlideToggleControl(index: number): FormControl {
    return this.formArrayDataInstances.controls[index].get('value') as FormControl;
  }
}
