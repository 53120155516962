import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { TJournalSubmenuRoute } from '../../../../calendar-plan/types/journal-submenu-route.type';
import { SvgIconsEnum } from '../../../../../core/enums/svg-icons.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { JournalProviderService } from '../../../../calendar-plan/services/journal-provider.service';
import { RoutesHelper } from '../../../../../helpers/routes.helper';

@Component({
  selector: 'documents-list-submenu-item',
  templateUrl: './documents-list-submenu-item.component.html',
  styleUrls: ['./documents-list-submenu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsListSubmenuItemComponent {
  @Input() document!: any;
  @Input() title!: string;
  @Input() menuItems!: TJournalSubmenuRoute[];
  @Input() mapIds!: Map<number, boolean>;
  @Output() readonly changeDocument = new EventEmitter<string>();
  @HostBinding('class.documents-submenu-item') private baseClass = true;

  readonly svgIconsEnum = SvgIconsEnum;

  isOpened: boolean | undefined = undefined;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private journalProviderService: JournalProviderService,
  ) {}

  ngOnInit(): void {
    this.journalProviderService.isOpenedId$
      .subscribe((id: number): void => {
        if (id !== +this.document.id) {
          return;
        }

        this.isOpened = true;
        this.cdr.markForCheck();
      });
  }

  getItemMenu(type: string): { id: string; type: string } | undefined {
    return (
      this.document.dataInstances.find((item: any): boolean => item.type === type) ??
      this.document.productionPlans.find((item: any): boolean => item.type === type)
    );
  }

  getRouterLink(menuItem: TJournalSubmenuRoute): string[] | null {
    return this.getItemMenu(menuItem.type) ? menuItem.url : null;
  }

  changeItem(menuItem: TJournalSubmenuRoute): void {
    const item = this.getItemMenu(menuItem.type);

    if (!item) {
      return;
    }

    void this.router.navigate([
      RoutesHelper.DATA_CALENDAR_PLAN,
      this.document.id,
      ...menuItem.url,
      item?.id,
    ]);

    this.changeDocument.emit(this.document.id);
  }

  isActive(): boolean {
    return +this.route.parent?.snapshot.params['calendarPlanId'] === +this.document.id;
  }
}
