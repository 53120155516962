import { Route } from '@angular/router';
import { RoutesHelper } from '../../helpers/routes.helper';
import { DocumentsPageComponent } from './components/pages/documents-page/documents-page.component';
import { CommodityShipmentListComponent } from '../calendar-plan/components/parts/commodity-shipment/commodity-shipment-list.component';
import { InternalLogisticsListComponent } from '../calendar-plan/components/parts/internal-logistics-list/internal-logistics-list.component';
import { ResultMiningPlanListComponent } from '../calendar-plan/components/parts/result-mining-plan-list/result-mining-plan-list.component';
import { ResultSupplyComponent } from '../calendar-plan/components/parts/result-supply/result-supply.component';
import { WarehouseDynamicsComponent } from '../calendar-plan/components/parts/warehouse-dynamics/warehouse-dynamics.component';
import { EnrichmentStandardListComponent } from '../data/components/parts/enrichment-standard/enrichment-standard-list/enrichment-standard-list.component';
import { MiningPlanListComponent } from '../data/components/parts/mining-plan/mining-plan-list/mining-plan-list.component';
import { ShippingPlanListComponent } from '../data/components/parts/shipping-plan/shipping-plan-list/shipping-plan-list.component';
import { WarehouseBalanceListComponent } from '../data/components/parts/warehouse-balance/warehouse-balance-list/warehouse-balance-list.component';

export const routes: Route[] = [
  {
    path: RoutesHelper.EMPTY_PATH,
    component: DocumentsPageComponent,
    children: [
      {
        path: RoutesHelper.DATA_SHIPPING_PLAN_PATH,
        loadComponent: () => ShippingPlanListComponent,
      },
      {
        path: RoutesHelper.DATA_SHIPPING_PLAN_URL,
        loadComponent: () => ShippingPlanListComponent,
      },
      {
        path: RoutesHelper.DATA_WAREHOUSE_BALANCE,
        loadComponent: () => WarehouseBalanceListComponent,
      },
      {
        path: RoutesHelper.DATA_WAREHOUSE_BALANCE_URL,
        loadComponent: () => WarehouseBalanceListComponent,
      },
      {
        path: RoutesHelper.DATA_MINING_PLAN,
        loadComponent: () => MiningPlanListComponent,
      },
      {
        path: RoutesHelper.DATA_MINING_PLAN_URL,
        loadComponent: () => MiningPlanListComponent,
      },
      {
        path: RoutesHelper.DATA_ENRICHMENT_STANDARD,
        loadComponent: () => EnrichmentStandardListComponent,
      },
      {
        path: RoutesHelper.DATA_ENRICHMENT_STANDARD_URL,
        loadComponent: () => EnrichmentStandardListComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_RESULT_MINING_PLAN,
        loadComponent: () => ResultMiningPlanListComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_RESULT_MINING_PLAN_URL,
        loadComponent: () => ResultMiningPlanListComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_WAREHOUSE_DYNAMICS,
        loadComponent: () => WarehouseDynamicsComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_WAREHOUSE_DYNAMICS_URL,
        loadComponent: () => WarehouseDynamicsComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_RESULT_SUPPLY,
        loadComponent: () => ResultSupplyComponent,
      },
      {
        path: RoutesHelper.DATA_CALENDAR_PLAN_RESULT_SUPPLY_URL,
        loadComponent: () => ResultSupplyComponent,
      },
      {
        path: RoutesHelper.PLAN_INTERNAL_LOGISTICS_PATH,
        loadComponent: () => InternalLogisticsListComponent,
      },
      {
        path: RoutesHelper.PLAN_INTERNAL_LOGISTICS_URL,
        loadComponent: () => InternalLogisticsListComponent,
      },
      {
        path: RoutesHelper.PLAN_COMMODITY_SHIPMENT_PATH,
        loadComponent: () => CommodityShipmentListComponent,
      },
      {
        path: RoutesHelper.PLAN_COMMODITY_SHIPMENT_URL,
        loadComponent: () => CommodityShipmentListComponent,
      },
      {
        path: RoutesHelper.EMPTY_PATH,
        pathMatch: 'full',
        redirectTo: RoutesHelper.DATA_SHIPPING_PLAN_PATH,
      },
    ],
  },
];
