<nguk-modal [visible]="isVisible" [close]="closeModal.bind(this, false)">
  <div class="modal">
    <div class="modal-header">
      <div class="modal-header-info">
        <nguk-text weight="600" styleName="text-xl">{{
          data && data.id ? 'Строка ' + (currentIndex + 1) : 'Создание строки'
        }}</nguk-text>

        <nguk-toggle
          [toggleList]="toggleList"
          [control]="toggleControl"
          *ngIf="data && data.id"
        ></nguk-toggle>
      </div>

      <modal-general-actions
        class="modal-mining-plan-actions"
        [toggleControl]="toggleControl"
        [data]="data"
        [formData]="formData"
        (close)="closeModal(true)"
        (updateData)="updateData()"
      />
    </div>

    <modal-general-form
      [dataInstance]="dataInstance"
      (form)="getForm($event)"
      (openModalSelectResource)="openModalSelectResource($event)"
      [data]="data"
    />
  </div>
</nguk-modal>
