import { Pipe, PipeTransform } from '@angular/core';
import { IResultMiningPlanItem } from 'src/app/data-models/result-mining-plan-item/result-mining-plan-item.interface';

@Pipe({
  name: 'resultMiningPlanValueCell',
  standalone: true,
})
export class ResultMiningPlanValueCellPipe implements PipeTransform {
  transform(
    key: string,
    day: number,
    mapKeyId: Map<string, IResultMiningPlanItem[]>,
    isOperationalPlan: boolean,
  ): string {
    const value = mapKeyId.get(key + day)?.reduce((acc, item) => +item.volume + acc, 0);

    return value
      ? (Math.round((value / (isOperationalPlan ? 1 : 1000)) * 100) / 100).toString()
      : '';
  }
}
