import { ApiProvider } from '../providers/api.provider';
import { Observable } from 'rxjs';
import { ApiRequestModel } from '../models/api-request.model';
import { IQueryRequest } from 'src/app/data-models/query-request/query-request.interface';

export abstract class EntityApi<EntityModel, EditEntityModel extends { id?: string | number }> {
  protected abstract api: string;

  protected constructor(protected apiProvider: ApiProvider) {}

  getList = (page: number, query?: IQueryRequest[]): Observable<EntityModel[]> => {
    const apiModel = new ApiRequestModel(this.api);

    if (page) apiModel.addToQuery('page', page);

    if (query && query.length) {
      query.forEach((item) => {
        if (Array.isArray(item.value)) {
          apiModel.addArrayToQuery(item.name, item.value);
        } else {
          apiModel.addToQuery(item.name, item.value);
        }
      });
    }

    return this.apiProvider.get(apiModel);
  };

  getOne = (id: number | string): Observable<EntityModel> => {
    const apiModel = new ApiRequestModel(this.api).addToPath(id);

    return this.apiProvider.get(apiModel);
  };

  create = (model: EditEntityModel): Observable<EntityModel> => {
    const apiModel = new ApiRequestModel(this.api, model);

    return this.apiProvider.post(apiModel);
  };

  update = (model: EditEntityModel): Observable<EditEntityModel> => {
    const apiModel = new ApiRequestModel(this.api, model).addToPath(model.id!);

    return this.apiProvider.put(apiModel);
  };

  delete = (id: number | string): Observable<void | string> => {
    const apiModel = new ApiRequestModel(this.api).addToPath(id);

    return this.apiProvider.delete(apiModel);
  };
}
