import { ChangeDetectorRef, ComponentRef, Injectable, Type } from '@angular/core';
import { IResourceAvailabilityTransform } from 'src/app/data-models/resource-availability/resource-availability.interface';
import { ModalGeneralComponent } from '../../components/parts/fund/modal-general/modal-general/modal-general.component';
import { ModalSelectResourceComponent } from '../../components/parts/fund/modal-select-resource/modal-select-resource/modal-select-resource.component';
import { ModalCellComponent } from '../../components/parts/fund/modal-cell/modal-cell/modal-cell.component';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { takeWhile } from 'rxjs';
import { isNull } from 'lodash-es';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { IEditResourceAvailabilityCondition } from 'src/app/data-models/resource-availability-condition/edit-resource-availability-condition.interface';
import { DataFundService } from './data-fund.service';

type TModals = ModalGeneralComponent | ModalSelectResourceComponent | ModalCellComponent;

@Injectable({
  providedIn: 'root',
})
export class ModalFundService {
  dataInstance!: IDataInstance;
  isShow!: boolean;
  initData!: () => void;
  open!: (comp: Type<TModals>) => ComponentRef<TModals>;
  cdr!: ChangeDetectorRef;
  dataMap!: Map<string, IEditResourceAvailabilityCondition[]>;

  constructor(private dataFundService: DataFundService) {}

  openModal(row?: IResourceAvailabilityTransform, index?: number, isCopy = false): void {
    let isAlive = true;
    const modalComponent = this.open(ModalGeneralComponent) as ComponentRef<ModalGeneralComponent>;

    modalComponent.instance.dataInstance = this.dataInstance;
    modalComponent.instance.isCopy = isCopy;
    modalComponent.instance.updateData = this.initData;

    if (row) modalComponent.instance.data = row;
    if (index || index === 0) modalComponent.instance.currentIndex = index;

    modalComponent.instance.openSubject.pipe(takeWhile(() => isAlive)).subscribe((res) => {
      isAlive = false;
      if (!isNull(res)) return;
      const data = modalComponent.instance.data;
      const isCopy =
        modalComponent.instance.toggleControl.value === 'Редактирование' ? false : true;

      const currentIndex = modalComponent.instance.currentIndex;

      this.openModalSelectResource(data, currentIndex, isCopy);
      this.cdr.markForCheck();
    });
  }

  openModalSelectResource(data: IResourceAvailabilityTransform, index: number, isCopy: boolean) {
    let isAlive = true;
    const modalComponent = this.open(
      ModalSelectResourceComponent,
    ) as ComponentRef<ModalSelectResourceComponent>;

    modalComponent.instance.data = data;
    modalComponent.instance.openSubject.pipe(takeWhile(() => isAlive)).subscribe(() => {
      isAlive = false;
      const data = modalComponent.instance.data;
      this.openModal(data, index, isCopy);
      this.cdr.markForCheck();
    });
  }

  openModalCell(
    row?: IResourceAvailabilityTransform,
    item?: IDataColumnTable,
    index?: number,
  ): void {
    const modalComponent = this.open(ModalCellComponent) as ComponentRef<ModalCellComponent>;

    modalComponent.instance.dataInstance = this.dataInstance;
    modalComponent.instance.isShow = this.isShow;
    modalComponent.instance.updateData = this.initData.bind(this);

    if (this.dataMap.size > 0) {
      modalComponent.instance.data = Array.from(this.dataMap, ([_, value]) => value);
    } else {
      if (row && item)
        modalComponent.instance.data = [
          this.dataFundService.getCurrentValue(row, item, this.dataInstance.dateStarted),
        ];
      if (index || index === 0) modalComponent.instance.currentRow = index;
      if (item) modalComponent.instance.currentDay = +item.display;
    }
  }
}
