<div class="modal-journal-list-short-print-root">
  <calendar-plan-modal-journal-list-short-print-header
    [isOperationalPlan]="isOperationalPlan"
    [form]="formCheckbox"
    [journal]="journal"
  />
  <calendar-plan-modal-journal-list-short-print-list
    [isOperationalPlan]="isOperationalPlan"
    [journal]="journal"
    (setFormGroup)="getFormGroup($event)"
  />
</div>
