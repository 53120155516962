import { Pipe, PipeTransform } from '@angular/core';
import { IResultMiningPlanItem } from 'src/app/data-models/result-mining-plan-item/result-mining-plan-item.interface';

@Pipe({
  name: 'resultMiningPlanColorCell',
  standalone: true,
})
export class ResultMiningPlanColorCellPipe implements PipeTransform {
  transform(key: string, day: number, mapKeyId: Map<string, IResultMiningPlanItem[]>): boolean {
    const value = mapKeyId.get(key + day);

    if (!value || !value.length) return false;

    return value[0].direction === 'TK2' || value[0].direction === 'TK1';
  }
}
