import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { MatTableModule } from '@angular/material/table';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { IMockDataSelectResource } from '../constants/mockData.interface';
import { mockData } from '../constants/mock';
import { IResourceAvailabilityTransform } from 'src/app/data-models/resource-availability/resource-availability.interface';

@Component({
  selector: 'modal-select-resource-table',
  templateUrl: './modal-select-resource-table.component.html',
  styleUrls: ['./modal-select-resource-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatTableModule, TextComponent],
})
export class ModalSelectResourceTableComponent implements OnInit {
  @Input() data!: IResourceAvailabilityTransform;
  @Input() mapValue!: Map<string, boolean>;
  dataSource!: IMockDataSelectResource[];

  columns: IDataColumnTable[] = [
    { name: 'type', display: '' },
    { name: 'generalWarehouse', display: 'Общий склад Рядового' },
    { name: 'productWarehouse', display: 'Общий склад Продукта' },
    { name: 'area', display: 'Участок погрузки' },
    { name: 'enrichmentTk1', display: 'Обогащение на ТК1' },
    { name: 'enrichmentTk2', display: 'Обогащение на ТК2' },
    { name: 'wagonsUFTracks', display: 'Вагоны на путях ОФ' },
    { name: 'wagonsLoadingTracks', display: 'Вагоны на путях участка погрузки' },
  ];

  listRow!: { display: string; value: string }[];

  listReleaseMode = [
    { display: 'Обогащение на ТК1', value: 'TK1' },
    { display: 'Обогащение на ТК2', value: 'TK2' },
  ];

  listCapacityKP = [
    { display: 'Общий склад Рядового', value: 'HUB' },
    { display: 'Общий склад Продукта', value: 'SGP' },
    { display: 'Участок погрузки', value: 'DSK' },
  ];

  displayedColumns = this.columns.map((col) => col.name);

  ngOnInit(): void {
    this.initTable();

    // this.dataSource = mockData;
    this.data.resourceCode
      ?.split(';')
      .map((item) => item.trim())
      .forEach((item) => {
        this.mapValue.set(item, true);
      });
  }

  initTable(): void {
    if (this.data.task === 'КП') {
      switch (this.data.ruleType) {
        case 'Поток':
          this.dataSource = mockData;
          break;

        case 'Выпуск':
        case 'Режим':
          this.listRow = this.listReleaseMode;
          break;

        case 'Емкость':
          this.listRow = this.listCapacityKP;
          break;

        default:
          this.dataSource = mockData;
          break;
      }
    } else {
      switch (this.data.ruleType) {
        case 'Поток':
          this.dataSource = mockData;
          break;

        case 'Выпуск':
        case 'Режим':
          this.listRow = this.listReleaseMode;
          break;

        case 'Емкость':
          this.listRow = this.listCapacityKP;
          break;

        default:
          this.dataSource = mockData;
          break;
      }
    }
  }

  checkActiveValue(key: string, value: string): boolean {
    if (key === 'type' || !value) return false;
    return !!this.mapValue.get(value);
  }

  selectValue(key: string, value: string): void {
    if (key === 'type' || !value) return;
    this.mapValue.get(value) ? this.mapValue.delete(value) : this.mapValue.set(value, true);
    this.data.resourceCode = Array.from(this.mapValue, ([key, _]) => key).join('; ');
  }
}
