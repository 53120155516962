import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from '../../../ui/components/text/text.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ControlDirective } from '../../directives/control.directive';
import { ControlErrorComponent } from '../control-error/control-error.component';

@Component({
  selector: 'nguk-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TextComponent,
    ReactiveFormsModule,
    MatSelectModule,
    ControlErrorComponent,
  ],
})
export class SelectComponent extends ControlDirective {
  @Input() options: { value: any; label: string }[] = [];
  @Input() multiple!: boolean;
  @Input() compareWithFn: (o1: any, o2: any) => boolean = (o1: any, o2: any) => o1 === o2;
  @HostBinding('class.nguk-select') readonly selectBaseClass = true;
}
