import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTable, MatTableModule } from '@angular/material/table';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { SectionComponent } from 'src/app/modules/ui/components/section/section.component';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CdkDropList, CdkDrag, CdkDragDrop } from '@angular/cdk/drag-drop';
import { EditableTextDirective } from 'src/app/modules/forms/directives/editable-text.directive';
import { NsiRowActionsComponent } from '../nsi-row-actions/nsi-row-actions.component';
import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { DictionaryColumnModel } from '../../../../../data-models/dictionary/dictionary-column.model';
import { ToStringPipe } from '../../../../../core/pipes/to-string.pipe';
import { Utils } from '../../../../../helpers/utils';
import { NsiDictionaryModel } from '../../../../../data-models/dictionary/dictionary.model';
import { forkJoin, mergeMap, Observable, of, take, tap } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SvgIconsEnum } from '../../../../../core/enums/svg-icons.enum';
import { EditListModeEnum } from '../../../enums/edit-list-mode.enum';
import { DictionaryRowModel } from '../../../../../data-models/dictionary/dictionary-row.model';
import { IDataInstance } from '../../../../../data-models/enrichment-standard/data-instance.interface';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { SpinnerService } from '../../../../ui/services/spinner.service';
import { NsiService } from '../../../services/nsi.service';
import { DictionaryRowItemModel } from '../../../../../data-models/dictionary/dictionary-row-item.model';
import { ICalculationLog } from '../../../../../data-models/calculation-log/calculation-log.interface';
import { NsiContainerButtonsComponent } from '../nsi-container-buttons/nsi-container-buttons.component';
import { NsiTableService } from '../../../services/nsi-table.service';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';

@Component({
  selector: 'nsi-table-list-type',
  templateUrl: './nsi-table-list-type.component.html',
  styleUrls: ['./nsi-table-list-type.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SectionComponent,
    ButtonComponent,
    TextComponent,
    MatTableModule,
    MatIconModule,
    MatMenuModule,
    EditableTextDirective,
    NsiRowActionsComponent,
    CdkDropList,
    CdkDrag,
    PortalModule,
    ToStringPipe,
    NsiContainerButtonsComponent,
  ],
})
export class NsiTableListTypeComponent implements OnInit {
  @ViewChild('tableContainer', { read: ElementRef }) tableContainer!: ElementRef<HTMLDivElement>;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(CdkPortal) contentTemplate!: CdkPortal;
  @ViewChildren(EditableTextDirective) editableNodes!: QueryList<EditableTextDirective>;

  readonly svgIconsEnum = SvgIconsEnum;
  readonly editListModeEnum = EditListModeEnum;

  data!: DictionaryRowModel[];
  isBlockedToEdit!: boolean;
  dictionary!: NsiDictionaryModel;
  actionsRowId!: string | null;
  dataInstance: IDataInstance = {} as any;
  listMode: EditListModeEnum = EditListModeEnum.View;
  definedColumns: DictionaryColumnModel[] = [];
  displayedColumns: string[] = ['index'];

  get headerRowEditableNodes(): EditableTextDirective[] {
    return this.editableNodes.filter(
      (node: EditableTextDirective) => node.nativeElement.nodeName === 'TH',
    );
  }

  get dataRowsEditableNodes(): EditableTextDirective[] {
    return this.editableNodes.filter(
      (node: EditableTextDirective) => node.nativeElement.nodeName === 'TD',
    );
  }

  private overlayRef!: OverlayRef | null;

  constructor(
    private cdr: ChangeDetectorRef,
    private overlay: Overlay,
    private ss: SpinnerService,
    private router: Router,
    private nsiService: NsiService,
    private activatedRoute: ActivatedRoute,
    private normativeInfoService: NsiService,
    private nsiTableService: NsiTableService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        tap(() => this.ss.startSpinner()),
        mergeMap((params: Params) =>
          this.normativeInfoService.getDictionary(params['dictionaryId']),
        ),
        take(1),
      )
      .subscribe(this.handleDictionary);
  }

  reloadList(): void {
    this.ss.startSpinner();
    this.normativeInfoService
      .getDictionary(this.dictionary!.id)
      .pipe(take(1))
      .subscribe(
        (dictionary: NsiDictionaryModel) => this.handleDictionary(dictionary),
        this.ss.stopSpinner,
      );
  }

  changeListMode(listMode: EditListModeEnum): void {
    if (this.listMode === listMode) {
      return;
    }

    if (this.listMode === EditListModeEnum.EditStructure) {
      this.switchFromEditStructureMode();
    } else if (this.listMode === EditListModeEnum.EditData) {
      this.switchFromEditDataMode();
    }

    this.listMode = listMode;
    this.cdr.detectChanges();
    if (listMode === EditListModeEnum.EditStructure) {
      this.switchToEditStructureMode();
    } else if (listMode === EditListModeEnum.EditData) {
      this.switchToEditDataMode();
    }

    this.cdr.detectChanges();
  }

  saveChanges(): void {
    if (this.listMode === EditListModeEnum.EditStructure) {
      this.saveFromEditStructureMode();
    } else if (this.listMode === EditListModeEnum.EditData) {
      this.changeListMode(EditListModeEnum.View);
    }
  }

  handleDuplicate(): void {
    this.data = this.nsiTableService.duplicateRow(this.data, this.actionsRowId!);
  }

  handleDelete(): void {
    this.data = Utils.sortBy(
      this.data.filter((row: DictionaryRowModel) => (row.id as any) !== this.actionsRowId),
      'id',
    );
    this.cdr.detectChanges();
  }

  handleMouseLeaveRow(event?: MouseEvent): void {
    if (this.nsiTableService.shouldLeaveRow(this.listMode, this.overlayRef!, event!)) {
      return;
    }

    this.overlayRef!.detach();
    this.actionsRowId = null;
  }

  handleMouseEnterRow(row: any, rowIndex: number): void {
    if (!0) {
      return;
    }

    if (this.listMode !== EditListModeEnum.EditStructure || this.actionsRowId) {
      return;
    }

    const rowElement = document.querySelectorAll('.mat-mdc-row').item(rowIndex);
    this.overlayRef = this.overlay.create(this.getOverlayConfig(rowElement));
    this.overlayRef.attach(this.contentTemplate);
    this.actionsRowId = row.id;
  }

  getOverlayConfig(rowElement: any): OverlayConfig {
    return this.nsiTableService.getOverlayConfig(this.overlay, rowElement);
  }

  onRowsReorder(event: CdkDragDrop<string[]>): void {
    this.nsiTableService.onRowsReorder(this.table, this.data, event);
    this.cdr.detectChanges();
  }

  addRow(): void {
    this.data = this.nsiTableService.addRowAtEnd(this.data);
  }

  private handleDictionary = (dictionary: NsiDictionaryModel): void => {
    this.dictionary = dictionary;
    this.setColumns(dictionary);
    forkJoin([
      this.normativeInfoService.getCalculationLogs(),
      this.normativeInfoService.getDataInstanceById(dictionary.dataInstance.id),
    ])
      .pipe(take(1))
      .subscribe(this.handleLogsAndInstance);
    this.getDictionaryRowItems(dictionary);
  };

  private getDictionaryRowItems = (dictionary: NsiDictionaryModel): void => {
    this.ss.startSpinner();
    this.cdr.detectChanges();
    const requestsForDictionaryRowItems: Observable<DictionaryRowItemModel[]>[] = [];
    dictionary.dictionaryRows.forEach((dictionaryRow: DictionaryRowModel) => {
      const request = this.normativeInfoService.getDictionaryRowItems(dictionaryRow.id);
      requestsForDictionaryRowItems.push(request);
    });

    forkJoin(requestsForDictionaryRowItems.length ? requestsForDictionaryRowItems : of([] as any))
      .pipe(take(1))
      .subscribe(this.handleDictionaryRowItems, this.ss.stopSpinner);
  };

  private handleDictionaryRowItems = (rowItems: DictionaryRowItemModel[][]): void => {
    this.dictionary?.dictionaryRows.forEach((dictionaryRow: any, index: number) => {
      dictionaryRow.items = rowItems[index].sort(
        (lhs: DictionaryRowItemModel, rhs: DictionaryRowItemModel) => lhs.ordinal - rhs.ordinal,
      );
    });
    this.data = Utils.sortBy(this.dictionary.dictionaryRows, 'id');
    this.ss.stopSpinner();
    this.cdr.detectChanges();
  };

  private handleLogsAndInstance = ([calculationLogs, dataInstance]: [
    IJournal[],
    IDataInstance,
  ]): void => {
    this.dataInstance = dataInstance;
    const calculationLogMap = new Map<string, boolean>();
    calculationLogs.forEach((item) => {
      calculationLogMap.set(item.id, !!item.productionPlans.length);
    });
    this.isBlockedToEdit = this.dataInstance.calculationLog.some((item) =>
      calculationLogMap.get(item.id),
    );
  };

  private switchFromEditStructureMode(): void {
    this.handleMouseLeaveRow();
    const newColumns = this.definedColumns.filter((column) =>
      column.id
        ? typeof column.id === 'number'
          ? true
          : !column.title.includes('Столбец')
        : !column.title.includes('Столбец'),
    );
    const newRows = this.data.filter((row: any) => {
      return Object.values(row).some((value) => !!String(value)?.trim?.());
    });

    this.definedColumns = newColumns;
    this.displayedColumns = ['index', ...newColumns.map((column) => String(column.id))];
    const rows = [...Utils.sortBy(this.data, 'id')];
    this.data = [];
    this.cdr.detectChanges();
    this.nsiService
      .saveDictionaryInternalChanges(this.dictionary, this.definedColumns, rows)
      .pipe(take(1))
      .subscribe(() => this.reloadList());
  }

  private switchToEditStructureMode(): void {
    const newColumns = Array.from(Array(50 - this.definedColumns.length), (_, index: number) => ({
      id: `column-#${this.definedColumns.length + index + 1}`,
      title: `Столбец ${this.definedColumns.length + index + 1}`,
      ordinal: this.definedColumns.length + index + 1,
    }));

    this.definedColumns = [...this.definedColumns, ...newColumns] as any;
    this.displayedColumns = [...this.displayedColumns, ...newColumns.map((column) => column.id)];
    this.cdr.detectChanges();
    this.headerRowEditableNodes.forEach((node: EditableTextDirective) =>
      node.changeViewToEditable(),
    );
  }

  private saveFromEditStructureMode(): void {
    this.headerRowEditableNodes.forEach((node: EditableTextDirective) => node.checkIsValidState());
    if (
      this.headerRowEditableNodes.some((node: EditableTextDirective) => !node.checkIsValidState())
    ) {
      return;
    }

    this.editableNodes
      .filter((node: EditableTextDirective) => node.nativeElement.nodeName === 'TH')
      .forEach((node: EditableTextDirective) => node.changeViewToReadable());
    this.changeListMode(EditListModeEnum.View);
  }

  private switchFromEditDataMode(): void {
    this.dataRowsEditableNodes.forEach((node: EditableTextDirective) =>
      node.changeViewToReadable(),
    );
    const rows = [...Utils.sortBy(this.data, 'id')];
    this.data = [];
    this.cdr.detectChanges();
    this.nsiService
      .saveDictionaryInternalChanges(this.dictionary, this.definedColumns, rows)
      .pipe(take(1))
      .subscribe(() => this.reloadList());
  }

  private switchToEditDataMode(): void {
    this.dataRowsEditableNodes.forEach((node: EditableTextDirective) =>
      node.changeViewToEditable(),
    );
  }

  private setColumns = (dictionary: NsiDictionaryModel): void => {
    const columns = Utils.sortBy(dictionary.dictionaryColumns, 'ordinal');
    this.definedColumns = [...columns];
    this.displayedColumns = [
      'index',
      ...this.definedColumns.map((column: DictionaryColumnModel) => String(column.id)),
    ];
  };
}
