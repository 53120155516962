import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationModel } from '../models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private readonly notificationSource = new BehaviorSubject<NotificationModel | null>(null);

  notifications$ = this.notificationSource.asObservable();

  showNotification(notification: NotificationModel): void {
    this.notificationSource.next(notification);
  }
}
