import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';

type StyleNameType =
  | 'text-xs'
  | 'text-sm'
  | 'text-base'
  | 'text-lg'
  | 'text-xl'
  | 'text-2xl'
  | 'text-3xl'
  | 'text-4xl'
  | 'text-5xl';

@Component({
  selector: 'nguk-text',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./text.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent implements OnInit {
  private static FONT_WEIGHT_PREFIX = 'nguk-text-';

  @Input() weight: '400' | '500' | '600' | '700' = '500';
  @Input() styleName: StyleNameType = 'text-base';
  @Input() color: 'black' | 'grey' | 'blue' = 'black';

  @HostBinding('class.nguk-text') baseClass = true;

  constructor(
    public elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(
      this.elementRef.nativeElement,
      `${TextComponent.FONT_WEIGHT_PREFIX}${this.weight}`,
    );
    this.renderer.addClass(this.elementRef.nativeElement, `${this.styleName}`);
    this.renderer.addClass(this.elementRef.nativeElement, `${this.color}`);
  }
}
