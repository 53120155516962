import { Injectable } from '@angular/core';
import { CustomerApiService } from '../../api/customer.api.service';
import { StationApiService } from '../../api/station.api.service';
import { TrainPickingApiService } from '../../api/train-picking.api.service';
import { DictionariesEnum } from '../enums/dictionaries.enum';
import { Observable, of, take, tap } from 'rxjs';
import { IDictionaryModel } from '../../data-models/dictionary-model/dictionary-model.interface';
import { MarkApiService } from 'src/app/api/dictionaries/mark.api.service';
import { MaterialTypeApiService } from 'src/app/api/dictionaries/material-type.api.service';
import { WarehouseApiService } from 'src/app/api/dictionaries/warehouse.api.service';
import { LayerApiService } from 'src/app/api/dictionaries/layer.api.service';
import { AreaApiService } from 'src/app/api/dictionaries/area.api.service';
import { ProfileApiService } from 'src/app/api/dictionaries/profile.api.service';
import { WarehouseForOperatingPlanApiService } from 'src/app/api/dictionaries/warehouseForOperatingPlan.api.service';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  readonly dictionaries = new Map<DictionariesEnum, IDictionaryModel[]>([]);

  constructor(
    private customerApiService: CustomerApiService,
    private stationApiService: StationApiService,
    private trainPickingApiService: TrainPickingApiService,
    private markApiService: MarkApiService,
    private materialTypeApiService: MaterialTypeApiService,
    private warehouseApiService: WarehouseApiService,
    private layerApiService: LayerApiService,
    private areaApiService: AreaApiService,
    private profileApiService: ProfileApiService,
    private warehouseForOperatingPlanApiService: WarehouseForOperatingPlanApiService,
  ) {}

  getDictionary(
    dictionaryName: DictionariesEnum,
    searchText: string,
  ): Observable<IDictionaryModel[]> {
    let dictionaryRequest;
    const query = [
      {
        name: 'title',
        value: searchText,
      },
      {
        name: 'itemsPerPage',
        value: 200,
      },
    ];

    switch (dictionaryName) {
      case DictionariesEnum.Customer: {
        dictionaryRequest = this.customerApiService.getList(1, query);
        break;
      }
      case DictionariesEnum.Station: {
        dictionaryRequest = this.stationApiService.getList(1, query);
        break;
      }
      case DictionariesEnum.TrainPicking: {
        dictionaryRequest = this.trainPickingApiService.getList(1, query);
        break;
      }
      case DictionariesEnum.Mark: {
        dictionaryRequest = this.markApiService.getList(1, query);
        break;
      }
      case DictionariesEnum.MaterialType: {
        dictionaryRequest = this.materialTypeApiService.getList(1, query);
        break;
      }
      case DictionariesEnum.Warehouse: {
        dictionaryRequest = this.warehouseApiService.getList(1, query);
        break;
      }
      case DictionariesEnum.Layer: {
        dictionaryRequest = this.layerApiService.getList(1, query);
        break;
      }
      case DictionariesEnum.Area: {
        dictionaryRequest = this.areaApiService.getList(1, query);
        break;
      }
      case DictionariesEnum.WarehouseForOperatingPlan: {
        dictionaryRequest = this.warehouseForOperatingPlanApiService.getList(1, query);
        break;
      }
      case DictionariesEnum.Profile: {
        dictionaryRequest = this.profileApiService.getList(1, query);
        break;
      }
      default: {
        dictionaryRequest = of([]);
        console.error(`DictionariesService >>> dictionaryName: ${dictionaryName} is unknown`);
      }
    }

    return dictionaryRequest.pipe(
      tap((dictionaryItems: IDictionaryModel[]) => {
        this.dictionaries.set(dictionaryName, dictionaryItems);
      }),
      take(1),
    );
  }

  createOption(dictionaryName: DictionariesEnum, title: string): Observable<IDictionaryModel> {
    let dictionaryRequest;

    switch (dictionaryName) {
      case DictionariesEnum.Customer: {
        dictionaryRequest = this.customerApiService.create({ title });
        break;
      }
      case DictionariesEnum.Station: {
        dictionaryRequest = this.stationApiService.create({ title });
        break;
      }
      case DictionariesEnum.TrainPicking: {
        dictionaryRequest = this.trainPickingApiService.create({ title });
        break;
      }
      case DictionariesEnum.Mark: {
        dictionaryRequest = this.markApiService.create({ title });
        break;
      }
      case DictionariesEnum.MaterialType: {
        dictionaryRequest = this.materialTypeApiService.create({ title });
        break;
      }
      case DictionariesEnum.Warehouse: {
        dictionaryRequest = this.warehouseApiService.create({ title });
        break;
      }
      case DictionariesEnum.Layer: {
        dictionaryRequest = this.layerApiService.create({ title });
        break;
      }
      case DictionariesEnum.Area: {
        dictionaryRequest = this.areaApiService.create({ title });
        break;
      }
      case DictionariesEnum.Profile: {
        dictionaryRequest = this.profileApiService.create({ title });
        break;
      }
      default: {
        dictionaryRequest = of([]) as Observable<any>;
        console.error(`DictionariesService >>> dictionaryName: ${dictionaryName} is unknown`);
      }
    }

    return dictionaryRequest;
  }

  getDictionaryOption = (dictionaryName: DictionariesEnum, title: string): IDictionaryModel => {
    return this.dictionaries
      .get(dictionaryName)!
      .find((dictionaryItem: IDictionaryModel) => dictionaryItem.title === title)!;
  };
}
