import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionModal } from 'src/app/modules/ui/base/action-modal';
import { MatTableModule } from '@angular/material/table';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { IDataColumnTable } from 'src/app/data-models/data-column-table/data-column-table.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap, Observable, take, takeWhile, tap } from 'rxjs';
import { TextComponent } from 'src/app/modules/ui/components/text/text.component';
import { SectionComponent } from 'src/app/modules/ui/components/section/section.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from 'src/app/modules/ui/components/button/button.component';
import { ProductionPlanApiService } from 'src/app/api/production-plan.api.service';
import { IProductionPlan } from 'src/app/data-models/production-plan/production-plan.interface';
import * as moment from 'moment';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';
import { SpinnerService } from 'src/app/modules/ui/services/spinner.service';
import { ResultMiningPlanHorizontalLinePipe } from '../../../pipes/result-mining-plan/result-mining-plan-horizontal-line.pipe';
import { ResultMiningPlanBorderHiddenPipe } from '../../../pipes/result-mining-plan/result-mining-plan-border-hidden.pipe';
import { ResultMiningPlanValueCellPipe } from '../../../pipes/result-mining-plan/result-mining-plan-value-cell.pipe';
import { ResultMiningPlanColorCellPipe } from '../../../pipes/result-mining-plan/result-mining-plan-color-cell.pipe';
import { ThSortFilterComponent } from 'src/app/modules/ui/components/th-sort-filter/th-sort-filter.component';
import { WeekendDayPipe } from '../../../../../core/pipes/weekend-day.pipe';
import { ResultMiningPlanHorizontalLineTotalPipe } from '../../../pipes/result-mining-plan/result-mining-plan-horizontal-line-total.pipe';
import { ResultMiningPlanIndexPipe } from '../../../pipes/result-mining-plan/result-mining-plan-index.pipe';
import { OperationalPlanColorShiftPipe } from '../../../pipes/operational-plan-color-shift.pipe';
import { IGantt, IGanttTransform } from 'src/app/data-models/gantt/gantt.interface';
import { GanttApiService } from 'src/app/api/gantt.api.service';
import { OperationalPlanJournalApiService } from 'src/app/api/operational-plan-journal.api.service';

@Component({
  selector: 'calendar-plan-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    TextComponent,
    SectionComponent,
    MatIconModule,
    ButtonComponent,
    ResultMiningPlanHorizontalLinePipe,
    ResultMiningPlanBorderHiddenPipe,
    ResultMiningPlanValueCellPipe,
    ResultMiningPlanColorCellPipe,
    ThSortFilterComponent,
    WeekendDayPipe,
    ResultMiningPlanHorizontalLineTotalPipe,
    ResultMiningPlanIndexPipe,
    OperationalPlanColorShiftPipe,
  ],
})
export class GanttComponent extends ActionModal implements OnInit, OnDestroy, AfterViewInit {
  @Input() id!: string | undefined;

  @ViewChild('table', { read: ElementRef })
  table!: ElementRef<HTMLTableElement>;

  @Output() getCurrentTable = new EventEmitter();

  isShow = false;
  isAlive = true;

  data!: IGanttTransform[];
  mapKeyId = new Map<string, IGantt[]>();

  productionPlan!: IProductionPlan;
  journal!: IJournal;

  days!: number;
  amountHiddenCols = 0;

  listDatesForOP: string[] = [];
  displayedRowDatesForOP: string[] = [];

  correctOrder = [
    { label: 'ТК1', value: 'TK1' },
    { label: '15,16 (конц)', value: '15' },
    { label: '12,13 (отсев)', value: '12' },
    { label: '9', value: '9' },
    { label: '11', value: '11' },
    { label: '10', value: '10' },
    { label: 'ТК2', value: 'TK2' },
    { label: 'СГП. Резерв', value: 'СГП' },
  ];

  orderPriority = ['Режим', 'Марка', 'Заказ', 'На СГП', 'Резерв'];

  readonly svgIconsEnum = SvgIconsEnum;

  columnsHours!: IDataColumnTable[];
  columns: IDataColumnTable[] = [
    { name: 'resource', display: 'Ресурс' },
    { name: 'differentValue', display: 'Ресурс' },
  ];

  displayedColumns!: string[];

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private productionPlanApiService: ProductionPlanApiService,
    private ganttApiService: GanttApiService,
    private operationalPlanJournalApiService: OperationalPlanJournalApiService,
    private ss: SpinnerService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngOnInit(): void {
    this.route.params.pipe(takeWhile(() => this.isAlive)).subscribe((params) => {
      const idJournal =
        this.route.parent?.snapshot.params['calendarPlanId'] ||
        this.route.snapshot.params['calendarPlanId'];

      if (!idJournal) return;

      const id = params['ganttId'] || this.id;

      id ? this.getProductionPlan(id, idJournal) : this.initRedirect(idJournal);
    });
  }

  ngAfterViewInit() {
    this.getCurrentTable.emit(this.table.nativeElement);
  }

  getHours(value: number): number {
    return value % 24 || 24;
  }

  getProductionPlan(id: string, idJournal: string): void {
    this.ss.startSpinner();
    this.productionPlanApiService
      .getOne(id)
      .pipe(
        take(1),
        mergeMap((productionPlan: IProductionPlan) => {
          this.productionPlan = productionPlan;
          return this.operationalPlanJournalApiService.getOne(idJournal);
        }),
        tap((journal) => {
          this.journal = journal;
          this.setAmountsDays();
        }),
      )
      .subscribe(() => this.initData(id));
  }

  setAmountsDays() {
    this.days = moment(this.journal.datePlanFinished).diff(
      moment(this.journal.datePlanStarted),
      'hours',
    );

    const amountDates = Math.ceil(this.days / 24);
    this.listDatesForOP = [];
    for (let index = 1; index <= amountDates; index++) {
      this.listDatesForOP.push(`dateStarted${index}`);
    }

    this.displayedRowDatesForOP = ['empty', ...this.listDatesForOP];
  }

  getAmountColsForDate(index: number): number {
    const maxLastIndex = index * 24;
    if (maxLastIndex <= this.days) return 24;

    return maxLastIndex - this.days;
  }

  getDate(index: number): string {
    const firstDate = moment(this.journal.datePlanStarted).utcOffset(0, true);
    return firstDate.add(index, 'days').format('DD.MM.YY');
  }

  initRedirect(idJournal: string): void {
    this.ss.startSpinner();
    this.productionPlanApiService
      .getList(1)
      .pipe(take(1))
      .subscribe((res: IProductionPlan[]) => {
        const find = res.find(
          (item) => +item.calculationLog?.id === +idJournal && item.type === 'gannt',
        );
        if (find) this.router.navigate([find.id], { relativeTo: this.route });
      });
  }

  initData(id: string): void {
    this.ss.startSpinner();
    this.initColumnsDays();
    this.initDisplayedColumns();
    this.getDataList(id);
  }

  initDisplayedColumns(): void {
    this.displayedColumns = [...this.columns, ...this.columnsHours].map((col) => col.name);
  }

  initColumnsDays(): void {
    this.columnsHours = Array.from(Array(this.days), (_, index) => ({
      name: `c${index + 1}`,
      display: `${index + 1}`,
    }));
  }

  getDataList(id: string): void {
    this.ganttApiService
      .getList(1, [
        { name: 'productionPlan.id', value: id },
        { name: 'itemsPerPage', value: 500 * 10000 },
      ])
      .pipe(take(1))
      .subscribe(
        (data: IGantt[]) => {
          this.initTransformData(data);
          this.ss.stopSpinner();
        },
        () => this.ss.stopSpinner(),
      );
  }

  initTransformData(data: IGantt[]): void {
    this.mapKeyId.clear();

    const dataFormat = data.reduce((result: IGanttTransform[], item) => {
      const key = `${item.recordType ?? ''}_${item.resource ?? ''}_${item.booking ?? ''}`;

      const currentHour =
        moment(item.tactStartDate).diff(moment(this.journal.datePlanStarted), 'hours') + 1;

      const duration = moment(item.tactFinishDate).diff(moment(item.tactStartDate), 'hours') + 1;

      item.duration = duration;
      item.currentHour = currentHour;

      const currentKey = key + currentHour;
      const currentValue = this.mapKeyId.get(currentKey);

      if (currentValue) {
        currentValue.push(item);
        this.mapKeyId.set(currentKey, currentValue);
      } else {
        this.mapKeyId.set(currentKey, [item]);
      }

      const existingItem: IGanttTransform | undefined = result.find((group) => group.key === key);

      if (existingItem) {
        existingItem.values?.push(item);
      } else {
        const value = {
          key: key,
          resource: item.resource,
          booking: item.booking,
          mode: item.mode,
          recordType: item.recordType,
          values: [item],
        };

        result.push(value);
      }

      return result;
    }, []);

    console.log(dataFormat);

    const valueSorting = dataFormat.sort(
      (a, b) => this.orderPriority.indexOf(a.recordType) - this.orderPriority.indexOf(b.recordType),
    );

    this.data = this.correctOrder.reduce((acc: IGanttTransform[], item) => {
      const finded = valueSorting
        .filter((el) => el.resource.includes(item.value))
        .map((el) => {
          el.label = item.label;
          return el;
        });
      acc.push(...finded);

      return acc;
    }, []);

    this.cdr.detectChanges();
  }

  getResourceLabel(index: number): string {
    if (this.data[index - 1] && this.data[index].label === this.data[index - 1].label) return '';

    return this.data[index].label || '';
  }

  isHasBottomBorder(index: number): boolean {
    if (this.data[index + 1] && this.data[index].label === this.data[index + 1].label) return false;
    else return true;
  }

  getValueInHour(row: IGanttTransform, hour: string): string {
    const value = this.mapKeyId.get(row.key + hour);

    if (!value) return '';
    if (value.length > 1) return 'check!';

    return value[0].recordType.toLocaleLowerCase() === 'заказ' ||
      value[0].recordType.toLocaleLowerCase() === 'резерв'
      ? value[0].wagonsCount?.toString() ?? '-'
      : value[0].weight
        ? `${value[0].mode}, ${Math.round((value[0].weight / 1000) * 100) / 100} тыс.т`
        : value[0].mode;
  }

  checkIsHidden(): boolean {
    if (this.amountHiddenCols) {
      this.amountHiddenCols--;
      return true;
    }

    return false;
  }

  getAmountCols(row: IGanttTransform, hour: string): number {
    if (hour === '1' || hour === this.days.toString()) this.amountHiddenCols = 0;

    const value = this.mapKeyId.get(row.key + hour);

    if (!value) return 1;

    const cols = value[0].duration as number;

    if (cols > 1) {
      this.amountHiddenCols = cols - 1;
    }

    return cols;
  }

  isLastCell(row: IGanttTransform, hour: string): boolean {
    if (+hour === this.days) return true;

    const value = this.mapKeyId.get(row.key + hour);
    if (!value) return false;

    const cols = value[0].duration as number;

    return +hour + cols >= this.days;
  }
}
