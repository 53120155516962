import { Pipe, PipeTransform } from '@angular/core';
import { IDynamicsTotalDay } from 'src/app/data-models/warehouse-dynamics-item/warehouse-dynamics.interface';

@Pipe({
  name: 'warehouseDynamicsTotalValueCell',
  standalone: true,
})
export class WarehouseDynamicsTotalValueCellPipe implements PipeTransform {
  transform(
    warehouse: string,
    day: number,
    totalValueMap: Map<string, IDynamicsTotalDay[]>,
  ): string {
    const currentValue = totalValueMap.get(warehouse);

    if (!currentValue) return '';

    return !currentValue[day].isHasDynamics
      ? +currentValue[day].amount > 0
        ? '——'
        : ''
      : currentValue[day].amount === '0'
        ? 'X'
        : currentValue[day].amount;
  }
}
