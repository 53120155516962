import { Pipe, PipeTransform } from '@angular/core';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { IJournal } from 'src/app/data-models/enrichment-standard/journal.interface';

@Pipe({
  name: 'checkCalculationComplete',
  standalone: true,
})
export class CheckCalculationCompletePipe implements PipeTransform {
  transform(
    dataInstance: IDataInstance,
    journalMap: Map<string, IJournal>,
    journalOPMap?: Map<string, IJournal>,
  ): boolean {
    return (
      dataInstance.calculationLog.some((item) => journalMap.has(item.id)) ||
      !!(journalOPMap && dataInstance.operationalPlan.some((item) => journalOPMap.has(item.id)))
    );
  }
}
