import { Injectable } from '@angular/core';
import { DictionaryRowModel } from '../../../data-models/dictionary/dictionary-row.model';
import { Utils } from '../../../helpers/utils';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { EditListModeEnum } from '../enums/edit-list-mode.enum';

@Injectable({ providedIn: 'root' })
export class NsiTableService {
  duplicateRow(data: DictionaryRowModel[], actionsRowId: string): DictionaryRowModel[] {
    const rowIndex = data.findIndex((row: any) => row.id === actionsRowId);
    const newRow = { ...data[rowIndex], id: Utils.getMaxValueByKey(data, 'id') + 1 } as any;

    return [...data, newRow];
  }

  addRowAtEnd(data: DictionaryRowModel[]): DictionaryRowModel[] {
    return Utils.sortBy([
      ...data,
      {
        id: data.length ? Utils.getMaxValueByKey(data, 'id') + 1 : 0,
        items: Array.from(Array(50), (_, index) => ({
          ordinal: index + 1,
          value: '',
        })),
      } as any
    ].filter(Boolean), 'id');
  }

  getOverlayConfig(overlay: Overlay, rowElement: any): OverlayConfig {
    const positionStrategy = overlay
      .position()
      .flexibleConnectedTo(rowElement)
      .withPush(true)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
          offsetX: -25,
        },
      ]);
    const scrollStrategy = overlay.scrollStrategies.reposition();

    return new OverlayConfig({
      positionStrategy: positionStrategy,
      scrollStrategy: scrollStrategy,
      hasBackdrop: false,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
  }

  onRowsReorder(table: MatTable<any>, data: DictionaryRowModel[], event: CdkDragDrop<string[]>): void {
    moveItemInArray(data, event.previousIndex, event.currentIndex);
    table.renderRows();
  }

  shouldLeaveRow(listMode: EditListModeEnum, overlayRef: OverlayRef, event: MouseEvent): boolean {
    return event && document.querySelector('.cdk-overlay-container')?.contains(event.relatedTarget as any) ||
      (listMode !== EditListModeEnum.EditStructure || !overlayRef);
  }
}
