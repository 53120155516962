import { HttpParams } from '@angular/common/http';

export class ApiRequestModel<T> {
  constructor(
    public path: string,
    public body?: T | unknown,
    public query?: HttpParams,
  ) {}

  addToPath(value: string | number): this {
    this.path += `/${value}`;

    return this;
  }

  addToQuery(
    param: string,
    value: boolean | string | number | { [key: string]: string } | string[],
  ): this {
    this.query = this.query
      ? this.query.set(param, typeof value === 'string' ? value : JSON.stringify(value))
      : new HttpParams().set(param, typeof value === 'string' ? value : JSON.stringify(value));

    return this;
  }

  addArrayToQuery(param: string, value: string[]): this {
    value.forEach((_) => {
      this.query = this.query
        ? this.query.has(`${param}%5B%5D`)
          ? this.query.append(`${param}%5B%5D`, JSON.stringify(_))
          : this.query.set(`${param}%5B%5D`, JSON.stringify(_))
        : new HttpParams().set(`${param}%5B%5D`, JSON.stringify(_));
    });

    return this;
  }
}
