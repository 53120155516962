<div class="calculations-list-root">
  <div class="sticky-container">
    <div class="container-button">
      <nguk-button type="secondary" class="refresh-button" (click)="checkCalculationStatuses()">
        <mat-icon [svgIcon]="svgIconsEnum.Refresh" />
      </nguk-button>
    </div>

    <table mat-table [dataSource]="taskLogs!">
      <ng-container [matColumnDef]="calculationsListColumnsEnum.Index">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs">#</nguk-text>
        </th>

        <td mat-cell *matCellDef="let row; let index = index">
          <nguk-text weight="400" styleName="text-xs">
            {{ index + 1 }}
          </nguk-text>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="calculationsListColumnsEnum.DateCreated">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs">Дата, время создания</nguk-text>
        </th>

        <td mat-cell *matCellDef="let row">
          <nguk-date-pair [date]="row.dateCreated">
            <ng-container left>
              {{ row.dateCreated | date: 'dd.MM' }}
            </ng-container>
            <ng-container right>
              {{ row.dateCreated | date: 'HH:mm' }}
            </ng-container>
          </nguk-date-pair>
          <nguk-text weight="400" styleName="text-xs"></nguk-text>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="calculationsListColumnsEnum.DateStarted">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs">Время начала расчёта</nguk-text>
        </th>

        <td mat-cell *matCellDef="let row">
          <nguk-date-pair [date]="row.dateStarted">
            <ng-container left>
              {{ row.dateStarted | date: 'dd.MM' }}
            </ng-container>
            <ng-container right>
              {{ row.dateStarted | date: 'HH:mm' }}
            </ng-container>
          </nguk-date-pair>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="calculationsListColumnsEnum.MaximumCalculationTime">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs"
            >Max время расчёта (сек)</nguk-text
          >
        </th>

        <td mat-cell *matCellDef="let row">
          <nguk-text weight="400" styleName="text-xs">
            {{ row.calculationLog?.maxDuration ?? row.operationalPlan?.maxDuration ?? '' }}
          </nguk-text>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="calculationsListColumnsEnum.DateFinished">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs">
            Прогноз времени окончания
          </nguk-text>
        </th>

        <td mat-cell *matCellDef="let row">
          <nguk-date-pair [date]="row.dateFinished">
            <ng-container left>
              {{ row.dateFinished | date: 'dd.MM' }}
            </ng-container>
            <ng-container right>
              {{ row.dateFinished | date: 'HH:mm' }}
            </ng-container>
          </nguk-date-pair>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="calculationsListColumnsEnum.Status">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs">Статус</nguk-text>
        </th>

        <td
          mat-cell
          *matCellDef="let row"
          (dblclick)="openModalStatus(row); $event.stopImmediatePropagation()"
        >
          <nguk-text weight="400" styleName="text-xs" class="noSelect">
            {{ row.status }}
          </nguk-text>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="calculationsListColumnsEnum.IdLog">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs">Идентификатор расчета</nguk-text>
        </th>

        <td mat-cell *matCellDef="let row; let index = index">
          <nguk-text weight="400" styleName="text-xs">
            {{ row['longId'] ?? plannerIds[index] }}
          </nguk-text>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="calculationsListColumnsEnum.Note">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs">Комментарий</nguk-text>
        </th>

        <td mat-cell *matCellDef="let row">
          <nguk-text weight="400" styleName="text-xs">
            {{ row.calculationLog?.note ?? row.operationalPlan?.note ?? '' }}
          </nguk-text>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="calculationsListColumnsEnum.ExternalWarnings">
        <th mat-header-cell *matHeaderCellDef>
          <nguk-text color="grey" weight="400" styleName="text-xs">Предупреждение</nguk-text>
        </th>

        <td mat-cell *matCellDef="let row">
          <nguk-text
            weight="400"
            styleName="text-xs"
            class="noSelect"
            [class.text-warning]="row.externalWarnings?.length"
            (dblclick)="openModalWarning(row)"
          >
            {{ row.externalWarnings?.length ? 'Есть' : 'Нет' }}
          </nguk-text>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="calculationsListColumnsEnum.Actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let row; let index = index">
          <div class="actions">
            <div class="container-icon">
              <mat-icon
                matTooltip="Обновить данные"
                [svgIcon]="svgIconsEnum.Refresh"
                (click)="reloadData(index)"
              />
            </div>
            <div class="container-icon">
              <mat-icon
                matTooltip="Исходные данные"
                [svgIcon]="svgIconsEnum.CloudUpload"
                (click)="downloadFile(index)"
              />
            </div>

            <div class="container-icon">
              <mat-icon
                matTooltip="Выходные данные"
                [svgIcon]="svgIconsEnum.CloudDownload"
                (click)="downloadFile(index, true)"
              />
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="calculationsListColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; let index = index; columns: calculationsListColumns"
        [class.hovering-calculation]="row.dateStarted | hoveringCalculation: row.status"
      ></tr>
    </table>
  </div>
</div>

<ng-template #modal />
