import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconsEnum } from 'src/app/core/enums/svg-icons.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { IWarehouseBalanceItem } from 'src/app/data-models/enrichment-standard/warehouse-balance.interface';
import { IDataInstance } from 'src/app/data-models/enrichment-standard/data-instance.interface';
import { ModalWarehouseBalanceArrayService } from 'src/app/modules/data/services/warehouse-balance/modal-warehouse-balance-array.service';
import { ModalActionsComponent } from 'src/app/modules/ui/components/modal-actions/modal-actions.component';

@Component({
  selector: 'modal-warehouse-balance-items-actions',
  templateUrl: './modal-warehouse-balance-items-actions.component.html',
  styleUrls: ['./modal-warehouse-balance-items-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalActionsComponent],
  providers: [ModalWarehouseBalanceArrayService],
})
export class ModalWarehouseBalanceItemsActionsComponent implements OnInit {
  @Input() formData!: FormGroup;
  @Input() data!: IWarehouseBalanceItem[];
  @Input() dataInstance!: IDataInstance;
  @Input() toggleControl!: FormControl<string | null>;

  @Output() readonly close = new EventEmitter<void>();
  @Output() readonly updateData = new EventEmitter<void>();

  readonly svgIconsEnum = SvgIconsEnum;

  isCopy = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalWarehouseBalanceArrayService: ModalWarehouseBalanceArrayService,
  ) {}

  ngOnInit(): void {
    this.toggleControl.valueChanges.subscribe((value) => {
      if (!value) return;
      this.isCopy = value.toLowerCase() === 'редактирование' ? false : true;
      this.cdr.detectChanges();
    });
  }

  initModalMiningPlanServiceData(service: ModalWarehouseBalanceArrayService): void {
    service.close = this.close;
    service.update = this.updateData;
    service.isCopy = this.isCopy;
    service.formData = this.formData;
    service.dataInstance = this.dataInstance;
    service.data = this.data;
  }

  initActionsData(): void {
    this.initModalMiningPlanServiceData(this.modalWarehouseBalanceArrayService);
  }

  save(): void {
    this.initActionsData();
    this.modalWarehouseBalanceArrayService.saveArray();
  }

  remove() {
    this.initActionsData();
    this.modalWarehouseBalanceArrayService.removeArray();
  }
}
